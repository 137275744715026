import React from 'react';
import {Content} from "carbon-components-react/es/components/UIShell";
import Loading from "../../components/Loading";
import SessionContext from "../../helpers/SessionContext";
import './main.scss';
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    TableExpandHeader,
    TableExpandRow,
    TableExpandedRow, TooltipDefinition
} from "carbon-components-react";
import Tests from "../../backend/Tests";
import RiskStatBars from "../../components/RiskStatBars";
import RiskHelper from "../../helpers/Risk";
import {Edit16, Launch16, DocumentAdd16} from "@carbon/icons-react";


class Main extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        headers: [
            {
                key: 'name',
                header: 'Test',
            },
            {
                key: 'project',
                header: 'Project',
            },
            {
                key: 'type',
                header: 'Type',
            },
            {
                key: 'start',
                header: 'Start Date',
            },
            {
                key: 'end',
                header: 'End Date',
            },
            {
                key: 'status',
                header: 'Status',
            },
            {
                key: 'findings',
                header: '# Findings',
            },
        ]
    };
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            data: {},
            rows: [],
            total: 0,
            loading: true, // skeleton components
            load_modal: false, // modal loading spinner
        };

        this._mounted = false;
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    componentDidMount() {
        this._mounted = true;

        Tests.list().then(d => {
            if (this._mounted && d) {
                if (!d.total) {
                    this.setState({rows: [], data: {}, total: 0, loading: false});
                    return;
                }
                const tests = Object.entries(d.tests);
                const rows = tests.map(([k, v]) => ({
                    id: k, name: v.name, type: v.type ? v.type.name : '', status: v.status_lbl,
                    project: v.project, start: v.start_date, end: v.end_date,
                    findings: v.report && v.report.summary && v.report.summary.findings ?
                        Object.keys(v.report.summary.findings).length : 0
                }));
                this.setState({rows: rows, data: d.tests, total: d.total, loading: false});
            }
        }).catch(this.context.networkFaultHandler);
    }
    render() {
        const { headers: propHeaders } = this.props;
        return (
            <Content className="xf-main">
                {this.state.load_modal && <Loading hasLogo={true} modal={true} message="Loading..." />}
                <div className="xft-wrap">
                    <div className="xft-head">
                        <h1>Requests and Tests</h1>
                    </div>
                    <div className="xft-list">
                        <div className="xft-ls-filter">
                            <Button size="sm" kind="ghost" onClick={() => {}}>Reset Filters</Button>
                        </div>
                        <div className="xft-ls-items">
                            <div className="xft-ls-head">
                                <SessionContext.Consumer>
                                    {s => s.user.authorised && s.user.internal ? <div>
                                        <br/><br/><br/>
                                        <h6>IBMer-specific header content</h6>
                                        <br/><br/><br/>
                                    </div> : <div>
                                        <br/><br/><br/>
                                        <h6>Client-specific header content</h6>
                                        <br/><br/><br/>
                                    </div>}
                                </SessionContext.Consumer>
                            </div>
                            <div className="xft-ls-tbl">
                                {this.state.loading ? <DataTableSkeleton columnCount={propHeaders.length + 3} /> :
                                    <DataTable rows={this.state.rows} headers={propHeaders} isSortable size="sm">
                                    {({
                                          rows, headers, getHeaderProps, getTableProps, getRowProps,
                                          onInputChange, getToolbarProps, getBatchActionProps
                                      }) => (
                                        <TableContainer stickyHeader={true}>
                                            <TableToolbar {...getToolbarProps()}>
                                                <TableToolbarContent>
                                                    <TableToolbarSearch
                                                        persistent={true} placeholder="Start typing to filter..."
                                                        tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                        onChange={onInputChange}
                                                    />
                                                </TableToolbarContent>
                                            </TableToolbar>
                                            <Table {...getTableProps()}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableExpandHeader enableExpando={false}/>
                                                        {headers.map((header) => (
                                                            <TableHeader
                                                                {...getHeaderProps({ header })}
                                                                className={'xfh-' + header.key}
                                                            >
                                                                {header.header}
                                                            </TableHeader>
                                                        ))}
                                                        <TableHeader className="xf-risk-head">Finding Risks</TableHeader>
                                                        <TableHeader/>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <React.Fragment key={row.id}>
                                                                <TableExpandRow {...getRowProps({ row })}>
                                                                {row.cells.map((cell) => (
                                                                    <TableCell
                                                                        key={cell.id}
                                                                        className={'xfc-' + cell.info.header}
                                                                    >{cell.value}</TableCell>
                                                                ))}
                                                                {(this.state.data[row.id].report && this.state.data[row.id].report.summary) ?
                                                                    <TableCell className="xf-risk-cell">
                                                                        <div className="xf-risk-itm">
                                                                            <RiskStatBars risks={this.state.data[row.id].report.summary.base_risk}/>
                                                                            <small className="bx--label">Base</small>
                                                                        </div>
                                                                        <div className="xf-risk-itm">
                                                                            <RiskStatBars risks={this.state.data[row.id].report.summary.contextual_risk}/>
                                                                            <small className="bx--label">Contextual</small>
                                                                        </div>
                                                                    </TableCell>
                                                                :
                                                                    <TableCell>
                                                                        <div>No Report</div>
                                                                    </TableCell>
                                                                }
                                                                <TableCell className="xf-act-wrap">
                                                                    <div className="xf-actions">
                                                                        <Button className="xff-act" tooltipPosition="left"
                                                                                hasIconOnly renderIcon={Launch16}
                                                                                size="sm" kind="ghost"
                                                                                iconDescription="Open Test"
                                                                                onClick={() => {}}
                                                                        />
                                                                        {this.state.data[row.id].report ? <Button
                                                                            className="xff-act xf-fin-edit"
                                                                            tooltipPosition="left"
                                                                            hasIconOnly renderIcon={Edit16}
                                                                            size="sm" kind="ghost"
                                                                            iconDescription="Edit Report"
                                                                            onClick={() => {}}
                                                                        /> : <Button
                                                                            className="xff-act xf-fin-edit"
                                                                            tooltipPosition="left"
                                                                            hasIconOnly renderIcon={DocumentAdd16}
                                                                            size="sm" kind="ghost"
                                                                            iconDescription="Create Report"
                                                                            onClick={() => {}}
                                                                        />}
                                                                    </div>
                                                                </TableCell>
                                                            </TableExpandRow>
                                                            <TableExpandedRow
                                                                colSpan={headers.length + 3}
                                                                className="demo-expanded-td">
                                                                {this.state.data[row.id].report ? <>
                                                                    <h3 className="bx--label">
                                                                        Findings for {this.state.data[row.id].report.title}
                                                                    </h3>
                                                                    <ul className="xff-ls">
                                                                        {Object.values(
                                                                            this.state.data[row.id].report.summary.findings
                                                                        ).map(finding =>
                                                                            <li key={finding.id}
                                                                                className={RiskHelper.getClass(
                                                                                    'xff-itm xfi', finding.contextual_risk
                                                                                )}
                                                                            >
                                                                                <h4>{finding.name}</h4>
                                                                                <small className="bx--label xfi-syn-lbl">
                                                                                    <TooltipDefinition
                                                                                        align="end" direction="top"
                                                                                        tooltipText={
                                                                                        <small className="bx--label xfi-sol-lbl">
                                                                                            <strong>Solution: </strong>
                                                                                            <br/>{finding.solution}
                                                                                        </small>
                                                                                    }>
                                                                                        <strong>Synopsis: </strong>
                                                                                    </TooltipDefinition>
                                                                                    <br/>{finding.synopsis}
                                                                                </small>

                                                                                <span className="xfi-risk-lbl">
                                                                                    <small className="bx--label xfr-base">
                                                                                        <strong>Base Risk: </strong>
                                                                                        {RiskHelper.getLabel(
                                                                                            finding.base_risk
                                                                                        )}
                                                                                    </small>
                                                                                    <small className="bx--label xfr-ctx">
                                                                                        <strong>Contextual Risk: </strong>
                                                                                        {RiskHelper.getLabel(
                                                                                            finding.contextual_risk
                                                                                        )}
                                                                                    </small>
                                                                                </span>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </> :
                                                                    <h3 className="bx--label">
                                                                        This test does not have a report yet
                                                                    </h3>}
                                                            </TableExpandedRow>
                                                        </React.Fragment>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </DataTable>}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
}

export default Main;