import {v4 as uuid4} from "uuid";
import Compressor from "compressorjs";
import DocHelper from "../Document";
import {PROP_TYPES} from "../../document/il";

export default class ImageHelper {
    static processImageFiles = (initCallback, updateCallback, finishCallback, addToast, ...files) => {
        const _files = {}, compress = [];
        const objectIn = files.length && !(files[0] instanceof File);

        const addFile = (uuid, file, invalid, merge = {}) => {
            _files[uuid] = {
                ...merge, uuid: uuid, file: file, name: file.name, type: file.type, valid: !invalid,
                error: invalid ? 'Invalid attachment' : null, message: invalid ? 'Unsupported type' : null,
                status: invalid ? 'edit' : 'uploading',  // 'uploading' 'edit' 'complete'
            }

            if (invalid) {
                addToast('error', 'Invalid attachment', 'Only image file types are currently supported as attachments', 'Please try again');
                return false;
            }

            compress.push(_files[uuid]);
            return true;
        };

        if (objectIn) {
            for (const [ key, val ] of Object.entries(files[0]))
                addFile(key, val.file, !val || !val?.file?.type?.match || !val.file.type.match(/image.*/), val);
        } else {
            for (const file of files)
                addFile(uuid4(), file, !file || !file?.type?.match || !file.type.match(/image.*/));
        }

        initCallback(_files);

        const count = {i: 0}, length = compress.length;
        const fin = () => finishCallback(objectIn ? _files : Object.values(_files));

        if (!length) return fin();

        for (const file of compress) {
            const size = {width: 0, height: 0};
            new Compressor(file.file, {
                strict: true,
                quality: 1,
                checkOrientation: true,
                maxWidth: 4096,
                maxHeight: 4096,

                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                drew(context, canvas) {
                    // probably an easier way to get the dimensions, but this
                    // saves extra calculations as the canvas has it anyway :)
                    size.width = canvas.width;
                    size.height = canvas.height;
                },
                success(result) {
                    const updates = {
                        status: 'complete', file: result, valid: true, width: size.width, height: size.height
                    };
                    _files[file.uuid] = {..._files[file.uuid], ...updates};
                    updateCallback(file.uuid, updates);
                    count.i++;
                    if (count.i >= length) fin();
                },
                error(err) {
                    const updates = {
                        status: 'edit', invalid: true, error: err.name, message: err.message
                    };
                    _files[file.uuid] = {..._files[file.uuid], ...updates};
                    updateCallback(file.uuid, updates);
                    addToast('error', err.name, err.message, 'Image processing error');
                    count.i++;
                    if (count.i >= length) fin();
                },
            });
        }
    };
    static buildImageBlock = (file, ctx, caption = true) => {
        const maxMM = ctx.width - (ctx.margin * 4), ppi = ctx.ppi;
        const wMM = DocHelper.mmFromPixels(file.width, ppi);
        const hMM = DocHelper.mmFromPixels(file.height, ppi);

        // if (wMM > maxMM) {
        //     // widthT = heightT * aspectRatio
        //     // heightT = widthT / aspectRatio
        //     const aspect = wMM / hMM;
        //     const newHMM = maxMM / aspect;
        // }

        const width = (wMM > maxMM) ? DocHelper.mmToEmu(maxMM) : DocHelper.mmToEmu(wMM);
        const height = (wMM > maxMM) ? DocHelper.mmToEmu(maxMM / (wMM / hMM)) : DocHelper.mmToEmu(hMM);

        // {
        //     image: TYPES.STRING, background: TYPES.BOOL, size: TYPES.LIST,
        //     offset: TYPES.LIST, anchor: TYPES.ANCHOR, wrap: TYPES.WRAP,
        //     behind: TYPES.BOOL, overlap: TYPES.BOOL, lock: TYPES.BOOL,
        //     zIndex: TYPES.NUMBER, inCell: TYPES.BOOL, margins: TYPES.OBJECT
        // }
        if (!file.base) {
            console.error('File base not found', file);
            return null;
        }
        const image = {
            type: PROP_TYPES.IMAGE, size: [width, height], align: DocHelper.ALIGN.CENTER, label: file.name,
            background: false, behind: false, overlap: false, lock: false, inCell: false,
            image: file.base.startsWith('data:') ? file.base : (`data:${file.type};base64,` + file.base)
        };

        if (!caption) return image;

        const cap = {
            type: PROP_TYPES.PARAGRAPH, style: DocHelper.STYLE.CAPTION, align: DocHelper.ALIGN.CENTER,
            runs: [{type: PROP_TYPES.TEXT, text: file.name}]
        };

        return [image, cap];
    };
}
