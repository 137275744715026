import { ILNode, RowNode } from "../../Nodes";


export default class TableCellNode extends ILNode {
    static defaultChild = RowNode;

    rerender = (callback = () => {}) => this.parent?.rerender && this.parent?.rerender(callback);

    get isFirstCell() {
        return !(this?.prev || this?.parent?.prev);
    }

    get isLastCell() {
        return !(this?.next || this?.parent?.next);
    }

    get siblingAbove() {
        if (!this?.parent?.prev) return undefined;
        const row = this.parent.prev;
        const idx = this.idx;
        const children = row.children;
        return (idx >= children.length) ? undefined : children[idx];
    }

    get siblingBelow() {
        if (!this?.parent?.next) return undefined;
        const row = this.parent.next;
        const idx = this.idx;
        const children = row.children;
        return (idx >= children.length) ? undefined : children[idx];
    }
}