import { PROP_TYPES } from "..";
import {DebugConsole} from "../../../helpers/Utility";
import { ILNode, RunNode } from "../Nodes";


export default class RowNode extends ILNode {
    static defaultChild = RunNode;
    static updateTimeout = 250;

    constructor(...args) {
        super(...args);

        // TODO: Decide: onChange = live updates, onBlur = potential speed improvement
        this.baseProps['ceProps'] = {
            onChange: this.onChange,
            onBlur: this.onBlur,
            onMouseLeave: this.onMouseLeave,
        };

        this.updateTimer = 0;
    }

    focus = (rightAlign = true) => {
        if (this?.ref?.current?.isEditing) {
            if (this.ref.current.isEditing()) this.focusRef(rightAlign);
            else this.ref.current.startEdit(() => this.focusRef(rightAlign));
        }
        else this.focusChild(rightAlign ? -1 : 0, rightAlign);
    }

    onBlur = () => {} // this.deactivate();

    onChange = e => {
        if (this.updateTimer) clearTimeout(this.updateTimer);
        const node = e.currentTarget;
        this.updateTimer = setTimeout(() => {
            this.updateTimer = 0;
            this.updateRowContent(node, true);
        }, this.constructor.updateTimeout);
    }

    // if we're mid-select and leave the CE box then disable editing so the selection can go outside the row,
    // we either do this, or render the selection box ourselves, which is a nightmare I'd rather not get into.
    onMouseLeave = e => e.buttons && (this.selection?.anchor?.node?.parent?.uuid === this.uuid) && this.deactivate();

    flushPendingUpdates = (e = null) => {
        if (this.updateTimer) { // if we have a pending update then cancel it and do it immediately
            clearTimeout(this.updateTimer);
            this.updateRowContent(e?.currentTarget, false);
        }
    }

    deactivate = (callback = null, sameNode = false) => {
        this.flushPendingUpdates();
        if (!sameNode) this.stopEditAndRestoreSelection(callback || (() => {}));
    }

    stopEditAndRestoreSelection = (callback = () => {}) => {
        this.flushPendingUpdates();
        this?.ref?.current ? this.ref.current.stopEdit(() => {
            this.restoreSelection();
            callback && callback();
        }) : (callback && callback());
    }

    updateRowContent = (domNode = null, canRender = true) => {
        // TODO: We know what keys are pressed, can't we catch the event and update the content ourselves instead?
        //       It would be a lot quicker, we just need to check if we can capture all edits reliably this way :)
        // only refresh nodes that have changed, or parent nodes if children are changed
        if (!domNode) domNode = this.getDOMNode();
        if (!domNode || !domNode.dataset || !domNode.dataset.uuid) return;
        const node = (this.uuid === domNode.dataset.uuid) ? this : this.retrieve(domNode.dataset.uuid);
        if (!node) return;
        let shouldRender = false;

        if (!domNode?.children?.length) { // rows always need runs, if its deleted then make sure we remove ourselves too
            node.remove(false);
            if (canRender) node.parent.rerender();
            return;
        }

        for (const child of domNode.children) {
            switch (child.nodeType) {
                case 1:  // Node.ELEMENT_NODE
                    if (!child || !child.dataset || !child.dataset.uuid) {
                        DebugConsole.warn(
                            "RowNode.updateRowContent: Unknown child node found (Node.ELEMENT_NODE)"
                        );
                        continue; // TODO: RowNode::updateRowContent - handle rogue (new) element nodes
                    }
                    break;
                case 3:  // Node.TEXT_NODE
                    DebugConsole.warn(
                        "RowNode.updateRowContent: Unknown child node found (Node.TEXT_NODE)"
                    );
                    // TODO: RowNode::updateRowContent - handle rogue (new) text-only nodes
                    continue;
                default:
                    continue;
            }

            const childNode = this.retrieve(child.dataset.uuid);
            if (!childNode) return DebugConsole.warn(
                "RowNode.updateRowContent: Child node not found", childNode, child.dataset.uuid
            );

            if (childNode.compareAndSwap(child, true, false)) {
                // ok contents were different and the node updated, make sure we re-render
                shouldRender = canRender;
            }
        }

        if (this.mergeChildren(true)) shouldRender = canRender;
        if (shouldRender) {
            this.select.pause();
            node.rerender(() => this.restoreSelection(true));
        }
    }

    mergeChildren = (store = true) => {
        // Here we search for duplicate nodes with the same props then merge them, this can happen if we make a word
        // bold then un-bold again, we will end up with three runs that have identical properties as the middle run is
        // no longer bold. We attempt to find and merge these to improve performance and ensure we have a clean tree.
        // This can be buggy if called during an edit, so we only do it when storing a node or updating its children.
        const children = this.children;
        if (children?.length < 2) return store ? false : children;

        let current = children[0];
        const newChildren = [current];

        for (let i = 1; i < children.length; i++) {
            const child = children[i], keys = new Set([...Object.keys(current.il), ...Object.keys(child.il)]);
            keys.delete(child.child);
            let different = false;
            for (const k of keys.keys()) {
                if (current.il[k] !== child.il[k]) {
                    // ok its different, nothing to merge, add to list and continue
                    current = child;
                    newChildren.push(current);
                    different = true;
                    break;
                }
            }
            if (different) continue;
            this.select.mergeSelectionCheck(current, child); // make sure we update the selection if we merge
            current.il[current.child] += child.il[child.child];
            child.unregister();
        }
        if (!store) return newChildren;

        if (newChildren.length !== children.length) {
            this.setChildren(...newChildren);
            return true;
        }
        return false;
    }

    // Returns a JSON tree object for this IL node
    store = (newChildren = undefined, props = {}, includeIDs = false) => {
        const json = {type: this.type, ...this.il, ...props};
        if (includeIDs) json['uuid'] = this.uuid;
        for (const key of Object.keys(json)) {
            if (key === this.child) {
                if (newChildren === undefined) {
                    this.mergeChildren(true);
                    json[key] = this.children;
                }
                else json[key] = newChildren;
            }
            if (json[key] instanceof ILNode) json[key] = json[key].storeWithIDs(includeIDs);
            else if (Array.isArray(json[key])) json[key] = json[key].map(p => (p instanceof ILNode) ? p.storeWithIDs(includeIDs) : p);
        }
        return this.storeFilter(json);
    }

    // splits a single text run into 2/3 identical text runs with the content split by pos/end i.e. caret position
    // will apply the new props given to the middle run i.e. the text between the pos/end offsets.
    splitRun = (idx, pos = 0, end = 0, callback = () => {}, render = true, newProps = {}, splice = true) => {
        const node = this.getChild(idx);
        if (node.type !== PROP_TYPES.TEXT) throw Error('Can only split a text node i.e. TextRun');
        if (!callback) callback = () => {};
        if (end < pos) end = node.length;

        const hasPre = pos > 0; // we skip the split if we're starting at ixd = 0
        const newPos = end - pos; // calculate position 2 (newPos) as offset from position 1 (pos)

        // split the actual run itself into two divergent runs
        const newNode = node.split(hasPre ? pos : end);

        if (!newPos || newPos >= newNode.length) { // single split (either pos/end is at the very start/end of the run)
            if (!hasPre) node.update(newProps, false); // first node gets updated only if we skip one
            else newNode.update(newProps, false); // update the correct props (middle node only)
            splice && this.spliceChild(idx + 1, 0, newNode); // now splice the new divergent run into the parent node
            render ? this.rerender(() => callback(newNode)) : callback(newNode); // then render
            return [node, newNode];
        }

        const endNode = newNode.split(newPos);
        newNode.update(newProps, false);
        splice && this.spliceChild(idx + 1, 0, newNode, endNode);
        render ? this.rerender(() => callback(newNode, endNode)) : callback(newNode, endNode);
        return [node, newNode, endNode];
    }

    // splits a single row into two identical rows with the context split by idx + pos i.e. run index + caret position
    splitRow = (idx, pos = 0, callback = () => {}, render = true, newProps = {}, splice = true) => {
        if(!callback) callback = () => {};
        const nodes = this.getChildList();

        if(idx < 0 || idx >= nodes.length) return DebugConsole.error('ILNode.splitRow: Invalid child index', idx, nodes);

        // if our idx is in the middle of a paragraph then we need to split the runs appropriately
        const prev = nodes.slice(0, idx);
        const node = nodes[idx];
        const next = nodes.slice(idx + 1);

        // split the actual run itself into two divergent runs
        const newN = node.split(pos);

        // now splice the new divergent run into the parent node
        this.setChildren(...prev, node);

        // create a new clone with the second half of the child nodes
        const newNode = this.clone([newN, ...next], newProps);

        // if we have no parent (direct row use) then just re-render ourselves instead
        if (!this.parent || !(this.parent instanceof ILNode)) {
            render ? this.rerender(() => callback(newNode)) : callback(newNode);
            return newNode;
        }

        if (splice) this.appendSibling(newNode);

        render ? this.parent.rerender(() => callback(newNode)) : callback(newNode);
        return newNode;
    }

}