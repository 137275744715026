import React from 'react';
import './contract-item-data-table.scss'
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableHeader, TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from "carbon-components-react";
import {Launch16} from "@carbon/icons-react";
import ContractItemEditTear from "../ContractItemEditTear";

class ContractItemDataTable extends React.Component {
    static defaultProps = {
        contractItems: null, contractItem: null, contract: null,
        contractItemHeaders: [
            {
                key: 'label',
                header: 'Label',
            },
            {
                key: 'contract',
                header: 'Contract'
            },
            {
                key: 'test_type',
                header: 'Test Type',
            },
            {
                key: 'price',
                header: 'Price',
            },
            {
                key: 'description',
                header: 'Description'
            }
        ]
    };

    constructor(props) {
        super(props);
        const {contractItems} = this.props;
        const {contractItem} = this.props;
        const { contract } = this.props;
        this.state = {
            errors: [],
            contract_item: contractItem,
            contract_items: contractItems,
            contract: contract,
            editing_contract_item: false,
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.contractItems !== this.props.contractItems || prevProps.contractItem !== this.props.contractItem) {
            this.setState({
                contract_items: this.props.contractItems,
                contract_item: this.props.contractItem
            })
        }
    }

    selectContractItem(id) {
        this.props.handler(null, id)
    }

    render() {

        const { contractItemHeaders } = this.props;
        const contract_items = this.state.contract_items ?
            this.state.contract_items
                .map(([k, v]) => ({
                        id: k, label: v.label, contract: v.contract.name, test_type: v.test_type ? v.test_type.name : '',
                        price: v.price, description: v.description,
                    })
                ) : [];

        return (
            <>
                    {this.state.loading ? <DataTableSkeleton columnCount={contractItemHeaders.length + 3}/> :
                        <DataTable rows={contract_items} headers={contractItemHeaders} isSortable size="sm" className="no-pad">
                            {({
                                  rows, headers, getHeaderProps, getTableProps, getRowProps,
                                  onInputChange, getToolbarProps, getBatchActionProps
                              }) => (
                                <TableContainer stickyHeader={true}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableToolbarContent>
                                            <TableToolbarSearch
                                                persistent={true} placeholder="Start typing to filter..."
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                            <Button
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onClick={() => this.setState({editing_contract_item: true})}
                                                size='small'
                                                kind='primary'
                                            >Add new</Button>
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        {...getHeaderProps({header})}
                                                        className={'xfh-' + header.key}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                                <TableHeader/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <React.Fragment key={row.id}>
                                                    <TableRow {...getRowProps({row})}>
                                                        {row.cells.map((cell) => (
                                                            <TableCell
                                                                key={cell.id}
                                                                className={'xfc-' + cell.info.header}
                                                            >{cell.value}</TableCell>
                                                        ))}
                                                        <TableCell className="xf-act-wrap">
                                                            <div className="xf-actions">
                                                                <Button className="xff-act" tooltipPosition="left"
                                                                        hasIconOnly renderIcon={Launch16}
                                                                        size="sm" kind="ghost"
                                                                        iconDescription="Open Item"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                contract_item_to_edit: this.state.contract_items.filter(([id, item]) => id === row.id)[0][1],
                                                                                editing_contract_item: true
                                                                            })
                                                                        }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>}
                {
                    this.state.editing_contract_item !== false && <ContractItemEditTear
                        contractItem={this.state.contract_item_to_edit}
                        onClose={f => {
                            if(f.updated_contract_item) {
                                let contract_item = {}
                                contract_item[f.updated_contract_item.uuid] = f.updated_contract_item;
                                this.props.handler({...this.state.contract_items, ...contract_item}, f.updated_contract_item.uuid)
                            }
                            this.setState({
                                editing_contract_item: false,
                                contract_item_to_edit: null
                            })
                        }}
                        contract={this.state.contract}
                    />
                }
    </>
        )
    }
}

export default ContractItemDataTable;