import {v4 as uuid4} from "uuid";
import isPrintableKeyEvent from "is-printable-key-event";
import {MAX_ITERATIONS, PROP_TYPES, RebuildDocTree, RT_KEYS} from ".";
import {drawBox} from "../../helpers/Formatting";
import {DebugConsole, getRealBoundingClientRect, invertStringCase} from "../../helpers/Utility";
import DocHelper from "../../helpers/Document";
import _ from "lodash";
import DocSelection from "./DocSelection";
import { DocNode, ILNode } from "./Nodes";
import ImageHelper from "../../helpers/Image";
import ParserHelper from "../../helpers/Parser";
import scrollIntoView from "scroll-into-view-if-needed";


// wos all dis den?
//   - We use DocEvents to handle adding/removing document-level browser events rather than doing it manually.
//     Specifically, this ensures only one DocIL object has document-level hooks active at any one time.
//     This was originally supposed to be a destructor implementation only so we can clean up properly,
//     but that's not possible without FinalizationRegistry (ES12), which my version of node doesn't like :(
const DOC_EVENT_SINGLETON = {ref: null};

class DocEvents {
    constructor(events, autoRegister = true) {
        // list of event objects i.e. {type: 'keydown', event: this.onKeyDown, capture: true},
        this.events = events;

        // ES2021/ES12 Only - note: every major browser supports this already, just node that doesn't (v14+ does!)
        // this.registry = new FinalizationRegistry(events =>
        //     events.map(({type, event, capture}) => document.removeEventListener(type, event, capture))
        // );
        //
        // this.registry.register(this, this.events);

        // poop global singleton version instead then, sigh, I wanted to play with garbage collection :(
        if (DOC_EVENT_SINGLETON) {
            if (DOC_EVENT_SINGLETON.ref && DOC_EVENT_SINGLETON.ref instanceof DocEvents)
                DOC_EVENT_SINGLETON.ref.unregister();
            DOC_EVENT_SINGLETON.ref = this;
        }

        autoRegister && this.register();
    }
    register = () => {
        DebugConsole.info('Registering document events', this.events);
        return this.events.map(({type, event, capture}) => document.addEventListener(type, event, capture));
    }
    unregister = () => {
        DebugConsole.info('Unregistering document events', this.events);
        return this.events.map(({type, event, capture}) => document.removeEventListener(type, event, capture));
    }
}

export default class DocIL {
    static showSelection = true;
    static directInserts = true;
    static snapBaseTimeout = 100; // ms value for timer to monitor and save edit updates i.e. snapshots
    static snapTextTimeout = 750; // timer value as above but for text input i.e. user typing rather than bulk actions

    /// TODO: Lots of graceful failures in here for performance reasons, if we want to log errors we need to do it
    //        asynchronously so we don't slow things down. Most of them relate to non-essential actions like cursor
    //        movement or keypress events so a worst case scenario would involve simply repeating the input action.
    //        They shouldn't really trigger anyway, these are mainly for outlier use-cases where the browser does
    //        something unexpected within the ContentEditable container corrupting the expected DOM tree structure.
    constructor(
        ilObject, filterInvalidProps = true, containerRef = null, docBarRef = null, context = null,
        autoRegisterEvents = true, editable = true, zoomCallback = zoom => zoom, rootReactNode = null,
        uuid = null, session = {}, saveCallback = () => {}, saveWarnCallback = () => {}, revision = 1, revisionCallback = () => {},
        autoLoadHistory = true, preview = false, navRef = {}
    ) {
        ilObject = RebuildDocTree(ilObject);

        this.uuid = uuid ? uuid : uuid4();
        this.filterProps = filterInvalidProps;
        this.containerRef = containerRef;
        this.docBarRef = docBarRef;
        this.editable = editable;
        this.context = context ? context : {};
        this.session = session ? session : {};
        this.zoomCallback = zoomCallback;
        this.rootReactNode = rootReactNode;
        this.saveCallback = saveCallback;
        this.saveWarning = saveWarnCallback;
        this.revisionWarning = revisionCallback;
        this.revision = revision;
        this.saveSafe = true;
        this._preview = preview;
        this._navRef = navRef;

        // We completely avoid the React state for most things; this is for efficiency i.e. its not an anti-pattern :)
        this.map = new Map();
        this.box = new Map();

        this.register();

        this.proxy = new Proxy(this, {
            get(target, prop) {
                if (target && prop in target) return target[prop];
                if (target.il && prop in target.il) return target.il[prop];
            }
        });

        this.il = new DocNode(ilObject, this.filterProps, this.proxy);

        // Everything we do related to text selection in the document should rely on this.select only.
        // This is so that if/when we stop relying on browser selection routines there's less to refactor :)
        this.select = new DocSelection(this.containerRef, this.retrieve,
            def => this.requeue(() => this.updateNodeFormatting(def.node)),
            def => this.requeue(() => {
                this.updateCollapsedState();
                !this.select.collapsed && this.deactivate(def.node);
            }),
            def => this.requeue(() => {
                this.updateCollapsedState();
                // def?.node?.parent && this.editAndRestoreSelection(def.node.parent);
            })
        );

        this.lastCutPlain = false;
        this.lastCopyPlain = false;
        this.lastPastePlain = false;
        this.lastKeyDownTimestamp = 0;
        this.lastDocKeyDownTimestamp = 0;
        this.lastMouseOffsetX = 0;
        this.lastMouseOffsetY = 0;

        this.registry = new DocEvents([
            // {type: 'selectstart', event: this.onSelectStart, capture: false},
            // {type: 'selectionchange', event: this.onSelectionChange, capture: false},
            {type: 'selectstart', event: this.select.onSelectStart, capture: false},
            {type: 'selectionchange', event: this.select.onSelectionChange, capture: false},
            {type: 'keydown', event: this.onDocKeyDown, capture: true},
            {type: 'wheel', event: this.onDocWheel, capture: false},
        ], autoRegisterEvents);

        this.formatting = {
            align: 'mixed',
            font: (Array.isArray(DocHelper.FORMAT_PROPS.fonts) && DocHelper.FORMAT_PROPS.fonts.length) > 0 ? DocHelper.FORMAT_PROPS.fonts[0] : null,
            size: (Array.isArray(DocHelper.FORMAT_PROPS.sizes) && DocHelper.FORMAT_PROPS.sizes.length) > 0 ? DocHelper.FORMAT_PROPS.sizes[0] : null,
            style: (Array.isArray(DocHelper.FORMAT_PROPS.styles) && DocHelper.FORMAT_PROPS.styles.length) > 0 ? DocHelper.FORMAT_PROPS.styles[0] : null,
            color: '#000000',
            highlight: null,
            bold: false,
            italic: false,
            underlined: false,
            coloring: false,
            aligning: false,
            bulleted: false,
            numbered: false,
            attaching: false,
            searching: false,
            collapsed: false,
            breakable: false,
        };

        this._active = null;

        this._snapMeta = {};
        this._snapText = false;
        this._snapping = false;
        this._snapTime = 0;
        this._snapsMap = new Map();

        this._history = [];
        this._histIdx = -1;
        this._dirty = false;

        // set autoLoadHistory to false on load to preview the latest version without wiping the history
        // combined with a read-only view this will provide a nice "preview latest version" feature :)
        if (autoLoadHistory) this.mountWait(this.loadHistory);
    }
    get preview() {
        return this._preview;
    }
    get dirty() {
        return this._dirty;
    }
    mountWait = (callback = () => {}, timeout = 10) => this?.rootReactNode?.mounted ? callback() : this.requeue(() => this.mountWait(callback, timeout), timeout);
    fullRender = (callback = () => {}) => this.mountWait(() => this.rootReactNode.forceUpdate(callback));
    editAndRestoreSelection = node => {
        const ref = node.getReactNode();
        if (ref?.isEditing && ref?.startEdit && !ref.isEditing()) ref.startEdit(() => this.requeue(this.select.restoreSelection));
        else this.requeue(this.select.restoreSelection);
    }
    updateFormatting = (props = {}, callback = () => {}) => Object.assign(this.formatting, props) &&
        (this?.docBarRef?.current?.setState ? this.docBarRef.current.setState(props, callback) : callback());
    updateCollapsedState = () => (this.select.collapsed !== this.formatting['collapsed']) && this.updateFormatting({collapsed: this.select.collapsed});
    updateNodeFormatting = (uuid, collapsed = undefined) => {
        const node = uuid instanceof ILNode ? uuid : this.retrieve(uuid);
        if (!node || !node.getProp) return;
        if (collapsed === undefined) collapsed = this.select.collapsed;
        const formatting = {};

        if (collapsed !== this.formatting['collapsed']) formatting['collapsed'] = collapsed;

        for (const runProp of [
            'font', 'bold', 'size', 'color', 'italic', 'underline', 'link',
            'highlight', 'strikethrough', 'superscript', 'subscript'
        ]) {
            const prop = node.getProp(runProp, DocHelper.FORMAT_DEFAULTS[runProp]);
            if (prop !== this.formatting[runProp]) formatting[runProp] = prop;
        }

        const prop = node.getProp('style', null);
        if (prop && prop !== this.formatting['style']) formatting['style'] = prop;

        if (node.parent) {
            const listable = node.parent.type === PROP_TYPES.ROW;
            if (listable !== this.formatting['listable']) formatting['listable'] = listable;

            if (node?.parent?.parent?.type === PROP_TYPES.LIST) {
                const numbered = node.parent.parent.getProp('numbered', DocHelper.FORMAT_DEFAULTS['numbered']);
                if (prop !== this.formatting['numbered']) {
                    formatting['numbered'] = numbered;
                    formatting['bulleted'] = !numbered; // these are relative
                }
            } else if(this.formatting['numbered'] || this.formatting['bulleted']) {
                formatting['numbered'] = false; // no list parent == no bullets :)
                formatting['bulleted'] = false;
            }

            for (const rowProp of ['indent', 'style']) {
                const prop = node.parent.getProp(rowProp, DocHelper.FORMAT_DEFAULTS[rowProp]);
                if (prop !== this.formatting[rowProp]) formatting[rowProp] = prop;
            }

            const fontSize = node.getProp('size', null);
            const rowStyle = node.parent.getProp('style', null);

            if (rowStyle && !fontSize) {
                // if we have a row style and the run doesn't have a custom size set then we sue the size the style uses
            }

            const breakable = !this.select?.focus?.node?.isSubTable;
            if (breakable !== this.formatting['breakable']) formatting['breakable'] = breakable;
        }

        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (startNode && stopNode) {
            const first = startNode.type === PROP_TYPES.TEXT ? startNode.parent : startNode;
            const last = stopNode.type === PROP_TYPES.TEXT ? stopNode.parent : stopNode;
            const rowProp = 'align', alignments = new Set();
            let current = first;

            do {
                if (!current) break;
                alignments.add(current.getProp(rowProp, DocHelper.FORMAT_DEFAULTS[rowProp]));
                if (current === last || alignments.size > 1) break;
                current = this.select.reversed ? current.prev : current.next;
            } while (current !== last);

            const align = alignments.size > 1 ? 'mixed' : alignments.values().next().value;
            if (align !== this.formatting[rowProp]) formatting[rowProp] = align;
        }

        this.updateFormatting(formatting);
    }

    registerEvents = () => this.registry.register();
    unregisterEvents = () => this.registry.unregister();
    register = (uuid = null, self = null) => this.map.set(uuid != null ? uuid : this.uuid, self != null ? self : this);
    retrieve = (uuid = null) => this.map.get(uuid != null ? uuid : this.uuid);
    unregister = (uuid = null) => this.map.delete(uuid != null ? uuid : this.uuid);
    activate = (node, callback = null) => { // enforce a single CE element at any one time :)
        this.deactivate(node, callback);
        this._active = node;
    }
    deactivate = (node, callback = null) => {
        if (this._active !== node && this?._active?.deactivate)
            this._active.deactivate(callback, false);
        else callback && callback();
    }
    flushPendingUpdates = () => this?._active?.flushPendingUpdates && this._active.flushPendingUpdates();

    getSelection = () => this.select;
    getDocBarNode = () => this?.docBarRef?.current?.baseRef?.current;
    getContainerNode = () => this?.containerRef?.current;

    // Letting react handle the state gets messy and inefficient; this time round we use the nodes themselves as a
    // state, then just force re-renders from the node's component ref whenever the content updates. This reduces
    // the amount of unnecessary renders and computation done by React. The vast majority of updates will be on key
    // press events i.e. typing, so the last thing we want is React going off and doing prop comparisons and renders
    // whenever the user presses a key, we monitor for changes ourselves and only update whats actually necessary.
    // We also use capture events here to get the event first so no child nodes can cancel propagation before us.
    docEventProps = () => ({
        onDragCapture: this.onDrag,
        onDragStartCapture: this.onDragStart,
        onDragEnterCapture: this.onDragEnter,
        onDragOverCapture: this.onDragOver,
        onDragLeaveCapture: this.onDragLeave,
        onDropCapture: this.onDrop,
        onCutCapture: this.onCut,
        onCopyCapture: this.onCopy,
        onPasteCapture: this.onPaste,
        onClickCapture: this.onClick,
        onDoubleClickCapture: this.onDoubleClick,
        onWheel: this.onMouseWheel,
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
        onMouseDownCapture: this.onMouseDown,
        onMouseMoveCapture: this.onMouseMove,
        onMouseUpCapture: this.onMouseUp,
        onKeyDownCapture: this.onKeyDown,
        onFocus: this.onFocus,
        onBlur: this.onBlur
    });
    docBarProps = () => ({
        onUndo: this.undo,
        onRedo: this.redo,
        onCut: this.onCut,
        onCopy: this.onCopy,
        onPaste: this.onPaste,
        onFont: this.setFont,
        onSize: this.setFontSize,
        onBold: this.bold,
        onItalic: this.italic,
        onUnderline: this.underline,
        onColor: this.setColor,
        onHighlight: this.setHighlight,
        onAlign: this.setAlign,
        onStrikeThrough: this.strikethrough,
        onSubscript: this.subscript,
        onSuperscript: this.superscript,
        onSentenceCase: this.sentenceCase,
        onUpperCase: this.upperCase,
        onLowerCase: this.lowerCase,
        onStartCase: this.startCase,
        onCamelCase: this.camelCase,
        onPascalCase: this.pascalCase,
        onToggleCase: this.toggleCase,
        onInvertCase: this.invertCase,
        onSnakeCase: this.snakeCase,
        onKebabCase: this.kebabCase,
        onBulleted: this.addBulleted,
        onNumbered: this.addNumbered,
        onIncIndent: this.increaseIndent,
        onDecIndent: this.decreaseIndent,
        onInsertTable: this.insertTable,
        onInsertLink: this.insertLink,
        onRemoveLink: this.removeLink,
        onStyle: this.setStyle,
        onSave: this.save,
        onAttach: this.onAttach,
        onSearch: () => alert('Search not implemented!'),  // TODO: Implement document search
        onBreak: this.breakPage,
        onClearFormatting: this.clearFormatting,
    });
    cancelEvent = e => {
        e.stopPropagation();
        e.stopImmediatePropagation ? e.stopImmediatePropagation() :
            (e.nativeEvent && e.nativeEvent.stopImmediatePropagation());
        e.preventDefault();
        return false;
    }

    onAttach = (init = true, ...files) => {
        if (!this.editable) return false;
        if (init) return this.rootReactNode.initLoad("Processing attachments...");
        const { ctx } = this.context;

        this.getFileBases(files).then(_files => {
            const rows = [];
            const row = this.select?.focus?.node?.closestRootNode || this.select?.anchor?.node?.closestRootNode;
            if (!row) {
                this.rootReactNode.stopLoad();
                DebugConsole.warn('Error pasting image: Row not found', row);
                return this.session.addToast(
                    'error', 'Error inserting content', 'Insertion point not found', 'Please try again'
                );
            }

            for (const file of _files) rows.push(...ImageHelper.buildImageBlock(file, ctx));

            this.snapInit(true);
            row.appendSibling(...rows);
            row.parent.rerender(() => this.paginate(this.rootReactNode.stopLoad));
        }).catch(e => {
            this.session.addToast('warning-alt', 'Processing Failed', e.message, e.name);
            this.rootReactNode.stopLoad();
        });
    }

    getFileBases = async files => {
        const _files = [];
        for (const file of files) {
            const b64 = await new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e) => resolve(reader.result);
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(file.file);
            });
            _files.push({...file, base: b64});
        }
        return _files;
    }

    // TODO: Implement and re-enable drag and drop
    onDrag = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDrag', e);
    }
    onDragStart = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDragStart', e);
    }
    onDragEnter = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDragEnter', e);
    }
    onDragOver = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDragOver', e);
    }
    onDragLeave = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDragLeave', e);
    }
    onDrop = e => {
        return this.cancelEvent(e);
        //DebugConsole.debug('DocIL:onDrop', e);
    }
    onFocus = e => {
        // DebugConsole.debug('DocIL:onFocus', e);
        // const range = this.select.selectionRange(), container = this.getContainerNode();
        // if (range && container && (container === range.startContainer || container === range.endContainer))
        //     this.il && this.il.focus && this.il.focus();
    }
    onBlur = e => {
        // DebugConsole.debug('DocIL:onBlur', e);
    }
    onCut = e => {
        DebugConsole.debug('DocIL:onCut', e);
        if (!this.editable) {
            this.cancelEvent(e);
            return false;
        }

        if (!this.select.collapsed) {
            this.snapInit(true);
            this.deleteSelectedNodes();
        }
    }
    onCopy = e => {
        DebugConsole.debug('DocIL:onCopy', e);
        if (!this.lastCopyPlain) return;
        this.session.addToast(
            'info-square', 'Data copied', 'Formatted rich text copied to clipboard', 'Operation successful'
        );
    }
    onPaste = e => {
        DebugConsole.debug('DocIL:onPaste', e);
        if (!this.editable) {
            this.cancelEvent(e);
            return false;
        }
        const clipboardData = e.clipboardData || e.originalEvent?.clipboardData || window.clipboardData;

        if (!clipboardData) return false;

        if (clipboardData?.files?.length) {
            // ok they pasted a file, if its a supported type i.e. image, then import as normal
            this.rootReactNode.initLoad("Processing images...", () => ImageHelper.processImageFiles(
                () => {},
                () => {},
                files => this.onAttach(false, ...files),
                this.session.addToast, ...clipboardData.files
            ));
            return false;
        }

        const types = clipboardData.types;
        if (!this.lastPastePlain && (((types instanceof DOMStringList) && types.contains("text/html")) || (types.indexOf && types.indexOf('text/html') >= 0))) {
            // Extract data and pass it to callback
            const pastedHTML = clipboardData.getData('text/html');

            if (pastedHTML) {
                const { ctx } = this.context;

                const row = this.select?.focus?.node?.closestRootNode || this.select?.anchor?.node?.closestRootNode;
                if (!row) {
                    this.session.addToast(
                        'error', 'Error inserting content', 'Insertion point not found', 'Please try again'
                    );
                    return false;
                }

                this.rootReactNode.initLoad("Processing pasted data...", () => ParserHelper.parseHTML(
                    pastedHTML, ctx, this.session.addToast, section => {
                        if (!section || !section?.rows?.length) {
                            this.session.addToast('warning-alt', 'Not Pasted', 'No compatible data found on clipboard', 'Invalid rich text content');
                            return this.rootReactNode.stopLoad();
                        }

                        this.snapInit(true);
                        row.appendSibling(...section.rows);
                        row.parent.rerender(() => this.paginate(this.rootReactNode.stopLoad));
                    },
                    (
                        ((types instanceof DOMStringList) && types.contains("text/rtf")) ||
                        (types.indexOf && types.indexOf('text/rtf') >= 0)
                    ) ? clipboardData.getData('text/rtf') : null
                ));

                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        }

        this.snapInit(true);

        if (!this.select.collapsed) this.deleteSelectedNodes();
        this.insertText(clipboardData.getData('Text'));

        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    onClick = e => {
        if (e.detail === 3) return this.onTripleClick(e);
        // DebugConsole.debug('DocIL:onClick', e);
    }
    onDoubleClick = e => {
        // DebugConsole.debug('DocIL:onDoubleClick', e);
    }
    onTripleClick = e => {
        // DebugConsole.debug('DocIL:onTripleClick', e);
    }
    onDocWheel = e => {
        // DebugConsole.debug('DocIL:onDocWheel', e);

        if (e.ctrlKey || e.shiftKey){ // we offer shift + scroll as zoom too cause ctrl + scroll is poop sometimes :(
            // turns out cancelling ctrl + zoom is super buggy in browsers - sometimes they just do it anyway... sigh
            e.stopPropagation();
            e.stopImmediatePropagation();

            const container = this.getContainerNode();
            if (container && container.contains(e.target)) {
                if (!this.zoomCallback) return false; // can't zoom so bail
                this.zoomCallback((e.deltaY > 0) ? -10 : 10); // e.deltaY > 0 == scroll out
            }
        }
        return false;
    }
    onMouseWheel = e => {
        // DebugConsole.debug('DocIL:onMouseWheel', e);

        if (e.ctrlKey || e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            if (!this.zoomCallback) return; // can't zoom so bail
            this.zoomCallback((e.deltaY > 0) ? -10 : 10); // e.deltaY > 0 == scroll out
        }
        return false;
    }
    onMouseEnter = e => {
        // DebugConsole.debug('DocIL:onMouseEnter', e);
    }
    onMouseLeave = e => {
        // DebugConsole.debug('DocIL:onMouseLeave', e);
    }
    onMouseDown = e => {
        //DebugConsole.debug('DocIL:onMouseDown', e);
        // if (e.button || e.shiftKey) return; // cancel if not the left button or the shift key is down
        // this.lastMouseOffsetX = e.clientX;
        // this.lastMouseOffsetY = e.clientY;
        // e.button =
        // 0: Main button pressed, usually the left button or the un-initialized state
        // 1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
        // 2: Secondary button pressed, usually the right button
        // 3: Fourth button, typically the Browser Back button
        // 4: Fifth button, typically the Browser Forward button
        //this.select.setAnchorPoint(e.clientX, e.clientY);
    }
    onMouseMove = e => {
        //DebugConsole.debug('DocIL:onMouseMove', e);
        //if (!e.buttons) return;
        // e.buttons =
        // 0 : No button or un-initialized
        // 1 : Primary button (usually the left button)
        // 2 : Secondary button (usually the right button)
        // 4 : Auxiliary button (usually the mouse wheel button or middle button)
        // 8 : 4th button (typically the "Browser Back" button)
        // 16 : 5th button (typically the "Browser Forward" button)
        //this.select.setFocusPoint(e.clientX, e.clientY, true);
    }
    onMouseUp = e => {
        // this is now all handled within the selection, if a text run is selected edit is auto-enabled

        // DebugConsole.debug('DocIL:onMouseUp', e);
        // if (e.button) return;
        // if (e.detail > 1) return; // only handle selection changes
        // if (this.lastMouseOffsetX === e.clientX && this.lastMouseOffsetY === e.clientY) {
        //     this.select.selectPoint(e.clientX, e.clientY);
        //     if (this.select.collapsed && this.select.anchor?.node?.parent?.getReactNode) {
        //         const ref = this.select.anchor.node.parent.getReactNode();
        //         ref?.startEdit && ref.startEdit(() => this.select.restoreSelection());
        //     }
        // }

        //const point = this.selectionFromPoint(e.clientX, e.clientY);
        // mouse never moved, make sure we update both ends of the click to fix the buggy stuff
        // if (this.lastMouseOffsetX === e.clientX && this.lastMouseOffsetY === e.clientY)
        //     this.select.selectPoint(e.clientX, e.clientY);
        //else this.select.setFocusPoint(e.clientX, e.clientY);

        // if (e.shiftKey) this.select.restoreSelection();
        // if (e.detail > 1) return; // only handle selection changes
        //
        // if (this.select.collapsed && this.select.anchor?.node?.parent?.getReactNode) {
        //     const ref = this.select.anchor.node.parent.getReactNode();
        //     ref?.startEdit && ref.startEdit(() => this.select.restoreSelection());
        // }
    }

    placeCaret = (node, afterNode = true) => this.select.placeCaret(node, afterNode);
    restoreSelection = (resumeIfPaused = true) => this.select.restoreSelection(resumeIfPaused);
    holdSelect = () => {
        this.select.pause(); // pause selection update event processing
        this.requeue(this.select.resume); // re-enable after the current thread execution stack finishes
    }

    // TODO: List methods are 2-dimensional only, make them support fully nested nodes.
    //  i.e. currently they only handle singular lists within rows and rows within lists,
    //       a list acts as its own row and cannot include a table, image or other "complex"
    //       data type such as another list, a list only supports text-based row list items.
    wrapListNode = numbered => {
        if (!this.editable) return false;
        // updates any selected rows with the given props, partial selections affect the whole row.
        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (!startNode || !RT_KEYS.has(startNode.type) || !stopNode || !RT_KEYS.has(stopNode.type)) {
            this.select.resume();
            DebugConsole.warn("wrapListNode: Invalid selection node reference", startNode, stopNode);
            return false;
        }

        this.snapInit(true);
        this.select.pause(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        startNode.flushPendingUpdates && startNode.flushPendingUpdates();

        if (this.select.collapsed) {
            const node = startNode?.isText ? startNode.parent : startNode;
            if (node.editable) {
                const section = node.parent; // get this before we nest as the parent will change
                node.replace({type: PROP_TYPES.LIST, numbered: numbered, items: [node]});
                section.rerender(() => this.editAndRestoreSelection(node));
            } else this.select.resume();
            this.updateNodeFormatting(startNode, true); // force a formatting update
            return;
        }

        const wrapped = [];
        const renders = new Map();
        let ric = 0;

        // TODO: This can corrupt the IL node tree if there are several nested types in the source IL. If
        //       we hit the wrapped.length check and not all rows inside wrapped have the same parent the
        //       operation will succeed but the document will be malformed with duplicated or moved data.
        //       Resolution: Do a similar bounds check like in unwrapListNode to manually remove nodes.
        for (const row of this.select.iterateRows(false, true, true, true)) {
            if (ric++ > MAX_ITERATIONS) {
                console.warn('wrapListNode: Max iterations reached!');
                break;
            }
            if (row.editable && !row?.parent?.isList) {
                renders.set(row.parent.uuid, row.parent);
                wrapped.push(row);
            } else if (wrapped.length) {
                wrapped[0].replaceMulti(wrapped.length, {type: PROP_TYPES.LIST, numbered: numbered, items: [...wrapped]});
                wrapped.length = 0;
            }
        }

        if (!ric) {
            this.select.resume();
            DebugConsole.warn("wrapListNode: No selected nodes found?", this.select.anchor, this.select.focus);
            return false;
        }

        if (wrapped.length) {
            renders.set(wrapped[0].parent.uuid, wrapped[0].parent);
            wrapped[0].replaceMulti(wrapped.length, {type: PROP_TYPES.LIST, numbered: numbered, items: [...wrapped]});
        }

        this.updateNodeFormatting(this.select.anchor.node, false); // force a formatting update
        renders.forEach(n => n.rerender());
        this.requeue(this.restoreSelection);
    }

    unwrapListNode = () => {
        if (!this.editable) return false;
        // updates any selected rows with the given props, partial selections affect the whole row.
        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (!startNode || !RT_KEYS.has(startNode.type) || !stopNode || !RT_KEYS.has(stopNode.type)) {
            this.select.resume();
            DebugConsole.warn("unwrapListNode: Invalid selection node reference", startNode, stopNode);
            return false;
        }

        this.snapInit(true);
        this.select.pause(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        startNode.flushPendingUpdates && startNode.flushPendingUpdates();

        if (this.select.collapsed) {
            const node = startNode?.isText ? startNode.parent : startNode;
            const parent = node?.parent;
            if (parent?.editable && parent?.isList) {
                const section = parent.parent; // get this before we unnest as the parent will change
                parent.replace(...parent.getChildList());
                section.rerender(() => this.editAndRestoreSelection(node));
            } else this.select.resume();
            this.updateNodeFormatting(startNode, true); // force a formatting update
            return true;
        }

        const wrapped = [];
        const renders = new Map();
        let ric = 0;

        const mergeUnwrappedNodes = () => {
            if (wrapped.length) {
                const parent = wrapped[0].parent;
                renders.set(parent.uuid, parent);
                wrapped.forEach(n => n.remove(false, false, true) ?
                    renders.set(n.parent.uuid, n.parent) : console.log('Cannot delete:', n.rep, n.getDOMNode())
                );
                parent.appendSibling(...wrapped);
                wrapped.length = 0;
                if (!parent.length) {
                    renders.set(parent.parent.uuid, parent.parent);
                    parent.remove(true, true, true);
                }
            }
        };

        for (const row of this.select.iterateRows(false, true, true, true)) {
            if (ric++ > MAX_ITERATIONS) {
                console.warn('unwrapListNode: Max iterations reached!');
                break;
            }
            if (row?.editable && row?.parent?.isList) {
                renders.set(row.parent.parent.uuid, row.parent.parent);
                wrapped.push(row);
            } else mergeUnwrappedNodes();
        }

        mergeUnwrappedNodes();

        if (!ric) {
            this.select.resume();
            DebugConsole.warn("unwrapListNode: No selected nodes found?", this.select.anchor, this.select.focus);
            return false;
        }

        this.updateNodeFormatting(this.select.anchor.node, false); // force a formatting update

        renders.forEach(n => n.rerender());
        this.requeue(this.restoreSelection);
    }

    deleteSelectedNodes = (replacementContent = undefined, mergePostFocus = true) => {
        if (!this.editable) return false;
        // This will delete the current selection and replace its contents with the given value, if any
        // Rows and runs will be merged if necessary and the cursor will be placed at the end of the inserted content

        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (!startNode || !RT_KEYS.has(startNode.type) || !stopNode || !RT_KEYS.has(stopNode.type))
            return DebugConsole.warn(
                "deleteSelectedNodes: Invalid selection node reference",
                startNode, stopNode, this.select.reversed, this.select.collapsed
            );

        this.snapInit(true);
        this.holdSelect(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        const renders = new Map(), startOffset = this.select.getAnchorOffset(), stopOffset = this.select.getFocusOffset();
        let first = this.select.reversed ? stopNode : startNode, start = this.select.reversed ? stopOffset : startOffset; // highest in DOM
        let last = this.select.reversed ? startNode : stopNode; // lowest in DOM
        let renderSection = false, cursorNode = first, cursorOffset = start;

        if (!first.getDOMNode() || !last.getDOMNode()) return DebugConsole.warn(
            "deleteSelectedNodes: Invalid selection node, no matching DOM node found",
            startNode, startOffset, stopNode, stopOffset, this.select.reversed, this.select.collapsed
        );

        // we have content selected, make sure we remove it first :)
        if (!this.select.collapsed) {
            // new iteration implementation, hopefully more reliable :)
            let preRun = null, postRun = null, firstRow = null, lastRow = null;
            for (const [run, isFirst, isLast] of this.select.iterateSelection(true, true, true, true, true)) {
                if (!preRun) preRun = run.prevRun;
                if (isFirst) firstRow = run.parent;
                if (isLast) {
                    lastRow = isFirst ? firstRow : run.parent;
                    postRun = run.nextRun;
                }
                if (run.isSubTable && run?.parent?.childCount <= 1) run.clearText();
                else run.remove(true); // very basic check so we don't remove table cells, only clear them
                renders.set(run.parent.uuid, run.parent);
            }
            if (preRun) {
                if (postRun && (preRun.parent !== firstRow) && (postRun.parent === lastRow)) {
                    cursorNode = postRun;
                    cursorOffset = 0;
                } else {
                    cursorNode = preRun;
                    cursorOffset = preRun?.length;
                }
                renderSection = true;

                if (firstRow && lastRow && (firstRow !== lastRow) && (firstRow.next === lastRow.prev)) {
                    lastRow.join(false, false);
                    renders.set(lastRow.parent.uuid, lastRow.parent);
                }
            }
        }

        if (replacementContent !== undefined && replacementContent !== null) {
            if (typeof replacementContent === "string") replacementContent = {
                type: PROP_TYPES.TEXT,
                text: replacementContent
            };
            if (!cursorNode.editable) {
                cursorOffset = cursorNode.length;
                if (replacementContent.type === PROP_TYPES.TEXT) // no edit = insert a new row
                    replacementContent = {type: PROP_TYPES.PARAGRAPH, runs: [replacementContent]};
            }

            if (replacementContent.type === PROP_TYPES.TEXT) {
                renders.set(cursorNode.parent.uuid, cursorNode.parent);
                const newNode = cursorNode.clone(undefined, replacementContent); // passing undefined will not replace child
                if (!newNode) return DebugConsole.error("deleteSelectedNodes: Unable to clone run, invalid node", newNode);
                newNode.editable = true;
                cursorNode = newNode;
                cursorOffset = newNode?.length;
            } else if (replacementContent.type === PROP_TYPES.PARAGRAPH || replacementContent.type === PROP_TYPES.TABLE) {
                if (!cursorNode.splitRow) return DebugConsole.warn(
                    "deleteSelectedNodes: Invalid cursor row selected",
                    cursorNode.rep, cursorNode?.parent?.rep, cursorNode?.parent?.parent?.rep,
                    cursorNode.getDOMNode()
                );

                renders.set(cursorNode.parent.parent.uuid, cursorNode.parent.parent);

                // Note: if we hit enter on the last list item when it's empty we insert a new row below the list rather
                // than a new list item, this is so we replicate the same behaviour people are used to in office / word.
                // TODO: this should also remove the empty list item; confirm if this is desired behaviour or not :)
                const isNonText = !cursorNode?.parent?.isRow;
                const postList = !cursorNode?.length && !cursorNode?.parent?.next && cursorNode?.parent?.parent?.isList;
                if (isNonText || postList || !mergePostFocus || replacementContent.type !== PROP_TYPES.ROW) {
                    const newTarget = postList ? cursorNode.parent.parent : cursorNode.parent;
                    const newChild = newTarget.parent.buildChild(replacementContent);

                    if (replacementContent.type === PROP_TYPES.TABLE) {
                        // We add a new blank row underneath the table as there is no way to select "after" a table
                        const newRow = newTarget.parent.buildChild({type: PROP_TYPES.PARAGRAPH, runs: [{type: PROP_TYPES.TEXT, text: ''}]});
                        newTarget.appendSibling(newChild, newRow);
                    }
                    else newTarget.appendSibling(newChild);

                    cursorNode = newChild;
                    cursorOffset = newChild?.length;
                    renderSection = newTarget.parent;
                } else {
                    const newNode = cursorNode.splitRow(cursorOffset, null, false, replacementContent, true);
                    if (!newNode) return DebugConsole.error("deleteSelectedNodes: Unable to split row, invalid node", newNode);
                    if (newNode?.il?.break && !replacementContent.break) newNode.il.break = false;
                    if (newNode?.il?.style) newNode.il.style = DocHelper.getStyleHeadingReset(newNode.il.style);
                    newNode.editable = true; // make sure we re-enable editing in case we copied a read-only node
                    cursorNode = newNode.getChild();
                    cursorOffset = 0;
                }
            } else DebugConsole.warn("deleteSelectedNodes: Invalid replacementContent type", replacementContent);
        }

        const finalize = () => this.select.setCursor(cursorNode, cursorOffset, true);

        // skip that horrendous mess below and just re-render the entire section instead, slow and clean :)
        if (renderSection) {
            if (typeof renderSection === "boolean") {
                if (cursorNode?.parent?.parent?.rerender) return cursorNode.parent.parent.rerender(finalize);
                if (first?.parent?.parent?.rerender) return first.parent.parent.rerender(finalize);
                if (last?.parent?.parent?.rerender) return last.parent.parent.rerender(finalize);
            } else if(renderSection?.rerender) return renderSection.rerender(finalize);
        }

        // loop through asynchronously, rendering all the way, restoring our selection after the final render
        // this is dumb... but unfortunately react doesn't have a way of saying "render all these at once" :(
        const r = renders.values();
        const n = r.next();
        if (!n.done) {
            const f = () => {
                const _r = r.next();
                if (_r.done) finalize();
                else _r.value.rerender(f);
            };
            n.value.rerender(f);
        } else finalize();
    }

    updateRowProps = (props = {}, nodeCallback = () => {}) => {
        if (!this.editable) return false;
        // updates any selected rows with the given props, partial selections affect the whole row.
        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (!startNode || !RT_KEYS.has(startNode.type) || !stopNode || !RT_KEYS.has(stopNode.type))
            return DebugConsole.warn(
                "updateRowProps: Invalid selection node reference", startNode, stopNode
            );

        this.snapInit(true);
        this.holdSelect(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        startNode.flushPendingUpdates && startNode.flushPendingUpdates();

        if (this.select.collapsed) {
            const node = startNode.type === PROP_TYPES.TEXT ? startNode.parent : startNode;
            if (node.editable) {
                node.update(props, true, () => {
                    nodeCallback(node);
                    this.editAndRestoreSelection(node);
                });
            }
            this.updateNodeFormatting(startNode, true); // force a formatting update
            return;
        }

        const first = startNode.type === PROP_TYPES.TEXT ? startNode.parent : startNode;
        const last = stopNode.type === PROP_TYPES.TEXT ? stopNode.parent : stopNode;
        const renders = [];
        let current = first;
        if (!current) return DebugConsole.warn(
            "updateRowProps: Invalid current node reference", startNode, stopNode, current
        );

        do {
            if (current.editable) {
                current.update(props, false);
                nodeCallback(current);
                renders.push(current);
            }
            if (current === last) break;
            current = this.select.reversed ? current.prev : current.next;
        } while (current);

        this.updateNodeFormatting(startNode, false); // force a formatting update

        const r = renders.values();
        const n = r.next();
        if (!n.done) {
            const f = () => {
                const _r = r.next();
                _r.done ? this.requeue(this.restoreSelection) : _r.value.rerender(f);
            };
            n.value.rerender(f);
        }
    }

    updateRunProps = (props = {}, nodeCallback = () => {}) => {
        if (!this.editable) return false;
        // if there is no current text selection then insert a new run, otherwise update
        // the selected runs with the given props, wrapping with new runs where necessary.
        if (this.select.collapsed) return this.deleteSelectedNodes({type: PROP_TYPES.TEXT, text: '', ...props});

        const startNode = this.select.getAnchorNode(), stopNode = this.select.getFocusNode();
        if (!startNode || !RT_KEYS.has(startNode.type) || !stopNode || !RT_KEYS.has(stopNode.type))
            return DebugConsole.warn("updateRunProps: Invalid selection node reference", startNode, stopNode);

        this.snapInit(true);
        this.holdSelect(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        startNode.flushPendingUpdates && startNode.flushPendingUpdates();

        const startOffset = this.select.getAnchorOffset(), stopOffset = this.select.getFocusOffset();
        if (startNode === stopNode) {
            if (!startNode.editable || !startNode?.parent?.editable) return; // can't modify a read-only row silly!
            if (startNode.type === PROP_TYPES.TEXT) {
                if (this.select.reversed ? (stopOffset <= 0 && startOffset >= startNode.length) : (startOffset <= 0 && stopOffset >= stopNode.length)) {
                    startNode.update(props, false);
                    nodeCallback(startNode);
                    startNode.parent.rerender(() => this.restoreSelection());
                }
                else {
                    const [,newNode] = startNode.splitRun(
                        this.select.reversed ? stopOffset : startOffset,
                        this.select.reversed ? startOffset : stopOffset, null, true, props
                    );
                    nodeCallback(newNode);
                    startNode.parent.rerender(() => this.select.select(newNode, 0, newNode, newNode.length));
                }
            }
            else DebugConsole.warn("updateRunProps: Invalid startNode type", startNode);
            return;
        }

        const first = this.select.reversed ? stopNode : startNode, start = this.select.reversed ? stopOffset : startOffset;
        const last = this.select.reversed ? startNode : stopNode, stop = this.select.reversed ? startOffset : stopOffset;
        const renders = new Map();
        let current = first, startNext = false, newStartNode = startNode, newStartOffset = 0, newStopNode = stopNode, newStopOffset = stopOffset;

        const updateTopSelect = () => {
            if (this.select.reversed) {
                newStopNode = current;
                newStopOffset = 0;
            } else {
                newStartNode = current;
                newStartOffset = 0;
            }
        };

        for (const run of this.select.iterateRuns(true, true, true)) {
            current = run;
            if (startNext) updateTopSelect();
            if (start && run === first) { // if we're not at the start of a run we need to split it and move onto the next one :)
                if (first?.editable && first?.parent?.editable) {
                    [,current] = first.splitRun(start);
                    updateTopSelect();
                }
                else startNext = true;
            } else if (stop && run === last) {
                last.splitRun(0, stop);
            }

            renders.set(current.parent.uuid, current.parent);
            current.update(props, false);
            nodeCallback(current);
        }

        // traverse the IL tree until all nodes between the start and stop selection points have updated props
        // while (current !== last) {
        //     if (!current || !current.parent || !current.parent.uuid) break; // no inf loops plz, thx
        //     if (current?.editable && current?.parent?.editable) {
        //         renders.set(current.parent.uuid, current.parent);
        //         current.update(props, false);
        //         nodeCallback(current);
        //     }
        //     current = current.next ? current.next : current?.parent?.next?.first;
        // }
        //
        // if (current !== last) DebugConsole.warn(
        //     "updateRunProps: Couldn't find the selection focus point in the node tree", current, last
        // );

        // finally split our last run if need be
        // if (last?.editable && last?.parent?.editable) {
        //     last.splitRun(0, stop);
        //     last.update(props, false);
        //     nodeCallback(last);
        //     renders.set(last.parent.uuid, last.parent);
        // }
        // if (this.select.reversed) {
        //     newStartNode = last;
        //     newStartOffset = stop;
        // } else {
        //     newStopNode = last;
        //     newStopOffset = stop;
        // }

        // loop through asynchronously, rendering all the way, restoring our selection after the final render
        const r = renders.values();
        const n = r.next();
        if (!n.done) { // our render callback chaining function means we re-render whats changed, not the whole doc :)
            const f = () => {
                const _r = r.next();
                if (_r.done) this.select.select(newStartNode, newStartOffset, newStopNode, newStopOffset);
                else _r.value.rerender(f);
            };
            n.value.rerender(f);
        }
    }

    // should only ever be called on "enter" press really, deletes current selection and inserts a new row in-place
    // I lied above, this also gets triggered when we insert new row-based things like lists, images, tables etc.
    insertRow = (props = {}, mergePostFocus = true) => this.deleteSelectedNodes({type: PROP_TYPES.PARAGRAPH, ...props}, mergePostFocus);
    insertRootRow = (props = {}) => {
        if (!this.editable) return false;
        const row = this.select?.focus?.node?.closestRootNode || this.select?.anchor?.node?.closestRootNode;
        if (!row) {
            this.session.addToast(
                'error', 'Error inserting content', 'Insertion point not found', 'Please try again'
            );
            return false;
        }
        this.snapInit(true);
        const newRow = row.parent.buildChild({type: PROP_TYPES.PARAGRAPH, ...props});
        row.appendSibling(newRow);
        row.parent.rerender(() => this.paginate(() => this.select.placeCaret(newRow)));
    }

    insertTable = (rows, columns) => {
        if (!this.editable) return false;
        this.insertRow({
            type: PROP_TYPES.TABLE,
            cells: rows > 0 ? Array.from({length: rows}, () => (
                {columns: Array.from({length: columns}, () => (
                        {cell: [{type: PROP_TYPES.PARAGRAPH}]}
                ))}
            )) : []
        }, false);
    }

    insertText = text => {
        if (!this.editable) return false;
        this.snapInit();
        this.holdSelect();
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying
        const node = this.select.getFocusNode();
        if (!node) return DebugConsole.warn(
            "insertText: Invalid selection node reference",
            node, this.select.reversed, this.select.collapsed
        );

        const run = node.isRun ? node : node.firstRun;
        if (!run || !run.insertText) return DebugConsole.warn("insertText: Text run not found", run);

        run.insertText(text, this.select.getFocusOffset());
        run.rerender(() => this.select.adjustCursorOffset(text.length, true, true));
    }

    delete = (forwards = true) => { // forwards = false === backspace
        if (!this.editable) return false;
        // if text is selected then remove all of it, merging rows and runs if necessary
        // if no text is selected then it will remove a single character, again merging if necessary
        if(!this.select.collapsed) {
            this.deleteSelectedNodes(); // if text is selected then just remove as normal
            return true;
        }

        this.snapInit();
        this.holdSelect(); // pause selection changes then re-enable afterwards using requeue
        this.flushPendingUpdates(); // make sure our node tree is always up to date before modifying

        // quick early exit :)
        if (forwards) {
            if (this.select.focus.offset < this.select.focus.node.length) return false;
        } else {
            if (this.select.focus.offset > 0) return false;
        }

        const el = this.select.findFocusRow();
        if (!el || !el.dataset || !el.dataset.uuid) return true;

        const node = this.retrieve(el.dataset.uuid);
        if (!node) return true;

        const collapsedRange = this.select.selectionRange().cloneRange();
        collapsedRange.collapse(!forwards);

        const selRect = getRealBoundingClientRect(collapsedRange), elRect = el.getBoundingClientRect();

        // if (!node?.length) {
        //     node.join();
        //     const dstNode = forwards ? node.next : node.prev;
        //     if (!dstNode) return true; // TODO: TMS - Add table merge support (do we need it, alt + tab does this for us?)
        //     const newNode = dstNode?.type === PROP_TYPES.LIST ? (forwards ? dstNode.first : dstNode.last) : dstNode;
        //     if (!newNode?.parent?.getReactNode) return DebugConsole.warn('Merge row failure, no valid parent! - getReactNode not found', newNode.rep);
        //     const pNode = dstNode.parent.getReactNode();
        //     const rNode = newNode.getReactNode();
        //     if (!rNode?.startEdit) return DebugConsole.warn('Merge row failure, no valid parent! - startEdit not found', newNode.rep, pNode, rNode);
        //     if (!node.remove(true)) DebugConsole.warn('Unable to remove node: ', node.rep);
        //     const txtNode = forwards ? newNode.first : newNode.last;
        //     pNode.forceUpdate(() => rNode.startEdit(() => this.requeue(() => this.placeCaret(txtNode, !forwards))));
        //     return true;
        // }

        if (node.length) {
            if (forwards) { // right
                if (elRect.bottom > selRect.bottom) return false;  // allow default if not the last line in the row
                collapsedRange.setEndAfter(el);
                if (collapsedRange.toString().length > 0) return false;  // allow default if not at the end of a row
            }
            else {  // left
                if (elRect.top < selRect.top) return false;  // allow default if not on the top line of the row
                if (elRect.top < selRect.top) return false;  // allow default if not on the top line of the row
                collapsedRange.setStart(el, 0);
                if (collapsedRange.toString().length > 0) return false;  // allow default if not at the start of a row
            }
        } else if (node?.il?.break) {
            node.update({break: false}, false);
            node.parent.rerender();
            return true; // cancel the page break to merge into the previous page before merging rows themselves
        }

        if (forwards ? (!node.next || !node.next.editable) : (!node.prev || !node.prev.editable))
            return true; // nowhere to go / target row is read only

        node.flushPendingUpdates && node.flushPendingUpdates();
        node.join(forwards, (pre, post) => {
            if (!pre || !pre?.parent?.getReactNode) return DebugConsole.warn('Merge row failure, no valid parent! - getReactNode not found', pre ? pre.rep : pre);
            const pNode = pre.parent.parent.getReactNode();
            const rNode = pre.parent.getReactNode();
            if (!rNode?.startEdit) return DebugConsole.warn('Merge row failure, no valid parent! - startEdit not found', pre.rep);
            pNode.forceUpdate(() => rNode.startEdit(() => this.requeue(() => this.restoreSelection())));
        });
        return true; // fall through will be row merges so make sure we cancel the character removal
    }

    selectAll = () => {
        if (!this.containerRef || !this.containerRef.current) return;
        const runs = this.containerRef.current.getElementsByClassName('xfp-run');
        if (!runs || !runs.length) return;
        const start = runs.item(0), stop = runs.item(runs.length - 1);
        this.select.select(start, 0, stop, stop?.textContent?.length);
    }

    undo = () => this.history(false);
    redo = () => this.history(true);

    _save = () => this.saveCallback(this.store(), revision => {
        this._dirty = false;
        this.saveSafe = true;
        this.revision = revision;
        window.localStorage.setItem(this.uuid + '-safe', 'y');
        window.localStorage.setItem(this.uuid + '-revision', revision);
    }, () => this._dirty = true);

    save = () => this.preview ? this.session.addToast(
        'warning', 'Cannot Save', 'Document previews are not editable', 'Saving Unavailable'
    ) : (this._dirty ? (this.saveSafe ? this._save() : this.saveWarning(this._save)) : this.session.addToast(
        'warning', 'Cannot Save', 'There are no changes to save', 'Document up to date'
    ));

    bold = () => this.updateRunProps({bold: !this.formatting.bold}); // bold hotkey (Ctrl + B)
    italic = () => this.updateRunProps({italic: !this.formatting.italic}); // italic hotkey (Ctrl + I)
    underline = () => this.updateRunProps({underline: !this.formatting.underline}); // underline hotkey (Ctrl + U)
    alignLeft = () => this.updateRowProps({align: DocHelper.ALIGN.LEFT}); // left align hotkey (Ctrl + L)
    alignCenter = () => this.updateRowProps({align: DocHelper.ALIGN.CENTER}); // center align hotkey (Ctrl + E)
    alignJustify = () => this.updateRowProps({align: DocHelper.ALIGN.JUSTIFIED}); // justify align hotkey (Ctrl + R)
    alignRight = () => this.updateRowProps({align: DocHelper.ALIGN.RIGHT}); // right align hotkey (Ctrl + R)
    breakPage = (insert = true) => this.select?.focus?.node?.isSubTable ? this.insertRootRow({break: true}) : (insert ? this.insertRow : this.updateRowProps)({break: true});

    clearFormatting = () => this.updateRunProps({}, n => n.restoreDefaultFormatting(false));
    strikethrough = () => this.updateRunProps({strikethrough: !this.formatting.strikethrough});
    subscript = () => this.updateRunProps({subscript: !this.formatting.subscript, superscript: false}); // subscript hotkey (Ctrl + =)
    superscript = () => this.updateRunProps({superscript: !this.formatting.superscript, subscript: false}); // superscript hotkey (Ctrl + Shift + +)
    decreaseFontSize = () => this.updateRunProps({size: Math.max(this.formatting.size - 1, 1)}); // decrease font size hotkey (Ctrl + Shift + <)
    increaseFontSize = () => this.updateRunProps({size: Math.max(this.formatting.size + 1, 1)}); // increase font size hotkey (Ctrl + Shift + >)
    increaseIndent = () => this.updateRowProps({indent: Math.max(this.formatting.indent + 1, 1)}); // increase indent hotkey (Tab)
    decreaseIndent = () => this.updateRowProps({indent: Math.max(this.formatting.indent - 1, 0)}); // decrease indent hotkey (Shift + Tab)
    setFontSize = size => size && !isNaN(size) && this.updateRunProps({size: size});
    setFont = font => this.updateRunProps({font: DocHelper.FONT_SET.has(font) ? font : DocHelper.FONTS[0]});
    setStyle = style => (this.select.collapsed ? this.updateRowProps : this.updateRunProps)(
        {style: DocHelper.STYLE_SET.has(style) ? style : DocHelper.STYLE.NORMAL}
    );

    setColor = color => this.updateRunProps({color: color});
    setHighlight = color => color === '#ffffff' ? this.updateRunProps({}, n => delete n.il.highlight) : this.updateRunProps({highlight: color});
    setAlign = align => this.updateRowProps({align: DocHelper.ALIGN_SET.has(align) ? align : DocHelper.ALIGN.LEFT});
    sentenceCase = () => this.updateRunProps({}, n => n.transformChild(t => t.replace(
        /(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<!(?:etc|inc)\.)(?<=[.?!])\s([a-z])/gm, m => m.toUpperCase()
    )));
    upperCase = () => this.updateRunProps({}, n => n.transformChild(t => t.toUpperCase()));
    lowerCase = () => this.updateRunProps({}, n => n.transformChild(t => t.toLowerCase()));
    startCase = () => this.updateRunProps({}, n => n.transformChild(_.startCase));
    camelCase = () => this.updateRunProps({}, n => n.transformChild(_.camelCase));
    pascalCase = () => this.updateRunProps({}, n => n.transformChild(t => _.upperFirst(_.camelCase(t))));
    toggleCase = () => this.updateRunProps({}, n => n.transformChild(t => invertStringCase(_.startCase(t))));
    invertCase = () => this.updateRunProps({}, n => n.transformChild(invertStringCase));
    snakeCase = () => this.updateRunProps({}, n => n.transformChild(_.snakeCase));
    kebabCase = () => this.updateRunProps({}, n => n.transformChild(_.kebabCase));

    insertLink = link => this.updateRunProps({link: link});
    removeLink = () => this.select.collapsed ? this.select.selectAnchorRun() : this.updateRunProps({link: ''});

    addBulleted = () => {
        if (!this.editable) return false;
        if (this.formatting['numbered']) return this.updateRowProps({}, // toggle bullet/number
                n => n?.parent?.type === PROP_TYPES.LIST && n.parent.update({numbered: false})
        );
        if (!this.formatting['listable'] || this.formatting['bulleted']) return this.unwrapListNode(); // remove wrapping list i.e. convert to rows
        return this.wrapListNode(false); // wrap selected rows in a list
    }

    addNumbered = () => {
        if (!this.editable) return false;
        if (this.formatting['bulleted']) return this.updateRowProps({}, // toggle bullet/number
            n => n?.parent?.type === PROP_TYPES.LIST && n.parent.update({numbered: true})
        );
        if (!this.formatting['listable'] || this.formatting['numbered']) return this.unwrapListNode();
        return this.wrapListNode(true);
    }

    // browsers won't send keyboard commands to the doc component if multi-row text is selected due to there being no CE
    // boxes in focus on a multi-row selection. This is due to a limitation with browsers Selection implementations, the
    // current ones do not allow selections to span multiple CE boundaries, and multi-selection support was never really
    // implemented fully by all browsers; this means we need to enable and disable the CE block every time the selection
    // spans more than one row... its absolutely absurd I know. Thanks to this massive headache we need to hook into the
    // document dispatcher so we can capture keypress events even when no CE box is currently enabled in the DOM tree :)
    onDocKeyDown = e => {
        if (!e || !e.timeStamp || e.timeStamp <= this.lastDocKeyDownTimestamp) return true;
        this.lastDocKeyDownTimestamp = e.timeStamp; // prevent double-triggers (not sure why this happens *shrug*)

        // DebugConsole.debug('DocIL:onDocKeyDown', e);

        const range = this.select.selectionRange(); // check the selection; nothing to do if there's no cursor anywhere
        if (!range || !range.commonAncestorContainer) return true;

        // check that the current selection is within the doc container and the doc bar isn't in focus
        const container = this.getContainerNode(), bar = this.getDocBarNode();
        if (container && bar && container.contains(range.commonAncestorContainer) &&
            (e.target === document.body || container.contains(e.target) || bar.contains(e.target))
        ) return this.onKeyDown(e);  // if its all good, then pass to the standard handler
    }

    onKeyDown = e => {
        if (!e || !e.timeStamp || e.timeStamp <= this.lastKeyDownTimestamp) return true;
        this.lastKeyDownTimestamp = e.timeStamp; // prevent double-triggers (not sure why this happens *shrug*)

        if (!this.editable) {
            switch (e.key) { // just ignore any keys that aren't arrow keys :)
                case 'ArrowUp':
                case 'ArrowRight':
                case 'ArrowDown':
                case 'ArrowLeft':
                    break;
                default:
                    return false;
            }
        }

        let domNode = e.target;
        while (domNode && domNode.parentElement) { // horrible loop to check where we are, got to be a better way than this surely?
            if (domNode.nodeType !== Node.ELEMENT_NODE) domNode = domNode.parentElement;
            if (domNode.classList.contains('xfi-actions')) return true;
            if (domNode.classList.contains('pad')) break;
            domNode = domNode.parentElement;
            if (domNode === document.body) break;
        }

        DebugConsole.debug('DocIL:onKeyDown', e);

        this.snapInit();
        // QQ: Why are we using ContentEditable but overriding pretty much everything?
        // QA: CE has very different behaviour between browsers and is generally awful to use and causes more issues
        //     than it resolves, therefore we use it as an event hook essentially, everything else is controlled :)
        let stop = false, checked = true;
        switch(e.key) {
            default:
                checked = false;
                break;
            case 'Home':
            case 'End':
                return true;
            case 'Enter':
                // new lines in the same paragraph are effectively new rows with no top spacing applied :)
                if (e.ctrlKey) this.breakPage(true); // new page break paragraph
                else if (e.shiftKey) this.insertRow({spacing: {before: 0}}); // new line in the same paragraph
                else this.insertRow(); // new normal paragraph
                stop = true;
                break;
            case 'Backspace':
                if (e.altKey && this.select?.focus?.node?.isSubTable) {
                    // Alt + Backspace in word deletes the last row in the table, so lets copy that behaviour here :)
                    const cell = this.select?.focus?.node?.closestTableCell;
                    const row = cell ? cell?.closestTableRow : null;
                    const table = row ? row?.closestTable : null;
                    const total = table ? table.length : 0;
                    if (!total && cell && cell.isFirstCell) {
                        // empty table, delete it :)
                        const parent = table.parent;
                        const prev = table.prevRun;
                        const select = prev ? prev : table.nextRun;
                        const offset = prev ? prev.length : 0;
                        table.remove(true);
                        parent.rerender(() => this.select.setCursor(select, offset ? offset : 0, true));
                    }
                    else if (row && !cell.prev && !row.length) {
                        // empty row in the table and the first cell is selected, delete the row
                        const prev = row.prevRun;
                        const select = prev ? prev : row.nextRun;
                        const offset = prev ? prev.length : 0;
                        row.remove(true);
                        table.rerender(() => this.select.setCursor(select, offset ? offset : 0, true));
                    }
                }
                else stop = this.delete(false);
                break;
            case 'Delete':
                stop = this.delete(true);
                break;
            case 'Tab': // different logic within tables to move the cursor between cells
                if (this.select?.focus?.node?.isSubTable) {
                    if (e.shiftKey) this.select.prevCaret();
                    else {
                        if (this.select?.focus?.node?.closestTableCell?.isLastCell) {
                            // last cell in the table, tab needs to insert a new row :)
                            const table = this.select?.focus?.node?.closestTable;

                            if (table && table.addRow) {
                                const rows = table.addRow();
                                const run = rows && rows?.length && rows[0]? rows[0]?.first?.first?.first : null;
                                if (run && table?.rerender) table.rerender(() => this.select.setCursor(run, 0, true));
                            }
                        } else this.select.nextCaret();
                    }
                }
                else e.shiftKey ? this.decreaseIndent() : this.increaseIndent();
                stop = true;
                break;
            case 'ArrowUp':
                stop = this.select.moveCursor(true, true, e.shiftKey);
                break;
            case 'ArrowRight':
                stop = this.select.moveCursor(false, false, e.shiftKey);
                break;
            case 'ArrowDown':
                stop = this.select.moveCursor(false, true, e.shiftKey);
                break;
            case 'ArrowLeft':
                stop = this.select.moveCursor(true, false, e.shiftKey);
                break;
            case 'a':
            case 'A':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.selectAll();
                break;
            case 's':
            case 'S':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.save();
                break;
            case 'b':
            case 'B':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.bold();
                break;
            case 'i':
            case 'I':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.italic();
                break;
            case 'u':
            case 'U':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.underline();
                break;
            case 'l':
            case 'L':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.alignLeft();
                break;
            case 'j':
            case 'J':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.alignJustify();
                break;
            case 'e':
            case 'E':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.alignCenter();
                break;
            case 'r':
            case 'R':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.alignRight();
                break;
            case 'x':
            case 'X':
                if (e.ctrlKey) {
                    this.lasCutPlain = e.shiftKey;
                    if (e.shiftKey) {
                        // change the cut data to be plain-text only,
                        // we do it here as some browsers don't fire the cut event with the shift key down
                        if (!this.select.collapsed) {
                            const text = window.getSelection().toString();
                            navigator.clipboard.writeText(text).then(() => this.deleteSelectedNodes(), () => this.session.addToast(
                                'error', 'Cut failed', 'Unable to move data to the clipboard', 'Operation failed'
                            ));
                        }
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                    return true;
                }
                else break;
            case 'c':
            case 'C':
                if (e.ctrlKey) {
                    this.lastCopyPlain = e.shiftKey;
                    if (e.shiftKey) {
                        // change the copy data to be plain-text only,
                        // we do it here as some browsers don't fire the copy event with the shift key down
                        if (!this.select.collapsed) {
                            const text = window.getSelection().toString();
                            navigator.clipboard.writeText(text).then(() => this.session.addToast(
                                'info-square', 'Data copied', 'Plain text data copied to clipboard', 'Operation successful'
                            ), () => this.session.addToast(
                                'error', 'Copy failed', 'Unable to copy data to the clipboard', 'Operation failed'
                            ));
                        }
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                    return true;
                }
                else break;
            case 'v':
            case 'V':
                // if (!e.ctrlKey) break;  // we only capture ctrl + key
                // stop = this.paste(e.shiftKey);
                // break;
                if (e.ctrlKey) {
                    this.lastPastePlain = e.shiftKey;
                    return true;
                }
                else break;
            case 'z':
            case 'Z':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.history(e.shiftKey);
                break;
            case 'y':
            case 'Y':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.history(true);
                break;
            case '=':
                if (!e.ctrlKey) break;  // we only capture ctrl + key
                stop = true;
                this.subscript();
                break;
            case '+':
                if (!e.ctrlKey || !e.shiftKey) break;  // we only capture ctrl + shift + key
                stop = true;
                this.superscript();
                break;
            case '<':
                if (!e.ctrlKey || !e.shiftKey) break;  // we only capture ctrl + shift + key
                stop = true;
                this.decreaseFontSize();
                break;
            case '>':
                if (!e.ctrlKey || !e.shiftKey) break;  // we only capture ctrl + shift + key
                stop = true;
                this.increaseFontSize();
                break;
        }
        if (!stop) {
            const printable = !e.ctrlKey && ((e.key === ' ') || isPrintableKeyEvent(e));
            // to prevent the browser randomly inserting br tags or whatever we manage edits ourselves here
            if (printable && !this.select.collapsed){
                this._snapText = false; // treat bulk deletes as non-textual so we get an immediate restore point
                this.deleteSelectedNodes(e.key);
                stop = true;
            } else if (this?.constructor?.directInserts) {
                this._snapText = printable;

                if (printable) {
                    this.insertText(e.key);
                    stop = true;
                }
            }

            if (!printable && !checked) {
                stop = true;  // cancel anything other than typing, if we haven't already processed it
                this._snapText = false;
            }
        } else this._snapText = false;
        if (stop) {
            e.preventDefault();
            e.stopPropagation();
        }
        return !stop;
    }


    render = (props = {}) => this.il.render(props); // Returns a react component JSX tree for this IL document
    docx = () => this.il.docx(); // Returns a docx OOXML document tree for this IL document
    store = (includeIDs = false) => this.il.storeWithIDs(includeIDs); // Returns a JSON tree object for this IL document

    get isRow() {
        return false;
    }

    get isRun() {
        return false;
    }

    get isRoot() {
        return true;
    }

    // moves callback execution to the end of the JS event queue (i.e. execute after the UI thread is finished)
    requeue = (callback, timeout = 0) => setTimeout(callback, timeout);

    // TODO: Upgrade pagination so it calculates pages rather than basing it on rendered content
    //       Is it even worth it? Word doesn't even do that, it calculates as it renders :shrug:
    paginate = (callback = () => {}) => this.requeue(() => this._paginateWithoutRenders(callback));

    // this version calculates all page breaks after a single overflowed render then only updates if they differ
    // TODO: Check performance of both pagination versions, a different approach may be needed, my laptop is rather warm XD
    _paginateWithoutRenders = (callback = () => {}) => {
        if (!this?.context?.ctx) return; // no context, no pagination :(
        const flow = new Set(), headers = [];
        let head = null;

        for (const section of this.il.children) {
            const sect = section.ref.current;
            if (!sect) {
                DebugConsole.warn('Invalid section reference', section.rep, sect);
                continue;
            }

            const { margin, height } = sect.proxy;
            if (!margin || !height) continue;
            const pixels = DocHelper.mmToPixels(height - (margin * 2), this.context.ctx.ppi);
            if (!pixels) {
                DebugConsole.warn('Page height is zero? somethings not working as it should be...', section.rep, margin, pixels);
                continue;
            }

            let pageHeight = 0;

            const first = section.first;
            if (!first) {
                DebugConsole.warn('First Node not found, pagination may be incorrect', section.rep, first);
                continue;
            }
            const f1Dom = first.dom;
            if (!f1Dom) {
                DebugConsole.warn('First DOM element not found, pagination may be incorrect', first.rep, f1Dom);
                continue;
            }

            let el = f1Dom.parentElement, lcm = 0, ignoreFooter = false;
            while (el && el.parentElement && !el.classList.contains('pad') && (lcm++ < MAX_ITERATIONS)) el = el.parentElement;
            const footerHeight = (!el || !el.nextElementSibling || !el.nextElementSibling.classList.contains('footer')) ? 0 : el.nextElementSibling.offsetHeight;
            const maxHeight = pixels - footerHeight;

            for (const row of section.children) {
                if (row.isImage && row?.il?.background) {
                    ignoreFooter = true;
                    continue; // skip background images as they're absolute
                }

                const level = DocHelper.getStyleHeadingLevel(row?.il?.style);
                if (level) {
                    const text = row.textContent;
                    if (text && text.length) {
                        const newHead = {
                            id: row.uuid,
                            value: text,
                            label: <span>{text}</span>,
                            renderIcon: null,
                            onSelect: () => this.scrollNodeIntoView(row, {block: 'start', inline: 'center'}),
                            isExpanded: level < 3,
                        };

                        if (level <= 1) {
                            headers.push(newHead);
                            head = newHead;
                        } else {
                            let _head = head;
                            for (let i = level; i > 2; i--) {
                                if (!_head || !_head.children) break;
                                _head = _head.children[_head.children.length - 1];
                            }
                            if (!_head.children) _head.children = [];
                            if (_head) _head.children.push(newHead);
                        }
                    }
                }

                const dom = row.dom;
                if (!dom) {
                    DebugConsole.warn('Row DOM element not found, pagination may be incorrect', row, row.rep, dom);
                    continue;
                }

                let top = dom;
                while (top && top.parentElement && !top.parentElement.classList.contains('pad')) top = top.parentElement;

                const rect = top.getBoundingClientRect();
                const style = window.getComputedStyle(top);
                const rowHeight = ['margin-top', 'margin-bottom'].map(
                    k => parseInt(style.getPropertyValue(k))
                ).reduce((a, b) => a + b, rect.height);

                const newHeight = pageHeight + rowHeight;

                if (row?.il?.break) {
                    // page break!
                    pageHeight = rowHeight;
                }
                else if (newHeight > (ignoreFooter ? pixels : maxHeight)) {
                    pageHeight = rowHeight;
                    flow.add(row.uuid);
                    ignoreFooter = false;
                }
                else pageHeight = newHeight;
            }
        }

        this?._navRef?.current?.update && this._navRef.current.update(headers);

        if ((this.context.ctx.flow.size === flow.size) && _.isEqual(this.context.ctx.flow, flow))
            return callback(); // nothing changed, save ourselves a render

        this.context.ctx.flow.clear();
        this.context.ctx.flow = flow;
        return this.fullRender(callback);
    }

    scrollNodeIntoView = (node, opts = {}) => scrollIntoView(node.dom, {behavior: 'smooth', scrollMode: 'if-needed', block: 'nearest', inline: 'nearest', ...opts});

    // TODO: This whole pagination thing is just eww, find a better way!
    _paginate = (callback = () => {}, currentUUID = null) => {
        // TODO: Implement multi-page paragraph support, at the moment we move the whole paragraph into a new page :(
        //       Ideally the rows themselves would monitor for overflows and split/merge/add rows/breaks where needed
        //       Or perhaps some system where a copy of the overflown content is shown on the next page... :shrug:

        if (!this?.context?.ctx) return; // no context, no pagination :(
        let lastSeen = null, skipping = currentUUID !== null, lastRow = null, lastPad = null;

        for (const section of this.il.children) {
            const sect = section.getReactNode(false);
            if (!sect) continue;

            for (const row of section.children) {
                if (skipping) { // super basic resume functionality, saves us some cycles, this thing was slow!
                    if (currentUUID === row.uuid) {
                        skipping = false;
                        lastSeen = row.uuid;
                        lastRow = row;
                    }
                    continue;
                }
                const hasRow = this.context.ctx.flow.has(row.uuid);
                const dom = row.dom;
                if (!dom) {
                    lastRow = row;
                    continue;
                }

                let pad = dom.parentElement, lcm = 0;
                while (pad && pad.parentElement && !pad.classList.contains('pad') && (lcm++ < MAX_ITERATIONS)) pad = pad.parentElement;
                if (!pad) {
                    DebugConsole.error("Page pad element not found");
                    lastRow = row;
                    continue;
                }

                const r1 = pad.getBoundingClientRect();
                const r2 = dom.getBoundingClientRect();
                if (!r1 || !r2) {
                    DebugConsole.error("Invalid page bounding rects:", r1, r2);
                    lastRow = row;
                    lastPad = pad;
                    continue;
                }

                let overflow = r2.bottom > r1.bottom;

                if (hasRow) {
                    if (overflow) {
                        DebugConsole.error("Row too long to paginate, multi-page row implementation needed");
                        lastRow = row;
                        continue;
                    }
                    // check that the row still needs to be overflowed, otherwise remove it and reflow
                    if (lastRow && lastPad) {
                        const d1 = lastRow.dom;
                        if (d1) {
                            const r3 = lastPad.getBoundingClientRect(), r4 = d1.getBoundingClientRect();
                            if (r3 && r4) {
                                const dif = r3.bottom - r4.bottom;
                                if (dif > r2.height) {
                                    this.context.ctx.flow.delete(row.uuid);
                                    overflow = true;
                                }
                            }
                        }
                    }

                    if (!overflow) {
                        lastSeen = row.uuid;
                        lastRow = row;
                        lastPad = pad;
                        continue;
                    }
                }
                lastRow = row;
                lastPad = pad;

                if (overflow) {
                    // overflowing content, copy our flow up to the last row then add ourselves to the end
                    // I hate copying this for every page, it feels stupidly inefficient, find a better way!
                    const flow = new Set();
                    if (lastSeen) {
                        for (const uuid of this.context.ctx.flow) {
                            if (uuid === lastSeen) break;
                            flow.add(uuid);
                        }
                        this.context.ctx.flow.clear();
                    }
                    flow.add(row.uuid);
                    this.context.ctx.flow = flow;
                    return this.rootReactNode.forceUpdate(() => this._paginate(callback, row.uuid));
                }
            }
        }

        return callback();
    }

    snapshot = () => this.il.snapshot();
    snapInit = (fresh = false) => {
        if (this._snapping) { // clear our old requeue, snapSave should always be last to run
            clearTimeout(this._snapTime);
            if (fresh) {
                this.snapSave();
                this._snapping = false; // force this in case snapSave bails on empty map
            }
        }
        // no else here as snapSave above will change the value of _snapping if it gets called :)
        if (!this._snapping) {
            this._snapping = true;
            this._snapMeta = {
                selection: { // store our old selection state with the history so we can undo properly
                    anchor: {uuid: this.select.anchor.uuid, offset: this.select.anchor.offset},
                    focus: {uuid: this.select.focus.uuid, offset: this.select.focus.offset}
                }
            };
        }
        this._snapTime = setTimeout(this.snapSave, this._snapText ? this.constructor.snapTextTimeout : this.constructor.snapBaseTimeout);
    }
    snapHook = (node, oldSnap, newSnap) => {
        if (!this._snapping) return; // bail if we're not tracking changes
        this.snapInit(); // always requeue or snapSave call, make sure its very last to execute

        const uuid = node.uuid;
        if (!uuid) return;

        // I'm not a big fan of storing both the before and after node content in the history,
        // but I can't see an easier way to store state changes without needing to loop through
        // history lists whenever undo/redo needs content not in its closest sibling history item.
        // As we want this to be performant, a slight memory overhead will suffice for now :)
        if (this._snapsMap.has(uuid))
            this._snapsMap.set(uuid, {node: node, base: this._snapsMap.get(uuid).base, snap: newSnap});
        else this._snapsMap.set(uuid, {node: node, base: oldSnap, snap: newSnap});
    }
    snapSave = () => {
        if (!this._snapping || !this._snapsMap.size) return;
        this._snapping = false;

        const snapStore = {
            meta: {
                base: this._snapMeta,
                snap: {
                    selection: { // store our new selection state with the history so we can redo properly
                        anchor: {uuid: this.select.anchor.uuid, offset: this.select.anchor.offset},
                        focus: {uuid: this.select.focus.uuid, offset: this.select.focus.offset}
                    }
                }
            },
            data: []
        };
        const ignore = new Set(), snappy = new Set();
        for (const [uuid, snap] of this._snapsMap) { // merge & re-snap
            // if multiple snaps refer to the same elements take a new snap of the outermost node and use that
            if (!snap?.uuid) snap.uuid = uuid;
            let node = snap.node.parent, nid = uuid;
            while (node && !node.isRoot) {
                if (this._snapsMap.has(node.uuid)){
                    ignore.add(nid);
                    snappy.add(node.uuid);
                }
                nid = node.uuid;
                node = node.parent;
            }
        }
        for (const [uuid, snap] of this._snapsMap) { // rebuild and store
            if (ignore.has(uuid)) continue;
            if (snappy.has(uuid)) snap.snap = snap.node.snapshot();
            snapStore.data.push({uuid: uuid, base: _.cloneDeep(snap.base), snap: _.cloneDeep(snap.snap)});
        }
        this._snapsMap.clear();
        this._snapMeta = {};

        this._histIdx++; // increment our history index
        this._history.length = this._histIdx; // delete anything forward of our current point
        this._history.push(snapStore);
        this._dirty = true;
        this.saveHistory();
        this.paginate(); // Do we really want to do this after every update?
    }

    loadHistory = (ignoreRevision = false) => {
        if (!this.editable) return;
        const rev = window.localStorage.getItem(this.uuid + '-revision');
        const revision = (rev !== null) ? parseInt(rev) : this.revision;
        const num = window.localStorage.getItem(this.uuid + '-histIdx');
        const idx = (num !== null) ? parseInt(num) : -1;

        // document changed on the server since the history was stored... oops!
        if (this.revision !== revision) { // revision changed, we need to confirm what to do with the user
            if (idx < 0) return this.clearHistory(); // if we're at idx < 0 then bail and wipe the history
            if (!ignoreRevision) {
                DebugConsole.warn('Local document data is outdated!');
                return this.revisionWarning(() => this.loadHistory(true), () => this.clearHistory());
            }
            this.saveSafe = false;
            window.localStorage.setItem(this.uuid + '-safe', 'n');
            window.localStorage.setItem(this.uuid + '-revision', this.revision); // update the cache
        }

        const history = this.readJSON(window.localStorage.getItem(this.uuid + '-history'));
        if (history) this._history = history;

        this._dirty = window.localStorage.getItem(this.uuid + '-dirty') === 'y';
        this.saveSafe = window.localStorage.getItem(this.uuid + '-safe') !== 'n';

        const resume = (idx >= 0) ? () => this.history(true, idx + 1, this.rootReactNode.stopLoad) : this.rootReactNode.stopLoad;

        // restore to the current history index state by repeating the edit history
        DebugConsole.log("Restoring previous edit state...");
        this.rootReactNode.initLoad('Restoring previous state...', () => this.requeue(() => {
            const base = this.readJSON(window.localStorage.getItem(this.uuid + '-baseSnap'));
            if (!base) return resume();
            this.il.restore(base, true, true); // restore our base doc and its original UUIDs
            this.fullRender(() => resume());
        }, 10));
    }
    saveHistory = () => {
        if (!this.editable) return;
        try { // TODO: local storage might need swapping out for something else if the size limit becomes an issue
            window.localStorage.setItem(this.uuid + '-revision', this.revision);
            window.localStorage.setItem(this.uuid + '-histIdx', this._histIdx);
            window.localStorage.setItem(this.uuid + '-history', this.saveJSON(this._history));
            window.localStorage.setItem(this.uuid + '-dirty', this._dirty ? 'y' : 'n');
            if (!window.localStorage.getItem(this.uuid + '-baseSnap'))
                window.localStorage.setItem(this.uuid + '-baseSnap', this.saveJSON(this.il.snapshot()));
        } catch (e) {
            if ((e.name === 'QuotaExceededError') && this._history.length) {
                // limit reached... remove the oldest element and try again
                this._history.shift()
                this._histIdx--;

                // handle instances where we're at the back of the queue, this should never really happen unless
                // the edit that reached the memory limit is a single edit i.e. a super-large paste or something
                // in these cases we revert back to the latest edit and restore the history as we do on page load
                if (this._history.length && (this._histIdx < 0)) {
                    this._histIdx = this._history.length - 1;
                    this.saveHistory();
                    this.loadHistory();
                }
                else this.saveHistory(); // ok so we've removed an entry, try saving again
            }
        }
    }
    clearHistory = () => {
        window.localStorage.removeItem(this.uuid + '-safe');
        window.localStorage.removeItem(this.uuid + '-revision');
        window.localStorage.removeItem(this.uuid + '-histIdx');
        window.localStorage.removeItem(this.uuid + '-history');
        window.localStorage.removeItem(this.uuid + '-baseSnap');
    }
    readJSON = snap => this.prepJSON(snap, true);
    saveJSON = snap => this.prepJSON(snap, false);
    prepJSON = (snap, read = false) => {
        try {
            return read ? JSON.parse(snap) : JSON.stringify(snap);
        } catch (e) {
            return null;
        }
    }

    // history - callback is a self-calling no-op pass-through by default, to use this you must
    //           pass in a callback function that accepts another callback as a single argument
    //           this is to be called at the end of whatever callback action is used. This only
    //           really exists so the history restore function can disable the loading spinner,
    //           before the browser selection range is then restored based on the history meta.
    history = (redo = false, count = 1, callback = (cb = () => {}) => cb()) => {
        if (!this.editable) return;
        const renders = new Map();
        let history = null;

        for (let i = 0; i < count; i++) {
            if (redo) {
                const newIdx = this._histIdx + 1;
                if (newIdx >= this._history.length) return; // no more forward history, nothing to redo
                history = this._history[newIdx];
                this._histIdx = newIdx;

                // when redoing we use the snap element from the next history item to "redo" the last change set

            } else {
                if (this._histIdx < 0) return; // we're right at the start, nothing to undo
                history = this._history[this._histIdx];
                this._histIdx--;

                // when undoing we use the base element from the current history item to "undo" the current change set
            }

            // we retain the snapshot update order and reverse it when undoing data to replay the edit actions
            for (const snap of history.data) {
                const node = this.retrieve(snap.uuid);
                if (!node) continue;
                node.restore(redo ? snap.snap : snap.base);
                if (node.isRun) renders.set(node.parent.uuid, node.parent); // need to re-render the row for CE blocks
                else renders.set(snap.uuid, node);
            }
        }

        const r = renders.values();
        const f = () => {
            const _r = r.next();
            if (_r.done) {
                const select = (redo ? history?.meta?.snap : history?.meta?.base)?.selection;
                const anchor = select ? this.retrieve(select.anchor.uuid) : null;
                const focus = select ? this.retrieve(select.focus.uuid) : null;
                this.paginate(() => callback(() => (anchor && focus) && this.select.select(anchor, select.anchor.offset, focus, select.focus.offset)));
            }
            else _r.value.rerender(f);
        };
        f();
    }

    drawBox = (
        top, left, width, height, color = 'red', style='dashed', border='1px',
        radius=null, timeout= 450, zIndex = '1000000', callback = () => true
    ) => {
        if (this.constructor.showSelection) {
            const uuid = uuid4();
            const bx = drawBox(
                top, left, width, height, color, style, border, radius, timeout, zIndex, _bx => {
                    if (!callback(_bx) || !this.box.has(uuid)) return false;
                    this.box.delete(uuid);
                    return true;
                }
            );
            this.box.set(uuid, bx);
        }
    }

    clearBoxes = () => {
        this.box.forEach(({ box , time }) => {
            clearTimeout(time);
            try {
                document.body.removeChild(box);
            } catch (_) {}
        });
        this.box.clear();
    }
    getReactNode = () => null;
}