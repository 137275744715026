import React from "react";
import Locale from "../../backend/Locale"

class TimezoneContext {
    constructor(tzList = null) {
        this.timezones = tzList || [];
        this.timezonesObj = Object.fromEntries(this.timezones.map(m => [m.id, m]))
    }
    getTimezones(callback = () => {}) {
        if (!this.timezones || !Array.isArray(this.timezones) || !this.timezones.length )
            Locale.timezones().then( result => {
                this.timezones = result.timezones;
                this.timezonesObj = Object.fromEntries(this.timezones.map(m => [m.id, m]));
                callback()
            });
        return this.timezones;
    }
    getTimezoneById(id) {
        return (!(id in this.timezones)) ? this.timezones[id] : null;
    }

}

export const baseTzContext = new TimezoneContext();

const TzContext = React.createContext({
    ctx: baseTzContext
});

export default TzContext;

