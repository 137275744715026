import React, { Component } from 'react';
import {
    Header,
    HeaderName,
    HeaderNavigation,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderGlobalBar,
    HeaderGlobalAction,
    HeaderPanel,
    SkipToContent,
    Switcher,
    SwitcherItem,
    SwitcherDivider
} from "carbon-components-react/es/components/UIShell";
import { Link } from "react-router-dom";
import SessionContext from "../../helpers/SessionContext";
import {HeaderContainer, SideNav, HeaderSideNavItems, SideNavMenuItem} from "carbon-components-react";
import NavigationHelper from "../../helpers/Navigation";
import {IbmSecurity20, User20} from "@carbon/icons-react";
import Account from "../../backend/Account";
import './main-header.scss';


class MainHeader extends Component {
    static contextType = SessionContext;
    constructor(props) {
        super(props);
        this.state = {
            panelActive: false
        };
    }
    render() {
        return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <Header aria-label="Header" className="xf-main-header">
                        <SessionContext.Consumer>
                            {s => <div className="xf-progress" style={s.progress > 0 ? {width: `${s.progress}%`} : {display: 'none'}}/>}
                        </SessionContext.Consumer>
                        <SkipToContent />
                        <HeaderMenuButton aria-label="Open menu" onClick={onClickSideNavExpand} isActive={isSideNavExpanded}/>
                        <div className="header-xf-icon">
                            <IbmSecurity20 />
                        </div>
                        <HeaderName element={Link} to="/" prefix="X-Force">
                            <span className="xf-divider">|</span>Portal
                        </HeaderName>
                        <HeaderNavigation aria-label="Portal">
                            <SessionContext.Consumer>
                                {s => s.user.authorised ? NavigationHelper.getHeaderMenuItems(s.user.internal, HeaderMenuItem) :
                                    <HeaderMenuItem element="a" href={Account.sso_url()} key="sso-redirect">Login with IBMid</HeaderMenuItem>
                                }
                            </SessionContext.Consumer>
                        </HeaderNavigation>
                        <HeaderGlobalBar>
                            <SessionContext.Consumer>
                                {s => s.user.authorised &&
                                    <HeaderGlobalAction
                                        key="user" aria-label="User Options" className="xf-profile-pic xf-nav-ico"
                                        tooltipAlignment="end"
                                        onClick={() => this.setState(state => ({panelActive: !state.panelActive}))}
                                    >
                                        <User20 />
                                        {/*<img src={Account.photo_url()} alt={'forename' in s.user ? s.user.forename : 'User'}/>*/}
                                    </HeaderGlobalAction>
                                }
                            </SessionContext.Consumer>
                        </HeaderGlobalBar>
                        <SessionContext.Consumer>
                            {s => s.user.authorised &&
                                <HeaderPanel aria-label="Header Panel" expanded={this.state.panelActive} className="xf-side-panel">
                                    <div className="xf-hp-inner">
                                        <div className="xf-user-info">
                                            <div className="xui-head">Logged in as:</div>
                                            <div className="xui-sub">{s.user.name} ({s.user.username})</div>
                                            <div className="xui-sub">{s.user.email}</div>
                                        </div>
                                        {s.user.username !== 'anon' && <Switcher aria-label="User" className="xf-switcher">
                                            <SwitcherDivider />
                                            <SwitcherItem aria-label="Logout" href="/api/account/logout">Logout</SwitcherItem>
                                            <SwitcherDivider />
                                        </Switcher>}
                                    </div>
                                </HeaderPanel>
                            }
                        </SessionContext.Consumer>
                        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
                            <HeaderSideNavItems>
                                <HeaderSideNavItems>
                                    <SessionContext.Consumer>
                                        {s => s.user.authorised ?
                                            NavigationHelper.getHeaderMenuItems(s.user.internal, SideNavMenuItem) :
                                            <SideNavMenuItem element="a" href={Account.sso_url()} key="sso-redirect">
                                                Login with IBMid
                                            </SideNavMenuItem>
                                        }
                                    </SessionContext.Consumer>
                                </HeaderSideNavItems>
                            </HeaderSideNavItems>
                        </SideNav>
                    </Header>
                )}
            />
        );
    }
}
export default MainHeader;
