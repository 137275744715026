import React from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import { withRouter } from 'react-router-dom';
import {
    FormGroup
} from "carbon-components-react";
import SessionContext from "../../helpers/SessionContext";
import Loading from "../../components/Loading";
import './clients.scss';
import ClientStructuredList from "../../components/ClientStructuredList";
import Client from "../../backend/Clients"
import Project from "../../backend/Projects"
import Contract from "../../backend/Contracts"
import ContractItems from "../../backend/ContractItems"
import ContractStructuredList from "../../components/ContractStructuredList";
import ProjectStructuredList from "../../components/ProjectStructuredList";
import ContractItemDataTable from "../../components/ContractItemDataTable";
import TestsDataTable from "../../components/TestsDataTable";
import update from 'immutability-helper'

class Clients extends React.Component {
    static contextType = SessionContext;

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            clients: null,
            contracts: null,
            projects: null,
            contract_items: null,
            tests: null,
            rows: [],
            total: 0,
            loading: false
        };

        this.clientHandler = this.clientHandler.bind(this);
        this.contractHandler = this.contractHandler.bind(this);
        this.projectHandler = this.projectHandler.bind(this);
        this.contractItemHandler = this.contractItemHandler.bind(this);
        this.testsHandler = this.testsHandler.bind(this);
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        Client.list().then(result => {
            if(result.clients){
                this.setState( s => ({
                    clients: { ...s.clients, ...result.clients}
                }));
                this.handleUrlUuids()
            }
        }).catch(this.context.networkFaultHandler)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props;
        if(prevProps.match.params !== match.params){
            this.handleUrlUuids()
        }
    }

    handleUrlUuids(){
        let { match } = this.props;
        let client_uuid = match && match.params && match.params.client_uuid ? match.params.client_uuid : null;
        let project_uuid = match && match.params && match.params.project_uuid ? match.params.project_uuid: null;
        let contract_uuid = match && match.params && match.params.contract_uuid ? match.params.contract_uuid: null;

        if (client_uuid) this.clientHandler(null, null)
        if (project_uuid) this.projectHandler(null, null)
        if (contract_uuid) this.contractHandler(null,null)
    }

    clientHandler(clients, client){
        let { match } = this.props;
        if(client && match?.params?.client_uuid){
            if(match.params.client_uuid !== client) this.props.history.push('/clients/' + client)
            else this.props.history.push('/clients/')
        }
        else if (client) this.props.history.push('/clients/' + client)

        if(clients) {
            this.setState({
                clients: clients
            })
        }

        //TODO: Check for existing data for cache
        if(client || match?.params?.client_uuid) {
            let id = client ? client : match.params.client_uuid
            Contract.list(id).then(result => {
                if (result.contracts) {
                    this.setState(s => ({
                        contracts: {...s.contracts, ...result.contracts}
                    }))
                }
            }).catch(this.context.networkFaultHandler)

            Project.list(id).then(result => {
                if (result.projects) {
                    this.setState(s => ({
                        projects: {...s.projects, ...result.projects}
                    }))
                }
            }).catch(this.context.networkFaultHandler)
        }
    }

    contractHandler(contracts, contract){
        let { match } = this.props;
        if(contract && match?.params?.contract_uuid && match.params.client_uuid) {
            if(match.params.contract_uuid !== contract) this.props.history.push('/clients/' +
                this.props.match.params.client_uuid + '/contracts/' + contract);
            else this.props.history.push('/clients/' + this.props.match.params.client_uuid);
        }
        else if(contract) this.props.history.push('/clients/'+ this.props.match.params.client_uuid +
            '/contracts/' + contract);

        if(contracts){
            this.setState(s => ({contracts: {...s.contracts, ...contracts}}))
            if(contract && !this.state.clients[match.params.client_uuid]
                .contracts.includes(contract)){
                    let newClients = update(this.state.clients, {
                        [match.params.client_uuid]: {
                            contracts: {$push: [contract]}
                        }
                    })
                    this.setState(s => ({clients: {...s.clients, ...newClients}}))
            }
        }

        if(match.params.contract_uuid && this.state.contracts &&
            this.state.contracts[match.params.contract_uuid] && this.state.contract_items){
            if(!this.state.contracts[match.params.contract_uuid]?.items.every(c => (c in this.state.contract_items))) {
                let c = !contract && match?.params?.contract_uuid ? match.params.contract_uuid : contract;
                ContractItems.list(c)
                    .then(result => {
                        if (result.contract_items) {
                            this.setState(s => ({
                                contract_items: {...s.contract_items, ...result.contract_items}
                            }))
                        }
                    })
                    .catch(this.context.networkFaultHandler)
            }
        }
        else {
            let c = !contract && match?.params?.contract_uuid ? match.params.contract_uuid : contract;
            ContractItems.list(c)
                .then(result => {
                    if (result.contract_items) {
                        this.setState(s => ({
                            contract_items: {...s.contract_items, ...result.contract_items}
                        }))
                    }
                })
                .catch(this.context.networkFaultHandler)
        }

    }

    contractItemHandler(contractItems, contractItem){
        let {match} = this.props;
        if(contractItems && match.params.contract_uuid){
            this.setState(s => ({contract_items: {...s.contract_items, ...contractItems}}))
            //TODO:update client contract id array
            if(contractItem && !this.state.contracts[match.params.contract_uuid]
                .items.includes(contractItem)){
                let newContracts = update(this.state.contracts, {
                    [match.params.contract_uuid]: {
                        items: {$push: [contractItem]}
                    }
                })
                this.setState(s => ({contracts: {...s.contracts, ...newContracts}}))
            }
        }
    }

    projectHandler(projects, project){
        let { match } = this.props;
        if(project && match?.params?.project_uuid && match.params.client_uuid) {
            if(match.params.project_uuid !== project) this.props.history.push('/clients/' +
                this.props.match.params.client_uuid + '/projects/' + project);
            else this.props.history.push('/clients/' + this.props.match.params.client_uuid);
        }
        else if(project) this.props.history.push('/clients/'+ this.props.match.params.client_uuid +
            '/projects/' + project);

        if(projects){
            this.setState(s => ({projects: {...s.projects, ...projects}}))
            //TODO:update client contract id array
            if(project && match?.params?.client_uuid && !this.state.clients[match.params.client_uuid]
                .projects.includes(projects)){
                let newClients = update(this.state.clients, {
                    [match.params.client_uuid]: {
                        projects: {$push: [project]}
                    }
                })
                this.setState(s => ({clients: {...s.clients, ...newClients}}))
            }
        }

        let p = !project && match?.params?.project_uuid ? match.params.project_uuid : project;
        Project.list_tests(p)
            .then(result => {
                if(result.tests) {
                    this.setState(s => ({
                        tests: {...s.tests, ...result.tests}
                    }))
                }
            })
            .catch(this.context.networkFaultHandler)

    }

    testsHandler(tests, test){

    }


    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { match } = this.props;
        const client_uuid = match && match.params && match.params.client_uuid ? match.params.client_uuid : null;
        const project_uuid = match && match.params && match.params.project_uuid ? match.params.project_uuid : null;
        const contract_uuid = match && match.params && match.params.contract_uuid ? match.params.contract_uuid : null;
        const hasClient = this.state.clients && client_uuid && client_uuid in this.state.clients;
        const hasProject = this.state.projects && project_uuid;
        const hasContract = this.state.contracts && contract_uuid;

        const contracts = hasClient && this.state.contracts ? Object.entries(this.state.contracts).filter(
            ([id]) => this.state.clients[client_uuid].contracts.includes(id)) : [];

        const projects = hasClient && this.state.projects ? Object.entries(this.state.projects).filter(
            ([id]) => this.state.clients[client_uuid].projects.includes(id)) : [];

        const tests = hasClient && hasProject && this.state.tests && this.state.projects[project_uuid] ?
            Object.entries(this.state.tests).filter(
                ([id]) => this.state.projects[project_uuid].tests.includes(parseInt(id))) : [];

        const contract_items = hasClient && hasContract && this.state.contract_items && this.state.contracts[contract_uuid]?
            Object.entries(this.state.contract_items).filter(
                ([id]) => this.state.contracts[contract_uuid].items.includes(id)) : [];

        return (
            <Content className="xfm-wrap">
                {this.state.loading && <Loading hasLogo={true} modal={true} message="Loading..." />}
                <div className="xf-manage">
                    <div className="bx--data-table-header">
                        <div className="xtp-header">
                            <h4 className="bx--data-table-header__title">Client Management</h4>
                        </div>
                    </div>
                    <div className="xfm-content">
                            { this.state.clients && <ClientStructuredList
                                client={client_uuid}
                                clients={this.state.clients}
                                handler={this.clientHandler}
                            />
                            }
                        <div className="xfm-projects">
                            {client_uuid != null && hasClient && <>
                                <FormGroup legendText="Client Contracts" className="xfc-projects">
                                    {contracts && <ContractStructuredList
                                        contracts={contracts}
                                        contract={contract_uuid}
                                        client={this.state.clients[client_uuid]}
                                        handler={this.contractHandler}
                                    />
                                    }
                                </FormGroup>
                                <FormGroup legendText="Client Projects" className="xfc-projects">
                                    {projects && <ProjectStructuredList
                                        contracts={contracts}
                                        projects={projects}
                                        project={project_uuid}
                                        client={this.state.clients[client_uuid]}
                                        handler={this.projectHandler}
                                    />
                                    }
                                </FormGroup>
                            </>}
                        </div>
                        <div className="xfm-tests">
                            {project_uuid != null && tests &&
                                <TestsDataTable
                                    tests={tests}
                                    test={null}
                                    handler={this.testsHandler}
                                />
                            }
                            {contract_uuid != null && contract_items && hasClient && hasContract &&
                                <ContractItemDataTable
                                    contractItems={contract_items}
                                    contractItem={null}
                                    contract={this.state.contracts[contract_uuid]}
                                    handler={this.contractItemHandler}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
}

export default withRouter(Clients);