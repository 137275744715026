import React from 'react';
import {Button, OverflowMenu, OverflowMenuItem} from "carbon-components-react";
import Menu, {
    MenuDivider,
    MenuItem,
    MenuRadioGroup,
    MenuSelectableItem
} from "carbon-components-react/lib/components/Menu";
import {selectorFocusable, selectorTabbable} from "carbon-components-react/lib/internal/keyboard/navigation";
import './docbar-menu.scss';


// Component to render either icon-based or text-based sub-menus in the document bar
// if no children are provided this will default to a demo, showing the required child components fro each menu type
// as this uses 3 different menu types internally the children provided must match the expected wrapping component
class DocBarMenu extends React.PureComponent {
    static defaultProps = {
        rawMenu: false, buttons: false, prefix: 'bx', tooltipPosition: 'bottom', tooltipAlignment: 'center', size: 'md'
    };
    state = {open: false, x: 0, y: 0};
    btnRef = React.createRef();

    openMenu = () => {
        const rect = this.btnRef?.current?.getBoundingClientRect && this.btnRef.current.getBoundingClientRect();
        const x = rect ? rect.left : 0, y = rect ? rect.bottom : 0;
        this.setState({open: true, x: x, y: y}, () => setTimeout(() => {
            const e = document.getElementById('xf-cc-menu');
            if(!e) return;
            const tabbableNode = e.querySelector(selectorTabbable);
            const focusableNode = e.querySelector(selectorFocusable);
            const focusTarget =
                tabbableNode || // First sequentially focusable node
                focusableNode || // First programmatic focusable node
                e;
            focusTarget.focus();
        }, 0));
    }
    closeMenu = () => this.setState({open: false});
    buttonComponent = (props, key) => {
        const { iconDescription, tooltipAlignment, tooltipPosition, renderIcon, size, onClick, ..._props } = props;
        return <OverflowMenuItem key={key} onClick={onClick} requireTitle={true} title={iconDescription} itemText={
            <Button kind="ghost" tooltipPosition={tooltipPosition} tooltipAlignment={tooltipAlignment} hasIconOnly
                    renderIcon={renderIcon} onClick={onClick} size={size} iconDescription={iconDescription} {..._props}
            />}
        />
    }
    render = () => {
        const { open = false, x = 0, y = 0 } = this.state;
        const {
            rawMenu = false, buttons = false, iconDescription = "", className = "", size = "md",
            tooltipPosition, tooltipAlignment, prefix, children = [], renderIcon: RenderIcon, ...props
        } = this.props;

        // using OverflowMenu is more complex as it doesn't use a carbon button component internally so we have no
        // built-in tooltip, this functionality below adds this back in so it looks the same as the other buttons :)
        const btnClasses = [ // '--tooltip--hidden'
            'xfb-tt-btn', className, prefix + '--btn', prefix + '--btn--icon-only', prefix + '--btn--' + size,
            prefix + '--btn--ghost', prefix + '--tooltip--a11y', prefix + '--tooltip__trigger',
            prefix + '--tooltip--visible', prefix + '--btn--icon-only--' + tooltipPosition,
            prefix + '--tooltip--align-' + tooltipAlignment,
        ].join(' ');

        if (buttons) {
            return (
                <OverflowMenu renderIcon={() => <>
                    <div className={prefix + "--assistive-text xfb-tt-btn-ast"}>{iconDescription}</div>
                    <RenderIcon className={prefix + '--btn__icon'}/>
                </>} className={btnClasses + ' xfb-corner'} iconDescription={iconDescription} menuOptionsClass="xfb-but-omo"
                >
                    {buttons.map((_props, i) => {
                        const {
                            onClick: _onClick = () => {}, iconDescription: _iconDescription = "",
                            renderIcon: RenderIconSub, className: _cn, ...p
                        } = _props;
                        return <OverflowMenuItem
                            key={i} onClick={_onClick} requireTitle={true}
                            title={_iconDescription} className={btnClasses + ' xfb-tt-btn ' + _cn}
                            itemText={<>
                                <div className={prefix + "--assistive-text xfb-tt-btn-ast"}>{_iconDescription}</div>
                                <RenderIconSub className={prefix + '--btn__icon'}/>
                            </>}
                            {...p}
                        />;
                    })}
                </OverflowMenu>
            );
        }

        if (!rawMenu) {
            return (
                <OverflowMenu renderIcon={() => <>
                    <div className={prefix + "--assistive-text xfb-tt-btn-ast"}>{iconDescription}</div>
                    <RenderIcon className={prefix + '--btn__icon'}/>
                </>} className={btnClasses + ' xfb-corner'} iconDescription={iconDescription} menuOptionsClass="xfb-omo"
                >{children ? children : <>
                    <OverflowMenuItem itemText="Menu Item A" />
                    <OverflowMenuItem itemText="Menu Item B" />
                </>}</OverflowMenu>
            );
        }

        return (<span className="xfd-mnb-wrap">
            <Button
                id="xf-cc-btn" ref={this.btnRef} onClick={open ? this.closeMenu : this.openMenu} aria-haspopup={true}
                aria-expanded={open} iconDescription={iconDescription} renderIcon={RenderIcon} hasIconOnly
                tooltipPosition={tooltipPosition} tooltipAlignment={tooltipAlignment} size={size} {...props}
            />
            <Menu id="xf-cc-menu" autoclose={true} open={open} onClose={this.closeMenu} x={x} y={y}>
                {children ? children : <>
                    {/* The stuff below is simply to demonstrate the "hidden" carbon menu components :) */}
                    <MenuItem label="Copy" />
                    <MenuItem label="Format">
                        <MenuItem label="Bold" />
                        <MenuItem label="Italic" />
                    </MenuItem>
                    <MenuDivider />
                    <MenuRadioGroup label="Share with" items={['None', 'All']} />
                    <MenuDivider />
                    <MenuSelectableItem label="Publish" initialChecked={true} onChange={() => {}} />
                </>}
            </Menu>
        </span>);
    }
}

export default DocBarMenu;