import React from 'react';
import './document.scss';
import DocHelper from "../../helpers/Document";
import DocContext, {DocumentContext, newDocContext} from "../../helpers/DocContext";
import DocIL from "../il/DocIL";
import DocBar from "../../components/DocBar";
import {BaseDocIL, RunDocIL} from "../il";
import ZoomSlider from "../../components/ZoomSlider";
import SpellChecker from "../../components/SpellChecker";
import Loading from "../../components/Loading";
import ReactDOM from "react-dom";
import {Modal} from "carbon-components-react";
import DocNav from "../../components/DocNav";


// wrapper component that forcibly prevents renders by react unless forceUpdate is called :)
class NoRenderDocWrapper extends React.Component {
    ShouldComponentUpdate = () => false;
    render = () => this.props.doc.render();
}

// basic component that exposes its render result so we can inject a portal without triggering a render of the parent
class PortalTarget extends React.Component {
    state = {portal: null};
    render = () => this.state.portal;
    update = (portal, callback = () => {}) => this.setState({portal: portal}, callback);
    clear = (callback = () => {}) => this.setState({portal: null}, callback);
}

// document object referencing an office open XML word document, page units are in mm
class Document extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        options: {}, zoom: 100, uuid: null, context: {},
        order: [[], [], [], [], []],
        media: {}, doc: {},
        sections: [],
        revision: 1,
        compact: false,
        editable: true,
        preview: false,
        showNavigation: false,
        showZoomSlider: true,
        showSpellCheck: true,
        showFullScreen: false,
        showBackButton: false,
        backButtonCallback: () => {},
        saveDocumentCallback: () => {},
        toolbarTooltipPosition: 'bottom', toolbarTooltipAlignment: 'center'
    };
    static baseState = {
        props: {},
        media: {},
        paragraphs: {},
        headings: {},
        dpi: 330,
        zoom: 100,
        width: DocHelper.A4MM_W,
        height: DocHelper.A4MM_H,
        margin: DocHelper.A4MM_M,
        header: DocHelper.A4MM_S,
        footer: DocHelper.A4MM_S,
        flow: new Set(),
        node: null
    };
    constructor(props) {
        super(props);

        const { uuid, context, media, zoom, sections, saveDocumentCallback: save, revision, editable, preview } = this.props;

        // TODO: This was originally done using state and context, then we ditched the state for performance reasons,
        //       and now due to a react bug we can't always use the context either (CE Static Render Bug). So we may
        //       want to stop using the react context entirely and just use the DocIL instance as a context object. :)
        const ctx = newDocContext(new DocumentContext(zoom));

        this._mounted = false;
        this.baseRef = React.createRef();
        this.docBarRef = React.createRef();
        this.previewRef = React.createRef();
        this.portalRef = React.createRef();
        this.navRef = React.createRef();
        this.document = new DocIL(
            (sections && sections.length) ? BaseDocIL(sections) : RunDocIL(), true,
            this.previewRef, this.docBarRef, ctx, false, editable, this.adjustZoom, this, uuid,
            context, save, this.saveWarnCallback, revision, this.revisionCallback, !preview, preview, this.navRef
        );
        this.state = {
            ...Document.baseState,
            props: {},
            heads: {},
            media: (typeof media === "object") ? {...media} : {},
            zoom: zoom,
            ctx: ctx,
            loading: false
        };
    }
    get mounted() {
        return this._mounted;
    }
    initLoad = (msg = true, callback = () => {}) => this.setState({loading: msg}, callback);
    stopLoad = (callback = () => {}) => this.setState({loading: false}, callback);
    componentDidMount = () => {
        this._mounted = true;
        this.document.registerEvents();
        this.document.paginate();
    }
    componentWillUnmount = () => {
        this._mounted = false;
        this.document.unregisterEvents();
    }
    setZoom = zoom => this.setState(s => ({zoom: s.ctx.ctx.ppi = zoom}));
    adjustZoom = zoom => this.setState(s => ({zoom: s.ctx.ctx.ppi = s.zoom + zoom}));
    toggleFullScreen = (newVal = undefined, className = 'fullscreen') => this.baseRef?.current?.classList && (
        (newVal === undefined) ? this.baseRef.current.classList.toggle :
        newVal ? this.baseRef.current.classList.add : this.baseRef.current.classList.remove
    ).apply(this.baseRef.current.classList, [className]);

    // called if there is a revision changed between the last edit history and the current revision
    // accept will keep local changes and ignore the update, reject will remove local and take the server version
    revisionCallback = (accept, reject) => {
        if (!this._mounted || !this.portalRef?.current?.update) return; // should we just exit, or provide another callback?
        this.portalRef.current.update(ReactDOM.createPortal(
            <Modal
                alert danger open size="md" modalLabel={"Newer Revision Available"}
                modalHeading="The document has changed on the server!"
                secondaryButtonText="Keep local changes"
                primaryButtonText="Discard changes and use new version"
                shouldSubmitOnEnter={false} preventCloseOnClickOutside={true}
                onRequestClose={() => {}} // we should probably do something here
                onSecondarySubmit={() => this.portalRef.current.clear(accept)}
                onRequestSubmit={() => this.portalRef.current.clear(reject)}
            >
                Would you like to keep your local changes or use the latest version available on the server?
                <br/><br/>
                This will discard any local changes made to the document, this action is permanent and cannot be undone.
                <br/>
                Alternatively, keep the local changes to ignore the updated version. Saving the document
                after this point will result in the newer changes to the document being permanently lost.
            </Modal>,
            document.body
        ));
    }

    // called if there is a revision changed between the last edit history and the current revision
    saveWarnCallback = (accept) => {
        if (!this._mounted || !this.portalRef?.current?.update) return; // should we just exit, or provide another callback?
        this.portalRef.current.update(ReactDOM.createPortal(
            <Modal
                alert danger open size="md" modalLabel={"Newer Revision Available"}
                modalHeading="The document has changed on the server!"
                secondaryButtonText="Return to editor"
                primaryButtonText="Overwrite new version with local copy"
                shouldSubmitOnEnter={false} preventCloseOnClickOutside={true}
                onRequestClose={() => this.portalRef.current.clear()} // we should probably do something here
                onSecondarySubmit={() => this.portalRef.current.clear()}
                onRequestSubmit={() => this.portalRef.current.clear(accept)}
            >
                <strong>Warning</strong>: This will permanently overwrite the newer version of this document on the server.
            </Modal>,
            document.body
        ));
    }

    handleBackRequest = (...args) => {
        const { backButtonCallback } = this.props;
        if (this.document.dirty) {
            // TODO: Add a modal requesting if the user wants to A, discard changes, B, leave anyway, C, save changes
        } else backButtonCallback(...args);
    }

    // Main render
    render() {
        const {
            toolbarTooltipPosition: ttPos, toolbarTooltipAlignment: ttAlign, showNavigation,
            showZoomSlider, showSpellCheck, showFullScreen, showBackButton, compact
        } = this.props;
        const { ctx, zoom, loading } = this.state;
        return (
            <div className="xf-document" ref={this.baseRef}>
                <DocBar
                    ref={this.docBarRef} {...this.document.docBarProps()}
                    tooltipPosition={ttPos} tooltipAlignment={ttAlign} compact={compact}
                    showBackButton={showBackButton} backButtonCallback={this.handleBackRequest}
                    showFullScreen={showFullScreen} fullScreenCallback={this.toggleFullScreen}
                />
                <div className="xfd-wrap">
                    {showNavigation &&
                        <div className="xfd-nav-wrap">
                            <div className="xfd-nav">
                                <DocNav ref={this.navRef} />
                            </div>
                            {showZoomSlider && // we assign the zoom level to the key to force a new component on change
                                <ZoomSlider key={zoom} className="xfd-zoom" zoom={zoom} callback={this.setZoom}/>
                            }
                        </div>
                    }
                    {showZoomSlider && !showNavigation && // key hax only needed cause react sucks with layered data :(
                        <ZoomSlider key={zoom} className="xfd-zoom" zoom={zoom} callback={this.setZoom}/>
                    }
                    {showSpellCheck && <SpellChecker className="xfd-spell" />}
                    {loading && <Loading hasLogo={true} modal={false} message={(typeof loading === "string") ? loading : 'Please wait...'} />}
                    <div tabIndex={0} className="preview" {...this.document.docEventProps()} ref={this.previewRef}>
                        <DocContext.Provider value={ctx}><NoRenderDocWrapper doc={this.document}/></DocContext.Provider>
                    </div>
                </div>
                <PortalTarget ref={this.portalRef} />
            </div>
        );
    }
}

export default Document;
