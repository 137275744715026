import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    DataTable,
    DataTableSkeleton, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from "carbon-components-react";
import SessionContext from "../../helpers/SessionContext";
import {RemoveModal} from "@carbon/ibm-cloud-cognitive";
import {TrashCan16, Launch16} from "@carbon/icons-react";
import Reporting from "../../backend/Reporting";
import Loading from "../Loading";
import './report-sections.scss';


class ReportSections extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        headers: [
            {
                key: 'title',
                header: 'Title',
            },
            {
                key: 'description',
                header: 'Description',
            },
            {
                key: 'is_title',
                header: 'Title section',
            },
            {
                key: 'numbered',
                header: 'Has page numbers',
            },
            {
                key: 'used',
                header: 'In use',
            },
        ]
    };
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            data: {},
            rows: [],
            total: 0,
            loading: true, // skeleton components
            load_modal: false, // modal loading spinner
            deleting: null,
        };

        this.mounted = false;
    }
    componentDidMount = () => {
        this.mounted = true;
        Reporting.sections().then(d => {
            if (!this.mounted) return;

            if (!d.success) {
                this.setState({rows: [], data: {}, total: 0, loading: false});
                if (d.error) {
                    this.context.addToast('error', 'An Error Occurred', 'Error retrieving section data', d.error);
                }
                return;
            }

            this.setState({...this.parseDataForState(d.sections), loading: false});

        }).catch(this.context.networkFaultHandler);
    }
    componentWillUnmount = () => this.mounted = false;
    parseDataForState = (data) => {
        const rows = Object.values(data).map(v => ({
            id: v.uuid, title: v.title, description: v.description,
            is_title: v.is_title ? 'Yes' : 'No', numbered: v.numbered ? 'Yes' : 'No', used: v.free ? 'No' : 'Yes',
        }));
        return {rows: rows, data: data, total: rows.length};
    }
    render = () => {
        const { headers: propHeaders } = this.props;
        const { data } = this.state;
        return (
            <div className="xf-ft-wrap">
                {this.state.load_modal && <Loading hasLogo={true} modal={true} message="Loading..." />}
                <div className="xft-left">
                    {this.state.loading ? <DataTableSkeleton columnCount={propHeaders.length} /> : <div>
                        <DataTable rows={this.state.rows} headers={propHeaders} isSortable size="md">
                            {({
                                  rows, headers, getHeaderProps, getTableProps, getRowProps,
                                  onInputChange, getToolbarProps, getBatchActionProps, getSelectionProps
                              }) => (
                                <TableContainer stickyHeader={true}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableToolbarContent>
                                            <TableToolbarSearch
                                                persistent={true} placeholder="Start typing to filter..."
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        {...getHeaderProps({ header })}
                                                        className={'xfh-' + header.key}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                                <TableHeader/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow {...getRowProps({ row })} key={row.id}>
                                                    {row.cells.map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className={'xfc-' + cell.info.header}
                                                        >{cell.value}</TableCell>
                                                    ))}
                                                    <TableCell className="xf-act-wrap">
                                                        <div className="xf-actions">
                                                            <Button className="xff-act"
                                                                    tooltipPosition="top" tooltipAlignment="end"
                                                                    hasIconOnly renderIcon={Launch16}
                                                                    size="sm" kind="ghost"
                                                                    iconDescription="Open Section Editor"
                                                                    onClick={() =>
                                                                        this.props.history.push('/edit/section/' + row.id)
                                                                    }
                                                            />
                                                            <Button className="xff-act" // disabled={!data[row.id]?.free}
                                                                    tooltipPosition="top" tooltipAlignment="end"
                                                                    danger hasIconOnly renderIcon={TrashCan16}
                                                                    size="sm" kind="danger--ghost"
                                                                    iconDescription="Delete Section"
                                                                    onClick={() => this.setState({deleting: !data[row.id]?.free ? false : row.id})}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div> }
                </div>
                {this.state.deleting === false ? <Modal
                    className="no-del-sm"
                    open passiveModal size="sm"
                    aria-label="Cannot delete section warning"
                    modalLabel="Report Sections"
                    modalHeading="Section In Use"
                    shouldSubmitOnEnter={true}
                    preventCloseOnClickOutside={false}
                    onRequestClose={() => this.setState({deleting: null})}
                >
                        Report sections which are currently being used by templates cannot be deleted until all templates
                        that reference them have been updated to no longer use these sections. This is to prevent
                        unexpected template content changes due to the removal of sections used in multiple templates.
                </Modal> :
                    (this.state.deleting && (this.state.deleting in data) && <RemoveModal
                    body={<>{"Are you sure you want to delete this section? This action cannot be undone."}<br/><br/></>}
                    className="remove-section-modal"
                    iconDescription="close" open={true}
                    inputInvalidText="A valid value is required"
                    inputLabelText={`Please enter "${data[this.state.deleting].title}" to continue, yes you can copy and paste it if you really want to.`}
                    inputPlaceholderText={data[this.state.deleting].title}
                    label="Remove section"
                    onRequestSubmit={ () => {
                        this.setState({deleting: null});
                    }}
                    onClose={ () => this.setState( {deleting: null})}
                    preventCloseOnClickOutside
                    primaryButtonText="Remove"
                    resourceName={data[this.state.deleting].title}
                    secondaryButtonText="Close"
                    title="Confirm removal"
                    textConfirmation={true}
                />)}
            </div>
        );
    }
}

export default withRouter(ReportSections);