import DocHelper from "../../helpers/Document";
import Section from "../Section";
import TextRun from "../parts/TextRun";
import ListRun from "../parts/ListRun";
import ImageRun from "../parts/ImageRun";
import Paragraph from "../Paragraph";

// TODO: migrate docparts/elements over
import FieldRun from "../../docparts/elements/FieldRun";
import TableOfContents from "../../docparts/elements/TableOfContents";
import ChartRun from "../../docparts/elements/ChartRun";
import {DebugConsole} from "../../helpers/Utility";
import TableRun from "../parts/TableRun";


export const TYPES = {
    OBJECT: 'obj',
    METHOD: 'func',
    STRING: 'str',
    NUMBER: 'num',
    BOOL: 'bool',
    LIST: 'list',
    MEDIA: 'media',  // uuid field for MediaObject ref
    ANCHOR: 'anchor',  // DocHelper.ANCHOR
    WRAP: 'wrap',  // DocHelper.WRAP
    ALIGN: 'align',  // DocHelper.ALIGN
    STYLE: 'style',  // DocHelper.STYLE
    FONT: 'font',  // DocHelper.FONTS
};

// props / component layout  -- basic IL definition
//
//  array<num(...) == numeric array
//  array<obj(...k: v|str) == array of objects with value type string
//
// nested definitions (just in a comment until we add proper type-checking):
//    size: array<num(topLeftEmus, topRightEmus) i.e. [0, 0] (text-based types take size as a number for font)
//    spacing: object(before:num, after:num) i.e. {before: 0, after: 0}
//    break: bool (whether or not this paragraph should be preceded by a page break)
//    background: bool (pretty much just a switch between page alignment vs margin alignment: true = page aligned)
//
// nested table definitions:
//    widths: object(...header: width|num) i.e. {heading1: 0, heading2: 0...}
//    headers: array<obj({header: label|str}) i.e. {heading1: 0, heading2: 0...}
//    rows: array<obj(...header: value|run)
//        complex row example: [
//            count: {bold: true, data: '1'},
//            title: '',
//            risk: {bold: true, data: 'Critical', color: RiskHelper.getColour(RiskHelper.RISK.CRITICAL)}
//        ]
//
// Any IL branch that doesn't have a type will default to a plain text run, data props that are a list but do not
// correlate with a list type will be treated as multiple runs within the same parent element.
//
// i.e. the below two definitions are functionally identical, one is simply condensed.
//
// Condensed:
//    let ilDefObj = {
//        data: [
//            "This is a basic text run with a ",
//            {type: 'field', field: 'customDocumentProperty'},
//            " placed in the middle of a sentence"
//        ]
//    };
//
//
// Expanded:
//    let ilDefObj = {
//        type: 'row', style: DocHelper.STYLE.NORMAL, spacing: {before: 0, after: 0}, break: false,
//        data: [
//            {type: 'text', data: "This is a basic text run with a ", ...text.defaultProps},
//            {type: 'field', field: 'customDocumentProperty', ...text.defaultProps},
//            {type: 'text', data: " placed in the middle of a sentence", ...text.defaultProps}
//        ]
//    };
//
// i.e.
// {
//     type: 'image',
//     data: 'defaults',
//     back: false,
//     anchor: ANCHOR.TopLeft,
//     offset: [0, 0],
//     size: [0, 0],  // will use original size
//     wrap: WRAP.NONE,
//     index: back ? 0 : 1
// },
//
// TODO: prop validity checks are only one level deep, finish the job, come on...
export const VALID = {
    [TYPES.STRING]: s => typeof s === "string",
    [TYPES.LIST]:   s => Array.isArray(s) || (typeof s === "object" && s.type in PROPS) || !!s,
    [TYPES.NUMBER]: n => !isNaN(n),
    [TYPES.BOOL]:   s => typeof s === "boolean",
    [TYPES.OBJECT]: s => typeof s === "object",
    [TYPES.METHOD]: s => typeof s === "function",
    [TYPES.MEDIA]:  s => typeof s === "string" && DocHelper.UUID_REGEX.test(s),
    [TYPES.ANCHOR]: s => typeof s === "number" && DocHelper.ANCHOR_SET.has(s),
    [TYPES.WRAP]:   s => typeof s === "number" && DocHelper.WRAP_SET.has(s),
    [TYPES.ALIGN]:  s => typeof s === "string" && DocHelper.ALIGN_SET.has(s),
    [TYPES.STYLE]:  s => typeof s === "string" && DocHelper.STYLE_SET.has(s),
    [TYPES.FONT]:  s => typeof s === "string" && DocHelper.FONT_SET.has(s),
};
export const RUN_TYPES = {
    SENTENCE: 'text',
    TEXT: 'text',
    RUN: 'text',
    IMAGE: 'image',
    FIELD: 'field',
    LIST: 'list',
    ARRAY: 'list',
    TABLE: 'table',
    TOC: 'toc',
    CHART: 'chart'
};
export const RT_KEYS = new Set(Object.values(RUN_TYPES));
export const PROP_TYPES = {  // prop / alias names & keys
    DOCUMENT: 'doc',
    SECTION: 'sect',
    PARAGRAPH: 'row',
    ROW: 'row',
    TABLE_ROW: 'table_row',
    TABLE_CELL: 'table_cell',
    ...RUN_TYPES
};
export const PT_KEYS = new Set(Object.values(PROP_TYPES));


// this may cause issues when iterating over extremely large documents,
// but will prevent infinite loops caused by a corrupt il node tree :)
export const MAX_ITERATIONS = 1_000_000_000; // a billion should be enough ;)


export const BaseDocIL = (sections, props = {}) => ({type: PROP_TYPES.DOCUMENT, sections: sections, ...props});
export const BaseSectIL = (rows, props = {}) => ({type: PROP_TYPES.SECTION, rows: rows, ...props});
export const BaseRowIL = (runs = [], props = {}) => ({type: PROP_TYPES.PARAGRAPH, runs: runs, ...props});
export const BaseRunIL = (text = "", props = {}) => ({type: PROP_TYPES.TEXT, text: text, ...props});
export const FirstRunIL = (text = "", props = {}) => ({type: PROP_TYPES.TEXT, text: text, required: true, ...props});
export const RunDocIL = (text = "") => BaseDocIL(BaseSectIL(BaseRowIL(BaseRunIL(text))));
export const RowDocIL = (runs = []) => BaseDocIL(BaseSectIL(BaseRowIL(runs)));
export const SectDocIL = (rows = []) => BaseDocIL(BaseSectIL(rows));
export const SectRowIL = (text = "") => BaseRowIL(BaseRunIL(text));

export const DetermineILType = (ilObject, parent = null, defaultValue = null) => {
    if (typeof ilObject === "object") {
        if (ilObject.type in PROP_TYPES) return ilObject.type;
        if ('text' in ilObject) return PROP_TYPES.TEXT;
        else if ('sections' in ilObject) return PROP_TYPES.DOCUMENT;
        else if ('cells' in ilObject) return PROP_TYPES.TABLE;
        else if ('columns' in ilObject) return PROP_TYPES.TABLE_ROW;
        else if ('cell' in ilObject) return PROP_TYPES.TABLE_CELL;
        else if ('image' in ilObject) return PROP_TYPES.IMAGE;
        else if ('items' in ilObject) return PROP_TYPES.LIST;
        else if ('rows' in ilObject) return PROP_TYPES.SECTION;
        else if ('runs' in ilObject) return PROP_TYPES.PARAGRAPH;
    }
    if (parent) switch(parent.type) {
        case PROP_TYPES.DOCUMENT:
            return PROP_TYPES.SECTION;
        case PROP_TYPES.SECTION: // TODO: this is unreliable as sections can contain more than just paragraphs,
        case PROP_TYPES.LIST:    //       lists can only contain text, but then tables can also contain lists too :(
        case PROP_TYPES.TABLE_CELL:
            return PROP_TYPES.PARAGRAPH;
        case PROP_TYPES.PARAGRAPH:
            return PROP_TYPES.TEXT;
        case PROP_TYPES.TABLE:
            return PROP_TYPES.TABLE_ROW;
        case PROP_TYPES.TABLE_ROW:
            return PROP_TYPES.TABLE_CELL;
        default:
            return defaultValue;
    }
    DebugConsole.warn('findLikelyType: fail', ilObject, defaultValue, parent);
    return defaultValue;
}

const _RebuildDocTree = ilObject => {
    switch(typeof ilObject) {
        case 'string':
            return RunDocIL(ilObject);
        case 'object':
            switch (ilObject.type || DetermineILType(ilObject)) {
                case PROP_TYPES.DOCUMENT:
                    return ilObject;
                case PROP_TYPES.SECTION:
                    return BaseDocIL(ilObject);
                case PROP_TYPES.PARAGRAPH:
                case PROP_TYPES.LIST: // potentially confusing: list is a row-level element, but includes rows itself
                    return SectDocIL(ilObject);
                case PROP_TYPES.TEXT:
                    return RowDocIL(ilObject);
                default:  // TODO: add other types to RebuildDocTree and DetermineILType helpers
                    throw Error('Invalid document prop type');
            }
        default:
            throw Error('Invalid document ilObject');
    }
}

export const RebuildDocTree = ilObject => {
    if (Array.isArray(ilObject)) {
        if (ilObject.length === 1) ilObject = ilObject.pop();
        else throw Error('Invalid document ilObject');
    }

    // rebuild top-down
    ilObject = _RebuildDocTree(ilObject);

    // second pass going bottom-up
    if (!ilObject?.sections?.length) {
        ilObject.sections = [BaseSectIL(BaseRowIL(FirstRunIL()))];
    } else if (!ilObject?.sections[0]?.rows?.length) {
        ilObject.sections[0].rows = [BaseRowIL(FirstRunIL())];
    } else if (ilObject?.sections[0]?.rows[0]?.runs) {
        if (!ilObject?.sections[0]?.rows[0]?.runs?.length)
            ilObject.sections[0].rows[0].runs = [FirstRunIL()];
        else if (!ilObject?.sections[0]?.rows[0]?.runs[0]?.required)
            ilObject.sections[0].rows[0].runs[0].required = true;
    }

    return ilObject;
}

// 'type' prop is assumed on all definitions otherwise it defaults to either text or row
// 'editable' and 'required' are also available on every type, required nodes can be emptied but not deleted
export const PROPS = {
    [PROP_TYPES.DOCUMENT]: {
        parent: 'sections',  // defaults to data
        props: {
            sections: TYPES.LIST, editable: TYPES.BOOL
        }
    },
    [PROP_TYPES.SECTION]: {
        parent: 'rows',
        injects: PROP_TYPES.PARAGRAPH,
        props: {
            rows: TYPES.LIST, editable: TYPES.BOOL
        },
        component: Section
    },
    [PROP_TYPES.TEXT]: {
        parent: 'text',
        props: {
            text: TYPES.STRING, link: TYPES.STRING, font: TYPES.FONT, style: TYPES.STYLE, size: TYPES.NUMBER,
            color: TYPES.STRING, bold: TYPES.BOOL, italic: TYPES.BOOL, underline: TYPES.BOOL, highlight: TYPES.STRING,
            subscript: TYPES.BOOL, superscript: TYPES.BOOL, strikethrough: TYPES.BOOL, editable: TYPES.BOOL
        },
        component: TextRun
    },
    [PROP_TYPES.PARAGRAPH]: {  // A paragraph definition with a data element but no children will default to a basic TextRun
        parent: 'runs',
        extends: PROP_TYPES.TEXT,
        injects: PROP_TYPES.TEXT,  // default child element if a string is provided rather than an object IL definition
        props: {
            runs: TYPES.LIST, style: TYPES.STYLE, spacing: TYPES.OBJECT, align: TYPES.ALIGN,
            level: TYPES.NUMBER, indent: TYPES.NUMBER, break: TYPES.BOOL
        },
        component: Paragraph
    },
    [PROP_TYPES.IMAGE]: {
        props: {
            image: TYPES.STRING, label: TYPES.STRING, background: TYPES.BOOL, size: TYPES.LIST,
            align: TYPES.ALIGN, offset: TYPES.LIST, anchor: TYPES.ANCHOR, wrap: TYPES.WRAP,
            behind: TYPES.BOOL, overlap: TYPES.BOOL, lock: TYPES.BOOL,
            zIndex: TYPES.NUMBER, inCell: TYPES.BOOL, margins: TYPES.OBJECT
        },
        component: ImageRun
    },
    [PROP_TYPES.FIELD]: {
        extends: 'text',  // extend props from the "parent" type :)
        props: {
            field: TYPES.STRING
        },
        component: FieldRun
    },
    [PROP_TYPES.LIST]: {
        parent: 'items',
        injects: PROP_TYPES.PARAGRAPH,
        props: {
            items: TYPES.LIST, style: TYPES.STYLE, numbered: TYPES.BOOL
        },
        component: ListRun
    },
    [PROP_TYPES.TABLE]: {
        parent: 'cells',
        props: {
            widths: TYPES.LIST, cells: TYPES.LIST, align: TYPES.ALIGN
        },
        component: TableRun
    },
    [PROP_TYPES.TABLE_ROW]: {
        parent: 'columns',
        props: {
            columns: TYPES.LIST
        }
    },
    [PROP_TYPES.TABLE_CELL]: {
        parent: 'cell',
        props: {
            cell: TYPES.LIST
        }
    },
    [PROP_TYPES.TOC]: {
        props: {
            data: TYPES.OBJECT
        },
        component: TableOfContents
    },
    [PROP_TYPES.CHART]: {
        props: {
            data: TYPES.OBJECT
        },
        component: ChartRun
    },
};
