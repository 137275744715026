import React from 'react';
import './doc-bar.scss';
import {Button, ComboBox, Dropdown, Modal, OverflowMenuItem} from "carbon-components-react";
import {
    Copy16, Cut16,
    ListBulleted16, ListNumbered16,
    PageBreak16, Paste16,
    Redo16,
    Save16, Search16,
    TextStrikethrough16, TextSubscript16, TextSuperscript16, TextClearFormat16, TextFont16,
    TextAlignCenter16, TextAlignJustify16, TextAlignLeft16, TextAlignMixed16, TextAlignRight16,
    TextBold16, TextIndentLess16, TextIndentMore16, TextItalic16, TextUnderline16, TextLineSpacing16,
    Undo16, TextScale16, Help16, Maximize16, Minimize16, ArrowLeft16
} from "@carbon/icons-react";
import DocHelper from "../../helpers/Document";
import DocBarMenu from "../DocBarMenu";
import DocBarColor from "../DocBarColor";
import DocBarTable from "../DocBarTable";
import DocBarAttach from "../DocBarAttach";
import ReactDOM from "react-dom";
import DocEditorGuide from "../DocEditorGuide";


class DocBar extends React.PureComponent {
    static defaultProps = {
        ...DocHelper.FORMAT_PROPS,
        tooltipPosition: 'bottom', tooltipAlignment: 'center',
        allowCustomFontSize: false, showFullScreen: false, showBackButton: false,
        compact: false, clipboard: false,
        onUndo: () => {},
        onRedo: () => {},
        onCut: () => {},
        onCopy: () => {},
        onPaste: () => {},
        onFont: () => {},
        onSize: () => {},
        onBold: () => {},
        onItalic: () => {},
        onUnderline: () => {},
        onColor: () => {},
        onAlign: () => {},
        onBulleted: () => {},
        onNumbered: () => {},
        onIncIndent: () => {},
        onDecIndent: () => {},
        onStyle: () => {},
        onAttach: () => {},
        onSearch: () => {},
        onBreak: () => {},
        onSave: () => {},
        onClearFormatting: () => {},
        onUpperCase: () => {},
        onLowerCase: () => {},
        onStartCase: () => {},
        onCamelCase: () => {},
        onPascalCase: () => {},
        onToggleCase: () => {},
        onInvertCase: () => {},
        onSnakeCase: () => {},
        onKebabCase: () => {},
        onSentenceCase: () => {},
        onInsertTable: () => {},
        fullScreenCallback: () => {},
        backButtonCallback: () => {},
    };
    static mixedAlignKey = 'mixed';
    static alignIcons = {
        [DocHelper.ALIGN.LEFT]: TextAlignLeft16,
        [DocHelper.ALIGN.RIGHT]: TextAlignRight16,
        [DocHelper.ALIGN.CENTER]: TextAlignCenter16,
        [DocHelper.ALIGN.JUSTIFIED]: TextAlignJustify16,
        [DocBar.mixedAlignKey]: TextAlignMixed16,
    };
    state = {...DocHelper.FORMAT_DEFAULTS, helping: false, fullscreen: false};
    baseRef = React.createRef();

    render = () => { // TODO: Finish DocBar line spacing and search
        const {
            fonts, sizes, styles, tooltipPosition: ttPos, tooltipAlignment: ttAlign, allowCustomFontSize, compact,
            onUndo, onRedo, onCut, onCopy, onPaste, onFont, onSize, onBold, onItalic, onUnderline, onColor, onHighlight,
            onAlign, onStrikeThrough, onSubscript, onSuperscript, onBulleted, onNumbered, onIncIndent, onDecIndent,
            onStyle, onAttach, onSearch, onBreak, onClearFormatting, onUpperCase, onLowerCase, onStartCase, onCamelCase,
            onPascalCase, onToggleCase, onInvertCase, onSnakeCase, onKebabCase, onSentenceCase, onInsertTable, onSave,
            onInsertLink, onRemoveLink, clipboard, showFullScreen, fullScreenCallback, showBackButton, backButtonCallback
        } = this.props;
        const {
            link, align, font, style, size, color, highlight, bold, italic, underline, strikethrough, subscript,
            superscript, bulleted, numbered, listable, searching, collapsed, breakable, helping, fullscreen
        } = this.state;
        const btnSize = 'md';
        const selectedFont = DocHelper.FONT_ITEMS[DocHelper.FONT_MAP[font]];
        const selectedFontSize = allowCustomFontSize ?
            DocHelper.FONT_SIZES[DocHelper.FONT_SIZE_MAP[
                size in DocHelper.FONT_SIZE_MAP ? size : DocHelper.FONT_SIZE
            ]] :
            (size in DocHelper.FONT_SIZE_MAP ?
                DocHelper.FONT_SIZES[DocHelper.FONT_SIZE_MAP[size]] :
                {id: null, value: size, label: size.toString() + ' pt'}
            );
        const selectedStyle = DocHelper.STYLE_ITEMS[DocHelper.STYLE_MAP[style]];
        const rowAlignIcon = (align in DocBar.alignIcons) ? DocBar.alignIcons[align] : DocBar.alignIcons[DocBar.mixedAlignKey];

        return (<>
            <div className="xfd-bar" ref={this.baseRef}>
                {showBackButton && <div className="xfd-grp back-btn">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={'start'}
                            hasIconOnly renderIcon={ArrowLeft16}
                            size={btnSize} kind="ghost"
                            iconDescription="Go back to previous page"
                            onClick={backButtonCallback}
                    />
                </div>}
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={'start'}
                            hasIconOnly renderIcon={Undo16}
                            size={btnSize} kind="ghost"
                            iconDescription="Undo (Ctrl + Z)"
                            onClick={onUndo}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={'start'}
                            hasIconOnly renderIcon={Redo16}
                            size={btnSize} kind="ghost"
                            iconDescription="Redo (Ctrl + Shift + Z / Ctrl + Y)"
                            onClick={onRedo}
                    />
                </div>
                {clipboard && <div className="xfd-grp">
                    {/* browsers don't actually support paste via click, cut/copy works but support is patchy */}
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={Cut16}
                            size={btnSize} kind="ghost"
                            iconDescription="Cut (Ctrl + X)"
                            onClick={onCut}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={Copy16}
                            size={btnSize} kind="ghost"
                            iconDescription="Copy (Ctrl + C)"
                            onClick={onCopy}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={Paste16}
                            size={btnSize} kind="ghost"
                            iconDescription="Paste (Ctrl + V)"
                            onClick={onPaste}
                    />
                </div>}
                <div className="xfd-grp xfa-drop xfd-font">
                    <Dropdown
                        id="font-select" className="xfd-font" hideLabel titleText="Font"
                        label="Please choose a font..." items={fonts} selectedItem={selectedFont}
                        onChange={({ selectedItem }) => onFont(selectedItem ? selectedItem.label : selectedItem)}
                        itemToElement={f => f && <span style={{fontFamily: f.family}}>{f.label}</span>}
                    />
                </div>
                <div className={"xfd-grp xfa-drop xfd-size " + (allowCustomFontSize ? 'xfs-combo' : 'xfs-fixed')}>
                    {allowCustomFontSize ? <ComboBox
                        id="font-size" className="xfd-font-size" titleText="Font Size" placeholder="Font size..."
                        items={sizes} selectedItem={selectedFontSize}
                        onChange={({ selectedItem }) => onSize(selectedItem ? selectedItem.value : selectedItem)}
                        onInputChange={v => {
                            if(!v.trim().endsWith('pt')) return;
                            const m = v.toString().match(/\d+/);
                            m && m.length && onSize(m[0]);
                        }}
                    /> : <Dropdown
                        id="font-size" className="xfd-font-size" hideLabel titleText="Font Size" label="Font size..."
                        items={sizes} selectedItem={selectedFontSize}
                        onChange={({ selectedItem }) => onSize(selectedItem ? selectedItem.value : selectedItem)}
                    />}
                </div>
                <div className="xfd-grp">
                    <DocBarMenu
                        className="xfd-act" iconDescription="Change Case"
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                        renderIcon={TextFont16} disabled={collapsed}
                        size={btnSize} kind="ghost"
                    >
                        <OverflowMenuItem itemText="Sentence case." onClick={onSentenceCase} />
                        <OverflowMenuItem itemText="lowercase" onClick={onLowerCase} />
                        <OverflowMenuItem itemText="UPPERCASE" onClick={onUpperCase} />
                        <OverflowMenuItem itemText="Capitalize Each Word (start case)" onClick={onStartCase} />
                        <OverflowMenuItem itemText="tOGGLE cASE" onClick={onToggleCase} />
                        <OverflowMenuItem itemText="camelCase" onClick={onCamelCase} />
                        <OverflowMenuItem itemText="PascalCase" onClick={onPascalCase} />
                        <OverflowMenuItem itemText="kebab-case" onClick={onKebabCase} />
                        <OverflowMenuItem itemText="snake_case" onClick={onSnakeCase} />
                        <OverflowMenuItem itemText="Invert Case" onClick={onInvertCase} />
                    </DocBarMenu>
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextBold16}
                            size={btnSize} kind="ghost"
                            iconDescription="Bold (Ctrl + B)"
                            isSelected={bold}
                            onClick={onBold}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextItalic16}
                            size={btnSize} kind="ghost"
                            iconDescription="Italic (Ctrl + I)"
                            isSelected={italic}
                            onClick={onItalic}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextUnderline16}
                            size={btnSize} kind="ghost"
                            iconDescription="Underline (Ctrl + U)"
                            isSelected={underline}
                            onClick={onUnderline}
                    />
                </div>
                <div className="xfd-grp">
                    {compact ?
                        <DocBarMenu
                            className="xfd-act" iconDescription="Text Type"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            renderIcon={TextScale16} size={btnSize} kind="ghost"
                            buttons={[
                                {renderIcon: TextStrikethrough16, iconDescription:"Strikethrough", onClick: onStrikeThrough},
                                {renderIcon: TextSubscript16, iconDescription:"Subscript (Ctrl + =)", onClick: onSubscript},
                                {renderIcon: TextSuperscript16, iconDescription:"Superscript (Ctrl + Shift + +)", onClick: onSuperscript},
                            ]}
                        /> : <>
                        <Button className="xfd-act"
                                tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                                hasIconOnly renderIcon={TextStrikethrough16}
                                size={btnSize} kind="ghost"
                                iconDescription="Strikethrough"
                                isSelected={strikethrough}
                                onClick={onStrikeThrough}
                        />
                        <Button className="xfd-act"
                                tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                                hasIconOnly renderIcon={TextSubscript16}
                                size={btnSize} kind="ghost"
                                iconDescription="Subscript (Ctrl + =)"
                                isSelected={subscript}
                                onClick={onSubscript}
                        />
                        <Button className="xfd-act"
                                tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                                hasIconOnly renderIcon={TextSuperscript16}
                                size={btnSize} kind="ghost"
                                iconDescription="Superscript (Ctrl + Shift + +)"
                                isSelected={superscript}
                                onClick={onSuperscript}
                        />
                    </>}
                </div>
                <div className="xfd-grp">
                    <DocBarColor
                        className="xfd-act xfa-col" iconDescription="Text Color"
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                        size={btnSize} color={color} onChange={onColor}
                    />
                    <DocBarColor
                        className="xfd-act xfa-col" iconDescription="Text Highlight" highlight={true}
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                        size={btnSize} color={highlight} onChange={onHighlight}
                    />
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextClearFormat16}
                            size={btnSize} kind="ghost"
                            iconDescription="Clear Formatting"
                            onClick={onClearFormatting}
                            disabled={collapsed}
                    />
                </div>
                <div className="xfd-grp">
                    <DocBarMenu
                        className="xfd-act" iconDescription="Text Align"
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                        renderIcon={rowAlignIcon} size={btnSize} kind="ghost"
                        buttons={[
                            {renderIcon: TextAlignLeft16, iconDescription:"Align Left (Ctrl + L)", onClick:() => onAlign(DocHelper.ALIGN.LEFT)},
                            {renderIcon: TextAlignCenter16, iconDescription:"Center (Ctrl + E)", onClick:() => onAlign(DocHelper.ALIGN.CENTER)},
                            {renderIcon: TextAlignRight16, iconDescription:"Align Right (Ctrl + R)", onClick:() => onAlign(DocHelper.ALIGN.RIGHT)},
                            {renderIcon: TextAlignJustify16, iconDescription:"Justify (Ctrl + J)", onClick:() => onAlign(DocHelper.ALIGN.JUSTIFIED)},
                        ]}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextLineSpacing16}
                            size={btnSize} kind="ghost"
                            iconDescription="Line Spacing Options"
                            onClick={() => {
                                // TODO: implement line spacing options dialog
                            }}
                    />
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={ListBulleted16}
                            size={btnSize} kind="ghost"
                            iconDescription="Bulleted List"
                            isSelected={!!bulleted} disabled={!listable}
                            onClick={onBulleted}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={ListNumbered16}
                            size={btnSize} kind="ghost"
                            iconDescription="Numbered List"
                            isSelected={!!numbered} disabled={!listable}
                            onClick={onNumbered}
                    />
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextIndentMore16}
                            size={btnSize} kind="ghost"
                            iconDescription="Increase Indent"
                            onClick={onIncIndent}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={TextIndentLess16}
                            size={btnSize} kind="ghost"
                            iconDescription="Decrease Indent"
                            onClick={onDecIndent}
                    />
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act" disabled={!breakable}
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={PageBreak16}
                            size={btnSize} kind="ghost"
                            iconDescription="Insert Page Break"
                            onClick={() => onBreak()}
                    />
                    <DocBarTable
                        className="xfd-act" iconDescription="Insert Table"
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                        size={btnSize} kind="ghost" onInsert={onInsertTable} />
                </div>
                <div className="xfd-grp xfa-drop xfd-style">
                    <Dropdown
                        id="doc-style" className="doc-style" hideLabel titleText="Style"
                        label="Please choose a style..." items={styles} selectedItem={selectedStyle}
                        onChange={({ selectedItem }) => onStyle(selectedItem ? selectedItem.id : selectedItem)}
                        itemToElement={item => item && <span className={'xfs-run s-' + item.clazz}>{item.label}</span>}
                    />
                </div>
                <div className="xfd-grp">
                    <DocBarAttach
                        className="xfd-act" collapsed={collapsed} size={btnSize} link={link}
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign} key={link}
                        onInsert={onInsertLink} onRemove={onRemoveLink} onAttach={onAttach}
                    />
                </div>
                <div className="xfd-grp">
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={Search16}
                            size={btnSize} kind="ghost"
                            iconDescription="Search Document"
                            isSelected={searching}
                            onClick={() =>{
                                // TODO: implement Search Document thingy
                            }}
                    />
                    <Button className="xfd-act"
                            tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={ttAlign}
                            hasIconOnly renderIcon={Help16}
                            size={btnSize} kind="ghost"
                            iconDescription="Editor Help"
                            isSelected={helping}
                            onClick={() => this.setState({helping: !helping})}
                    />
                </div>
                <Button className="xfd-act"
                        tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={fullscreen ? ttAlign : 'end'}
                        hasIconOnly renderIcon={Save16}
                        size={btnSize} kind="ghost"
                        iconDescription="Save Document"
                        onClick={onSave}
                />
                {showFullScreen && <Button
                    className="xfd-act"
                    tooltipPosition={fullscreen ? 'bottom' : ttPos} tooltipAlignment={'end'}
                    hasIconOnly kind="ghost" size={btnSize}
                    renderIcon={fullscreen ? Minimize16 : Maximize16}
                    iconDescription={fullscreen ? 'Close full screen view' : 'Open full screen view'}
                    onClick={() => this.setState({fullscreen: !fullscreen}, () => fullScreenCallback(!fullscreen))}
                />}
            </div>

            {helping && typeof document !== 'undefined' && ReactDOM.createPortal(
                <Modal
                    className="xfd-guide-modal"
                    open passiveModal hasScrollingContent size="lg"
                    aria-label="Document editor user guide"
                    modalLabel="Document Editor"
                    modalHeading="User Guide"
                    shouldSubmitOnEnter={true}
                    preventCloseOnClickOutside={false}
                    onRequestClose={() => this.setState({helping: false})}
                ><DocEditorGuide/></Modal>,
                document.body
            )}
        </>);
    }
}

export default DocBar;