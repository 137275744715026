import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Tests
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/test';

    static list(grouped = false){
        const url = `${Tests.API_URL}/list${grouped ? '/1' : ''}`;
        return axios.get(url).then(response => response.data);
    }
}
