import React from 'react';
import { StackedBarChart } from "@carbon/charts-react";
import './combined-contract-budget-by-month-bar.scss';
import SessionContext from "../../helpers/SessionContext";

class CombinedContractBudgetByMonthBar extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        contracts: null
    }
    constructor(props) {
        super(props);
        const { contracts } = this.props;
        this.state = {
            errors: [],
            contracts: contracts,
            chart_options: {
                'title': 'Combined Budget By Month',
                'axes': {
                    'left': {
                        'mapsTo': 'value',
                        'stacked': true
                    },
                    'bottom': {
                        'mapsTo': 'date',
                        'scaleType': 'time'
                    }
                },
                "toolbar": {
                    "enabled": true,
                    "controls": [
                        {
                            "type": "Zoom in"
                        },
                        {
                            "type": "Zoom out"
                        },
                        {
                            "type": "Reset zoom"
                        },
                        {
                            'type': "Show as data-table"
                        },
                        {
                            'type': 'Export as CSV'
                        },
                        {
                            'type': 'Export as JPG'
                        },
                        {
                            'type': 'Export as PNG'
                        },
                        {
                            'type': 'Make fullscreen'
                        }
                    ],
                    "numberOfIcons": 6
                },
                "zoomBar": {
                    "top": {
                        "enabled": true
                    }
                },
                'height': '400px',
                'width': '700px'
            },
            chart_data: [],
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        let temp = this.calculateChartData();
        this.setState({chart_data: temp})
    }

    componentWillUnmount() {
        this.mounted = false;
        this.calculateChartData()
    }

    calculateChartData(){
        let cd = [];
        this.state.contracts.forEach(c => {
            let tmp = this.calculateContractData(c);
            cd = [...cd, ...tmp];
        })
        return cd;
    }

    calculateContractData(contract){
        let range = this.calculateDateRange(contract.start_date, contract.end_date);
        let monthlySpend = contract.budget / range.length;
        let chartData = [];
        for(let i = 0; i<range.length; i++){
            let date = new Date(range[i]+'T00:00:00').toISOString()
            chartData.push({'group': contract.name, 'value': monthlySpend, 'date': date})
        }
        return chartData;
    }

    calculateDateRange(startDate, endDate){
        let start = startDate.split('-');
        let end = endDate.split('-');
        let startYear = parseInt(start[0]);
        let endYear = parseInt(end[0]);
        let dates = [];

        for(let i = startYear; i <= endYear; i++) {
            let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
            let startMon = i === startYear ? parseInt(start[1])-1 : 0;
            for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
                let month = j+1;
                let displayMonth = month < 10 ? '0'+month : month;
                dates.push([i, displayMonth, '01'].join('-'));
            }
        }
        return dates;
    }

    render() {
        return (<>
                { this.state.chart_data.length && <StackedBarChart
                    data={this.state.chart_data}
                    options={this.state.chart_options}
                />}
            </>
        );
    }
}

export default CombinedContractBudgetByMonthBar;