import sanitizeHtml from 'sanitize-html';
import {HTMLElement, NodeType, parse} from 'node-html-parser';
import {PROP_TYPES, PROPS} from "../../document/il";
import DocHelper from "../Document";
import {DebugConsole} from "../Utility";
import ImageHelper from "../Image";


export default class ParserHelper {
    static rtfPictRegex = /{\\pict([\\a-z-0-9\s]*)((?:{[^{}]*})*)\s*([a-f0-9\s]+)}/gim;
    static sanitizeOptions = {
        allowedTags: [
            "h1", "h2", "h3", "h4", "h5", "h6",   // header rows
            "blockquote", "pre", "code",          // block code row
            "q", "kbd", "samp",                   // inline code run
            "figcaption",                         // caption row/run
            "ul", "li", "ol",                     // list runs
            "p",                                  // paragraph row
            "span",                               // standard run
            "a",                                  // hyperlink run
            "b", "strong",                        // bold run
            "i", "dfn", "em",                     // italic run
            "br", "hr",                           // empty paragraph row
            "mark",                               // highlight run
            "s",                                  // strikethrough
            "sub",                                // subscript
            "sup",                                // superscript
            "var",                                // bold + italic run
            "col", "colgroup", "table", "td", "th", "tr", // table stuff
            "img",                                // image run
        ],
        transformTags: {
            'blockquote': 'code',
            'pre': 'code',
            'kbd': 'q',
            'samp': 'q',
            'strong': 'b',
            'hr': 'br',
            'em': 'i',
            'dfn': 'i',
        },
        disallowedTagsMode: 'discard',
        allowedAttributes: {
            '*': [ 'style', 'class' ],
            a: [ 'href', 'name', 'alt', 'style', 'class' ],
            img: [ 'src', 'style', 'class', 'width', 'height', 'v:shapes' ], // v:shapes contains our RTF object reference :)
            th: [ 'width', 'style', 'class' ],
            td: [ 'width', 'style', 'class' ],
        },
        allowedStyles: {
            '*': {
                'width': [/^([\d.]+(?:px|pc|cm|mm|pt)\s*){1,4}$/],
                'height': [/^([\d.]+(?:px|pc|cm|mm|pt)\s*){1,4}$/],
                'margin': [/^([\d.]+(?:px|pc|cm|mm|pt)\s*){1,4}$/],
                'margin-top': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'margin-right': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'margin-bottom': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'margin-left': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'padding': [/^([\d.]+(?:px|pc|cm|mm|pt)\s*){1,4}$/],
                'padding-top': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'padding-right': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'padding-bottom': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                'padding-left': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
                // Match HEX and RGB
                'background': [/^[a-z]+$/i, /^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'background-color': [/^[a-z]+$/i, /^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'color': [/^[a-z]+$/i, /^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
                // Match any number with px, em, % etc.
                'font-size': [/^[\d.]+(?:px|pc|cm|mm|pt)$/],
            },
            'span': {
                'mso-list': [/.*/],
                'font-family': [/.*/],
            }
        },
        allowedClasses: {
            '*': [ 'Mso*', 'CopyrightFont' ]
        },
        // Lots of these won't come up by default because we don't allow them
        selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
        // URL schemes we permit
        allowedSchemes: [ 'http', 'https' ],
        allowedSchemesByTag: {
            img: [ 'data' ]
        },
        allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
        nonTextTags: [ 'style', 'script', 'textarea', 'option', 'noscript' ],
        allowProtocolRelative: false,
        enforceHtmlBoundary: false,
        nestingLimit: 10
    };
    static colourNames = {
        "aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
        "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
        "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
        "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
        "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
        "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
        "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
        "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
        "honeydew":"#f0fff0","hotpink":"#ff69b4",
        "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
        "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
        "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
        "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
        "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
        "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
        "navajowhite":"#ffdead","navy":"#000080",
        "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
        "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
        "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
        "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
        "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
        "violet":"#ee82ee",
        "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
        "yellow":"#ffff00","yellowgreen":"#9acd32"
    };
    static clean = html => sanitizeHtml(html, ParserHelper.sanitizeOptions);
    static parseHTML = (html, ctx, addToast = () => {}, callback = () => {}, rtf = null) => {

        const clean = ParserHelper.clean(html).replace(/>\s+</g,'><').trim(); // clean and remove dead space
        const nodes = parse(clean, {
            lowerCaseTagName: false,  // convert tag name to lower case (hurt performance heavily)
            comment: false,            // retrieve comments (hurt performance slightly)
            blockTextElements: {
                script: false,	// keep text content when parsing
                noscript: false,	// keep text content when parsing
                style: false,		// keep text content when parsing
                pre: true			// keep text content when parsing
            }
        });
        // I know sanitizeHtml uses htmlparser2 internally so we have an additional dependency here... why?
        // We only want to parse the pre-cleaned DOM tree returned by sanitizeHtml, therefore a faster, simpler
        // parser is better suited, yes we could probably hook into the underlying htmlparser2 ref but eww :P

        const media = ParserHelper.parseRTF(rtf);

        // compress / resize any pasted images before we add them to our document :)
        ImageHelper.processImageFiles(
            () => {},
            () => {},
            i => ParserHelper.getFileBases(i).then(images => {

                const parentKey = PROPS[PROP_TYPES.SECTION].parent;
                const section = {type: PROP_TYPES.SECTION, [parentKey]: []};
                const shared = {store: {}}; // data storage object shared between all recursive iterations

                if (nodes && nodes.childNodes) for (const node of nodes.childNodes) {
                    const parsedNode = ParserHelper.parseNode(node, ctx, images, section, shared);
                    if (parsedNode && parsedNode.type) section[parentKey].push(
                        parsedNode.type === PROP_TYPES.TEXT ? {type: PROP_TYPES.ROW, runs: [parsedNode]} : parsedNode
                    );
                }

                callback(section);
            }).catch(e => {
                console.error('Processing error', e.name, e.message, e);
                addToast('warning-alt', 'Processing Failed', e.message, e.name)
            }),
            addToast, media
        );
    }

    static getFileBases = async files => {
        for (const file of Object.keys(files)) {
            files[file].base = await new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e) => resolve(reader.result);
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(files[file].file);
            });
        }
        return files;
    }

    static isAlphaNumericCharCode = code => (code > 47 && code < 58) || (code > 64 && code < 91) || (code > 96 && code < 123);

    // basic wrapper to discourage use of idx/level params :)
    static parseRTFGroup = (group, callback = () => {}) => ParserHelper._parseRTFGroup(group, callback)[0];

    // this essentially converts it into a usable AST rather than just text :)
    static _parseRTFGroup = (group, callback = () => {}, idx = 0, level = 0) => {
        let first = true, control = false, cStr = '', sub = null;
        const rtf = {
            options: [],
            control: '',
            groups: {},
            content: '',
            level: level,
        };

        const controlStop = chr => {
            if (first && (chr !== '*') && cStr.length) {
                first = false;
                rtf.control = cStr;
            }
            else if (cStr.length) rtf.options.push(cStr);
            control = false;
            cStr = '';
        };

        const max = group.length;
        for (let i = idx; i < max; i++) {
            const char = group.charAt(i);

            switch (char) {
                case '\r': // skip carriage return
                case '\t': // I guess tabs as well
                case '\n': // and new lines too :)
                    controlStop(char);
                    break;
                case '{':
                    controlStop(char);
                    [sub, i] = ParserHelper._parseRTFGroup(group, callback, i + 1, level + 1);
                    callback(sub);

                    if (sub.control === 'sp') {
                        // special handling to convert property lists to a map
                        if (!(sub.control in rtf.groups)) rtf.groups[sub.control] = {};
                        rtf.groups[sub.control][sub.groups?.sn[0]?.content] = sub.groups?.sv[0]?.content;
                    } else {
                        if (!(sub.control in rtf.groups)) rtf.groups[sub.control] = [];
                        rtf.groups[sub.control].push(sub);
                    }
                    break;
                case '}':
                    controlStop(char);
                    rtf.content = rtf.content.trim();
                    return [rtf, i];
                case '\\':
                    controlStop(char);
                    if (control && cStr.length) rtf.options.push(cStr);
                    control = true;
                    cStr = '';
                    break;
                default:
                    if (control) {
                        if ((char === '-') || ParserHelper.isAlphaNumericCharCode(group.charCodeAt(i))) cStr += char;
                        else controlStop(char);
                        break;
                    }
                    if (!first) rtf.content += char;
            }
        }

        return [rtf, max];
    }
    // This is in no way perfect, or in any way even remotely close to an actual RTF parser, those
    // don't exist in node/js apparently, so we're doing the bare minimum needed to pull images :)
    static parseRTF = rtf => {
        if (!rtf) return {};
        const images = {};
        const media = []; // super basic, super limited, rtf image extraction utility
        const inlineImg = 'shppict';
        const floatImg = 'shp';

        ParserHelper.parseRTFGroup(rtf, c => ((c.control === inlineImg) || c.control === floatImg) && media.push(c));

        DebugConsole.info(`Found ${media.length} media files in RTF pasted content`, media);

        for (const img of media) {
            if ((img.control === inlineImg) && img.groups?.pict?.length && img.groups.pict[0]?.groups?.picprop?.length) {
                const opts = img.groups.pict[0].options;
                const data = img.groups.pict[0].content;
                const name = img.groups.pict[0].groups.picprop[0]?.groups?.sp?.wzName;
                const image = {name: name, file: null};

                // TODO: Add missing image formats to supported RTF paste processing
                for (const opt of opts) {
                    switch (opt) {
                        case 'emfblip': // Source of the picture is an EMF (enhanced metafile).
                            break;
                        case 'pngblip': // Source of the picture is a PNG.
                            // this isn't always hex, RTF supports binary encoding too, but word doesn't use it :shrug:
                            try {
                                const bin = Buffer.from(data.trim(), 'hex');
                                image.file = new File([bin], name + '.png', {type: 'image/png'});

                                // image.data = 'data:image/png;base64,' + Buffer.from(data.trim(), 'hex').toString('base64');
                            } catch (e) {
                                DebugConsole.warn('Error decoding RTF PNG image data', e);
                            }
                            break;
                        case 'jpegblip': // Source of the picture is a JPEG.
                            try {
                                const bin = Buffer.from(data.trim(), 'hex');
                                image.file = new File([bin], name + '.jpg', {type: 'image/jpeg'});

                                // image.data = 'data:image/jpeg;base64,' + Buffer.from(data.trim(), 'hex').toString('base64');
                            } catch (e) {
                                DebugConsole.warn('Error decoding RTF JPG image data', e);
                            }
                            break;
                        case 'macpict': // Source of the picture is QuickDraw.
                            break;
                        default:
                            // if (opt.startsWith('pmmetafile')) {
                            //     // OS/2 metafile. The N argument in wmetafileN identifies the metafile type.
                            // }
                            // if (opt.startsWith('wmetafile')) {
                            //     // Windows metafile. The N argument identifies the metafile type (the default is 1).
                            // }
                            // if (opt.startsWith('dibitmap')) {
                            //     // Windows device-independent bitmap. The N argument identifies the bitmap type (must equal 0).
                            // }
                            // if (opt.startsWith('wbitmap')) {
                            //     // Windows device-dependent bitmap. The N argument identifies the bitmap type (must equal 0).
                            // }
                            break;
                    }
                }

                if (data && name) images[name.trim()] = image;
            }
            // TODO: Add floating image support when parsing RTF pasted content
        }

        return images;
    }

    static parseNode = (node, ctx, media = {}, root = {}, shared = {store: {}}, styleCallback = () => false) => {
        switch (node && node?.nodeType) {
            default:
                return null;
            case NodeType.TEXT_NODE: // plain text node, no fun!
                return {type: PROP_TYPES.TEXT, text: node.textContent};
            case NodeType.ELEMENT_NODE:
                return ParserHelper.parseElement(node, ctx, media, root, shared, styleCallback);
        }
    }
    static parseListItem = (el: HTMLElement, list, ctx, media = {}, root = {}, shared = {store: {}}) => {
        const row = {type: PROP_TYPES.ROW, runs: []};
        let text = '';

        if (el?.childNodes?.length) {
            for (const node: HTMLElement of el.childNodes) {
                if (node.nodeType !== Node.ELEMENT_NODE) {
                    text += node.textContent;
                    continue;
                }
                if (text.trim().length) {
                    row.runs.push({type: PROP_TYPES.TEXT, text: text});
                    text = '';
                }
                const styles = ParserHelper.parseStyles(node);
                if (('font-family' in styles) && (styles['font-family'] === 'symbol')) {
                    list.numbered = false;
                    continue;
                }
                if (('mso-list' in styles) && (styles['mso-list'] === 'ignore')) continue;

                if (node.childNodes.length) {
                    row.runs.push(...node.childNodes.map(n => ParserHelper.parseNode(n, ctx, media, root, shared, s => {
                        if (('font-family' in s) && (s['font-family'] === 'symbol')) {
                            list.numbered = false;
                            return true;
                        }
                        return ('mso-list' in s) && (s['mso-list'] === 'ignore');
                    })).filter(n => !!n));
                }
            }
        }
        if (text.trim().length) row.runs.push({type: PROP_TYPES.TEXT, text: text});

        return row;
    }
    // TODO: onPaste parser doesn't clean pasted data very much,
    //       meaning we could end up with invalid node nesting.
    static parseElement = (el: HTMLElement, ctx, media = {}, root = {}, shared = {store: {}}, styleCallback = () => false) => {
        let nodeIL = {}, parent = true, addChildren = true, hasChildren = false, parsed = true;

        // stupid word doesn't use UL/OL/LI for lists in its HTML..... WHY?!?!?!?!?
        if (el.classList.contains('MsoListParagraph')) { // single item list
            nodeIL['type'] = PROP_TYPES.LIST;
            nodeIL['style'] = DocHelper.STYLE.LIST;
            nodeIL['numbered'] = true; // true by default, we catch symbol and disable if needed in parseListItem
            nodeIL['items'] = [ParserHelper.parseListItem(el, nodeIL, ctx, media, root, shared)];
            addChildren = false;
        } else if (el.classList.contains('MsoListParagraphCxSpFirst')) { // list start
            nodeIL['type'] = PROP_TYPES.LIST;
            nodeIL['style'] = DocHelper.STYLE.LIST;
            nodeIL['numbered'] = true;
            nodeIL['items'] = [ParserHelper.parseListItem(el, nodeIL, ctx, media, root, shared)];
            shared.store.inList = true;
            shared.store.list = nodeIL;
            addChildren = false;
        }
        else if (el.classList.contains('MsoListParagraphCxSpMiddle')) { // list middle
            if (shared.store.inList && shared.store?.list?.items) { // access the previously added list and add our li
                shared.store.list.items.push(ParserHelper.parseListItem(el, shared.store.list, ctx, media, root, shared));
                return null; // skip this one now as we injected it into the previous list node
            }
        }
        else if (el.classList.contains('MsoListParagraphCxSpLast')) { // list end
            if (shared.store.inList && shared.store?.list?.items) { // access the previously added list and add our li
                shared.store.list.items.push(ParserHelper.parseListItem(el, shared.store.list, ctx, media, root, shared));
                shared.store.inList = false;
                shared.store.list = undefined;
                return null;
            }
        }
        else parsed = false;
        const styles = ParserHelper.parseStyles(el);

        if (styleCallback(styles)) return null;

        if (!parsed) switch (el.tagName) {
            default:
                return null;

            case 'IMG':
                // _x0020_ is clearly part of an encoding format, that we aren't decoding...
                // but word uses standard names e.g. "Picture 1", so this will do for now :)
                const name = el.getAttribute('v:shapes').replace('_x0020_', ' ');
                if (!name || !(name in media) || !media[name] || !media[name].base) {
                    console.warn('RTF source file matching HTML metadata not found', name, media);
                    return null; // skip files not found in the RTF document
                }

                // TODO: handle embedded base64 dataURL images on paste parsing
                nodeIL = ImageHelper.buildImageBlock(media[name], ctx, false);
                addChildren = false;
                break;

            case 'H1':
            case 'H2':
            case 'H3':
            case 'H4':
            case 'H5':
            case 'H6':
            case 'CODE':
            case 'BR':
            case 'figcaption':
            case 'LI': // list items are just rows :)
            case 'P':
                // insert row
                nodeIL['type'] = PROP_TYPES.PARAGRAPH;

                // eslint-disable-next-line default-case
                switch (el.tagName) { // default not needed, handled in outer switch
                    case 'H1':
                        nodeIL['style'] = DocHelper.STYLE.H1;
                        break;
                    case 'H2':
                        nodeIL['style'] = DocHelper.STYLE.H2;
                        break;
                    case 'H3':
                        nodeIL['style'] = DocHelper.STYLE.H3;
                        break;
                    case 'H4':
                        nodeIL['style'] = DocHelper.STYLE.H4;
                        break;
                    case 'H5':
                        nodeIL['style'] = DocHelper.STYLE.H5;
                        break;
                    case 'H6':
                        nodeIL['style'] = DocHelper.STYLE.H6;
                        break;
                    case 'CODE':
                        nodeIL['style'] = DocHelper.STYLE.CODE;
                        break;
                    case 'BR':
                        nodeIL[PROPS[nodeIL['type']].parent] = {type: PROP_TYPES.TEXT, text: ''};
                        addChildren = false;
                        break;
                    case 'figcaption':
                        nodeIL['style'] = DocHelper.STYLE.CAPTION;
                        break;
                    case 'P':
                    case 'LI':
                        nodeIL['style'] = DocHelper.STYLE.NORMAL;
                        break;
                }

                break;
            case 'B':
            case 'I':
            case 'Q':
            case 'A':
            case 'S':
            case 'MARK':
            case 'SUB':
            case 'SUP':
            case 'VAR':
            case 'SPAN':
                // insert run
                nodeIL['type'] = PROP_TYPES.RUN;
                parent = false;

                if (el?.childNodes?.length) {
                    addChildren = false;
                    const nodeKey = PROPS[nodeIL.type].parent;
                    const rootKey = PROPS[root.type].parent;

                    // loop through and check if there's any images, move to their own rows if there are
                    nodeIL[nodeKey] = el.childNodes.map(n => {
                        if (n.tagName === 'IMG') {
                            if (!(rootKey in root)) root[rootKey] = [];
                            const img = ParserHelper.parseNode(n, ctx, media, root, shared, styleCallback);
                            if (img) root[rootKey].push(img);
                            return '';
                        }
                        return n.textContent;
                    });
                    nodeIL[nodeKey] = nodeIL[nodeKey].join('');
                    hasChildren = !!nodeIL[nodeKey].length;
                }

                // eslint-disable-next-line default-case
                switch (el.tagName) { // default not needed, handled in outer switch
                    case 'B':
                        nodeIL['bold'] = true;
                        break;
                    case 'I':
                        nodeIL['italic'] = true;
                        break;
                    case 'Q':
                        nodeIL['style'] = DocHelper.STYLE.CODE;
                        break;
                    case 'A':
                        nodeIL['link'] = el.getAttribute('href');
                        break;
                    case 'S':
                        nodeIL['strikethrough'] = true;
                        break;
                    case 'MARK':
                        nodeIL['highlight'] = '#fdff32';
                        break;
                    case 'SUB':
                        nodeIL['subscript'] = true;
                        break;
                    case 'SUP':
                        nodeIL['superscript'] = true;
                        break;
                    case 'VAR':
                        nodeIL['bold'] = true;
                        nodeIL['italic'] = true;
                        break;
                }

                break;
            case 'TABLE':
                // insert table
                nodeIL['type'] = PROP_TYPES.TABLE;
                nodeIL['style'] = DocHelper.STYLE.TABLE;
                break;
            case 'COLGROUP':
            case 'TR':
                // insert table row
                nodeIL['type'] = PROP_TYPES.TABLE_ROW;
                break;
            case 'COL':
            case 'TH':
                // insert table cell
                nodeIL['type'] = PROP_TYPES.TABLE_CELL;
                nodeIL['style'] = DocHelper.STYLE.TABLE_TITLE;
                break;
            case 'TD':
                // insert table cell
                nodeIL['type'] = PROP_TYPES.TABLE_CELL;
                nodeIL['style'] = DocHelper.STYLE.TABLE_TEXT;
                break;
            case 'UL':
                // insert unordered list
                nodeIL['type'] = PROP_TYPES.LIST;
                nodeIL['style'] = DocHelper.STYLE.LIST;
                nodeIL['numbered'] = false;
                break;
            case 'OL':
                // insert ordered list
                nodeIL['type'] = PROP_TYPES.LIST;
                nodeIL['style'] = DocHelper.STYLE.LIST;
                nodeIL['numbered'] = true;
                break;
        }

        if (addChildren && el?.childNodes?.length) {
            nodeIL[PROPS[nodeIL['type']].parent] = el.childNodes.map(n => parent ?
                ParserHelper.parseNode(n, ctx, media, root, shared, styleCallback) :
                (((n.nodeType === Node.ELEMENT_NODE) && styleCallback(ParserHelper.parseStyles(n))) ? '' : n.textContent)
            ).filter(n => !!n);
            if (!parent) nodeIL[PROPS[nodeIL['type']].parent] = nodeIL[PROPS[nodeIL['type']].parent].join('');
            hasChildren = !!nodeIL[PROPS[nodeIL['type']].parent].length;
        }

        // Example inline styles from word HTML:
        //
        //    margin-left:-.25pt;border-collapse:collapse;border:none;mso-border-alt:
        //    solid #5B9BD5 .5pt;mso-border-themecolor:accent1;mso-yfti-tbllook:1184;
        //    mso-padding-alt:0cm 5.4pt 0cm 5.4pt;mso-border-insideh:.75pt solid #5B9BD5;
        //    mso-border-insideh-themecolor:accent1;mso-border-insidev:.75pt solid #5B9BD5;
        //    mso-border-insidev-themecolor:accent1;
        //
        //    font:7.0pt "Times New Roman";text-indent:-18.0pt;mso-list:l0 level1 lfo1;tab-stops:36.0pt
        //    font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-ansi-language:EN-CA
        //
        //    position:absolute;margin-left:375.35pt;margin-top:71.85pt;width:164.65pt;
        //    height:120.1pt;z-index:251670527;visibility:visible;mso-wrap-style:square;
        //    mso-width-percent:0;mso-height-percent:0;mso-wrap-distance-left:9pt;
        //    mso-wrap-distance-top:0;mso-wrap-distance-right:9pt;
        //    mso-wrap-distance-bottom:0;mso-position-horizontal:absolute;
        //    mso-position-horizontal-relative:margin;mso-position-vertical:absolute;
        //    mso-position-vertical-relative:margin;mso-width-percent:0;
        //    mso-height-percent:0;mso-width-relative:page;mso-height-relative:page
        //
        if ('color' in styles) {
            const colour = (styles['color'] in ParserHelper.colourNames) ? ParserHelper.colourNames[styles['color']] : styles['color'];
            if (colour && (colour.startsWith('#') || colour.startsWith('rgb(') || colour.startsWith('rgba('))) {
                if (parent) {
                    if (hasChildren) nodeIL[PROPS[nodeIL['type']].parent].map(c => c['color'] = colour);
                }
                else nodeIL['color'] = colour;
            }
        }
        const highlight = ('background' in styles) ? styles['background'] : (('background-color' in styles) ? styles['background-color'] : null);
        if (highlight) {
            for (const col of highlight.split(' ')) {
                const colour = (col in ParserHelper.colourNames) ? ParserHelper.colourNames[col] : col;
                if (colour && (colour.startsWith('#') || colour.startsWith('rgb(') || colour.startsWith('rgba('))) {
                    if (parent) {
                        if (hasChildren) nodeIL[PROPS[nodeIL['type']].parent].map(c => c['highlight'] = colour);
                    }
                    else nodeIL['highlight'] = colour;
                    break;
                }
            }
        }
        if ('font-size' in styles) {
            const fontSize = ParserHelper.unitToPoint(styles['font-size']);

            if (fontSize) {
                if (parent) {
                    if (hasChildren) nodeIL[PROPS[nodeIL['type']].parent].map(c => c['size'] = fontSize);
                }
                else nodeIL['size'] = fontSize;
            }
        }
        if (parent) {
            if ('text-align' in styles) {
                // eslint-disable-next-line default-case
                switch (styles['text-align']) {
                    case 'center':
                        nodeIL['align'] = DocHelper.ALIGN.CENTER;
                        break;
                }
            }

            const hasTopM = 'margin-top' in styles;
            const hasBotM = 'margin-bottom' in styles;

            if (hasTopM || hasBotM) {
                nodeIL['spacing'] = {before: 0, after: 0};

                if (hasTopM) nodeIL['spacing'].before = DocHelper.ptToDxa(ParserHelper.unitToPoint(styles['margin-top']));
                if (hasBotM) nodeIL['spacing'].after = DocHelper.ptToDxa(ParserHelper.unitToPoint(styles['margin-bottom']));
            }

            // if ('margin' in styles) {
            //     nodeIL['spacing'] = [];
            // }
            // if ('margin-right' in styles) {
            //     nodeIL['spacing'] = [];
            // }
            // if ('margin-left' in styles) {
            //     nodeIL['spacing'] = [];
            // }
        }

        return nodeIL;
    }
    // very basic style tag parser to pul our things like margin / colour / highlight etc.
    static parseStyles = (el: HTMLElement) => {
        const style = el.getAttribute('style');
        return style ? Object.fromEntries(style.trim().toLowerCase().split(';').map(s => s.trim()).map(s => s.split(':'))) : {};
    }
    static unitToPoint = unit => {
        if (!unit || (unit.length < 3)) return 0;
        const units = unit.substring(unit.length - 2);
        const float = parseFloat(unit);

        if (!units || !float) return 0;

        let finalSize = float;
        // Unit 	Description
        // cm 	    centimeters
        // mm 	    millimeters
        // in 	    inches (1in = 96px = 2.54cm)
        // px   	pixels (1px = 1/96th of 1in)
        // pt 	    points (1pt = 1/72 of 1in)  // word uses points internally for fonts
        // pc 	    picas (1pc = 12 pt)
        switch (units) { // px|pc|cm|mm|pt
            default:
                return 0;
            case 'pt':
                break;
            case 'px':
                finalSize = (float / 96) * 72; // px > in > pt
                break;
            case 'cm':
                finalSize = (float / 2.54) * 72; // cm > in > pt
                break;
            case 'mm':
                finalSize = (float / 25.4) * 72; // cm > in > pt
                break;
            case 'pc':
                finalSize = float * 12; // pc > pt
                break;
        }

        return finalSize ? finalSize : 0;
    }
}
