import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Projects
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/project';

    static createOrUpdate(id, project){
        const url =`${Projects.API_URL}/${id}`
        return axios.post(url, project).then(response => response.data);
    }

    static list(id){
        const url = `${Projects.API_URL}/list${id ? '/'+id : ''}`;
        return axios.get(url).then(response => response.data);
    }

    static list_tests(id){
        const url = `${Projects.API_URL}/${id ? id : ''}/tests`;
        return axios.get(url).then(response => response.data);
    }
}
