import React from 'react';
import './contact-type-edit-modal.scss';
import SessionContext from "../../helpers/SessionContext";
import { FormGroup, Modal, TextInput} from "carbon-components-react";
import Types from '../../backend/Types'

class ContactTypeEditModal extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        contactType: null, onClose: null
    };

    constructor(props) {
        super(props);
        const { contactType } = this.props;

        this.state = {
            errors: [],
            data: contactType,
            loading: false,
            closing: false,
        };

        this.save = this.save.bind(this);
        this.onClose = this.onClose.bind(this);

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onClose = (reset = false) => this?.props?.onClose && this.props.onClose(reset ? null : this.state.data);

    save() {
        Types.createOrUpdateContact(this.state.data.uuid, this.state.data)
            .then(result => {
                if(result.success) {
                    this.setState({
                        data: result.contact_type
                    })
                    this.onClose()
                }
                else this.context.networkFaultHandler(result.success)
            })
            .catch(this.context.networkFaultHandler)
    }

    render() {
        const open = this.state.data != null;
        return (<>
            <Modal
                open={open}
                modalHeading={this.state.data.name}
                modalLabel="Contract Management"
                primaryButtonText={this.state.data.name ? 'Save' : 'Create'}
                secondaryButtonText="Cancel"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    this.setState({closing: true})}
                }
                onRequestSubmit={(e) => this.save(e)}
                onSecondarySubmit={() => this.setState({closing: true})}
                size='xs'
            >
                <div className='xts-tear'>
                    <FormGroup
                        hasMargin
                        invalid={false}
                        legendText="Contact Type"
                        message={false}
                        messageText="none"
                    >
                        <TextInput
                            id="name" name="name" labelText="Name" value={this.state.data.name}
                            placeholder="Please enter a name..."
                            invalid={false}
                            invalidText="Name cannot be blank."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    data: { ...s.data, name: val}
                                }))
                            }}
                        />
                    </FormGroup>
                </div>
            </Modal>
            {this.state.closing && <Modal
                danger open size="xs" modalLabel={open ? this.state.data.name : ''}
                modalHeading="Are you sure?"
                secondaryButtonText="Go Back"
                primaryButtonText="Discard Changes"
                shouldSubmitOnEnter={false}
                onRequestClose={() => this.setState({closing: false})}
                onSecondarySubmit={() => this.setState({closing: false})}
                onRequestSubmit={() => this.onClose(true)}
                >This will discard any changes.</Modal>}
        </>);
    }
}

export default ContactTypeEditModal;