import React from 'react';
import './client-edit-tear.scss';
import SessionContext from "../../helpers/SessionContext";
import TzContext from "../../helpers/TimezoneContext"
import {Dropdown, Form, Modal, TextInput} from "carbon-components-react";
import '@carbon/ibm-cloud-cognitive/scss/components/Tearsheet/_tearsheet.scss';
import ClientHelper from "../../helpers/Client"
import LocaleHelper from "../../helpers/Locale"
import Client from "../../backend/Clients"

class ClientEditTear extends React.Component {
    static contextType = SessionContext;
    static tzContext = TzContext;
    static defaultProps = {
        client: null, onClose: null
    };
    constructor(props) {
        super(props);
        const { client } = this.props;
        this.state = {
            client: client,
            name: '',
            status:  ClientHelper.getStatusById(1),
            timezone: null, //TODO: Set to user's default timezone
            currency: LocaleHelper.getCurrencyById('usd'),
            language: LocaleHelper.getLanguageById('en'),
            contacts: [],
            loading: false,
            closing: false,
            invalid: false
        };

        this.forceUpdate = this.forceUpdate.bind(this);
        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
        this.onClose = this.onClose.bind(this);
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true
        if(this.state.client){
            this.setState({
                name: this.state.client.name,
                status: ClientHelper.getStatusById(this.state.client.status) || null,
                timezone: this.state.client.timezone ? {
                    id: this.state.client.timezone,
                    timezone: this.state.client.timezone
                } : null,
                currency: LocaleHelper.getCurrencyById(this.state.client.currency) || null,
                language: LocaleHelper.getLanguageById(this.state.client.language) || null,
                contacts: this.state.client.contacts || null,
            })
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    onClose() {
        const { onClose: callback } = this.props;
        if (callback) callback(this.state);
    }
    reset(callback = () => {}) {
        this.setState({
            name: '',
            status: null,
            timezone: null,
            currency: null,
            language: null,
            contacts: null,
            loading: false,
            closing: false,
        }, callback);
    }
    save() {
        let client = {
            name: this.state.name,
            status: this.state.status.id,
            timezone: this.state.timezone ? this.state.timezone.id : null,
            currency: this.state.currency.id,
            language: this.state.language.id,
            contacts: this.state.contacts
        }
        if(this.state.client) {
            Client.createOrUpdate(this.state.client.uuid, client)
                .then(result => {
                    this.setState({
                        updated_client: result
                    })
                    this.onClose()
                })
                .catch(e => {
                    console.log(e)
                })//TODO: Catch errors / add handler
        }
        else {
            Client.createOrUpdate('create', client)
                .then(result => {
                    this.setState({
                        updated_client: result
                    })
                    this.onClose()
            }).catch(e => {
                console.log(e)
            })//TODO: Catch errors / add handler
        }
    }
    render() {
        const open = true;
        return (<>
            <Modal
                open={open}
                modalHeading={this.state.name}
                modalLabel="Client Management"
                primaryButtonText={this.state.client ? 'Save' : 'Create'}
                secondaryButtonText="Cancel"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    this.setState({closing: true})}
                }
                onRequestSubmit={(e) => this.save(e)}
                onSecondarySubmit={() => this.setState({closing: true})}
            >
                <div className='xts-tear'>
                    <Form>
                        <TextInput
                            id="name" name="name" labelText="Name" value={this.state.name}
                            required
                            invalid={!this.state.name.length}
                            invalidText="Client name cannot be blank."
                            placeholder="Please enter a client name..."
                            onChange={e => {
                                console.log(e.target)
                                const val = e.target.value;
                                this.setState(s => ({
                                    name: val
                                }));
                            }}
                        /><br/>
                        <Dropdown
                        id="timezone"
                        titleText="Timezone"
                        label="Please select a timezone..."
                        invalid={this.state.timezone === null}
                        invalidText="Timezone cannot be blank."
                        items={LocaleHelper.Timezones}
                        itemToString={(tz) => (tz ? tz.timezone : '')}
                        selectedItem={this.state.timezone}
                        onChange={({ selectedItem }) => {
                            const val = selectedItem;
                            this.setState(s => ({
                                timezone: val
                            }));
                        }}
                        /><br/>
                        <Dropdown
                            id="language"
                            titleText="Language"
                            label="Please choose a language..."
                            invalid={this.state.language === null}
                            invalidText="Language cannot be blank."
                            items={LocaleHelper.Languages}
                            itemToString={(lang) => (lang ? lang.language : '')}
                            selectedItem={this.state.language}
                            onChange={({ selectedItem }) => {
                                const val = selectedItem;
                                this.setState(s => ({
                                    language: val
                                }));
                            }}
                        /><br/>
                        <Dropdown
                            id="status"
                            titleText="Status"
                            label="Please select a status..."
                            invalid={this.state.status === null}
                            invalidText="Status cannot be blank."
                            items={ClientHelper.Statuses}
                            itemToString={(status) => (status ? status.status : '')}
                            selectedItem={this.state.status}
                            onChange={({ selectedItem }) => {
                                const val = selectedItem;
                                this.setState(s => ({
                                    status: val
                                }));
                            }}
                        /><br/>
                        <Dropdown
                            id="currency"
                            titleText="Currency"
                            label="Please select a currency..."
                            invalid={this.state.currency === null}
                            invalidText="Currency cannot be blank."
                            items={LocaleHelper.Currencies}
                            itemToString={(currency) => (currency ? currency.currency : '')}
                            selectedItem={this.state.currency}
                            onChange={({ selectedItem }) => {
                                const val = selectedItem;
                                this.setState(s => ({
                                    currency: val
                                }));
                            }}
                        /><br/>
                    </Form>
                </div>
            </Modal>
            {this.state.closing && <Modal
                danger open size="xs" modalLabel={open ? this.state.name : ''}
                modalHeading="Are you sure?"
                secondaryButtonText="Go Back"
                primaryButtonText="Discard Changes"
                shouldSubmitOnEnter={false}
                onRequestClose={() => this.setState({closing: false})}
                onSecondarySubmit={() => this.setState({closing: false})}
                onRequestSubmit={this.onClose}
            >This will discard any changes.</Modal>}
        </>);
    }
}

export default ClientEditTear;