import React from 'react';
import { Route, Link } from "react-router-dom";
import Main from "../../content/Main";
import { HeaderMenuItem } from "carbon-components-react";
import Management from "../../content/Management";
import Clients from "../../content/Clients"
import Budget from "../../content/Budget"
import Editor from "../../content/Editor";

class NavigationHelper {
    static NAVIGATION = {
        // hidden: route exists, but isn't in the menu, internal: no menu or route to any external users i.e. non-IBMers
        'main': {path: '/', label: 'Home', component: Main, routes: [], hidden: false, internal: false, exact: true},
        'manage': {
            path: '/manage', label: 'Management', component: Management, hidden: false, internal: true, exact: true,
            routes: [
                '/catalog', '/catalog/:uuid', '/reports', '/report/:uuid', '/sections', '/section/:uuid', '/findings',
                '/finding/:uuid', '/types/contacts', '/types/contact/:uuid', '/types/findings', '/types/finding/:uuid',
                '/types/reports', '/types/report/:uuid', '/types/targets', '/types/target/:uuid', '/types/tests',
                '/types/test/:uuid',
            ]
        },
        'clients': {
            path: '/clients', label: 'Clients', component: Clients, hidden: false, internal: true, exact: true,
            routes: [
              '/:client_uuid', '/:client_uuid/contracts/:contract_uuid', '/:client_uuid/projects/:project_uuid',
                '/:client_uuid/contracts/:contract_uuid/contract_item/:contract_item_uuid'
            ]
        },
        'budget': {
            path: '/budget', label: 'Budget', component: Budget, hidden: false, internal: true,
            routes: [

            ]
        },
        'edit': {
            path: '/edit', label: 'Edit', component: Editor, hidden: true, internal: true, exact: true,
            routes: [
                '/report/:uuid', '/section/:uuid',
            ]
        },
    }

    static getRoutes = (internal = false) =>
        Object.values(NavigationHelper.NAVIGATION).filter(p => internal || !p.internal).map(
            r => <Route exact={!!r.exact} path={[r.path, ...r.routes.map(p => r.path + p)]} key={r.path} component={r.component} />
        );

    // Component needs to be UCFirst for <Component to render the target component directly, do not change this
    static getHeaderMenuItems = (internal = false, Component = HeaderMenuItem, element = Link) =>
        Object.values(NavigationHelper.NAVIGATION).filter(
            p => !p.hidden && (internal || !p.internal)
        ).map(
            p => <Component element={element} to={p.path} key={p.path}>{p.label}</Component>
        );
}

export default NavigationHelper;