import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Account
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api';
    static UNI_URL = Account.API_URL + '/oidc/authenticate';
    static EXT_URL = 'https://www.ibm.com/security/services';

    static sso_url(){
        return Account.UNI_URL;
    }
    static photo_url(){
        return `${Account.API_URL}/account/photo`;
    }
    static logout(){
        const url = `${Account.API_URL}/account/logout`;
        return axios.get(url).then(response => response.data);
    }
    static user(){
        const url = `${Account.API_URL}/account/user`;
        return axios.get(url).then(response => response.data);
    }
    static fault(){
        const url = `${Account.API_URL}/sso/fault`;
        return axios.get(url).then(response => response.data);
    }
    static sso_go() {
        window.location = Account.UNI_URL;
    }
    static bail() {
        window.location = Account.EXT_URL;
    }
}
