import React from 'react';
import { Switch } from "react-router-dom";
import MainHeader from "./components/MainHeader";
import SessionContext from "./helpers/SessionContext";
import NavigationHelper from "./helpers/Navigation";
import Account from "./backend/Account";

import './App.scss';
import Toasty from "./components/Toasty";


class App extends React.Component {
  constructor(props) {
    super(props);
    const { dark } = props;

    this.toastRef = React.createRef();
    this._networkFaultHandler = this.networkFaultHandler;
    this._addToast = this.addToast;
    this._mounted = false;

    this.state = {
      user: {
        authorised: false,
        internal: false,
        forename: 'Anonymous',
        surname: 'User',
        username: 'anon',
        email: 'anon@x-force.ibm',
      },
      progress: 0, errors: [], dark: dark,
      setProgress: this.setProgress, addToast: this.addToast,
      networkFaultHandler: this.networkFaultHandler
    };
  }
  setProgress = p => this.setState({progress: p});
  addToast = (kind, title, subtitle, caption, timeout = 7500, _uuid = null) =>
      this.toastRef?.current?.addToast ? this.toastRef.current.addToast(kind, title, subtitle, caption, timeout, _uuid)
          : console.log(kind, title, subtitle, caption, timeout, _uuid);

  addError = (message, caption='Please try again', title='Error') => this.addToast('error', title, message, caption);
  addInfo = (message, caption='Information', title='Information') => this.addToast('info', title, message, caption);
  addWarning = (message, caption='Warning', title='Warning') => this.addToast('warning', title, message, caption);
  addSuccess = (message, caption='Operation Successful', title='Success') => this.addToast('success', title, message, caption);

  networkFaultHandler = e => this.addError(e?.response?.data?.error || e?.data?.error || 'A network error occurred');
  componentWillUnmount = () => {
    this._mounted = false;
    this.networkFaultHandler = () => {};
    this.addError = () => {};
  }
  componentDidMount = () => {
    this._mounted = true;
    this.networkFaultHandler = this._networkFaultHandler;
    this.addToast = this._addToast;

    Account.user().then(d => {
      if (this._mounted && d) this.setState({user: d});
    }).catch(e => this.addWarning(
        'A connection to the backend server was not established.',
        'Please refresh and try again',
        e.message,
    ));
  }
  render = () => {
    return (
        <SessionContext.Provider value={this.state}>
          <div className="App">
            <MainHeader/>
            <Switch>{this.state.user && this.state.user.authorised && NavigationHelper.getRoutes(this.state.user.internal)}</Switch>
            <Toasty ref={this.toastRef}/>
          </div>
        </SessionContext.Provider>
    );
  }
}

export default App;
