import React from 'react';
import './section.scss';
import DocHelper from "../../helpers/Document";
import DocContext from "../../helpers/DocContext";
import {newNodePropProxy} from "../../helpers/Utility";


class Section extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        title: false,
        numbered: true,
        width: DocHelper.A4MM_W,
        height: DocHelper.A4MM_H,
        margin: DocHelper.A4MM_M,
        header: DocHelper.A4MM_S,
        footer: DocHelper.A4MM_S,
        sid: 0, rows: [],
        handles: new Set(),
        bubblyHandles: false,
        editable: true,
        node: null,
    };
    constructor(props) {
        super(props);
        this.baseRef = React.createRef();
        this.proxy = newNodePropProxy(this);
    }
    renderPage(pageIndex, rows, key) {
        const { ctx } = this.context;
        const { width, height, margin, numbered } = this.proxy;
        const ppi = ctx.ppi;
        const pageNum = pageIndex + 1;

        // TODO: make the header / footer use templates rather than being static :)
        return <div className="page" key={pageNum + key} id={'page-' + pageNum} style={{
            width: DocHelper.mmToPixels(width, ppi),
            height: DocHelper.mmToPixels(height, ppi)
        }}>
            <div key={pageNum + key + '-m'} className="margin" style={{
                margin: DocHelper.mmToPixels(margin, ppi),
                width: DocHelper.mmToPixels(width - (margin * 2), ppi),
                height: DocHelper.mmToPixels(height - (margin * 2), ppi),
                fontSize: DocHelper.mmToPixels(DocHelper.mmFromDxa(DocHelper.ptToDxa(0.5)), ppi)
            }}>
                <div className="pad">
                    {rows.map((r, i) =>
                        r.render && typeof r.render === "function" ? r.render() : <React.Fragment key={i}>{r}</React.Fragment>
                    )}
                </div>
                {numbered ? <div className="footer" key={pageNum + key + '-f'} style={{
                    fontSize: DocHelper.mmToPixels(DocHelper.mmFromDxa(DocHelper.ptToDxa(10)), ppi)
                }}>
                    <div className="foot-split">
                        <span>X-Force | !TODO!</span>
                        <span>IBM and !TODO! confidential</span>
                    </div>
                    <div>{pageNum}</div>
                </div> : <></>}
            </div>
        </div>;
    }
    render() {
        const { ctx } = this.context;
        const { node, width, height, margin, numbered, rows } = this.proxy;
        const pages = [], page = [];
        ctx.page = 0;
        ctx.width = width;
        ctx.height = height;
        ctx.margin = margin;

        let full = false, first = true;

        const pager = (p, i) => {
            if (!p) return;

            if (page.length > 0 && ((((p.getProp ? p.getProp('break', false) : p.break) || ctx.flow.has(p.uuid)) && !first) || full)) {
                full = false;
                pages.push(this.renderPage(pages.length, page, pages.length + '-p-' + i));
                page.length = 0;  // clear the list without reallocating the underlying array
            }
            page.push(p);
            first = false;
        };

        rows.forEach(pager);

        if (page.length > 0 || pages.length < 1)
            pages.push(this.renderPage(pages.length, page, pages.length + '-p-x'));

        ctx.page += pages.length;

        return <div
            ref={this.baseRef} key={node.uuid} id={node.getDOMId()}
            className={"section" + (numbered ? " paged" : "")}
        >{pages}</div>;
    }
}

export default Section;