import React from 'react';
import './spell-checker.scss';
import {SpellCheck20} from "@carbon/icons-react";


class SpellChecker extends React.Component {
    static defaultProps = {callback: () => {}, className: ''};
    constructor(props) {
        super(props);
        this.state = {valid: false}; // we use state so we can update and force a render without rendering the document
    }
    render() {
        const { className } = this.props;
        const { valid } = this.state;
        return (
            <div className={"xf-spell " + className}>
                <span className="xfs-icon"><SpellCheck20 fill={valid ? '#198038' : '#ba1b23'} /></span>
                <span className="xfs-label bx--label">{valid ? 'Yay! Perfect spelling!' : 'Potential spelling mistakes detected'}</span>
            </div>
        );
    }
}

export default SpellChecker;