import React from 'react';


// Create a Context
const SessionContext = React.createContext({
    user: {}, toasts: [], progress: 0, setProgress: () => {}, muted: false, toggleMuted: () => {},
    addToast: (kind, title, subtitle, caption, timeout=3500, _uuid = null) => {},
    networkFaultHandler: (event) => {}
});
// It returns an object with 2 values:
// { Provider, Consumer }

export default SessionContext;