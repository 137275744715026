import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Reporting
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/reports';

    static sections(){
        const url = `${Reporting.API_URL}/sections`;
        return axios.get(url).then(response => response.data);
    }

    static section(uuid, data = undefined){
        const url = `${Reporting.API_URL}/section/${uuid}`;
        return ((data === undefined) ? axios.get(url) : axios.post(url, data)).then(response => response.data);
    }

    static reports(){
        const url = `${Reporting.API_URL}/reports`;
        return axios.get(url).then(response => response.data);
    }

    static report(uuid, data = undefined){
        const url = `${Reporting.API_URL}/report/${uuid}`;
        return ((data === undefined) ? axios.get(url) : axios.post(url, data)).then(response => response.data);
    }
}
