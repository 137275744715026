class ClientHelper {

    static Statuses = [
        {"id": 0, "status": 'Other'},
        {"id": 1, "status": 'Active'},
        {"id": 2, "status": 'Inactive'}
    ];

    static getStatusById(id){
        return this.Statuses.find(s => s.id === id || '');
    }
}

export default ClientHelper;
