import React from 'react';
import './contract-item-edit-tear.scss';
import SessionContext from "../../helpers/SessionContext";
import {Dropdown, FormGroup, Modal, TextInput} from "carbon-components-react";
import '@carbon/ibm-cloud-cognitive/scss/components/Tearsheet/_tearsheet.scss';
import Types from '../../backend/Types'
import ContractItems from '../../backend/ContractItems';

class ContractItemEditTear extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        contractItem: null, onClose: null, contract: null
    };
    constructor(props) {
        super(props);
        const { contractItem } = this.props;
        const { contract } = this.props;
        this.state = {
            contractItem: contractItem,
            label: '',
            contract: contract,
            test_type: null,
            test_types: [],
            price: '',
            description: '',
            loading: false,
            closing: false
        };

        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
        this.onClose = this.onClose.bind(this);
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;
        if(this.state.contractItem){
            this.setState({
                label: this.state.contractItem.label || '',
                contract: this.state.contractItem.contract || null,
                test_type: this.state.contractItem.test_type || '',
                description: this.state.contractItem.description || '',
                price: this.state.contractItem.price || null,
            })
        }
        Types.tests().then( result => {
            if(result.success){
                this.setState({test_types: Object.entries(result.test_types).map(tt => tt[1])})
            }
        }).catch(this.context.networkFaultHandler)
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    onClose() {
        const { onClose: callback } = this.props;
        if (callback) callback(this.state);
    }
    reset(callback = () => {}) {
        this.setState({
            label: '',
            contract: null,
            test_type: null,
            price: null,
            description: null,
            loading: false,
            closing: false,
        }, callback);
    }
    save() {
        let contractItem = {
            label: this.state.label,
            contract: this.state.contract.id,
            test_type: this.state.test_type.uuid,
            price: this.state.price,
            description: this.state.description
        }
        if(this.state.contractItem){
            ContractItems.createOrUpdate(this.state.contractItem.uuid, contractItem)
                .then(result => {
                        this.setState({
                            updated_contract_item: result
                        })
                        this.onClose()
                    }
                )
                .catch(this.context.networkFaultHandler)
        }
        else {
            ContractItems.createOrUpdate('create', contractItem)
                .then(result => {
                    this.setState({
                        updated_contract_item: result
                    })
                    this.onClose()
                })
                .catch(this.context.networkFaultHandler)
        }

    }
    render() {
        const open = true;
        return (<>
            <Modal
                open={open}
                modalHeading={this.state.name}
                modalLabel="Contract Management"
                primaryButtonText={this.state.contract ? 'Save' : 'Create'}
                secondaryButtonText="Cancel"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    this.setState({closing: true})}
                }
                onRequestSubmit={(e) => this.save(e)}
                onSecondarySubmit={() => this.setState({closing: true})}
            >
                <div className='xts-tear'>
                    <FormGroup
                        hasMargin
                        invalid={false}
                        legendText="Contract Properties"
                        message={false}
                        messageText="hi"
                    >
                        <TextInput
                            id="label" name="label" labelText="Label" value={this.state.label}
                            placeholder="Please enter a name..."
                            invalid={!this.state.label.length}
                            invalidText="Label cannot be blank."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    label: val
                                }));
                            }}
                        /><br/>
                        <Dropdown
                            id="test_type"
                            titleText="Test Type"
                            label="Please choose a test type..."
                            items={this.state.test_types}
                            itemToString={(test_type) => (test_type ? test_type.name : '')}
                            selectedItem={this.state.test_type}
                            onChange={({ selectedItem }) => {
                                const val = selectedItem;
                                this.setState(s => ({
                                    test_type: val
                                }));
                            }}
                        /><br/>
                        <TextInput
                            id="price" name="price" labelText="Price" value={this.state.price}
                            placeholder="Please enter a price..."
                            invalid={!this.state.price.length}
                            invalidText="Price cannot be blank."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    price: val
                                }));
                            }}
                        /><br/>
                        <TextInput
                            id="description" name="description" labelText="Description" value={this.state.description}
                            placeholder="Please enter a description..."
                            invalidText="Description cannot be blank."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    description: val
                                }));
                            }}
                        /><br/>

                    </FormGroup>
                </div>
            </Modal>
            {this.state.closing && <Modal
                danger open size="xs" modalLabel={open ? this.state.name : ''}
                modalHeading="Are you sure?"
                secondaryButtonText="Go Back"
                primaryButtonText="Discard Changes"
                shouldSubmitOnEnter={false}
                onRequestClose={() => this.setState({closing: false})}
                onSecondarySubmit={() => this.setState({closing: false})}
                onRequestSubmit={this.onClose}
            >This will discard any changes.</Modal>}
        </>);
    }
}

export default ContractItemEditTear;