import React from 'react';
import './text-run.scss';
import DocHelper from "../../../helpers/Document";
import DocContext from "../../../helpers/DocContext";
import {newNodePropProxy} from "../../../helpers/Utility";


class TextRun extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        text: '', node: null, link: null, style: null, size: null, font: DocHelper.FONTS[0], color: null,
        bold: false, italic: false, underline: false, highlight: false, strikethrough: false, subscript: false, superscript: false
    };
    constructor(props) {
        super(props);
        this.baseRef = React.createRef();
        this.proxy = newNodePropProxy(this);
    }
    render() {
        const {
            style, size, color, bold, italic, underline, highlight, strikethrough, subscript, superscript,
            text: _text, font, link, node, ...otherProps
        } = this.proxy;
        const  { ctx } = (node && node.context) || this.context;

        // -- CE Static Render Bug
        // So in newer versions of React they stopped context working when using renderToStaticMarkup.
        // This means there is no way of using context inside ContentEditable children, apparently the
        // functionality was a "bug" that the react devs removed without implementing an alternative.
        // 3 year old ticket here detailing "Its a bug, not a feature": https://github.com/facebook/react/issues/14292
        const styles = {
            fontSize: DocHelper.mmToPixels(DocHelper.mmFromEmu(DocHelper.emuFromPt(
                (typeof size == "number" ? size : DocHelper.FONT_SIZE) * ((subscript || superscript) ? 0.83 : 1)
            )), ctx.ppi)
        };
        if (color)  styles['color'] = color;
        if (font)   styles['fontFamily'] = font;
        if (bold)   styles['fontWeight'] = '600';
        if (italic) styles['fontStyle'] = 'italic';
        if (underline) styles['textDecoration'] = 'underline';
        if (highlight) styles['background'] = highlight;
        if (strikethrough) styles['textDecoration'] = underline ? 'underline line-through' : 'line-through';
        if (superscript) styles['top'] = '-0.5em';
        else if (subscript) styles['bottom'] = '-0.5em';

        const styleClass = style && (style in DocHelper.STYLE_CLASSES) ? ' s-' + DocHelper.STYLE_CLASSES[style] : '';
        const child = (node && node.getChild) ? node.getChild() : null;

        // override props if the node has changed (we bypass react when handling onChange to improve performance)
        const text = (child && child !== _text) ? child : _text;
        const props = {
            key: node.uuid, id: node.getDOMId(), style: styles, 'data-uuid': node.uuid, ref: this.baseRef, ...otherProps
        };
        const content = text && (Array.isArray(text) ? text.join('') : text);

        return link ? <a href={link} title={content} className={'xfp-link xfp-run' + styleClass} {...props}>{content}</a> : <span className={'xfp-run' + styleClass} {...props}>{content}</span>;
    }
}

export default TextRun;