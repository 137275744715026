import React from 'react';
import './zoom-slider.scss';
import {Slider} from "carbon-components-react";


class ZoomSlider extends React.Component {
    static defaultProps = {zoom: 100, callback: () => {}, className: ''};
    // static getDerivedStateFromProps = (newProps, oldState) => // causes recursion errors... sometimes I hate react!
    //     (newProps.zoom !==  oldState.zoom) ? {zoom: newProps.zoom, newZoom: newProps.zoom} : null;
    constructor(props) {
        super(props);

        const { zoom } = this.props;

        this.state = {
            zoom: zoom,
            newZoom: zoom
        };
    }
    setZoom(zoom) {
        const { callback } = this.props;
        this.setState({zoom: zoom}, () => callback(zoom));
    }
    setNewZoom(zoom) {
        this.setState({newZoom: zoom});
    }
    render() {
        const { zoom: _, callback, className, ...props } = this.props;
        const { zoom, newZoom } = this.state;
        return (
            <div className={"xf-zoom " + className}>
                <Slider
                    ariaLabelInput="Document zoom level"
                    hideTextInput id="zoom-slider" name="zoom-slider"
                    labelText={`Zoom Level (${newZoom}%)`}
                    min={50} max={150} value={newZoom}
                    step={1} stepMultiplier={10} {...props}
                    onChange={({value}) => this.setNewZoom(value)}
                    onRelease={({value}) => this.setZoom(value)}
                    onBlur={() => newZoom !== zoom && this.setZoom(newZoom)}
                />
            </div>
        );
    }
}

export default ZoomSlider;