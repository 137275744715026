import React from 'react';
import { Content } from "carbon-components-react/es/components/UIShell";
import { GaugeChart } from "@carbon/charts-react";
import './budget.scss';
import SessionContext from "../../helpers/SessionContext";
import ContractBudgetByMonthBar from "../../components/ContractBudgetByMonthBar";
import CombinedContractBudgetByMonthBar from "../../components/CombinedContractBudgetByMonthBar";

class Budget extends React.Component {
    static contextType = SessionContext;
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            gauge_data: [{
                'group': "value",
                'value': 66
            },{
                'group': "delta",
                'value': -10
            }],
            gauge_options: {
                "title": "Budget",
                "resizable": true,
                "height": "250px",
                "width": "700px",
                "gauge": {
                    "type": "semi",
                    "status": "danger"
                }
            },
            data: {
                contract: {
                    name: 'Default contract',
                    budget: 120000,
                    start_date: '2021-01-01',
                    end_date: '2021-12-31',
                    client: 'default client'
                },
                contracts: [
                    {
                        name: 'Contract 1',
                        budget: 120000,
                        start_date: '2021-01-01',
                        end_date: '2021-12-31',
                        client: 'default client'
                    },
                    {
                        name: 'Contract 2',
                        budget: 120000,
                        start_date: '2021-06-01',
                        end_date: '2022-05-31',
                        client: 'default client'
                    }
                ],
                contracts2: [
                    {
                        name: 'Contract 1',
                        budget: 120000,
                        start_date: '2021-01-01',
                        end_date: '2021-12-31',
                        client: 'default client'
                    },
                    {
                        name: 'Contract 2',
                        budget: 120000,
                        start_date: '2021-06-01',
                        end_date: '2022-05-31',
                        client: 'default client'
                    },{
                        name: 'Contract 3',
                        budget: 120000,
                        start_date: '2022-01-01',
                        end_date: '2023-12-31',
                        client: 'default client'
                    },
                    {
                        name: 'Contract 4',
                        budget: 120000,
                        start_date: '2023-06-01',
                        end_date: '2024-05-31',
                        client: 'default client'
                    }
                ]
            },
            loading: false
        };

        this.handleUpdateResponse = this.handleUpdateResponse.bind(this);
        this.handleUpdateException = this.handleUpdateException.bind(this);
        this.mounted = false;
    }
    handleUpdateResponse(d) {
        if(!this.mounted) return;
        if (d && d.data)
            this.setState({
                errors: [],
                data: d.data,
                loading: false
            });
    }
    handleUpdateException(e) {
        if(!this.mounted) return;
        if(!e.response || !e.response.data || typeof e.response.data !== 'object' || !('error' in e.response.data))
            return this.setState(s => ({errors: [...s.errors, 'A network error occurred'], loading: false}));
        return this.setState(s => ({errors: [...s.errors, e.response.data['error']], loading: false}));
    }
    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <Content className='xf-budget'>
                <div className='xfm-wrap'>
                    <div className='xfm-head'>
                        <h1>Budget Overview</h1>
                    </div>
                    <div className='xfm-main'>
                        <ContractBudgetByMonthBar
                            contract={this.state.data.contract}
                        />
                        <br/>
                        <CombinedContractBudgetByMonthBar
                            contracts={this.state.data.contracts2}
                        />
                    </div>
                    <div className='xfm-main'>
                        <GaugeChart
                            data={this.state.gauge_data}
                            options={this.state.gauge_options}
                        />
                        <CombinedContractBudgetByMonthBar
                            contracts={this.state.data.contracts}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}

export default Budget;