import {v4 as uuid4} from "uuid";
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {PROPS, VALID, TYPES, DetermineILType, RT_KEYS, PROP_TYPES, MAX_ITERATIONS} from ".";
import {DebugConsole} from "../../helpers/Utility";
import {SectNode, ListNode, RowNode, TextRunNode, DocNode, TableNode, TableRowNode, TableCellNode, ImageNode} from "./Nodes";


export default class ILNode {
    static defaultChild = ILNode;
    static defaultProps = {};

    constructor(nodeObj, filterInvalidProps = true, parent = null, idx = 0, props = {}, prev = null, next = null, customUUID = false) {
        if (!nodeObj) throw EvalError("ILNode.constructor: Invalid node object representation provided");
        if (nodeObj instanceof ILNode) return nodeObj; // skip nested constructs
        if (typeof nodeObj === "string") {
            try {
                nodeObj = JSON.parse(nodeObj);
            } catch (e) {
                console.error('Error parsing DocIL object', nodeObj, e);
                nodeObj = {data: nodeObj};
            }
        }

        if (!parent) DebugConsole.warn("new ILNode: Invalid parent provided!", parent);

        // generators have to use bind still cause they don't work with arrow functions :(
        this.iterateRuns = this.iterateRuns.bind(this);
        this.iterateRunsBetween = this.iterateRunsBetween.bind(this);
        this.iterateRunsTo = this.iterateRunsTo.bind(this);
        this.iterateRunsUpTo = this.iterateRunsUpTo.bind(this);
        this.iterateEditableRuns = this.iterateEditableRuns.bind(this);

        this._idx = idx;
        this.uuid = (customUUID && ('uuid' in nodeObj) && nodeObj.uuid) ? nodeObj.uuid : uuid4();
        this.ref = React.createRef();
        this._parent = parent;
        this.prev = prev;
        this.next = next;
        this.registered = false;
        this.baseProps = {...this.constructor.defaultProps, ...props};
        this._editable = (typeof nodeObj.editable === "boolean") ? nodeObj.editable : true;
        this._required = (typeof nodeObj.required === "boolean") ? nodeObj.required : false;
        this.type = nodeObj.type in PROPS ? nodeObj.type : DetermineILType(nodeObj, parent);
        const typeDef = PROPS[this.type];
        this.component = typeDef.component;
        this.props = typeDef.extends in PROPS ? {
            ...PROPS[typeDef.extends].props, ...typeDef.props
        } : typeDef.props;
        this.child = typeof typeDef.parent === "string" ? typeDef.parent : 'data';
        this.clean = filterInvalidProps;
        this.clazz = this.constructor;

        if (this.constructor !== this.getNodeClass(this.type)) {
            DebugConsole.error(
                "ILNode.constructor: Node type mismatch with instantiated class type", this.rep, this.constructor?.name,
                this.getNodeClass(this.type)?.name
            );
            console.trace();
        }

        //  TODO: this will silently ignore any invalid data, we might want to at least log it somewhere? maybe?
        this.il = this.parseILData(nodeObj, this.clean, customUUID);

        // handle cases where we either have a completely empty element, so default to row > text, or a partial def
        if (typeDef.parent) {
            if (!(this.child in this.il))  // default to an empty text run
                this.il[this.child] = this.injectChild("", 0, {}, null, null, customUUID);
            if (typeDef.props[this.child] === TYPES.LIST && !Array.isArray(this.il[this.child]))
                this.il[this.child] = [this.il[this.child]];
        }

        if(this._parent) this.register();
        if (nodeObj.postInit && typeof nodeObj.postInit === "function") nodeObj.postInit(this);

        this._snapping = false;
        this._snapTime = 0;
        this._snap = this.snapshot();
    }

    get parent() {
        return this._parent;
    }

    set parent(node) { // make sure we capture and register nodes constructed before having a parent set
        this._parent = node;
        this.register();
    }

    get editable() { // overridden to take the parent setting into account too :)
        return this._editable && (!this.parent || this.parent.editable);
    }

    set editable(editable) {
        this._editable = editable;
        if ('editable' in this.il) {
            if (editable) delete this.il['editable']; // we reduce to defaults wherever possible to reduce storage size
            else this.il['editable'] = false;
        }
    }

    get required() { // overridden so we enforce at least one child for required parents
        if (this._required || !this.parent) return this._required;
        return (this.parent instanceof ILNode) && this?.parent?.required && (this?.parent?.childCount <= 1);
    }

    set required(required) {
        this._required = required;
    }

    restoreDefaultFormatting = (rerender = true, callback = () => {}, newProps = {}) => this.restoreDefaults(rerender, callback, newProps, true);

    restoreDefaults = (rerender = true, callback = () => {}, newProps = {}, formatOnly = false) => {
        const keys = [
            'align', 'font', 'size', 'style', 'color', 'highlight', 'bold',
            'italic', 'underline', 'strikethrough', 'subscript', 'superscript'
        ];
        if(!formatOnly) keys.push('link', 'label', 'level', 'spacing', 'indent', 'break');
        for (const key of keys) delete this.il[key];
        return this.update(newProps, rerender, callback);
    }

    register = (uuid = null, self = null) => {
        if (this?.parent?.register) {
            this.registered = true;
            return this.parent.register(uuid || this.uuid, self || this);
        }
        DebugConsole.warn("ILNode.register: Unable to register UUID", uuid || this.uuid, this?.parent?.register, self || this);
    }

    retrieve = (uuid = null) => {
        if (this?.parent?.retrieve) {
            this.registered = false;
            return this.parent.retrieve(uuid || this.uuid);
        }
        DebugConsole.warn("ILNode.retrieve: Unable to retrieve UUID", uuid || this.uuid, this?.parent?.retrieve);
    }

    unregister = (uuid = null) => {
        // TODO: Find out why elements are being unregistered when still in use, this whole idea is messy, sort it out!
        //       If we ever get FinalizationRegistry working properly in node then we can just use that to have nodes
        //       unregister themselves automatically when they get deleted (local node map will need WeakRefs for this).
        // if (this?.parent?.unregister) return this.parent.unregister(uuid || this.uuid);
        // DebugConsole.warn("ILNode.unregister: Unable to unregister UUID", uuid || this.uuid, this?.parent?.unregister);
    }

    activate = (node = null, callback = null) => {
        if (this?.parent?.activate) return this.parent.activate(node || this, callback || (() => {}));
        DebugConsole.warn("ILNode.activate: Unable to activate node", node || this, this?.parent?.activate);
    }

    // abstract method, implement in child nodes
    deactivate = (callback = null, sameNode = false) => callback && callback();

    // this shoots up the node tree until we hit DocIL and get the actual selection, woop
    getSelection = () => this?.parent?.getSelection ? this.parent.getSelection() :
        DebugConsole.warn("ILNode.getSelection: Parent selection unavailable", this?.parent?.getSelection);

    restoreSelection = (resumeIfPaused = true) => this?.parent?.restoreSelection ? this.parent.restoreSelection(resumeIfPaused) :
        DebugConsole.warn("ILNode.restoreSelection: Parent selection unavailable", this?.parent?.restoreSelection);

    placeCaret = (node, afterNode = true) => this?.parent?.placeCaret ? this.parent.placeCaret(node, afterNode) :
        DebugConsole.warn("ILNode.placeCaret: Parent caret unavailable", this?.parent?.placeCaret);

    paginate = (callback = () => {}) => this?.parent?.paginate ? this.parent.paginate(callback) :
        DebugConsole.warn("ILNode.paginate: Paginate unavailable", this?.parent?.paginate);

    get selection() {
        return this.getSelection();
    }

    get select() {
        return this.getSelection();
    }

    getProp = (key, defaultValue = null) => key in this.il ? this.il[key] : defaultValue;

    focusRef = (rightAlign = true) => {
        if (!this?.ref?.current) return;
        let node = this?.ref?.current?.baseRef?.current || ReactDOM.findDOMNode(this.ref.current);
        if (!node) return;

        while (node.nodeType === 1 && (
            node.classList.contains('page') || node.classList.contains('margin') || node.classList.contains('xfp')
        )) node = node.firstElementChild;

        if (rightAlign) while (node && node.nodeType !== 3 && (node.lastElementChild || node.lastChild))
            node = node.lastElementChild || node.lastChild;
        else while (node && node.nodeType !== 3 && (node.firstElementChild || node.firstChild))
            node = node.firstElementChild || node.firstChild;

        this.placeCaret(node, !rightAlign);
    }

    focus = (rightAlign = true) => {
        this.focusChild(rightAlign ? -1 : 0, rightAlign);
    }

    // calls .focus on a child element with index == idx, if idx < 0 then idx = children.length
    focusChild = (idx = 0, rightAlign = true) => {
        const children = this.il[this.child];
        const child = Array.isArray(children) ? ((idx >= 0 && idx < children.length) ? children[idx] : (idx >= 0 ? children[0] : children[children.length - 1])) : children;
        if (child instanceof ILNode) child.focus(rightAlign);
    }

    get idx() { // overridden to self-validate and ensure we always have the correct index
        //return this._idx;
        if (!this?.parent?.il || !(this?.parent?.child in this.parent.il)) return this._idx = 0;
        const children = this.parent.il[this.parent.child];
        if (!Array.isArray(children)) return this._idx = 0;
        if (this._idx >= 0 && this._idx < children.length && children[this._idx] === this) return this._idx;
        const idx = this.parent.il[this.parent.child].indexOf(this);
        if (idx < 0) return this._idx;
        return this._idx = idx;
    }

    set idx(idx) { // again, self-validating to ensure we only set valid index values
        if (this.parent) {
            const children = this.parent.getChildList();
            if (idx < 0 || idx >= children.length || children[idx] !== this) {
                console.warn("Invalid IDX being set!", this.type, this.uuid, idx, children.length);
                console.trace();
            }
        }

        this._idx = idx; // access the internal var to bypass the validation check, e.g. after the node has been removed
    }

    // update the indexes -- I really dislike this, looping through every list each time... eww
    updateChildReferences = () => {
        const child = this.il[this.child];
        if (!Array.isArray(child)) {
            if (!(child instanceof ILNode)) return;
            child._idx = 0;
            child.prev = null;
            child.next = null;
            return child;
        }
        this._updateChildReferences(child, this);
        return child;
    }

    _updateChildReferences = (child, parent = undefined) => {
        const max = child.length - 1;
        for (let i = 0; i <= max; i++) {
            const node = child[i];
            if (!(node instanceof ILNode)) continue;
            node._idx = i; // use _idx to bypass validity check
            node.prev = i > 0 ? child[i-1] : null;
            node.next = i < max ? child[i+1] : null;
            if(parent !== undefined) node.parent = parent;
        }
        return child;
    }

    setChildren = (...children) => {
        this.snapInit();
        this.il[this.child] = this.filterNewChildren(children);
        this.updateChildReferences();
        return this.il[this.child];
    }

    prependChildren = (...children) => {
        this.snapInit();
        children = this.filterNewChildren(children);
        if (this.child in this.il) {
            if (Array.isArray(this.il[this.child])) this.il[this.child].unshift(...children);
            else this.il[this.child] = [...children, this.il[this.child]];
        } else {
            this.il[this.child] = children;
        }
        this.updateChildReferences();
        return children;
    }

    appendChildren = (...children) => {
        this.snapInit();
        children = this.filterNewChildren(children);
        if (this.child in this.il) {
            if (Array.isArray(this.il[this.child])) this.il[this.child].push(...children);
            else this.il[this.child] = [this.il[this.child], ...children];
        } else {
            this.il[this.child] = children;
        }
        this.updateChildReferences();
        return children;
    }

    filterNewChildren = children => children.map(c => {
        if (c instanceof ILNode) {
            c.register();
            return c;
        }
        return this.buildChild(c);
    });

    spliceChild = (idx, deleteCount = 1, ...children) => {
        const r = this._spliceChild(idx, deleteCount, ...this.filterNewChildren(children));
        this.updateChildReferences();
        return r;
    }

    spliceOffsetChild = (child, offset = 1, deleteCount = 0, ...children) => {
        if (!this.il[this.child]) {
            this.snapInit();
            return this.il[this.child] = this.filterNewChildren(children);
        }
        if (!child) {
            DebugConsole.warn('spliceOffsetChild: child is falsy, is this expected?');
            return this.spliceChild(offset, deleteCount, ...children);
        }
        return this.spliceChild(child.idx + offset, deleteCount, ...children);
    }

    prependSibling = (...nodes) => this?.parent?.spliceOffsetChild && this.parent.spliceOffsetChild(this, 0, 0, ...nodes);
    appendSibling = (...nodes) => this?.parent?.spliceOffsetChild && this.parent.spliceOffsetChild(this, 1, 0, ...nodes);
    spliceSibling = (offset, deleteCount, ...nodes) => this?.parent?.spliceOffsetChild && this.parent.spliceOffsetChild(this, offset, deleteCount, ...nodes);

    _spliceChild(idx, deleteCount = 1, ...children) {
        this.snapInit();
        if (!this.il[this.child] || idx == null) return this.il[this.child] = children;

        if (!Array.isArray(this.il[this.child])) {
            if (deleteCount) {
                const old = [this.il[this.child]];
                this.il[this.child] = children;
                return old;
            }
            this.il[this.child] = idx <= 0 ? [...children, this.il[this.child]] : [this.il[this.child], ...children];
            return 0;
        }

        if (idx >= this.il[this.child].length) return this.il[this.child].push(...children);
        if (idx < 0) return this.il[this.child].unshift(...children);

        return this.il[this.child].splice(idx, deleteCount, ...children);
    }

    // removes a child from this node, if cleanTree is true this node will also delete itself if it is now empty
    // returns a boolean indicating whether a re-render of the tree is necessary i.e. did we delete ourselves
    removeChild = (child, cleanTree = false, deleted = true) => {
        if (!cleanTree) return this.spliceOffsetChild(child, 0, 1);
        if (this.childCount <= 1 && this.parent) this.parent.snapInit(); // pre-snap the parent as we will delete this
        const del = this.spliceOffsetChild(child, 0, 1);
        return this?.il[this.child]?.length ? del : this.remove(true, deleted);
    }

    remove = (cleanTree = true, deleted = true, force = false) => {
        if (!force && this.required) {
            if (!this.editable) return false; // not allowed to delete these!!
            if (deleted) {
                this.clearChildren(true); // parent is required, remove child content only
                // we have to reset the parent too!... Do we?.... nah
                // this?.parent?.editable && this.parent.restoreDefaults(false);
            }
            return true;
        }
        if (deleted) this.unregister();
        return this?.parent?.editable && this.parent.removeChild(this, cleanTree);
    }

    replaceMulti = (deleteCount, ...nodes) => this?.parent?.spliceOffsetChild && this.parent.spliceOffsetChild(this, 0, deleteCount, ...nodes);
    replaceAll = (...nodes) => this.replaceMulti(nodes.length, ...nodes);
    replace = (...nodes) => this.replaceMulti(1, ...nodes);

    getChild = (idx = 0, defaultValue = null) => {
        if (this.child in this.il) {
            const child = this.il[this.child];
            return (Array.isArray(child) && idx >= 0 && idx < child.length) ? child[idx] : (child || defaultValue);
        }
        return defaultValue;
    }

    getChildren = (defaultValue = null) => (this.child in this.il) ? this.il[this.child] : defaultValue;
    clearChildren = (restoreDefaults = false) => {
        this.snapInit();
        restoreDefaults && this.restoreDefaults(false); // make sure we reset formatting and styles etc.
        if (!this.il || !(this.child in this.il)) return this.il[this.child];
        if (Array.isArray(this.il[this.child])) {
            this.il[this.child] = this.il[this.child].map(c => {
                if (c instanceof ILNode) {
                    c.clearChildren(restoreDefaults);
                    return c;
                }
                return null;
            }).filter(c => !!c);
            if (!this.il[this.child].length) this.il[this.child] = this.injectChild("");
        } else if (this.il[this.child] instanceof ILNode)
            this.il[this.child].clearChildren(restoreDefaults);
        return this.il[this.child];
    }

    getChildList = () => (this.il && this.child in this.il) ? (Array.isArray(this.il[this.child]) ? [...this.il[this.child]] : (this.il[this.child] ? [this.il[this.child]] : [])) : [];
    getChildCount = () => (this.il && this.child in this.il) ? (Array.isArray(this.il[this.child]) ? this.il[this.child].length : 1) : 0;

    get children() {
        return this.getChildList();
    }

    get childCount() {
        return this.getChildCount();
    }

    mapChildren = (f = () => {}) => {
        if (!(this.child in this.il)) return;
        const child = this.il[this.child];
        return Array.isArray(child) ? child.map(f) : f(child, 0);
    }

    get length() { // return the length of all children, sums up recursive children
        if (!(this.child in this.il)) return 0;
        const child = this.il[this.child];
        return Array.isArray(child) ?
            child.map(c => c && c.length ? c.length : 0).reduce((a, b) => a + b, 0) :
            (child && child.length ? child.length : 0);
    }

    get textContent() { // return the text of all children recursively
        if (!(this.child in this.il)) return '';
        const child = this.il[this.child];
        if (typeof child === "string") return child;
        return Array.isArray(child) ?
            child.map(c => c && c.textContent ? c.textContent : '').reduce((a, b) => a + b, '') :
            (child && child.textContent ? child.textContent : '');
    }

    get first() { // get the first child
        if (!(this.child in this.il)) return null;
        const child = this.il[this.child];
        return Array.isArray(child) ? (child.length > 0 ? child[0] : null) : child;
    }

    get last() { // get the last child
        if (!(this.child in this.il)) return null;
        const child = this.il[this.child];
        return Array.isArray(child) ? (child.length > 0 ? child[child.length - 1] : null) : child;
    }

    // TODO: check all isRoot usage, it may block traversal across multiple sections as its currently used
    get firstRun() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.isRun) return node;
            const first = node.first;
            if (!first) return node.nextRun;
            node = first;
        }
        return this.nextRun;
    }

    get firstEditableRun() {
        let node = this.firstRun, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.firstRun;
        return node;
    }

    get lastRun() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.isRun) return node;
            const last = node.last;
            if (!last) return node.nextRun;
            node = last;
        }
        return this.prevRun;
    }

    get lastEditableRun() {
        let node = this.lastRun, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.lastRun;
        return node;
    }

    get firstRow() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.isRow) return node;
            const first = node.first;
            if (!first) return node.nextRow;
            node = first;
        }
        return this.nextRow;
    }

    get firstEditableRow() {
        let node = this.firstRow, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.firstRow;
        return node;
    }

    get lastRow() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.isRow) return node;
            const last = node.last;
            if (!last) return node.nextRow;
            node = last;
        }
        return this.prevRow;
    }

    get lastEditableRow() {
        let node = this.lastRow, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.lastRow;
        return node;
    }

    // TODO: Tidy up these traversal helpers, they're horribly messy, do we really need all these nested loops?
    get nextRun() { // get the next run in the tree, breaking out into parent elements if need be
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node?.next) node = node.next; // no need to break out
            else {
                while (!node?.isRoot && (node?.parent !== node) && node?.parent && (lcm++ < MAX_ITERATIONS)) {
                    node = node.parent;
                    if (!node?.next) continue;
                    node = node.next;
                    if (node?.isRun) return node;
                    while (node?.first && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                        node = node.first;
                        if (node?.isRun) return node;
                    }
                }
                if (!node?.parent) return undefined; // nowhere to go to
            }
            // if (node?.type && RT_KEYS.has(node.type)) return node;
            if (node?.isRun) return node;
            while (node?.first && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                node = node.first;
                if (node?.isRun) return node;
            }
        }
        return (node === this || !node?.isRun) ? undefined : node;
    }

    get nextEditableRun() {
        let node = this.nextRun, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.nextRun;
        return node;
    }

    get prevRun() { // get the prev run in the tree, breaking out into parent elements if need be
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node?.prev) node = node.prev; // no need to break out
            else {
                while (!node?.isRoot && (node?.parent !== node) && node?.parent && (lcm++ < MAX_ITERATIONS)) {
                    node = node.parent;
                    if (!node?.prev) continue;
                    node = node.prev;
                    if (node?.isRun) return node;
                    while (node?.last && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                        node = node.last;
                        if (node?.isRun) return node;
                    }
                }
                if (!node?.parent) return undefined; // nowhere to go to
            }
            // if (node?.type && RT_KEYS.has(node.type)) return node;
            if (node?.isRun) return node;
            while (node?.last && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                node = node.last;
                if (node?.isRun) return node;
            }
        }
        return (node === this || !node?.isRun) ? undefined : node;
    }

    get prevEditableRun() {
        let node = this.prevRun, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.prevRun;
        return node;
    }

    get nextRow() { // get the next row in the tree, breaking out into parent elements if need be
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node?.next) node = node.next; // no need to break out
            else {
                while (!node?.isRoot && (node?.parent !== node) && node?.parent && (lcm++ < MAX_ITERATIONS)) {
                    node = node.parent;
                    if (!node?.next) continue;
                    node = node.next;
                    if (node?.isRow) return node;
                    while (node?.first && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                        node = node.first;
                        if (node?.isRow) return node;
                    }
                }
                if (!node?.parent) return undefined; // nowhere to go to
            }
            // if (node?.type && RT_KEYS.has(node.type)) return node;
            if (node?.isRow) return node;
            while (node?.first && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                node = node.first;
                if (node?.isRow) return node;
            }
        }
        return (node === this || !node?.isRow) ? undefined : node;
    }

    get nextEditableRow() {
        let node = this.nextRow, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.nextRow;
        return node;
    }

    get prevRow() { // get the prev run in the tree, breaking out into parent elements if need be
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node?.prev) node = node.prev; // no need to break out
            else {
                while (!node?.isRoot && (node?.parent !== node) && node?.parent && (lcm++ < MAX_ITERATIONS)) {
                    node = node.parent;
                    if (!node?.prev) continue;
                    node = node.prev;
                    if (node?.isRow) return node;
                    while (node?.last && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                        node = node.last;
                        if (node?.isRow) return node;
                    }
                }
                if (!node?.parent) return undefined; // nowhere to go to
            }
            // if (node?.type && RT_KEYS.has(node.type)) return node;
            if (node?.isRow) return node;
            while (node?.last && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
                node = node.last;
                if (node?.isRow) return node;
            }
        }
        return (node === this || !node?.isRow) ? undefined : node;
    }

    get prevEditableRow() {
        let node = this.prevRow, lcm = 0;
        while (node && !node.editable && (lcm++ < MAX_ITERATIONS)) node = node.prevRow;
        return node;
    }

    get isText() {
        return this?.type === PROP_TYPES.TEXT;
    }

    get isTable() {
        return this?.type === PROP_TYPES.TABLE;
    }

    get isImage() {
        return this?.type === PROP_TYPES.IMAGE;
    }

    get isList() {
        return this?.type === PROP_TYPES.LIST;
    }

    get isRow() {
        return this?.type === PROP_TYPES.ROW;
    }

    get isRun() {
        return this?.type === PROP_TYPES.RUN;
    }

    get isRoot() {
        return this?.type === PROP_TYPES.DOCUMENT;
    }

    get isSubList() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.type === PROP_TYPES.LIST) return true;
            if (node.type === PROP_TYPES.SECTION) return false;
            node = node?.parent;
        }
        return false;
    }

    get isSubTable() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.type === PROP_TYPES.TABLE) return true;
            if (node.type === PROP_TYPES.TABLE_ROW) return true;
            if (node.type === PROP_TYPES.TABLE_CELL) return true;
            if (node.type === PROP_TYPES.SECTION) return false;
            node = node?.parent;
        }
        return false;
    }

    get closestTableCell() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.type === PROP_TYPES.TABLE_CELL) return node;
            if (node.type === PROP_TYPES.TABLE_ROW) return null;
            if (node.type === PROP_TYPES.TABLE) return null;
            if (node.type === PROP_TYPES.SECTION) return null;
            node = node?.parent;
        }
        return null;
    }

    get closestTableRow() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.type === PROP_TYPES.TABLE_ROW) return node;
            if (node.type === PROP_TYPES.TABLE) return null;
            if (node.type === PROP_TYPES.SECTION) return null;
            node = node?.parent;
        }
        return null;
    }

    get closestTable() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.type === PROP_TYPES.TABLE) return node;
            if (node.type === PROP_TYPES.SECTION) return null;
            node = node?.parent;
        }
        return null;
    }

    get closestRow() {
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.isRow) return node;
            if (node.type === PROP_TYPES.SECTION) return null;
            node = node?.parent;
        }
        return null;
    }

    get closestRootNode() {
        if (this.parent?.type === PROP_TYPES.SECTION) return this;
        let node = this, lcm = 0;
        while (node && !node?.isRoot && (lcm++ < MAX_ITERATIONS)) {
            if (node.parent?.type === PROP_TYPES.SECTION) return node;
            if (node.type === PROP_TYPES.SECTION) return null;
            node = node?.parent;
        }
        return null;
    }

    getReactNode = (checkParent = true) => this?.ref?.current ? this.ref.current : (checkParent && this.parent?.getReactNode ? this.parent.getReactNode() : undefined);
    getDOMNode = () => {
        return document.getElementById(this.getDOMId());
    }
    getDOMId = () => `${this.type}-${this.uuid}`;
    rerender = (callback = () => {}) => this?.ref?.current ? this.ref.current.forceUpdate(callback) : callback();

    render = (props = {}, preProps = {}) => { // props overwrite everything, preProps will be overwritten by il
        // DebugConsole.debug('Rendering ilNode component:', this.component ? this.component.name : this.type, this.uuid);
        if (!this.component) return this.children.map((c, i) => c && c.render && c.render(props));
        const DocComponent = this.component;
        return <DocComponent
            key={this.uuid} ref={this.ref} node={this} {...({...this.baseProps, ...preProps, ...this.il, ...props})}
        />;
    }

    get context() {
        return this?.parent?.context;
    }

    // node-level implementations required to validate HTML content against node type and update if necessary
    compareAndSwap = domNode => {
        console.error("CAS needs to be implemented on a node-level, the base definition is essentially abstract.");
        return false;
    }

    transformChild = (f = c => c) => {
        if (!(this.child in this.il)) return null;
        const child = this.il[this.child];
        const _f = c => {
            if(c instanceof ILNode) {
                c.transformChild(f);
                return c;
            }
            return f(c);
        };
        this.snapInit();
        this.il[this.child] = Array.isArray(child) ? child.map(_f) : _f(child);
    }

    storeFilter = json => {
        if ((this.child in json) && (json[this.child] === undefined)) json[this.child] = [];
        return json;
    }

    // Returns a JSON tree object for this IL node
    store = (newChildren = undefined, props = {}, includeIDs = false) => {
        const json = {
            type: this.type, ...this.il, ...props
        };
        if (includeIDs) json['uuid'] = this.uuid;
        for (const key of Object.keys(json)) {
            if ((newChildren !== undefined) && (key === this.child))
                json[key] = newChildren;
            if (json[key] instanceof ILNode)
                json[key] = json[key].storeWithIDs(includeIDs);
            else if (Array.isArray(json[key]))
                json[key] = json[key].map(p => (p instanceof ILNode) ? p.storeWithIDs(includeIDs) : p);
        }
        return this.storeFilter(json);
    }
    storeWithIDs = (includeIDs = true) => this.store(undefined, {}, includeIDs);

    // Restores a JSON tree object as passed from edit history
    restore = (data, clean = true, force = false) => {
        if (!data || (!force && (data.uuid !== this.uuid))) // we only allow restores of a valid snapshot, use .update for anything else!
            return DebugConsole.error("Trying to restore the wrong il object, is this expected?", data);

        const {uuid: _, ...il} = data;
        this.il = this.parseILData(il, clean, true);
    }

    // TODO: check snapshot and edit history performance on large docs, I'm not sure it will hold up in its current form
    // Snapshots: These are the basis around which the edit history works (ctrl+z etc.)
    //            Each node monitors itself for changes and reports these back to the root node i.e. DocIL
    //            Anywhere a node is modified but snapshot functionality is not used is noted with NO_SNAP comments,
    //            this is normally due to the functionality being used further up the tree. There will be overlapping
    //            checks so the root snapshot functionality will monitor and merge these changes into a single update.
    snapshot = () => this.storeWithIDs(true);
    snapInit = () => {
        if (this._snapping) clearTimeout(this._snapTime); // clear our old requeue, snapSave should always be last to run
        this._snapTime = this.requeue(() => this.snapSave()); // once we're done check the snaps and update history if need be
        this._snapping = true;
    }
    snapHook = (node, oldSnap, newSnap) => this.parent.snapHook && this.parent.snapHook(node, oldSnap, newSnap);
    snapSave = () => {
        if (!this._snapping) return;
        const snap = this.snapshot();
        if (!_.isEqual(this._snap, snap)) { // easy deep comparison, thanks lodash!
            // we changed, store the snapshot data!
            this.snapHook(this, this._snap, snap);
            this._snap = snap;
        }
        this._snapping = false;
    }

    // Returns a JSON tree string for this IL node
    toString = (newChildren = undefined, props = {}, includeIDs = false) => JSON.stringify(this.store(newChildren, props, includeIDs));

    get rep() {
        return `<${this?.constructor?.name}:${this?.component ? this?.component?.name : 'raw'}:${this?.type} ${this?.uuid}>`;
    }

    get dom() {
        return document.getElementById(this.getDOMId());
    }

    // creates a brand new copy of this by simply converting to json and back :)
    clone = (newChildren = null, props = {}, parent = null, clean = null) => {
        return this.reconstruct(
            this.toString(newChildren, props),
            parent ? parent : this.parent,
            clean ? clean : this.clean
        );
    }

    // basic placeholder implementation, implement custom split in child types
    split = (pos = 0) => this.clone();

    cut = (start, stop = undefined) => { // essentially the same as slice but removes the sliced bit instead
        // basic placeholder implementation, implement custom split in child types
        if (!(this.child in this.il) || !this.il[this.child] || typeof this.il[this.child] !== "string") return;
        this.snapInit();
        if (stop === undefined) return this.il[this.child] = this.il[this.child].slice(start);
        return this.il[this.child] = this.il[this.child].slice(0, start) + this.il[this.child].slice(start, stop);
    }

    // merges this nodes children with its closest sibling, deleting the merged sibling if a parent node is accessible
    join = (forwards = true, render = false) => {
        const source = forwards ? this.next : this;
        const target = forwards ? this : this.prev;
        if (!target || !source) { // nothing to do if there's no sibling to merge
            DebugConsole.error("ILNode.join: Invalid source or target definition for join", source, target);
            return [this, false];
        }
        if (!target.editable || !source.editable) return [this, false];

        // TODO: Make this use forwards/backwards rather then prev/next so we can iterate the tree cleanly :)
        const src = source?.type === PROP_TYPES.LIST ? (forwards ? source.first : source.last) : source;
        const dst = target?.type === PROP_TYPES.LIST ? (forwards ? target.last : target.first) : target;
        const oldLast = dst.last, oldFirst = src.first;

        this.snapInit();
        dst.appendChildren(...src.children);

        if (!this.parent) {
            // nothing to do if there's no parent to update (we should never get here, this is for CQ only)
            if (render) {
                if (typeof render === "function") this.rerender(() => render(oldLast, oldFirst));
                else this.rerender();
            }
            DebugConsole.warn("ILNode.join: No parent node, is this expected?", src, dst);
            return [dst, true];
        }
        const changed = src.remove(true);
        if (render) {
            if (typeof render === "function") this.parent.rerender(() => render(oldLast, oldFirst));
            else this.parent.rerender();
        }
        return [dst, changed];
    }

    // convert an IL representation object into an ILNode i.e. call this after JSON.parse, or just pass in a JSON string
    // this is useful for cloning nodes as it will keep the same parent as the current node i.e. creates a new sibling
    reconstruct = (ilObject, parent = null, clean = null) => {
        const thisNode = this.constructor;
        if (ilObject instanceof ILNode) {
            ilObject.register();
            return ilObject;
        }
        return new thisNode(
            ilObject,
            clean ? clean : this.clean,
            parent ? parent : this.parent
        );
    }

    // same as above but rather than a copy it returns a child node i.e. creates a new child where this is the parent
    buildChild = (ilObject, parent = null, clean = null, idx = 0, props = {}, prev = null, next = null, allowUUIDs = false) => {
        if (ilObject instanceof ILNode) {
            ilObject.register();
            return ilObject;
        }
        const childType = DetermineILType(ilObject, this);
        const childNode = this.getNodeClass(childType);
        return childNode ? new childNode(
            ilObject,
            clean ? clean : this.clean,
            parent ? parent : this, idx,
            props, prev, next, allowUUIDs
        ) : ilObject;
    }

    injectChild = (n, i = 0, props = {}, prev = null, next = null, allowUUIDs = false) => {
        if (n instanceof ILNode) {
            n.register();
            return n;
        }
        const typeDef = PROPS[this.type];
        const inject = typeDef.injects in PROPS ? PROPS[typeDef.injects] : false;
        if (inject && (!n || typeof n === "string")) n = {type: typeDef.injects, [inject.parent]: n};
        if (typeof n === "object") {
            const childType = DetermineILType(n, this);
            const childNode = this.getNodeClass(childType);
            return childNode ? new childNode(n, this.clean, this, i, props, prev, next, allowUUIDs) : n;
        }
        return n;
    }

    parseILData = (nodeObj, clean = null, allowUUIDs = false) => {
        const typeDef = PROPS[this.type];
        return Object.fromEntries(
            Object.entries(this.props).filter(
                ([p, t]) => p in nodeObj && (
                    (!(clean === null ? this.clean : clean)) || (nodeObj[p] instanceof ILNode) ||
                    (t && VALID[t] && VALID[t](nodeObj[p]))
                )
            ).map(([p]) =>
                [p, (typeDef.parent && p === this.child) ? this.parseILChild(nodeObj[p], 0, allowUUIDs) : (nodeObj[p] ? _.cloneDeep(nodeObj[p]) : nodeObj[p])]
            )
        );
    }

    parseILChild = (child, idx = 0, allowUUIDs = false) => Array.isArray(child) ?
        this._updateChildReferences(child.map((v, i) => this.parseILChild(v, i, allowUUIDs)), this) :
        this.injectChild(child, idx, {}, null, null, allowUUIDs);

    update(nodeObj, rerender = true, callback = () => {}) {
        this.snapInit();
        Object.assign(this.il, this.parseILData(nodeObj, this.clean));
        if(rerender) {
            if (this?.ref?.current) return this.ref.current.forceUpdate(callback);
            if (this?.parent?.ref?.current) return this.parent.ref.current.forceUpdate(callback);
            DebugConsole.warn("ILNode.update: Unable to find render target", this?.ref, this?.parent?.ref);
        }
        return callback();
    }

    getNodeClass = nodeType => {
        switch (nodeType) {
            case PROP_TYPES.DOCUMENT:
                return DocNode;
            case PROP_TYPES.SECTION:
                return SectNode;
            case PROP_TYPES.PARAGRAPH:
                return RowNode;
            case PROP_TYPES.TEXT:
                return TextRunNode;
            case PROP_TYPES.LIST:
                return ListNode;
            case PROP_TYPES.TABLE:
                return TableNode;
            case PROP_TYPES.TABLE_ROW:
                return TableRowNode;
            case PROP_TYPES.TABLE_CELL:
                return TableCellNode;
            case PROP_TYPES.IMAGE:
                return ImageNode;
            default:
                DebugConsole.error('ILNode.getNodeClass: Unknown type', nodeType);
                return this.constructor.defaultChild;
        }
    }

    getChildNodeClass = nodeType => {
        switch (nodeType) {
            case PROP_TYPES.DOCUMENT:
                return SectNode;
            case PROP_TYPES.SECTION:
            case PROP_TYPES.LIST:
            case PROP_TYPES.TABLE_CELL:
                return RowNode;
            case PROP_TYPES.PARAGRAPH:
                return TextRunNode;
            case PROP_TYPES.TABLE:
                return TableRowNode;
            case PROP_TYPES.TABLE_ROW:
                return TableCellNode;
            case PROP_TYPES.TEXT:
            case PROP_TYPES.IMAGE:
                return null;
            default:
                DebugConsole.error('ILNode.getChildNodeClass: Unknown type', nodeType);
                return this.constructor.defaultChild;
        }
    }

    // moves callback execution to the end of the JS event queue (i.e. execute after the UI thread is finished)
    requeue = callback => setTimeout(callback, 0);

    // Returns a docx OOXML document tree for this IL node
    docx = () => {
        //  TODO: Implement document generation from IL (or migrate rather)
        throw Error('Not Implemented');
    }

    flushPendingUpdates = () => this?.parent?.flushPendingUpdates && this.parent.flushPendingUpdates();

    // this will iterate and yield all runs between the two nodes, including the specified nodes if need be)
    // this traverses linearly from left to right, top to bottom, or reversed traverses bottom to top, right to left.
    *iterateRuns(from, to, skipReadOnly = true, reversed = false, textRunsOnly = true, includeFromNode = true, includeToNode = true) {
        let current = from;

        while (current && (current.uuid !== to.uuid)) {
            const node = current, isRun = textRunsOnly ? (node.type === PROP_TYPES.RUN) : RT_KEYS.has(node.type);

            if (isRun && (reversed ? current.prev : current.next)) current = reversed ? current.prev : current.next;
            else {
                if (!isRun && (reversed ? current.last : current.first)) current = reversed ? current.last : current.first;
                else if (!isRun && (reversed ? current.prev : current.next)) current = reversed ? current.prev : current.next;
                else {
                    let found = false, idx = 0;
                    while (current.parent && current.parent instanceof ILNode) {
                        if (idx++ > MAX_ITERATIONS) return DebugConsole.error("ILNode.iterateRuns: Recursion error, max depth reached:", current);
                        current = current.parent;
                        if (reversed ? current.prev : current.next) {
                            current = reversed ? current.prev : current.next;
                            found = true;
                            break;
                        }
                    }
                    if (!found) current = false; // bail early
                }
            }

            if (isRun && (!skipReadOnly || node.editable) && (includeFromNode || node !== from))
                yield node; // yield after we find the next node in case any refs get changed during the yield
        }

        if (includeToNode && to && (
            textRunsOnly ? (to.type === PROP_TYPES.RUN) : RT_KEYS.has(to.type)
        ) && (!skipReadOnly || to.editable))
            yield to; // finally yield our end run if it matches the specified requirements :)
    }

    // same as iterateRuns but includeBraceNodes is always false
    *iterateRunsBetween(from, to, skipReadOnly = true, reversed = false, textRunsOnly = true) {
        yield* this.iterateRuns(from, to, skipReadOnly, reversed, textRunsOnly, false, false);
    }

    // same as iterateRuns but this instance is used as the "from" value
    *iterateRunsTo(to, skipReadOnly = true, reversed = false, textRunsOnly = true, includeFromNode = true, includeToNode = true) {
        yield* this.iterateRuns(this, to, skipReadOnly, reversed, textRunsOnly, includeFromNode, includeToNode);
    }

    // same as iterateRunsBetween but this instance is used as the "from" value
    *iterateRunsUpTo(to, skipReadOnly = true, reversed = false, textRunsOnly = true) {
        yield* this.iterateRuns(this, to, skipReadOnly, reversed, textRunsOnly, false, false);
    }

    // same as iterateRunsBetween but this instance is used as the "from" value
    *iterateEditableRuns(to, includeFromNode = true, includeToNode = true, reversed = false, textRunsOnly = true) {
        yield* this.iterateRuns(this, to, true, reversed, textRunsOnly, includeFromNode, includeToNode);
    }

    get preview() {
        return (this?._parent?._preview === undefined) ? false : this._parent._preview;
    }
}