import {RunNode} from "../../Nodes";
import {DebugConsole} from "../../../../helpers/Utility";


export default class TextRunNode extends RunNode {
    // Updates the current node data with the content of the HTML dom node given and returns a
    // boolean indicating whether the content has changed or not i.e. is a re-render required.
    compareAndSwap = (domNode, cleanTree = false, cleanOnlyWhenDifferent = false) => {
        // text runs are nice and easy, we just need to check that the contents match, update if they don't
        if (this.il[this.child] === domNode?.textContent)  // if its empty and we clean non-changed then remove itself
            return (cleanTree && !cleanOnlyWhenDifferent && !domNode?.textContent?.length) && this.remove(cleanTree);
        if (cleanTree && !domNode?.textContent?.length && (this.parent?.length > 1))
            return this.remove(cleanTree); // if its empty then don't update, remove and bail early
        this.snapInit();
        this.il[this.child] = domNode?.textContent;
        return true;
    }

    setText = text => {
        this.snapInit();
        this.il[this.child] = text;
    }
    clearText = () => {
        this.snapInit();
        this.il[this.child] = "";
    }
    appendText = text => {
        this.snapInit();
        this.il[this.child] += text;
    }
    insertText = (text, offset) => {
        this.snapInit();
        this.il[this.child] = this.il[this.child].slice(0, offset) + text + this.il[this.child].slice(offset);
    }

    // splits the current node in half and returns the second half, modifying the current node in-place
    split = (pos = 0, props = {}) => {
        if (!(this.child in this.il)) {
            DebugConsole.error("Can't split a node if there are no children!", this.type, this.uuid);
            return this.clone("", props);  // leave this one as-is and return a blank copy
        }
        const child = this.il[this.child] || "";
        if (typeof child !== "string" || pos >= child.length) return this.clone("", props);
        if (pos < 0) return this.clone(child, props);
        this.snapInit();
        this.il[this.child] = child.slice(0, pos);
        return this.clone(child.slice(pos), props);
    }

    focus = (rightAlign = true) => this.placeCaret(this, rightAlign);

    storeFilter = json => {
        if ((this.child in json) && (json[this.child] === undefined || !json[this.child]?.length)) json[this.child] = '';
        return json;
    }

    filterNewChildren = children => children.map(c => c.toString());

    clearChildren = (restoreDefaults = false) => {
        this.snapInit();
        restoreDefaults && this.restoreDefaults(false); // make sure we reset formatting and styles etc.
        return this.il[this.child] = '';
    }

    get rep() {
        return `<${this?.constructor?.name}:${this?.component?.name}:${this?.type} ${this?.uuid} ${this.il[this.child]}>`;
    }

    rerender = (callback = () => {}) => this.parent?.rerender && this.parent?.rerender(callback);
}