import {v4 as uuid4} from "uuid";
import {drawBox} from "../../helpers/Formatting";
import {DebugConsole, getRealBoundingClientRect} from "../../helpers/Utility";
import {MAX_ITERATIONS, PROP_TYPES} from "./index";
import { ILNode } from "./Nodes";
import scrollIntoView from 'scroll-into-view-if-needed';


export default class DocSelection {
    static showSelection = false;
    static selectTemplate = {uuid: null, node: null, offset: 0};

    constructor(containerRef, registryRetrieve = () => null, anchorCallback = () => null, focusCallback = () => null, caretCallback = () => null) {
        this.container = containerRef;
        this.collapsed = false;
        this.reversed = false;
        this.__anchor = {...this.constructor.selectTemplate};
        this.__focus = {...this.constructor.selectTemplate};
        this.registry = registryRetrieve;
        this.anchorCallback = anchorCallback;
        this.focusCallback = focusCallback;
        this.caretCallback = caretCallback;
        this.timestamp = 0;
        this.anchorX = 0;
        this.anchorY = 0;
        this.focusX = 0;
        this.focusY = 0;
        this.caretX = 0;
        this.caretY = 0;
        this.box = new Map();
        this.paused = false;
        this.caret = null;
    }

    _internalAnchorCallback = () => {};
    _internalFocusCallback = () => {
        // const range = this.currentRange();
        // if (!range) return;
        //
        // const rect = getRealBoundingClientRect(range);
        // if (!rect) return;
        //
        // console.log('drawing', rect.y, rect.x, rect.width, rect.height);
        // const bx = document.createElement('div');
        // bx.id = "xfc-focus";
        // bx.style.position = 'absolute';
        // bx.style.top = rect.y.toFixed(3) + 'px';
        // bx.style.left = rect.x.toFixed(3) + 'px';
        // bx.style.width = '1px';
        // bx.style.height = rect.height.toFixed(3) + 'px';
        // bx.style.zIndex = '9000';
        // document.body.appendChild(bx);
        //
        // if (this.caret) document.body.removeChild(this.caret);
        // this.caret = bx;
        //
        //
        // console.log('drawn!', bx);
    };

    get _anchor() {
        return this.__anchor;
    }
    set _anchor(def) {
        this.__anchor = def;
        this._internalAnchorCallback(def);
    }
    get _focus() {
        return this.__focus;
    }
    set _focus(def) {
        this.__focus = def;
        this._internalFocusCallback(def);
    }

    get anchor() {
        return this._anchor;
    }
    set anchor(def) {
        this._anchor = def;
        this.anchorCallback && this.anchorCallback(def);
    }
    get focus() {
        return this._focus;
    }
    set focus(def) {
        this._focus = def;
        this.focusCallback && this.focusCallback(def);
    }

    // Iterates the currently selected text, splitting runs around the selection if need be, ready for modification
    *iterateSelection(skipReadOnly = true, includeFromNode = true, includeToNode = true, topDown = undefined, withStatus = false) {
        if (topDown === undefined) topDown = !this.reversed;
        const swap = this.reversed ? topDown : !topDown;
        const from = swap ? this.focus.node : this.anchor.node;
        const to = swap ? this.anchor.node : this.focus.node;
        const fromOffset = swap ? this.focus.offset : this.anchor.offset;
        const toOffset = swap ? this.anchor.offset : this.focus.offset;

        if (from === to) {
            const reversed = fromOffset > toOffset;
            const pos = reversed ? toOffset : fromOffset;
            const end = reversed ? fromOffset : toOffset;

            const nodes = to.splitRun(pos, end);
            const node = (nodes.length > 2 || pos > 0) ? nodes[1] : nodes[0];

            yield withStatus ? [node, true, true] : node;
            return;
        }

        let current = from, ric = 0;

        while (current && (current.uuid !== to.uuid) && (ric++ < MAX_ITERATIONS)) {
            const node = current;
            current = topDown ? current.nextRun : current.prevRun;

            // yield after we find the next node in case any refs get changed during the yield
            const isFrom = node === from;
            if ((!skipReadOnly || node.editable) && (includeFromNode || !isFrom) && node.isRun)
            {
                if (isFrom) {
                    if (topDown ? (fromOffset >= node.length) : (fromOffset <= 0))
                        continue; // skip if we selected the very end
                    if (fromOffset > 0) {
                        const [oldNode, newNode] = node.splitRun(fromOffset);
                        yield withStatus ? [topDown ? newNode : oldNode, isFrom, false] : (topDown ? newNode : oldNode);
                        continue;
                    }
                }
                yield withStatus ? [node, isFrom, false] : node;
            }
        }

        // finally yield our end run if it matches the specified requirements :)
        if (includeToNode && to && (!skipReadOnly || to.editable) && to.isRun)
        {
            if (topDown ? (toOffset <= 0) : (toOffset >= to.length))
                return; // skip if we selected the very end
            if (toOffset > 0) {
                const [oldNode, newNode] = to.splitRun(toOffset);
                yield withStatus ? [topDown ? oldNode : newNode, false, true] : (topDown ? oldNode : newNode);
                return;
            }
            yield withStatus ? [to, false, true] : to;
        }
    }

    *iterateRuns(skipReadOnly = true, includeFromNode = true, includeToNode = true, topDown = undefined) {
        if (topDown === undefined) topDown = !this.reversed;
        const swap = this.reversed ? topDown : !topDown;
        const from = swap ? this.focus.node : this.anchor.node;
        const to = swap ? this.anchor.node : this.focus.node;
        let current = from, ric = 0;

        while (current && (current.uuid !== to.uuid) && (ric++ < MAX_ITERATIONS)) {
            const node = current;
            current = topDown ? current.nextRun : current.prevRun;

            if ((!skipReadOnly || node.editable) && (includeFromNode || node !== from) && node.isRun)
                yield node; // yield after we find the next node in case any refs get changed during the yield
        }

        if (includeToNode && to && (!skipReadOnly || to.editable) && to.isRun)
            yield to; // finally yield our end run if it matches the specified requirements :)
    }

    *iterateRows(skipReadOnly = true, includeFromNode = true, includeToNode = true, topDown = undefined) {
        if (topDown === undefined) topDown = !this.reversed;
        const swap = this.reversed ? topDown : !topDown;
        const from = swap ? this.focus.node?.parent : this.anchor.node?.parent;
        const to = swap ? this.anchor.node?.parent : this.focus.node?.parent;
        let current = from, ric = 0;

        while (current && (current.uuid !== to.uuid) && (ric++ < MAX_ITERATIONS)) {
            const node = current;
            current = topDown ? current.nextRow : current.prevRow;

            if ((!skipReadOnly || node.editable) && (includeFromNode || node !== from) && node.isRow)
                yield node; // yield after we find the next node in case any refs get changed during the yield
        }

        if (includeToNode && to && (!skipReadOnly || to.editable) && to.isRow)
            yield to; // finally yield our end run if it matches the specified requirements :)
    }

    pause = () => this.paused = true;
    resume = () => this.paused = false;
    updateSelectionNode = (select, target) => ({uuid: target.uuid, node: target, offset: select.offset + target.length});
    mergeSelectionCheck = (target, source) => {
        if (this._anchor.uuid === source.uuid) this.anchor = this.updateSelectionNode(this._anchor, target);
        if (this._focus.uuid === source.uuid) this.focus = this.updateSelectionNode(this._focus, target);
    }
    onSelectStart = e => {
        // DebugConsole.info('DocSelection:onSelectStart', e);
    }
    onSelectionChange = e => {
        if (this.paused || !this?.container?.current?.contains || e.timeStamp <= this.timestamp) return;
        this.timestamp = e.timeStamp;
        const select = this.selectionObject();
        if (!select?.rangeCount) return;
        const range = select.getRangeAt(0);
        if (!range || !select.anchorNode || !select.focusNode || !range.commonAncestorContainer) return;
        if (!this.container.current.contains(range.commonAncestorContainer)) return; // check its in the right place

        let domNode = select.focusNode;
        while (domNode && domNode.parentElement) { // horrible loop to check where we are, got to be a better way than this surely?
            if (domNode.nodeType !== Node.ELEMENT_NODE) domNode = domNode.parentElement;
            if (domNode.classList.contains('xfi-actions')) return true;
            if (domNode.classList.contains('pad')) break;
            domNode = domNode.parentElement;
            if (domNode === document.body) break;
        }

        DebugConsole.info('DocSelection:onSelectionChange', select);

        let an = select.anchorNode, ao = select.anchorOffset,
            fn = select.focusNode, fo = select.focusOffset,
            aid = an?.dataset?.uuid || an?.parentElement?.dataset?.uuid, fid;

        if (an && (an?.nodeName === 'BR' || an?.nodeName === 'DIV'))  // skip erroneous selects
        {
            DebugConsole.info('Restoring selection...', an);
            return this.restoreSelection(false);
        }

        // browse around until we get the correct node i.e. one with a valid UUID
        while (!aid && an && an.nodeType === Node.ELEMENT_NODE && an.childElementCount > 0) {
            an = an.children.item(an.childElementCount > ao ? 0 : ao);
            ao = 0;
            if (an) aid = an?.dataset?.uuid || an?.parentElement?.dataset?.uuid;
        }

        // same again for the focus, if collapsed then no need as the nodes and offsets are identical
        if (select.isCollapsed) {
            fid = aid;
            fo = ao;
        } else {
            fid = fn?.dataset?.uuid || fn?.parentElement?.dataset?.uuid;

            while (!fid && fn && fn.nodeType === Node.ELEMENT_NODE && fn.childElementCount >= 0) {
                fn = fn.children.item(fn.childElementCount > fo ? 0 : fo);
                fo = 0;
                if (fn) fid = fn?.dataset?.uuid || fn?.parentElement?.dataset?.uuid;
            }
        }

        if (!aid) DebugConsole.warn("DocSelection.onSelectionChange: Selection anchor UUID not found", aid, an, ao);
        if (!fid) DebugConsole.warn("DocSelection.onSelectionChange: Selection focus UUID not found", aid, an, ao);
        if (!aid || !fid) return;

        let anchor = this.registry(aid);
        let focus = select.isCollapsed ? anchor : this.registry(fid);

        if (!anchor) DebugConsole.warn("DocSelection.onSelectionChange: Selection anchor UUID not found in registry", aid, anchor);
        if (!focus) DebugConsole.warn("DocSelection.onSelectionChange: Selection focus UUID not found in registry", fid, focus);
        if (!anchor || !focus) return;

        if (aid === this.anchor.uuid && ao === this.anchor.offset && fn === this.focus.uuid && fo === this.focus.offset) {
            if (an !== select.anchorNode || ao !== select.anchorOffset || fn !== select.focusNode || fo !== select.focusOffset) {
                DebugConsole.info('S1: Adjusting selection...', an, ao, fn, fo);
                this.setBrowserSelectionRange(an, ao, fn, fo);
            }
            return; // nothing to do if the selection is the same, save some cycles
        }

        while(anchor && anchor.type !== PROP_TYPES.TEXT) {
            switch (anchor?.type) {
                case PROP_TYPES.DOCUMENT:
                case PROP_TYPES.SECTION:
                case PROP_TYPES.PARAGRAPH:
                    const children = anchor.getChildList();
                    if (!children?.length) return DebugConsole.warn("Selection anchor has no child nodes", anchor);
                    anchor = ao ? ((ao >= children.length) ? children.pop() : children[ao]) : children[0];
                    ao = 0;
                    break;
                default:
                    return DebugConsole.warn("Selection anchor type unknown", anchor);
            }
        }

        if (!anchor) DebugConsole.warn("Selection anchor has no selectable text nodes", anchor);

        if (select.isCollapsed) {
            focus = anchor;
            fo = ao;
        } else {
            while(focus && focus.type !== PROP_TYPES.TEXT) {
                switch (focus.type) {
                    case PROP_TYPES.DOCUMENT:
                    case PROP_TYPES.SECTION:
                    case PROP_TYPES.PARAGRAPH:
                        const children = focus.getChildList();
                        if (!children?.length) return DebugConsole.warn("Selection focus has no child nodes", focus);
                        focus = fo ? ((fo > children.length) ? children.pop() : children[fo]) : children[0];
                        fo = 0;
                        break;
                    default:
                        return DebugConsole.warn("Selection focus type unknown", focus);
                }
            }
        }

        if (!focus) DebugConsole.warn("Selection focus has no selectable text nodes", focus);
        if (!anchor || !focus) return;

        const reversed = (select.anchorNode === select.focusNode && select.anchorOffset > select.focusOffset) ||
            !!(select.focusNode.compareDocumentPosition(select.anchorNode) & Node.DOCUMENT_POSITION_FOLLOWING);

        // shrink our selection if we're outside a run
        if (!range.collapsed && anchor !== focus) {
            if (reversed) {
                if (focus.next && fo >= focus.length) {
                    focus = focus.next;
                    fo = 0;
                }
                if (anchor.prev && ao <= 0) {
                    anchor = anchor.prev;
                    ao = anchor.length;
                }
            } else {
                if (anchor.next && ao >= anchor.length) {
                    anchor = anchor.next;
                    ao = 0;
                }
                if (focus.prev && fo <= 0) {
                    focus = focus.prev;
                    fo = focus.length;
                }
            }
        }

        if (aid !== anchor.uuid) {
            aid = anchor.uuid;
            [an, ao] = this.findTextDOMNode(anchor.getDOMNode(), ao);
        }
        if (fid !== focus.uuid) {
            fid = focus.uuid;
            [fn, fo] = this.findTextDOMNode(focus.getDOMNode(), fo);
        }

        // selection changed, we need to update our internal selection as well as the browser selection
        if (aid !== this.anchor.uuid || ao !== this.anchor.offset || fid !== this.focus.uuid || fo !== this.focus.offset || reversed !== this.reversed) {
            DebugConsole.info('S2: Adjusting selection...', anchor, ao, focus, fo, reversed);
            this.setSelect(anchor, ao, focus, fo, reversed);
        }

        if (an !== range.startContainer || ao !== range.startOffset || fn !== range.endContainer || fo !== range.endOffset) {
            DebugConsole.info('S3: Adjusting selection...', an, ao, fn, fo);
            return this.setBrowserSelectionRange(an, ao, fn, fo);
        }

        if (select.isCollapsed && anchor?.parent?.getReactNode) {
            const rn = anchor.parent.getReactNode();
            if (rn && !rn?.editing && rn.startEdit) {
                DebugConsole.info('Restoring CE selection...', an, ao, fn, fo);
                rn.startEdit(() => this.restoreSelection());
                return;
            }
        }

        const rects = range.getClientRects();

        if (rects.length) {
            const head = rects.item(0);
            const tail = rects.item(rects.length - 1);

            if (reversed) {
                this.anchorX = tail.x;
                this.anchorY = tail.y + Math.floor(tail.height / 2); // center of the text line
                this.focusX = head.x;
                this.focusY = head.y + Math.floor(head.height / 2);
                if (!this.caretX) this.caretX = this.focusX;
                if (!this.caretY) this.caretY = this.focusY;
            } else {
                this.anchorX = head.x;
                this.anchorY = head.y + Math.floor(head.height / 2);
                this.focusX = tail.x;
                this.focusY = tail.y + Math.floor(tail.height / 2);
                if (!this.caretX) this.caretX = this.anchorX;
                if (!this.caretY) this.caretY = this.anchorY;
            }

            if (this.constructor.showSelection) {
                this.drawBox(
                    this.anchorY, this.anchorX, 0, 0,
                    'blue', 'solid', '2px'
                );
                this.drawBox(
                    this.focusY, this.focusX, 0, 0,
                    'green', 'solid', '2px'
                );

                const box = getRealBoundingClientRect(range);
                this.drawBox(box.y, box.x, box.width, box.height, 'purple', 'dashed', '1px');
            }
        }
    }

    // Moves the cursor around between the various rows, only rows themselves are editable so we bounce around a bit
    moveCursor = (back, vertical, adjust = false) => { // b+u = up, b+!u = left, !b+u = down, !b+!u = right
        const range = this.selectionRange(), node = this.findClosestRowNode(this.focus.node);
        if (!range || !node) return;

        const el = node.getDOMNode();
        const collapsedRange = range.cloneRange();
        collapsedRange.collapse(back);

        const selRect = getRealBoundingClientRect(collapsedRange), elRect = el.getBoundingClientRect();
        const isValid = !!selRect.x || !!selRect.y || !!selRect.width || !!selRect.height;

        if (isValid && (back ? (elRect.top < selRect.top) : (elRect.bottom > selRect.bottom))) {
            this.clearCaretOffset();
            return false;
        }

        if (!vertical) { // change position (horizontal movement)
            if (back) collapsedRange.setStart(el, 0); // left
            else collapsedRange.setEndAfter(el); // right
            if (collapsedRange.toString().length > 0){  // allow default if not at the start/end of a row
                this.clearCaretOffset();
                return false;
            }
        }

        const subTable = this.focus?.node?.isSubTable;
        if (subTable && (back ? !this.focus?.node?.parent?.prev : !this.focus?.node?.parent?.next)) {
            // TODO: table selection is very limited, finish off the selection routines for it to allow bulk edits
            // slightly different logic for a table to allow up/down/left/right box movements
            const cell = this.focus?.node?.closestTableCell;
            const newCell = cell ? (vertical ? (back ? cell.siblingAbove : cell.siblingBelow) : (back ? cell.prev : cell.next)) : null;
            if (newCell) {
                const newRun = back ? newCell.lastRun : newCell.firstRun;
                const offset = back ? newCell.length : 0;

                if (vertical) {
                    if (!newRun.length) { // empty run, focus but don't update x,y values
                        this.placeCaret(newRun, false);
                        return true;
                    }

                    const [node] = this.findTextDOMNode(newRun, offset, back);

                    const newRange = document.createRange();
                    newRange.selectNodeContents(node);

                    const rect = getRealBoundingClientRect(newRange);
                    const offsetY = back ? (rect.bottom - 1) : (rect.top + 1);
                    const offsetX = adjust ? this.focusX : this.caretX;

                    if (rect.right <= offsetX) {
                        this.placeCaret(newRun, true);
                        return true;
                    } else if (rect.left >= offsetX) {
                        this.placeCaret(newRun, false);
                        return true;
                    }

                    const point = this.selectionFromPoint(offsetX || rect.x, offsetY);
                    if (!point || !point?.node?.getDOMNode || !this.container.current.contains(point.node.getDOMNode()))
                        return DebugConsole.error('Move Cursor Failure! (table)', point);

                    this.selectCaret(point.node, point.offset);
                }
                else this.selectCaret(newRun, offset);
                return true;
            }
        }
        // TODO: Below code wants migrating over to use this method above, we want to use the new interface :)

        const newNode = subTable ? (back ? node?.closestTable?.prevEditableRow : node?.closestTable?.nextEditableRow) : (back ? node.prevEditableRow : node.nextEditableRow);
        if (!newNode || !newNode.ref || !newNode.ref.current) return true; // cancel and stay where we are

        const current = newNode.ref.current;

        // TODO: This is buggy when a table cell has multiple paragraphs in, but only when going up... weird? :shrug:
        (adjust ? node.ref.current.stopEdit : (current.isEditing() ? (f => f()) : current.startEdit))(() => {
            const newRef = current.isEditing() ? current?.ref?.current?.el?.current : current?.ref?.current;
            if (adjust) this.restoreSelection();
            if (vertical) { // change line (vertical movement)
                const start = newNode?.firstRun?.dom;
                const stop = newNode?.lastRun?.dom;

                const newRange = document.createRange();
                newRange.selectNodeContents(stop);
                newRange.setStart(start, 0);

                if (!newRange.toString().length) { // empty run, focus but don't update x,y values
                    if (adjust) this.setFocus(newRange.endContainer, newRange.endOffset);
                    else this.select(newRange.startContainer, newRange.startOffset, newRange.endContainer, newRange.endOffset);
                    return;
                }

                scrollIntoView(back ? stop : start, { behavior: 'smooth', scrollMode: 'if-needed' });
                const rect = getRealBoundingClientRect(newRange);
                const offset = back ? (rect.bottom - 1) : (rect.top + 1);
                const offsetX = adjust ? this.focusX : this.caretX;
                const offsetY = adjust ? this.focusX : this.caretY;
                const point = this.selectionFromPoint((offsetX > 0) ? offsetX : rect.x, offset);
                if (!point || !point?.node?.getDOMNode || !this.container.current.contains(point.node.getDOMNode()))
                    return DebugConsole.error('Move Cursor Failure! (row)', point);

                this.drawBox(offsetY, offsetX, 0, 0, 'lime', 'solid', '3px', '50%');
                this.drawBox(offset, offsetX, 0, 0, 'orange', 'solid', '3px', '50%');
                this.drawBox(selRect.y, selRect.x, selRect.width, selRect.height, 'silver', 'dashed', '2px');
                this.drawBox(rect.y, rect.x, rect.width, rect.height, 'purple', 'solid', '2px');

                // clear first so they get updated in the selection change event handler
                // by not clearing when the run is empty we retain the old x offset :)
                this.clearCaretOffset();

                if (adjust) this.setFocus(point.node, point.offset);
                else this.select(point.node, point.offset, point.node, point.offset, false, true);

            } else { // change position (horizontal movement)

                let child = newRef;
                const newRange = document.createRange();

                if (back) {
                    if (child.lastElementChild) child = child.lastElementChild; // traverse down the elements
                    if (child.lastChild) child = child.lastChild; // get the base text node
                    newRange.selectNodeContents(child);
                    newRange.collapse(false);
                } else {
                    if (child.firstElementChild) child = child.firstElementChild; // traverse down the elements
                    if (child.firstChild) child = child.firstChild; // get the base text node
                    newRange.setStart(child, 0);
                    newRange.setEnd(child, 0);
                }

                if (adjust) this.setFocus(newRange.endContainer, newRange.endOffset);
                else this.select(newRange.startContainer, newRange.startOffset, newRange.endContainer, newRange.endOffset, false, true);
            }
        });

        return true; // return true to prevent default behaviour, false to allow
    }
    setCursor = (node, offset, resumeIfPaused = false) => this.setSelect(node, offset, node, offset, false, true) && this.setBrowserSelectionRange(node, offset, node, offset, resumeIfPaused);
    setAnchor = (node, offset, resumeIfPaused = false) => this.setSelect(node, offset, this.focus.node, this.focus.offset) && this.setBrowserSelectionRange(node, offset, this.focus.node, this.focus.offset, resumeIfPaused);
    setFocus = (node, offset, resumeIfPaused = false) => this.setSelect(this.anchor.node, this.anchor.offset, node, offset) && this.setBrowserSelectionRange(this.anchor.node, this.anchor.offset, node, offset, resumeIfPaused);
    setAnchorPoint = (x, y, quiet = false) => {
        const point = this.selectionFromPoint(x, y);
        if (!point || !point.uuid)
            return quiet || DebugConsole.warn("DocSelection.setAnchorPoint: Invalid x,y coordinates", x, y, point);
        this.clearCaretOffset();
        return this.setAnchor(point.node, point.offset, this.focus.node, this.focus.offset);
    }
    setFocusPoint = (x, y, quiet = false) => {
        const point = this.selectionFromPoint(x, y);
        if (!point || !point.uuid)
            return quiet || DebugConsole.warn("DocSelection.setFocusPoint: Invalid x,y coordinates", x, y, point);
        this.clearCaretOffset();
        return this.setFocus(this.anchor.node, this.anchor.offset, point.node, point.offset);
    }
    selectPoint = (x, y, quiet = false) => {
        const point = this.selectionFromPoint(x, y);
        if (!point || !point.uuid)
            return quiet || DebugConsole.warn("DocSelection.selectPoint: Invalid x,y coordinates", x, y, point);
        this.clearCaretOffset();
        return this.select(point.node, point.offset, point.node, point.offset, false, true);
    }
    selectCaret = (node, offset, reversed = null, collapsed = null) => {
        if(!this.setSelect(node, offset, node, offset, reversed, collapsed)) return false;
        this.clearCaretOffset();
        return this.setBrowserSelectionRange(node, offset, node, offset);
    }
    selectAnchorRun = (reversed = false) => this.selectRun(this.anchor, reversed);
    selectFocusRun = (reversed = false) => this.selectRun(this.focus, reversed);
    selectRun = (node, reversed = null) => {
        if(!this.setSelect(node, 0, node, node.length, reversed, false)) return false;
        return this.setBrowserSelectionRange(node, 0, node, node.length);
    }
    select = (anchor, anchorOffset, focus, focusOffset, reversed = null, collapsed = null) => {
        if (!anchor) {
            anchor = this.anchor.node;
            anchorOffset = this.anchor.offset;
        }
        if (!focus) {
            focus = this.focus.node;
            focusOffset = this.focus.offset;
        }
        if(!this.setSelect(anchor, anchorOffset, focus, focusOffset, reversed, collapsed)) return false;
        this.clearCaretOffset();
        return this.setBrowserSelectionRange(anchor, anchorOffset, focus, focusOffset);
    }
    setSelectPoint = (x, y, quiet = false) => {
        const point = this.selectionFromPoint(x, y);
        if (!point || !point.uuid)
            return quiet || DebugConsole.warn("DocSelection.setSelectPoint: Invalid x,y coordinates", x, y, point);
        this.clearCaretOffset();
        return this.setSelect(point.node, point.offset, point.node, point.offset, false, true);
    }
    setSelectPoints = (anchorX, anchorY, focusX, focusY, quiet = false) => {
        const aPoint = this.selectionFromPoint(anchorX, anchorY);
        if (!aPoint || !aPoint.uuid) return quiet || DebugConsole.warn(
            "DocSelection.setSelectPoints: Invalid anchor x,y coordinates", anchorX, anchorY, aPoint
        );
        const fPoint = this.selectionFromPoint(focusX, focusY);
        if (!fPoint || !fPoint.uuid) return quiet || DebugConsole.warn(
            "DocSelection.setSelectPoints: Invalid anchor x,y coordinates", focusX, focusY, fPoint
        );
        this.clearCaretOffset();
        return this.setSelect(aPoint.node, aPoint.offset, fPoint.node, fPoint.offset);
    }
    setSelect = (anchor, anchorOffset, focus, focusOffset, reversed = null, collapsed = null) => {
        let changed = false;

        this.collapsed = (typeof collapsed === "boolean") ? collapsed : (anchor === focus && anchorOffset === focusOffset);

        if (anchor) {
            if (!(anchor instanceof ILNode)) anchor = this.registry(this.getClosestILNode(anchor).uuid);
            if (this._setAnchor(anchor, anchorOffset)) changed = true;
        } else {
            anchor = this.anchor.node;
            anchorOffset = this.anchor.offset;
        }
        if (focus) {
            if (!(focus instanceof ILNode)) focus = this.registry(this.getClosestILNode(focus).uuid);
            if (this._setFocus(focus, focusOffset)) changed = true;
        } else {
            focus = this.focus.node;
            focusOffset = this.focus.offset;
        }

        if (!changed) return false; // nothing to do, nothing to see, nowhere to go

        if (!anchor || !focus || !(anchor instanceof ILNode) || !(focus instanceof ILNode)) return DebugConsole.error(
            "DocSelection.setSelect: Invalid anchor or focus nodes", anchor, focus
        );

        const anchorDOM = anchor.getDOMNode(), focusDOM = focus.getDOMNode();

        this.reversed = anchorDOM && focusDOM && (
            (typeof reversed === "boolean") ? reversed : ((anchor === focus && anchorOffset > focusOffset) ||
                !!(focusDOM.compareDocumentPosition(anchorDOM) & Node.DOCUMENT_POSITION_FOLLOWING))
        );

        if (this.collapsed && this.caretCallback) this.caretCallback(this.anchor);
        return true;
    }
    clearCaretOffset = () => this.caretX = this.caretY = 0;
    _setAnchor = (node, offset) => (this.anchor.node === node && this.anchor.offset === offset) ? false : this.anchor = {uuid: node?.uuid, node: node, offset: offset};
    _setFocus = (node, offset) => (this.focus.node === node && this.focus.offset === offset) ? false : this.focus = {uuid: node?.uuid, node: node, offset: offset};
    getAnchorOffset = () => !!this?.anchor?.offset ? this.anchor.offset : 0;
    getFocusOffset = () => !!this?.focus?.offset ? this.focus.offset : 0;
    getAnchorNode = () => this?.anchor?.uuid ? (this?.anchor?.node || this.registry(this.anchor.uuid)) : undefined;
    getFocusNode = () => this?.focus?.uuid ? (this?.focus?.node || this.registry(this.focus.uuid)) : undefined;
    getAnchorDOMNode = () => {
        const node = this.getAnchorNode();
        return (node && node?.getDOMNode) ? node.getDOMNode() : node;
    }
    getFocusDOMNode = () => {
        const node = this.getFocusNode();
        return (node && node?.getDOMNode) ? node.getDOMNode() : node;
    }
    incrementAnchorOffset = () => this.anchor.offset++;
    decrementAnchorOffset = () => this.anchor.offset--;
    adjustAnchorOffset = amount => this.anchor.offset += amount;
    incrementFocusOffset = () => this.focus.offset++;
    decrementFocusOffset = () => this.focus.offset--;
    adjustFocusOffset = amount => this.focus.offset += amount;
    incrementCursorOffset = (restore, resumeIfPaused = false) => {
        this.incrementAnchorOffset();
        this.incrementFocusOffset();
        if (restore) this.restoreSelection(resumeIfPaused);
    }
    decrementCursorOffset = (restore, resumeIfPaused = false) => {
        this.decrementAnchorOffset();
        this.decrementFocusOffset();
        if (restore) this.restoreSelection(resumeIfPaused);
    }
    adjustCursorOffset = (amount, restore, resumeIfPaused = false) => {
        this.adjustAnchorOffset(amount);
        this.adjustFocusOffset(amount);
        if (restore) this.restoreSelection(resumeIfPaused);
    }
    // custom selectionFromPoint implementation to handle the more dynamic word-like selection system
    selectionFromPoint = (x, y) => {
        this.clearBoxes();  // make sure our drawings don't mess up the calculations
        const { node: n, offset: o } = this.caretPositionFromPoint(x, y);
        let node = n, offset = o, uuid = null, ilNode = null, type = null;

        if (!node) {
            node = document.elementFromPoint(x, y);
            offset = 0;
        }

        // we need a little custom DOM traversal to select outside the document area
        if (node && node.parentElement && (node.nodeType !== 1)) node = node.parentElement;
        if (!node || !node.classList) return {...this.constructor.selectTemplate};

        // handle the various out of bounds selects i.e. if the point is outside a page/row/run boundary etc.
        if (node.classList.contains('preview') || node.classList.contains('section')) {
            // here we find the central click location
            const r = node.getBoundingClientRect();
            const rMid = r.x + Math.floor(r.width / 2);
            node = document.elementFromPoint(rMid, y);
            offset = 0;

            // then we traverse up the DOM to find the parent container
            while (
                node && node.classList && !node.classList.contains('preview') && !node.classList.contains('section') &&
                !node.classList.contains('margin') && !node.classList.contains('page') && !node.classList.contains('pad')
                ) node = node.parentElement;
        }

        if (node.classList.contains('page') || node.classList.contains('margin')) node = node.querySelector('.pad');

        // if we're at the pad container, we limit our x, y values to the pad boundary
        if (node.classList.contains('pad')) {
            const pad = node.getBoundingClientRect();

            if (x <= pad.left) x = pad.left + 1;
            else if (x >= pad.right) x = pad.right - 1;
            if (y <= pad.top) y = pad.top + 1;
            else if (y >= pad.bottom) y = pad.bottom - 1;

            // we try the browser built-in method again first on our new bound xy, failing that we keep traversing
            const { node: _node, offset: _offset } = this.caretPositionFromPoint(x, y);
            if (_node) {
                node = _node;
                offset = _offset;
            } else {
                node = document.elementFromPoint(x, y);
                offset = 0;
            }
        }
        if (!node) return {...this.constructor.selectTemplate};

        if (node.nodeType === 1) { // element, not text
            if (node.classList.contains('xfp')) node = node.querySelector('.xfp-row, .xfp-edit');
            if (!node) return {...this.constructor.selectTemplate};

            // ok we're at a row, limit our coordinates to the run boundaries
            if (node.classList.contains('xfp-row') || node.classList.contains('xfp-edit')) {
                let done = false, top = Number.MIN_SAFE_INTEGER, lines = [];
                const rect = node.getBoundingClientRect();

                if (x <= rect.left) x = rect.left + 1;
                else if (x >= rect.right) x = rect.right - 1;
                if (y <= rect.top) y = rect.top + 1;
                else if (y >= rect.bottom) y = rect.bottom - 1;

                for (let i = 0; i < node.children.length; i++) {
                    const run = node.children[i];
                    const rect = run.getBoundingClientRect();
                    const rects = run.getClientRects();
                    const firstRun = i <= 0, lastRun = i >= (node.children.length - 1);

                    if (firstRun && y < rect.top && x < rect.left) {  // pre-row click
                        if (rects.length > 0) {
                            const _rect = rects[0];
                            y = _rect.top + Math.floor(_rect.height / 2);
                        } else {
                            y = rect.top + 1;
                        }

                        node = run.firstChild || run;
                        offset = 0;
                        ilNode = run;
                        uuid = run.dataset.uuid;
                        type = 'run';
                        done = true;

                        break;
                    }

                    if (lastRun && y > rect.bottom && x > rect.right) {  // post-row click
                        if (rects.length > 0) {
                            const _rect = rects[rects.length - 1];
                            y = _rect.bottom - Math.floor(_rect.height / 2);
                            x = _rect.right;
                        } else {
                            y = rect.bottom - 1;
                            x = rect.right - 1;
                        }

                        if (run.lastChild) {
                            node = run.lastChild;
                            offset = run.lastChild.length;
                        } else {
                            node = run;
                            offset = 0;
                        }

                        ilNode = run;
                        uuid = run.dataset.uuid;
                        type = 'run';
                        done = true;

                        break;
                    }

                    for (let j = 0; j < rects.length; j++) {
                        const rect = rects[j];

                        if (top < rect.top) {
                            top = rect.top;
                            lines.push({top: top, bot: rect.bottom, items: []});
                        }

                        lines[lines.length - 1].items.push({run: run, rect: rect});
                    }
                }

                const lc = lines.length - 1;  // store a 0-index adjusted length, less maths down the road ;)
                for (let i = 0; i <= lc; i++) {
                    const line = lines[i];
                    if (i >= lc || (line.top <= y && line.bot >= y)) {  // last line or correct line

                        const ic = line.items.length - 1;
                        for (let j = 0; j <= ic; j++) {
                            const item = line.items[j];

                            const isLast = j >= ic, isValid = item.rect.left <= x && item.rect.right >= x;
                            if (isLast || isValid) {
                                y = item.rect.bottom - Math.floor(item.rect.height / 2);
                                if (isLast && !isValid) x = item.rect.right;

                                const { node: _n, offset: _o } = this.caretPositionFromPoint(x, y);

                                if (!_n || _n === item.run.parentElement) {
                                    // empty runs usually end up with null or the parent row
                                    node = item.run;
                                    offset = 0;
                                } else {
                                    node = _n;
                                    offset = _o;
                                }

                                ilNode = item.run;
                                uuid = item.run.dataset.uuid;
                                type = 'run';
                                done = true;

                                this.drawBox(
                                    item.rect.y, item.rect.x, item.rect.width, item.rect.height,
                                    'purple', 'solid', '2px'
                                );
                            }

                            this.drawBox(item.rect.y, item.rect.x, item.rect.width, item.rect.height, 'green');
                            if (done) break;
                        }
                    }
                    if (done) break;
                }
            }
        }

        this.drawBox(y, x, 0, 0, 'red', 'solid', '3px', '50%');

        if (!uuid || !ilNode || !type) {
            const { uuid: _uuid } = this.getClosestILNode(node);
            return {uuid: _uuid, node: this.registry(_uuid), offset: offset};
        }
        return {uuid: uuid, node: this.registry(uuid), offset: offset};
    }
    drawBox(
        top, left, width, height, color = 'red', style='dashed', border='1px',
        radius=null, timeout= 450, zIndex = '10000', callback = () => true
    ) {
        if (this.constructor.showSelection) {
            const uuid = uuid4();
            const bx = drawBox(
                top, left, width, height, color, style, border, radius, timeout, zIndex, _bx => {
                    if (!callback(_bx) || !this.box.has(uuid)) return false;
                    this.box.delete(uuid);
                    return true;
                }
            );
            this.box.set(uuid, bx);
        }
    }
    clearBoxes = () => {
        this.box.forEach(({ box , time }) => {
            clearTimeout(time);
            try {
                document.body.removeChild(box);
            } catch (_) {}
        });
        this.box.clear();
    }
    caretPositionFromPoint = (x, y, returnRange = false) => {
        if (document.caretPositionFromPoint) {
            const range = document.caretPositionFromPoint(x, y);
            if (range) return returnRange ? range : {node: range.offsetNode, offset: range.offset};
        } else if (document.caretRangeFromPoint) {
            const range = document.caretRangeFromPoint(x, y);
            if (range) return returnRange ? range : {node: range.startContainer, offset: range.startOffset};
        }
        // we could support IE, but eww, no thanks, let them fail
        return returnRange ? null : {node: null, offset: null};
    }
    selectionObject = () => window.getSelection ? window.getSelection() : null;
    selectionRange = () => {
        const selection = this.selectionObject();
        return selection?.rangeCount ? selection.getRangeAt(0) : null;
    }
    currentRange = () => {
        const range = document.createRange();
        let start, stop, startOffset, stopOffset;
        if (this.reversed) {
            [start, startOffset] = this.findTextDOMNode(this.focus.node, this.focus.offset);
            [stop, stopOffset] = this.findTextDOMNode(this.anchor.node, this.anchor.offset);
        } else {
            [start, startOffset] = this.findTextDOMNode(this.anchor.node, this.anchor.offset);
            [stop, stopOffset] = this.findTextDOMNode(this.focus.node, this.focus.offset);
        }
        if (!start || !stop) return null;
        range.setStart(start, startOffset);
        range.setEnd(stop, stopOffset);
        return range;
    }
    // traverses down the DOM to find the correct text node, ignoring blank text nodes
    findTextDOMNode = (node, nodeOffset = 0, tail = false) => {
        if (node instanceof ILNode) node = node.getDOMNode();
        if (tail) {
            while (node && node.nodeType !== 3 && (node.lastElementChild || node.lastChild)) {
                if (node.lastElementChild) node = node.lastElementChild;
                else {
                    node = node.lastChild;
                    while (!node.length && node.previousSibling) node = node.previousSibling;
                }
            }
        } else {
            while (node && node.nodeType !== 3 && (node.firstElementChild || node.firstChild)) {
                if (node.firstElementChild) node = node.firstElementChild;
                else {
                    node = node.firstChild;
                    while (!node.length && node.nextSibling) node = node.nextSibling;
                }
            }
            if (node && node.nodeType === 1 && !node.firstChild) nodeOffset = 0;
        }
        return [node, nodeOffset];
    }
    getClosestILNode = node => {
        if (node instanceof ILNode) node = node.getDOMNode();
        while(node && node.parentElement && (node.nodeType !== undefined) && (!node.dataset || !node.dataset.uuid)) {
            switch (node.nodeType) {
                case Node.ELEMENT_NODE:
                    node = node.parentElement;
                    continue;
                case Node.TEXT_NODE:
                    node = node.parentElement;
                    continue;
                default:
                    return null;
            }
        }
        return {uuid: node?.dataset?.uuid, node: node};
    }
    findClosestRow = node => {
        if (node instanceof ILNode) node = node.getDOMNode();
        while (node && node.parentElement && (node.nodeType !== undefined)) {
            if (node.classList && (node.classList.contains('xfp-row') || node.classList.contains('xfp-edit')))
                return node;
            switch (node?.nodeType) {
                case Node.ELEMENT_NODE:
                    node = node.parentElement;
                    continue;
                case Node.TEXT_NODE:
                    node = node.parentElement;
                    continue;
                default:
                    return null;
            }
        }
        return node;
    }
    findClosestRowNode = (node, tail = false) => {
        if (!(node instanceof ILNode)) {
            if (!(node instanceof Node)) return null;
            if (!node?.dataset?.uuid && node.parentElement) node = node.parentElement;
            if (!node?.dataset?.uuid) return null;
            node = this.registry(node.dataset.uuid);
        }
        while (node && node.type !== PROP_TYPES.ROW) {
            switch (node?.type) {
                case PROP_TYPES.RUN:
                    return node.parent;
                case PROP_TYPES.DOCUMENT:
                case PROP_TYPES.SECTION:
                case PROP_TYPES.LIST:
                    node = tail ? node.last : node.first;
                    continue;
                default:
                    return null;
            }
        }
        return node;
    }
    findAnchorRow = node => this.findClosestRow(this.getAnchorNode());
    findFocusRow = node => this.findClosestRow(this.getFocusNode());
    restoreSelection = (resumeIfPaused = true) => this.setBrowserSelectionRange(this.anchor.node, this.anchor.offset, this.focus.node, this.focus.offset, resumeIfPaused);
    placeCaret = (node, afterNode = true) => {
        const offset = afterNode ? node.length : 0;
        this.pause();
        this.setSelect(node, offset, node, offset, false, true);
        this.restoreSelection();
    }
    prevCaret() { // same as above but uses the previous available run, if editable, regardless of type
        const src = this.reversed ? (this.focus?.node ? this.focus.node : this.anchor.node) : (this.anchor?.node ? this.anchor.node : this.focus.node);
        if (!src) return; // no selection i.e. nowhere to go
        let node = src.prevRun;
        while(node) {
            if (node.editable) return this.placeCaret(node, true);
            node = node.prevRun;
        }
    }
    nextCaret() { // same as above but uses the next available run, if editable, regardless of type
        const src = this.reversed ? (this.anchor?.node ? this.anchor.node : this.focus.node) : (this.focus?.node ? this.focus.node : this.anchor.node);
        if (!src) return; // no selection i.e. nowhere to go
        let node = src.nextRun;
        while(node) {
            if (node.editable) return this.placeCaret(node, false);
            node = node.nextRun;
        }
    }
    setBrowserSelectionRange = (start, startOffset, stop, stopOffset, resumeIfPaused = false) => {
        try {
            [start, startOffset] = this.findTextDOMNode(start, startOffset);
            [stop, stopOffset] = this.findTextDOMNode(stop, stopOffset);
            if (!start || !stop) return;
            if (startOffset > start.length || stopOffset > stop.length) return false;
            scrollIntoView(start.parentElement, { behavior: 'smooth', scrollMode: 'if-needed' });

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.setBaseAndExtent(start, startOffset, stop, stopOffset);

            if (resumeIfPaused) this.resume();
            return true;
        } catch (e) {
            DebugConsole.error('DocSelection.setBrowserSelectionRange', e);
            // DOMException: Index or size is negative or greater than the allowed amount
            // TODO: This sometimes triggers even though the selection is "valid", maybe its a race condition
            //       if the node re-renders and removes start/stop from the DOM during this method invocation?
        }
        return false;
    }
}
