class LocaleHelper {

    static Languages = [
        {"id": 'ab', "language": 'Abkhaz'},
        {"id": 'aa', "language": 'Afar'},
        {"id": 'af', "language": 'Afrikaans'},
        {"id": 'ak', "language": 'Akan'},
        {"id": 'sq', "language": 'Albanian'},
        {"id": 'am', "language": 'Amharic'},
        {"id": 'ar', "language": 'Arabic'},
        {"id": 'an', "language": 'Aragonese'},
        {"id": 'hy', "language": 'Armenian'},
        {"id": 'as', "language": 'Assamese'},
        {"id": 'av', "language": 'Avaric'},
        {"id": 'ae', "language": 'Avestan'},
        {"id": 'ay', "language": 'Aymara'},
        {"id": 'az', "language": 'Azerbaijani'},
        {"id": 'bm', "language": 'Bambara'},
        {"id": 'ba', "language": 'Bashkir'},
        {"id": 'eu', "language": 'Basque'},
        {"id": 'be', "language": 'Belarusian'},
        {"id": 'bn', "language": 'Bengali'},
        {"id": 'bh', "language": 'Bihari'},
        {"id": 'bi', "language": 'Bislama'},
        {"id": 'bs', "language": 'Bosnian'},
        {"id": 'br', "language": 'Breton'},
        {"id": 'bg', "language": 'Bulgarian'},
        {"id": 'my', "language": 'Burmese'},
        {"id": 'ca', "language": 'Catalan; Valencian'},
        {"id": 'ch', "language": 'Chamorro'},
        {"id": 'ce', "language": 'Chechen'},
        {"id": 'ny', "language": 'Chichewa; Chewa; Nyanja'},
        {"id": 'zh', "language": 'Chinese'},
        {"id": 'cv', "language": 'Chuvash'},
        {"id": 'kw', "language": 'Cornish'},
        {"id": 'co', "language": 'Corsican'},
        {"id": 'cr', "language": 'Cree'},
        {"id": 'hr', "language": 'Croatian'},
        {"id": 'cs', "language": 'Czech'},
        {"id": 'da', "language": 'Danish'},
        {"id": 'dv', "language": 'Divehi; Maldivian;'},
        {"id": 'nl', "language": 'Dutch'},
        {"id": 'dz', "language": 'Dzongkha'},
        {"id": 'en', "language": 'English'},
        {"id": 'eo', "language": 'Esperanto'},
        {"id": 'et', "language": 'Estonian'},
        {"id": 'ee', "language": 'Ewe'},
        {"id": 'fo', "language": 'Faroese'},
        {"id": 'fj', "language": 'Fijian'},
        {"id": 'fi', "language": 'Finnish'},
        {"id": 'fr', "language": 'French'},
        {"id": 'ff', "language": 'Fula'},
        {"id": 'gl', "language": 'Galician'},
        {"id": 'ka', "language": 'Georgian'},
        {"id": 'de', "language": 'German'},
        {"id": 'el', "language": 'Greek, Modern'},
        {"id": 'gn', "language": 'Guaraní'},
        {"id": 'gu', "language": 'Gujarati'},
        {"id": 'ht', "language": 'Haitian'},
        {"id": 'ha', "language": 'Hausa'},
        {"id": 'he', "language": 'Hebrew (modern}'},
        {"id": 'hz', "language": 'Herero'},
        {"id": 'hi', "language": 'Hindi'},
        {"id": 'ho', "language": 'Hiri Motu'},
        {"id": 'hu', "language": 'Hungarian'},
        {"id": 'ia', "language": 'Interlingua'},
        {"id": 'id', "language": 'Indonesian'},
        {"id": 'ie', "language": 'Interlingue'},
        {"id": 'ga', "language": 'Irish'},
        {"id": 'ig', "language": 'Igbo'},
        {"id": 'ik', "language": 'Inupiaq'},
        {"id": 'io', "language": 'Ido'},
        {"id": 'is', "language": 'Icelandic'},
        {"id": 'it', "language": 'Italian'},
        {"id": 'iu', "language": 'Inuktitut'},
        {"id": 'ja', "language": 'Japanese'},
        {"id": 'jv', "language": 'Javanese'},
        {"id": 'kl', "language": 'Kalaallisut'},
        {"id": 'kn', "language": 'Kannada'},
        {"id": 'kr', "language": 'Kanuri'},
        {"id": 'ks', "language": 'Kashmiri'},
        {"id": 'kk', "language": 'Kazakh'},
        {"id": 'km', "language": 'Khmer'},
        {"id": 'ki', "language": 'Kikuyu, Gikuyu'},
        {"id": 'rw', "language": 'Kinyarwanda'},
        {"id": 'ky', "language": 'Kirghiz, Kyrgyz'},
        {"id": 'kv', "language": 'Komi'},
        {"id": 'kg', "language": 'Kongo'},
        {"id": 'ko', "language": 'Korean'},
        {"id": 'ku', "language": 'Kurdish'},
        {"id": 'kj', "language": 'Kwanyama, Kuanyama'},
        {"id": 'la', "language": 'Latin'},
        {"id": 'lb', "language": 'Luxembourgish'},
        {"id": 'lg', "language": 'Luganda'},
        {"id": 'li', "language": 'Limburgish'},
        {"id": 'ln', "language": 'Lingala'},
        {"id": 'lo', "language": 'Lao'},
        {"id": 'lt', "language": 'Lithuanian'},
        {"id": 'lu', "language": 'Luba-Katanga'},
        {"id": 'lv', "language": 'Latvian'},
        {"id": 'gv', "language": 'Manx'},
        {"id": 'mk', "language": 'Macedonian'},
        {"id": 'mg', "language": 'Malagasy'},
        {"id": 'ms', "language": 'Malay'},
        {"id": 'ml', "language": 'Malayalam'},
        {"id": 'mt', "language": 'Maltese'},
        {"id": 'mi', "language": 'Māori'},
        {"id": 'mr', "language": 'Marathi (Marāṭhī}'},
        {"id": 'mh', "language": 'Marshallese'},
        {"id": 'mn', "language": 'Mongolian'},
        {"id": 'na', "language": 'Nauru'},
        {"id": 'nv', "language": 'Navajo, Navaho'},
        {"id": 'nb', "language": 'Norwegian Bokmål'},
        {"id": 'nd', "language": 'North Ndebele'},
        {"id": 'ne', "language": 'Nepali'},
        {"id": 'ng', "language": 'Ndonga'},
        {"id": 'nn', "language": 'Norwegian Nynorsk'},
        {"id": 'no', "language": 'Norwegian'},
        {"id": 'ii', "language": 'Nuosu'},
        {"id": 'nr', "language": 'South Ndebele'},
        {"id": 'oc', "language": 'Occitan'},
        {"id": 'oj', "language": 'Ojibwe, Ojibwa'},
        {"id": 'cu', "language": 'Old Church Slavonic'},
        {"id": 'om', "language": 'Oromo'},
        {"id": 'or', "language": 'Oriya'},
        {"id": 'os', "language": 'Ossetian, Ossetic'},
        {"id": 'pa', "language": 'Panjabi, Punjabi'},
        {"id": 'pi', "language": 'Pāli'},
        {"id": 'fa', "language": 'Persian'},
        {"id": 'pl', "language": 'Polish'},
        {"id": 'ps', "language": 'Pashto, Pushto'},
        {"id": 'pt', "language": 'Portuguese'},
        {"id": 'qu', "language": 'Quechua'},
        {"id": 'rm', "language": 'Romansh'},
        {"id": 'rn', "language": 'Kirundi'},
        {"id": 'ro', "language": 'Romanian, Moldavan'},
        {"id": 'ru', "language": 'Russian'},
        {"id": 'sa', "language": 'Sanskrit (Saṁskṛta}'},
        {"id": 'sc', "language": 'Sardinian'},
        {"id": 'sd', "language": 'Sindhi'},
        {"id": 'se', "language": 'Northern Sami'},
        {"id": 'sm', "language": 'Samoan'},
        {"id": 'sg', "language": 'Sango'},
        {"id": 'sr', "language": 'Serbian'},
        {"id": 'gd', "language": 'Scottish Gaelic'},
        {"id": 'sn', "language": 'Shona'},
        {"id": 'si', "language": 'Sinhala, Sinhalese'},
        {"id": 'sk', "language": 'Slovak'},
        {"id": 'sl', "language": 'Slovene'},
        {"id": 'so', "language": 'Somali'},
        {"id": 'st', "language": 'Southern Sotho'},
        {"id": 'es', "language": 'Spanish; Castilian'},
        {"id": 'su', "language": 'Sundanese'},
        {"id": 'sw', "language": 'Swahili'},
        {"id": 'ss', "language": 'Swati'},
        {"id": 'sv', "language": 'Swedish'},
        {"id": 'ta', "language": 'Tamil'},
        {"id": 'te', "language": 'Telugu'},
        {"id": 'tg', "language": 'Tajik'},
        {"id": 'th', "language": 'Thai'},
        {"id": 'ti', "language": 'Tigrinya'},
        {"id": 'bo', "language": 'Tibetan'},
        {"id": 'tk', "language": 'Turkmen'},
        {"id": 'tl', "language": 'Tagalog'},
        {"id": 'tn', "language": 'Tswana'},
        {"id": 'to', "language": 'Tonga'},
        {"id": 'tr', "language": 'Turkish'},
        {"id": 'ts', "language": 'Tsonga'},
        {"id": 'tt', "language": 'Tatar'},
        {"id": 'tw', "language": 'Twi'},
        {"id": 'ty', "language": 'Tahitian'},
        {"id": 'ug', "language": 'Uighur, Uyghur'},
        {"id": 'uk', "language": 'Ukrainian'},
        {"id": 'ur', "language": 'Urdu'},
        {"id": 'uz', "language": 'Uzbek'},
        {"id": 've', "language": 'Venda'},
        {"id": 'vi', "language": 'Vietnamese'},
        {"id": 'vo', "language": 'Volapük'},
        {"id": 'wa', "language": 'Walloon'},
        {"id": 'cy', "language": 'Welsh'},
        {"id": 'wo', "language": 'Wolof'},
        {"id": 'fy', "language": 'Western Frisian'},
        {"id": 'xh', "language": 'Xhosa'},
        {"id": 'yi', "language": 'Yiddish'},
        {"id": 'yo', "language": 'Yoruba'},
        {"id": 'za', "language": 'Zhuang, Chuang'},
        {"id": 'zu', "language": 'Zulu'}
    ];

    static Currencies = [
        {"id": "AFN", "currency": "Afghani"},
        {"id": "DZD", "currency": "Algerian Dinar"},
        {"id": "ARS", "currency": "Argentine Peso"},
        {"id": "AMD", "currency": "Armenian Dram"},
        {"id": "AWG", "currency": "Aruban Guilder"},
        {"id": "AUD", "currency": "Australian Dollar"},
        {"id": "AZN", "currency": "Azerbaijanian Manat"},
        {"id": "BSD", "currency": "Bahamian Dollar"},
        {"id": "BHD", "currency": "Bahraini Dinar"},
        {"id": "THB", "currency": "Baht"},
        {"id": "PAB", "currency": "Balboa"},
        {"id": "BBD", "currency": "Barbados Dollar"},
        {"id": "BYR", "currency": "Belarussian Ruble"},
        {"id": "BZD", "currency": "Belize Dollar"},
        {"id": "BMD", "currency": "Bermudian Dollar"},
        {"id": "VEF", "currency": "Bolivar Fuerte"},
        {"id": "BOB", "currency": "Boliviano"},
        {"id": "BRL", "currency": "Brazilian Real"},
        {"id": "BND", "currency": "Brunei Dollar"},
        {"id": "BGN", "currency": "Bulgarian Lev"},
        {"id": "BIF", "currency": "Burundi Franc"},
        {"id": "CAD", "currency": "Canadian Dollar"},
        {"id": "CVE", "currency": "Cape Verde Escudo"},
        {"id": "KYD", "currency": "Cayman Islands Dollar"},
        {"id": "GHS", "currency": "Cedi"},
        {"id": "CLP", "currency": "Chilean Peso"},
        {"id": "COP", "currency": "Colombian Peso"},
        {"id": "KMF", "currency": "Comoro Franc"},
        {"id": "CDF", "currency": "Congolese Franc"},
        {"id": "BAM", "currency": "Convertible Mark"},
        {"id": "NIO", "currency": "Cordoba Oro"},
        {"id": "CRC", "currency": "Costa Rican Colon"},
        {"id": "HRK", "currency": "Croatian Kuna"},
        {"id": "CUP", "currency": "Cuban Peso"},
        {"id": "CZK", "currency": "Czech Koruna"},
        {"id": "GMD", "currency": "Dalasi"},
        {"id": "DKK", "currency": "Danish Krone"},
        {"id": "MKD", "currency": "Denar"},
        {"id": "DJF", "currency": "Djibouti Franc"},
        {"id": "STD", "currency": "Dobra"},
        {"id": "DOP", "currency": "Dominican Peso"},
        {"id": "VND", "currency": "Dong"},
        {"id": "XCD", "currency": "East Caribbean Dollar"},
        {"id": "EGP", "currency": "Egyptian Pound"},
        {"id": "SVC", "currency": "El Salvador Colon"},
        {"id": "ETB", "currency": "Ethiopian Birr"},
        {"id": "EUR", "currency": "Euro"},
        {"id": "FKP", "currency": "Falkland Islands Pound"},
        {"id": "FJD", "currency": "Fiji Dollar"},
        {"id": "HUF", "currency": "Forint"},
        {"id": "GIP", "currency": "Gibraltar Pound"},
        {"id": "XAU", "currency": "Gold"},
        {"id": "HTG", "currency": "Gourde"},
        {"id": "PYG", "currency": "Guarani"},
        {"id": "GNF", "currency": "Guinea Franc"},
        {"id": "GYD", "currency": "Guyana Dollar"},
        {"id": "HKD", "currency": "Hong Kong Dollar"},
        {"id": "UAH", "currency": "Hryvnia"},
        {"id": "ISK", "currency": "Iceland Krona"},
        {"id": "INR", "currency": "Indian Rupee"},
        {"id": "IRR", "currency": "Iranian Rial"},
        {"id": "IQD", "currency": "Iraqi Dinar"},
        {"id": "JMD", "currency": "Jamaican Dollar"},
        {"id": "JOD", "currency": "Jordanian Dinar"},
        {"id": "KES", "currency": "Kenyan Shilling"},
        {"id": "PGK", "currency": "Kina"},
        {"id": "LAK", "currency": "Kip"},
        {"id": "KWD", "currency": "Kuwaiti Dinar"},
        {"id": "MWK", "currency": "Kwacha"},
        {"id": "AOA", "currency": "Kwanza"},
        {"id": "MMK", "currency": "Kyat"},
        {"id": "GEL", "currency": "Lari"},
        {"id": "LVL", "currency": "Latvian Lats"},
        {"id": "LBP", "currency": "Lebanese Pound"},
        {"id": "ALL", "currency": "Lek"},
        {"id": "HNL", "currency": "Lempira"},
        {"id": "SLL", "currency": "Leone"},
        {"id": "RON", "currency": "Leu"},
        {"id": "LRD", "currency": "Liberian Dollar"},
        {"id": "LYD", "currency": "Libyan Dinar"},
        {"id": "SZL", "currency": "Lilangeni"},
        {"id": "LTL", "currency": "Lithuanian Litas"},
        {"id": "LSL", "currency": "Loti"},
        {"id": "MGA", "currency": "Malagasy Ariary"},
        {"id": "MYR", "currency": "Malaysian Ringgit"},
        {"id": "MUR", "currency": "Mauritius Rupee"},
        {"id": "MZN", "currency": "Metical"},
        {"id": "MXN", "currency": "Mexican Peso"},
        {"id": "MDL", "currency": "Moldovan Leu"},
        {"id": "MAD", "currency": "Moroccan Dirham"},
        {"id": "BOV", "currency": "Mvdol"},
        {"id": "NGN", "currency": "Naira"},
        {"id": "ERN", "currency": "Nakfa"},
        {"id": "NAD", "currency": "Namibia Dollar"},
        {"id": "NPR", "currency": "Nepalese Rupee"},
        {"id": "ANG", "currency": "Netherlands Antillean Guilder"},
        {"id": "ILS", "currency": "New Israeli Sheqel"},
        {"id": "TMT", "currency": "New Manat"},
        {"id": "TWD", "currency": "New Taiwan Dollar"},
        {"id": "NZD", "currency": "New Zealand Dollar"},
        {"id": "BTN", "currency": "Ngultrum"},
        {"id": "KPW", "currency": "North Korean Won"},
        {"id": "NOK", "currency": "Norwegian Krone"},
        {"id": "PEN", "currency": "Nuevo Sol"},
        {"id": "MRO", "currency": "Ouguiya"},
        {"id": "PKR", "currency": "Pakistan Rupee"},
        {"id": "XPD", "currency": "Palladium"},
        {"id": "MOP", "currency": "Pataca"},
        {"id": "TOP", "currency": "Pa’anga"},
        {"id": "CUC", "currency": "Peso Convertible"},
        {"id": "UYU", "currency": "Peso Uruguayo"},
        {"id": "PHP", "currency": "Philippine Peso"},
        {"id": "XPT", "currency": "Platinum"},
        {"id": "GBP", "currency": "Pound Sterling"},
        {"id": "BWP", "currency": "Pula"},
        {"id": "QAR", "currency": "Qatari Rial"},
        {"id": "GTQ", "currency": "Quetzal"},
        {"id": "ZAR", "currency": "Rand"},
        {"id": "OMR", "currency": "Rial Omani"},
        {"id": "KHR", "currency": "Riel"},
        {"id": "MVR", "currency": "Rufiyaa"},
        {"id": "IDR", "currency": "Rupiah"},
        {"id": "RUB", "currency": "Russian Ruble"},
        {"id": "RWF", "currency": "Rwanda Franc"},
        {"id": "SHP", "currency": "Saint Helena Pound"},
        {"id": "SAR", "currency": "Saudi Riyal"},
        {"id": "RSD", "currency": "Serbian Dinar"},
        {"id": "SCR", "currency": "Seychelles Rupee"},
        {"id": "XAG", "currency": "Silver"},
        {"id": "SGD", "currency": "Singapore Dollar"},
        {"id": "SBD", "currency": "Solomon Islands Dollar"},
        {"id": "KGS", "currency": "Som"},
        {"id": "SOS", "currency": "Somali Shilling"},
        {"id": "TJS", "currency": "Somoni"},
        {"id": "ZAR", "currency": "South African Rand"},
        {"id": "LKR", "currency": "Sri Lanka Rupee"},
        {"id": "XSU", "currency": "Sucre"},
        {"id": "SDG", "currency": "Sudanese Pound"},
        {"id": "SRD", "currency": "Surinam Dollar"},
        {"id": "SEK", "currency": "Swedish Krona"},
        {"id": "CHF", "currency": "Swiss Franc"},
        {"id": "SYP", "currency": "Syrian Pound"},
        {"id": "BDT", "currency": "Taka"},
        {"id": "WST", "currency": "Tala"},
        {"id": "TZS", "currency": "Tanzanian Shilling"},
        {"id": "KZT", "currency": "Tenge"},
        {"id": "TTD", "currency": "Trinidad and Tobago Dollar"},
        {"id": "MNT", "currency": "Tugrik"},
        {"id": "TND", "currency": "Tunisian Dinar"},
        {"id": "TRY", "currency": "Turkish Lira"},
        {"id": "AED", "currency": "UAE Dirham"},
        {"id": "USD", "currency": "US Dollar"},
        {"id": "UGX", "currency": "Uganda Shilling"},
        {"id": "COU", "currency": "Unidad de Valor Real"},
        {"id": "CLF", "currency": "Unidades de fomento"},
        {"id": "UYI", "currency": "Uruguay Peso en Unidades Indexadas (URUIURUI}"},
        {"id": "UZS", "currency": "Uzbekistan Sum"},
        {"id": "VUV", "currency": "Vatu"},
        {"id": "KRW", "currency": "Won"},
        {"id": "YER", "currency": "Yemeni Rial"},
        {"id": "JPY", "currency": "Yen"},
        {"id": "CNY", "currency": "Yuan Renminbi"},
        {"id": "ZMK", "currency": "Zambian Kwacha"},
        {"id": "ZWL", "currency": "Zimbabwe Dollar"},
        {"id": "PLN", "currency": "Zloty"}
    ];

    static Timezones = [
        {"id": "Africa/Abidjan", "timezone": "Africa/Abidjan"}, {
            "id": "Africa/Accra",
            "timezone": "Africa/Accra"
        }, {"id": "Africa/Addis_Ababa", "timezone": "Africa/Addis_Ababa"}, {
            "id": "Africa/Algiers",
            "timezone": "Africa/Algiers"
        }, {"id": "Africa/Asmara", "timezone": "Africa/Asmara"}, {
            "id": "Africa/Bamako",
            "timezone": "Africa/Bamako"
        }, {"id": "Africa/Bangui", "timezone": "Africa/Bangui"}, {
            "id": "Africa/Banjul",
            "timezone": "Africa/Banjul"
        }, {"id": "Africa/Bissau", "timezone": "Africa/Bissau"}, {
            "id": "Africa/Blantyre",
            "timezone": "Africa/Blantyre"
        }, {"id": "Africa/Brazzaville", "timezone": "Africa/Brazzaville"}, {
            "id": "Africa/Bujumbura",
            "timezone": "Africa/Bujumbura"
        }, {"id": "Africa/Cairo", "timezone": "Africa/Cairo"}, {
            "id": "Africa/Casablanca",
            "timezone": "Africa/Casablanca"
        }, {"id": "Africa/Ceuta", "timezone": "Africa/Ceuta"}, {
            "id": "Africa/Conakry",
            "timezone": "Africa/Conakry"
        }, {"id": "Africa/Dakar", "timezone": "Africa/Dakar"}, {
            "id": "Africa/Dar_es_Salaam",
            "timezone": "Africa/Dar_es_Salaam"
        }, {"id": "Africa/Djibouti", "timezone": "Africa/Djibouti"}, {
            "id": "Africa/Douala",
            "timezone": "Africa/Douala"
        }, {"id": "Africa/El_Aaiun", "timezone": "Africa/El_Aaiun"}, {
            "id": "Africa/Freetown",
            "timezone": "Africa/Freetown"
        }, {"id": "Africa/Gaborone", "timezone": "Africa/Gaborone"}, {
            "id": "Africa/Harare",
            "timezone": "Africa/Harare"
        }, {"id": "Africa/Johannesburg", "timezone": "Africa/Johannesburg"}, {
            "id": "Africa/Juba",
            "timezone": "Africa/Juba"
        }, {"id": "Africa/Kampala", "timezone": "Africa/Kampala"}, {
            "id": "Africa/Khartoum",
            "timezone": "Africa/Khartoum"
        }, {"id": "Africa/Kigali", "timezone": "Africa/Kigali"}, {
            "id": "Africa/Kinshasa",
            "timezone": "Africa/Kinshasa"
        }, {"id": "Africa/Lagos", "timezone": "Africa/Lagos"}, {
            "id": "Africa/Libreville",
            "timezone": "Africa/Libreville"
        }, {"id": "Africa/Lome", "timezone": "Africa/Lome"}, {
            "id": "Africa/Luanda",
            "timezone": "Africa/Luanda"
        }, {"id": "Africa/Lubumbashi", "timezone": "Africa/Lubumbashi"}, {
            "id": "Africa/Lusaka",
            "timezone": "Africa/Lusaka"
        }, {"id": "Africa/Malabo", "timezone": "Africa/Malabo"}, {
            "id": "Africa/Maputo",
            "timezone": "Africa/Maputo"
        }, {"id": "Africa/Maseru", "timezone": "Africa/Maseru"}, {
            "id": "Africa/Mbabane",
            "timezone": "Africa/Mbabane"
        }, {"id": "Africa/Mogadishu", "timezone": "Africa/Mogadishu"}, {
            "id": "Africa/Monrovia",
            "timezone": "Africa/Monrovia"
        }, {"id": "Africa/Nairobi", "timezone": "Africa/Nairobi"}, {
            "id": "Africa/Ndjamena",
            "timezone": "Africa/Ndjamena"
        }, {"id": "Africa/Niamey", "timezone": "Africa/Niamey"}, {
            "id": "Africa/Nouakchott",
            "timezone": "Africa/Nouakchott"
        }, {"id": "Africa/Ouagadougou", "timezone": "Africa/Ouagadougou"}, {
            "id": "Africa/Porto-Novo",
            "timezone": "Africa/Porto-Novo"
        }, {"id": "Africa/Sao_Tome", "timezone": "Africa/Sao_Tome"}, {
            "id": "Africa/Tripoli",
            "timezone": "Africa/Tripoli"
        }, {"id": "Africa/Tunis", "timezone": "Africa/Tunis"}, {
            "id": "Africa/Windhoek",
            "timezone": "Africa/Windhoek"
        }, {"id": "America/Adak", "timezone": "America/Adak"}, {
            "id": "America/Anchorage",
            "timezone": "America/Anchorage"
        }, {"id": "America/Anguilla", "timezone": "America/Anguilla"}, {
            "id": "America/Antigua",
            "timezone": "America/Antigua"
        }, {"id": "America/Araguaina", "timezone": "America/Araguaina"}, {
            "id": "America/Argentina/Buenos_Aires",
            "timezone": "America/Argentina/Buenos_Aires"
        }, {
            "id": "America/Argentina/Catamarca",
            "timezone": "America/Argentina/Catamarca"
        }, {"id": "America/Argentina/Cordoba", "timezone": "America/Argentina/Cordoba"}, {
            "id": "America/Argentina/Jujuy",
            "timezone": "America/Argentina/Jujuy"
        }, {
            "id": "America/Argentina/La_Rioja",
            "timezone": "America/Argentina/La_Rioja"
        }, {
            "id": "America/Argentina/Mendoza",
            "timezone": "America/Argentina/Mendoza"
        }, {
            "id": "America/Argentina/Rio_Gallegos",
            "timezone": "America/Argentina/Rio_Gallegos"
        }, {"id": "America/Argentina/Salta", "timezone": "America/Argentina/Salta"}, {
            "id": "America/Argentina/San_Juan",
            "timezone": "America/Argentina/San_Juan"
        }, {
            "id": "America/Argentina/San_Luis",
            "timezone": "America/Argentina/San_Luis"
        }, {"id": "America/Argentina/Tucuman", "timezone": "America/Argentina/Tucuman"}, {
            "id": "America/Argentina/Ushuaia",
            "timezone": "America/Argentina/Ushuaia"
        }, {"id": "America/Aruba", "timezone": "America/Aruba"}, {
            "id": "America/Asuncion",
            "timezone": "America/Asuncion"
        }, {"id": "America/Atikokan", "timezone": "America/Atikokan"}, {
            "id": "America/Bahia",
            "timezone": "America/Bahia"
        }, {"id": "America/Bahia_Banderas", "timezone": "America/Bahia_Banderas"}, {
            "id": "America/Barbados",
            "timezone": "America/Barbados"
        }, {"id": "America/Belem", "timezone": "America/Belem"}, {
            "id": "America/Belize",
            "timezone": "America/Belize"
        }, {"id": "America/Blanc-Sablon", "timezone": "America/Blanc-Sablon"}, {
            "id": "America/Boa_Vista",
            "timezone": "America/Boa_Vista"
        }, {"id": "America/Bogota", "timezone": "America/Bogota"}, {
            "id": "America/Boise",
            "timezone": "America/Boise"
        }, {"id": "America/Cambridge_Bay", "timezone": "America/Cambridge_Bay"}, {
            "id": "America/Campo_Grande",
            "timezone": "America/Campo_Grande"
        }, {"id": "America/Cancun", "timezone": "America/Cancun"}, {
            "id": "America/Caracas",
            "timezone": "America/Caracas"
        }, {"id": "America/Cayenne", "timezone": "America/Cayenne"}, {
            "id": "America/Cayman",
            "timezone": "America/Cayman"
        }, {"id": "America/Chicago", "timezone": "America/Chicago"}, {
            "id": "America/Chihuahua",
            "timezone": "America/Chihuahua"
        }, {"id": "America/Costa_Rica", "timezone": "America/Costa_Rica"}, {
            "id": "America/Creston",
            "timezone": "America/Creston"
        }, {"id": "America/Cuiaba", "timezone": "America/Cuiaba"}, {
            "id": "America/Curacao",
            "timezone": "America/Curacao"
        }, {"id": "America/Danmarkshavn", "timezone": "America/Danmarkshavn"}, {
            "id": "America/Dawson",
            "timezone": "America/Dawson"
        }, {"id": "America/Dawson_Creek", "timezone": "America/Dawson_Creek"}, {
            "id": "America/Denver",
            "timezone": "America/Denver"
        }, {"id": "America/Detroit", "timezone": "America/Detroit"}, {
            "id": "America/Dominica",
            "timezone": "America/Dominica"
        }, {"id": "America/Edmonton", "timezone": "America/Edmonton"}, {
            "id": "America/Eirunepe",
            "timezone": "America/Eirunepe"
        }, {"id": "America/El_Salvador", "timezone": "America/El_Salvador"}, {
            "id": "America/Fort_Nelson",
            "timezone": "America/Fort_Nelson"
        }, {"id": "America/Fortaleza", "timezone": "America/Fortaleza"}, {
            "id": "America/Glace_Bay",
            "timezone": "America/Glace_Bay"
        }, {"id": "America/Goose_Bay", "timezone": "America/Goose_Bay"}, {
            "id": "America/Grand_Turk",
            "timezone": "America/Grand_Turk"
        }, {"id": "America/Grenada", "timezone": "America/Grenada"}, {
            "id": "America/Guadeloupe",
            "timezone": "America/Guadeloupe"
        }, {"id": "America/Guatemala", "timezone": "America/Guatemala"}, {
            "id": "America/Guayaquil",
            "timezone": "America/Guayaquil"
        }, {"id": "America/Guyana", "timezone": "America/Guyana"}, {
            "id": "America/Halifax",
            "timezone": "America/Halifax"
        }, {"id": "America/Havana", "timezone": "America/Havana"}, {
            "id": "America/Hermosillo",
            "timezone": "America/Hermosillo"
        }, {
            "id": "America/Indiana/Indianapolis",
            "timezone": "America/Indiana/Indianapolis"
        }, {"id": "America/Indiana/Knox", "timezone": "America/Indiana/Knox"}, {
            "id": "America/Indiana/Marengo",
            "timezone": "America/Indiana/Marengo"
        }, {
            "id": "America/Indiana/Petersburg",
            "timezone": "America/Indiana/Petersburg"
        }, {"id": "America/Indiana/Tell_City", "timezone": "America/Indiana/Tell_City"}, {
            "id": "America/Indiana/Vevay",
            "timezone": "America/Indiana/Vevay"
        }, {"id": "America/Indiana/Vincennes", "timezone": "America/Indiana/Vincennes"}, {
            "id": "America/Indiana/Winamac",
            "timezone": "America/Indiana/Winamac"
        }, {"id": "America/Inuvik", "timezone": "America/Inuvik"}, {
            "id": "America/Iqaluit",
            "timezone": "America/Iqaluit"
        }, {"id": "America/Jamaica", "timezone": "America/Jamaica"}, {
            "id": "America/Juneau",
            "timezone": "America/Juneau"
        }, {
            "id": "America/Kentucky/Louisville",
            "timezone": "America/Kentucky/Louisville"
        }, {"id": "America/Kentucky/Monticello", "timezone": "America/Kentucky/Monticello"}, {
            "id": "America/Kralendijk",
            "timezone": "America/Kralendijk"
        }, {"id": "America/La_Paz", "timezone": "America/La_Paz"}, {
            "id": "America/Lima",
            "timezone": "America/Lima"
        }, {"id": "America/Los_Angeles", "timezone": "America/Los_Angeles"}, {
            "id": "America/Lower_Princes",
            "timezone": "America/Lower_Princes"
        }, {"id": "America/Maceio", "timezone": "America/Maceio"}, {
            "id": "America/Managua",
            "timezone": "America/Managua"
        }, {"id": "America/Manaus", "timezone": "America/Manaus"}, {
            "id": "America/Marigot",
            "timezone": "America/Marigot"
        }, {"id": "America/Martinique", "timezone": "America/Martinique"}, {
            "id": "America/Matamoros",
            "timezone": "America/Matamoros"
        }, {"id": "America/Mazatlan", "timezone": "America/Mazatlan"}, {
            "id": "America/Menominee",
            "timezone": "America/Menominee"
        }, {"id": "America/Merida", "timezone": "America/Merida"}, {
            "id": "America/Metlakatla",
            "timezone": "America/Metlakatla"
        }, {"id": "America/Mexico_City", "timezone": "America/Mexico_City"}, {
            "id": "America/Miquelon",
            "timezone": "America/Miquelon"
        }, {"id": "America/Moncton", "timezone": "America/Moncton"}, {
            "id": "America/Monterrey",
            "timezone": "America/Monterrey"
        }, {"id": "America/Montevideo", "timezone": "America/Montevideo"}, {
            "id": "America/Montserrat",
            "timezone": "America/Montserrat"
        }, {"id": "America/Nassau", "timezone": "America/Nassau"}, {
            "id": "America/New_York",
            "timezone": "America/New_York"
        }, {"id": "America/Nipigon", "timezone": "America/Nipigon"}, {
            "id": "America/Nome",
            "timezone": "America/Nome"
        }, {"id": "America/Noronha", "timezone": "America/Noronha"}, {
            "id": "America/North_Dakota/Beulah",
            "timezone": "America/North_Dakota/Beulah"
        }, {
            "id": "America/North_Dakota/Center",
            "timezone": "America/North_Dakota/Center"
        }, {"id": "America/North_Dakota/New_Salem", "timezone": "America/North_Dakota/New_Salem"}, {
            "id": "America/Nuuk",
            "timezone": "America/Nuuk"
        }, {"id": "America/Ojinaga", "timezone": "America/Ojinaga"}, {
            "id": "America/Panama",
            "timezone": "America/Panama"
        }, {"id": "America/Pangnirtung", "timezone": "America/Pangnirtung"}, {
            "id": "America/Paramaribo",
            "timezone": "America/Paramaribo"
        }, {"id": "America/Phoenix", "timezone": "America/Phoenix"}, {
            "id": "America/Port-au-Prince",
            "timezone": "America/Port-au-Prince"
        }, {"id": "America/Port_of_Spain", "timezone": "America/Port_of_Spain"}, {
            "id": "America/Porto_Velho",
            "timezone": "America/Porto_Velho"
        }, {"id": "America/Puerto_Rico", "timezone": "America/Puerto_Rico"}, {
            "id": "America/Punta_Arenas",
            "timezone": "America/Punta_Arenas"
        }, {"id": "America/Rainy_River", "timezone": "America/Rainy_River"}, {
            "id": "America/Rankin_Inlet",
            "timezone": "America/Rankin_Inlet"
        }, {"id": "America/Recife", "timezone": "America/Recife"}, {
            "id": "America/Regina",
            "timezone": "America/Regina"
        }, {"id": "America/Resolute", "timezone": "America/Resolute"}, {
            "id": "America/Rio_Branco",
            "timezone": "America/Rio_Branco"
        }, {"id": "America/Santarem", "timezone": "America/Santarem"}, {
            "id": "America/Santiago",
            "timezone": "America/Santiago"
        }, {"id": "America/Santo_Domingo", "timezone": "America/Santo_Domingo"}, {
            "id": "America/Sao_Paulo",
            "timezone": "America/Sao_Paulo"
        }, {"id": "America/Scoresbysund", "timezone": "America/Scoresbysund"}, {
            "id": "America/Sitka",
            "timezone": "America/Sitka"
        }, {"id": "America/St_Barthelemy", "timezone": "America/St_Barthelemy"}, {
            "id": "America/St_Johns",
            "timezone": "America/St_Johns"
        }, {"id": "America/St_Kitts", "timezone": "America/St_Kitts"}, {
            "id": "America/St_Lucia",
            "timezone": "America/St_Lucia"
        }, {"id": "America/St_Thomas", "timezone": "America/St_Thomas"}, {
            "id": "America/St_Vincent",
            "timezone": "America/St_Vincent"
        }, {"id": "America/Swift_Current", "timezone": "America/Swift_Current"}, {
            "id": "America/Tegucigalpa",
            "timezone": "America/Tegucigalpa"
        }, {"id": "America/Thule", "timezone": "America/Thule"}, {
            "id": "America/Thunder_Bay",
            "timezone": "America/Thunder_Bay"
        }, {"id": "America/Tijuana", "timezone": "America/Tijuana"}, {
            "id": "America/Toronto",
            "timezone": "America/Toronto"
        }, {"id": "America/Tortola", "timezone": "America/Tortola"}, {
            "id": "America/Vancouver",
            "timezone": "America/Vancouver"
        }, {"id": "America/Whitehorse", "timezone": "America/Whitehorse"}, {
            "id": "America/Winnipeg",
            "timezone": "America/Winnipeg"
        }, {"id": "America/Yakutat", "timezone": "America/Yakutat"}, {
            "id": "America/Yellowknife",
            "timezone": "America/Yellowknife"
        }, {"id": "Antarctica/Casey", "timezone": "Antarctica/Casey"}, {
            "id": "Antarctica/Davis",
            "timezone": "Antarctica/Davis"
        }, {"id": "Antarctica/DumontDUrville", "timezone": "Antarctica/DumontDUrville"}, {
            "id": "Antarctica/Macquarie",
            "timezone": "Antarctica/Macquarie"
        }, {"id": "Antarctica/Mawson", "timezone": "Antarctica/Mawson"}, {
            "id": "Antarctica/McMurdo",
            "timezone": "Antarctica/McMurdo"
        }, {"id": "Antarctica/Palmer", "timezone": "Antarctica/Palmer"}, {
            "id": "Antarctica/Rothera",
            "timezone": "Antarctica/Rothera"
        }, {"id": "Antarctica/Syowa", "timezone": "Antarctica/Syowa"}, {
            "id": "Antarctica/Troll",
            "timezone": "Antarctica/Troll"
        }, {"id": "Antarctica/Vostok", "timezone": "Antarctica/Vostok"}, {
            "id": "Arctic/Longyearbyen",
            "timezone": "Arctic/Longyearbyen"
        }, {"id": "Asia/Aden", "timezone": "Asia/Aden"}, {
            "id": "Asia/Almaty",
            "timezone": "Asia/Almaty"
        }, {"id": "Asia/Amman", "timezone": "Asia/Amman"}, {
            "id": "Asia/Anadyr",
            "timezone": "Asia/Anadyr"
        }, {"id": "Asia/Aqtau", "timezone": "Asia/Aqtau"}, {
            "id": "Asia/Aqtobe",
            "timezone": "Asia/Aqtobe"
        }, {"id": "Asia/Ashgabat", "timezone": "Asia/Ashgabat"}, {
            "id": "Asia/Atyrau",
            "timezone": "Asia/Atyrau"
        }, {"id": "Asia/Baghdad", "timezone": "Asia/Baghdad"}, {
            "id": "Asia/Bahrain",
            "timezone": "Asia/Bahrain"
        }, {"id": "Asia/Baku", "timezone": "Asia/Baku"}, {
            "id": "Asia/Bangkok",
            "timezone": "Asia/Bangkok"
        }, {"id": "Asia/Barnaul", "timezone": "Asia/Barnaul"}, {
            "id": "Asia/Beirut",
            "timezone": "Asia/Beirut"
        }, {"id": "Asia/Bishkek", "timezone": "Asia/Bishkek"}, {
            "id": "Asia/Brunei",
            "timezone": "Asia/Brunei"
        }, {"id": "Asia/Chita", "timezone": "Asia/Chita"}, {
            "id": "Asia/Choibalsan",
            "timezone": "Asia/Choibalsan"
        }, {"id": "Asia/Colombo", "timezone": "Asia/Colombo"}, {
            "id": "Asia/Damascus",
            "timezone": "Asia/Damascus"
        }, {"id": "Asia/Dhaka", "timezone": "Asia/Dhaka"}, {
            "id": "Asia/Dili",
            "timezone": "Asia/Dili"
        }, {"id": "Asia/Dubai", "timezone": "Asia/Dubai"}, {
            "id": "Asia/Dushanbe",
            "timezone": "Asia/Dushanbe"
        }, {"id": "Asia/Famagusta", "timezone": "Asia/Famagusta"}, {
            "id": "Asia/Gaza",
            "timezone": "Asia/Gaza"
        }, {"id": "Asia/Hebron", "timezone": "Asia/Hebron"}, {
            "id": "Asia/Ho_Chi_Minh",
            "timezone": "Asia/Ho_Chi_Minh"
        }, {"id": "Asia/Hong_Kong", "timezone": "Asia/Hong_Kong"}, {
            "id": "Asia/Hovd",
            "timezone": "Asia/Hovd"
        }, {"id": "Asia/Irkutsk", "timezone": "Asia/Irkutsk"}, {
            "id": "Asia/Jakarta",
            "timezone": "Asia/Jakarta"
        }, {"id": "Asia/Jayapura", "timezone": "Asia/Jayapura"}, {
            "id": "Asia/Jerusalem",
            "timezone": "Asia/Jerusalem"
        }, {"id": "Asia/Kabul", "timezone": "Asia/Kabul"}, {
            "id": "Asia/Kamchatka",
            "timezone": "Asia/Kamchatka"
        }, {"id": "Asia/Karachi", "timezone": "Asia/Karachi"}, {
            "id": "Asia/Kathmandu",
            "timezone": "Asia/Kathmandu"
        }, {"id": "Asia/Khandyga", "timezone": "Asia/Khandyga"}, {
            "id": "Asia/Kolkata",
            "timezone": "Asia/Kolkata"
        }, {"id": "Asia/Krasnoyarsk", "timezone": "Asia/Krasnoyarsk"}, {
            "id": "Asia/Kuala_Lumpur",
            "timezone": "Asia/Kuala_Lumpur"
        }, {"id": "Asia/Kuching", "timezone": "Asia/Kuching"}, {
            "id": "Asia/Kuwait",
            "timezone": "Asia/Kuwait"
        }, {"id": "Asia/Macau", "timezone": "Asia/Macau"}, {
            "id": "Asia/Magadan",
            "timezone": "Asia/Magadan"
        }, {"id": "Asia/Makassar", "timezone": "Asia/Makassar"}, {
            "id": "Asia/Manila",
            "timezone": "Asia/Manila"
        }, {"id": "Asia/Muscat", "timezone": "Asia/Muscat"}, {
            "id": "Asia/Nicosia",
            "timezone": "Asia/Nicosia"
        }, {"id": "Asia/Novokuznetsk", "timezone": "Asia/Novokuznetsk"}, {
            "id": "Asia/Novosibirsk",
            "timezone": "Asia/Novosibirsk"
        }, {"id": "Asia/Omsk", "timezone": "Asia/Omsk"}, {
            "id": "Asia/Oral",
            "timezone": "Asia/Oral"
        }, {"id": "Asia/Phnom_Penh", "timezone": "Asia/Phnom_Penh"}, {
            "id": "Asia/Pontianak",
            "timezone": "Asia/Pontianak"
        }, {"id": "Asia/Pyongyang", "timezone": "Asia/Pyongyang"}, {
            "id": "Asia/Qatar",
            "timezone": "Asia/Qatar"
        }, {"id": "Asia/Qostanay", "timezone": "Asia/Qostanay"}, {
            "id": "Asia/Qyzylorda",
            "timezone": "Asia/Qyzylorda"
        }, {"id": "Asia/Riyadh", "timezone": "Asia/Riyadh"}, {
            "id": "Asia/Sakhalin",
            "timezone": "Asia/Sakhalin"
        }, {"id": "Asia/Samarkand", "timezone": "Asia/Samarkand"}, {
            "id": "Asia/Seoul",
            "timezone": "Asia/Seoul"
        }, {"id": "Asia/Shanghai", "timezone": "Asia/Shanghai"}, {
            "id": "Asia/Singapore",
            "timezone": "Asia/Singapore"
        }, {"id": "Asia/Srednekolymsk", "timezone": "Asia/Srednekolymsk"}, {
            "id": "Asia/Taipei",
            "timezone": "Asia/Taipei"
        }, {"id": "Asia/Tashkent", "timezone": "Asia/Tashkent"}, {
            "id": "Asia/Tbilisi",
            "timezone": "Asia/Tbilisi"
        }, {"id": "Asia/Tehran", "timezone": "Asia/Tehran"}, {
            "id": "Asia/Thimphu",
            "timezone": "Asia/Thimphu"
        }, {"id": "Asia/Tokyo", "timezone": "Asia/Tokyo"}, {
            "id": "Asia/Tomsk",
            "timezone": "Asia/Tomsk"
        }, {"id": "Asia/Ulaanbaatar", "timezone": "Asia/Ulaanbaatar"}, {
            "id": "Asia/Urumqi",
            "timezone": "Asia/Urumqi"
        }, {"id": "Asia/Ust-Nera", "timezone": "Asia/Ust-Nera"}, {
            "id": "Asia/Vientiane",
            "timezone": "Asia/Vientiane"
        }, {"id": "Asia/Vladivostok", "timezone": "Asia/Vladivostok"}, {
            "id": "Asia/Yakutsk",
            "timezone": "Asia/Yakutsk"
        }, {"id": "Asia/Yangon", "timezone": "Asia/Yangon"}, {
            "id": "Asia/Yekaterinburg",
            "timezone": "Asia/Yekaterinburg"
        }, {"id": "Asia/Yerevan", "timezone": "Asia/Yerevan"}, {
            "id": "Atlantic/Azores",
            "timezone": "Atlantic/Azores"
        }, {"id": "Atlantic/Bermuda", "timezone": "Atlantic/Bermuda"}, {
            "id": "Atlantic/Canary",
            "timezone": "Atlantic/Canary"
        }, {"id": "Atlantic/Cape_Verde", "timezone": "Atlantic/Cape_Verde"}, {
            "id": "Atlantic/Faroe",
            "timezone": "Atlantic/Faroe"
        }, {"id": "Atlantic/Madeira", "timezone": "Atlantic/Madeira"}, {
            "id": "Atlantic/Reykjavik",
            "timezone": "Atlantic/Reykjavik"
        }, {"id": "Atlantic/South_Georgia", "timezone": "Atlantic/South_Georgia"}, {
            "id": "Atlantic/St_Helena",
            "timezone": "Atlantic/St_Helena"
        }, {"id": "Atlantic/Stanley", "timezone": "Atlantic/Stanley"}, {
            "id": "Australia/Adelaide",
            "timezone": "Australia/Adelaide"
        }, {"id": "Australia/Brisbane", "timezone": "Australia/Brisbane"}, {
            "id": "Australia/Broken_Hill",
            "timezone": "Australia/Broken_Hill"
        }, {"id": "Australia/Darwin", "timezone": "Australia/Darwin"}, {
            "id": "Australia/Eucla",
            "timezone": "Australia/Eucla"
        }, {"id": "Australia/Hobart", "timezone": "Australia/Hobart"}, {
            "id": "Australia/Lindeman",
            "timezone": "Australia/Lindeman"
        }, {"id": "Australia/Lord_Howe", "timezone": "Australia/Lord_Howe"}, {
            "id": "Australia/Melbourne",
            "timezone": "Australia/Melbourne"
        }, {"id": "Australia/Perth", "timezone": "Australia/Perth"}, {
            "id": "Australia/Sydney",
            "timezone": "Australia/Sydney"
        }, {"id": "Canada/Atlantic", "timezone": "Canada/Atlantic"}, {
            "id": "Canada/Central",
            "timezone": "Canada/Central"
        }, {"id": "Canada/Eastern", "timezone": "Canada/Eastern"}, {
            "id": "Canada/Mountain",
            "timezone": "Canada/Mountain"
        }, {"id": "Canada/Newfoundland", "timezone": "Canada/Newfoundland"}, {
            "id": "Canada/Pacific",
            "timezone": "Canada/Pacific"
        }, {"id": "Europe/Amsterdam", "timezone": "Europe/Amsterdam"}, {
            "id": "Europe/Andorra",
            "timezone": "Europe/Andorra"
        }, {"id": "Europe/Astrakhan", "timezone": "Europe/Astrakhan"}, {
            "id": "Europe/Athens",
            "timezone": "Europe/Athens"
        }, {"id": "Europe/Belgrade", "timezone": "Europe/Belgrade"}, {
            "id": "Europe/Berlin",
            "timezone": "Europe/Berlin"
        }, {"id": "Europe/Bratislava", "timezone": "Europe/Bratislava"}, {
            "id": "Europe/Brussels",
            "timezone": "Europe/Brussels"
        }, {"id": "Europe/Bucharest", "timezone": "Europe/Bucharest"}, {
            "id": "Europe/Budapest",
            "timezone": "Europe/Budapest"
        }, {"id": "Europe/Busingen", "timezone": "Europe/Busingen"}, {
            "id": "Europe/Chisinau",
            "timezone": "Europe/Chisinau"
        }, {"id": "Europe/Copenhagen", "timezone": "Europe/Copenhagen"}, {
            "id": "Europe/Dublin",
            "timezone": "Europe/Dublin"
        }, {"id": "Europe/Gibraltar", "timezone": "Europe/Gibraltar"}, {
            "id": "Europe/Guernsey",
            "timezone": "Europe/Guernsey"
        }, {"id": "Europe/Helsinki", "timezone": "Europe/Helsinki"}, {
            "id": "Europe/Isle_of_Man",
            "timezone": "Europe/Isle_of_Man"
        }, {"id": "Europe/Istanbul", "timezone": "Europe/Istanbul"}, {
            "id": "Europe/Jersey",
            "timezone": "Europe/Jersey"
        }, {"id": "Europe/Kaliningrad", "timezone": "Europe/Kaliningrad"}, {
            "id": "Europe/Kiev",
            "timezone": "Europe/Kiev"
        }, {"id": "Europe/Kirov", "timezone": "Europe/Kirov"}, {
            "id": "Europe/Lisbon",
            "timezone": "Europe/Lisbon"
        }, {"id": "Europe/Ljubljana", "timezone": "Europe/Ljubljana"}, {
            "id": "Europe/London",
            "timezone": "Europe/London"
        }, {"id": "Europe/Luxembourg", "timezone": "Europe/Luxembourg"}, {
            "id": "Europe/Madrid",
            "timezone": "Europe/Madrid"
        }, {"id": "Europe/Malta", "timezone": "Europe/Malta"}, {
            "id": "Europe/Mariehamn",
            "timezone": "Europe/Mariehamn"
        }, {"id": "Europe/Minsk", "timezone": "Europe/Minsk"}, {
            "id": "Europe/Monaco",
            "timezone": "Europe/Monaco"
        }, {"id": "Europe/Moscow", "timezone": "Europe/Moscow"}, {
            "id": "Europe/Oslo",
            "timezone": "Europe/Oslo"
        }, {"id": "Europe/Paris", "timezone": "Europe/Paris"}, {
            "id": "Europe/Podgorica",
            "timezone": "Europe/Podgorica"
        }, {"id": "Europe/Prague", "timezone": "Europe/Prague"}, {
            "id": "Europe/Riga",
            "timezone": "Europe/Riga"
        }, {"id": "Europe/Rome", "timezone": "Europe/Rome"}, {
            "id": "Europe/Samara",
            "timezone": "Europe/Samara"
        }, {"id": "Europe/San_Marino", "timezone": "Europe/San_Marino"}, {
            "id": "Europe/Sarajevo",
            "timezone": "Europe/Sarajevo"
        }, {"id": "Europe/Saratov", "timezone": "Europe/Saratov"}, {
            "id": "Europe/Simferopol",
            "timezone": "Europe/Simferopol"
        }, {"id": "Europe/Skopje", "timezone": "Europe/Skopje"}, {
            "id": "Europe/Sofia",
            "timezone": "Europe/Sofia"
        }, {"id": "Europe/Stockholm", "timezone": "Europe/Stockholm"}, {
            "id": "Europe/Tallinn",
            "timezone": "Europe/Tallinn"
        }, {"id": "Europe/Tirane", "timezone": "Europe/Tirane"}, {
            "id": "Europe/Ulyanovsk",
            "timezone": "Europe/Ulyanovsk"
        }, {"id": "Europe/Uzhgorod", "timezone": "Europe/Uzhgorod"}, {
            "id": "Europe/Vaduz",
            "timezone": "Europe/Vaduz"
        }, {"id": "Europe/Vatican", "timezone": "Europe/Vatican"}, {
            "id": "Europe/Vienna",
            "timezone": "Europe/Vienna"
        }, {"id": "Europe/Vilnius", "timezone": "Europe/Vilnius"}, {
            "id": "Europe/Volgograd",
            "timezone": "Europe/Volgograd"
        }, {"id": "Europe/Warsaw", "timezone": "Europe/Warsaw"}, {
            "id": "Europe/Zagreb",
            "timezone": "Europe/Zagreb"
        }, {"id": "Europe/Zaporozhye", "timezone": "Europe/Zaporozhye"}, {
            "id": "Europe/Zurich",
            "timezone": "Europe/Zurich"
        }, {"id": "GMT", "timezone": "GMT"}, {
            "id": "Indian/Antananarivo",
            "timezone": "Indian/Antananarivo"
        }, {"id": "Indian/Chagos", "timezone": "Indian/Chagos"}, {
            "id": "Indian/Christmas",
            "timezone": "Indian/Christmas"
        }, {"id": "Indian/Cocos", "timezone": "Indian/Cocos"}, {
            "id": "Indian/Comoro",
            "timezone": "Indian/Comoro"
        }, {"id": "Indian/Kerguelen", "timezone": "Indian/Kerguelen"}, {
            "id": "Indian/Mahe",
            "timezone": "Indian/Mahe"
        }, {"id": "Indian/Maldives", "timezone": "Indian/Maldives"}, {
            "id": "Indian/Mauritius",
            "timezone": "Indian/Mauritius"
        }, {"id": "Indian/Mayotte", "timezone": "Indian/Mayotte"}, {
            "id": "Indian/Reunion",
            "timezone": "Indian/Reunion"
        }, {"id": "Pacific/Apia", "timezone": "Pacific/Apia"}, {
            "id": "Pacific/Auckland",
            "timezone": "Pacific/Auckland"
        }, {"id": "Pacific/Bougainville", "timezone": "Pacific/Bougainville"}, {
            "id": "Pacific/Chatham",
            "timezone": "Pacific/Chatham"
        }, {"id": "Pacific/Chuuk", "timezone": "Pacific/Chuuk"}, {
            "id": "Pacific/Easter",
            "timezone": "Pacific/Easter"
        }, {"id": "Pacific/Efate", "timezone": "Pacific/Efate"}, {
            "id": "Pacific/Enderbury",
            "timezone": "Pacific/Enderbury"
        }, {"id": "Pacific/Fakaofo", "timezone": "Pacific/Fakaofo"}, {
            "id": "Pacific/Fiji",
            "timezone": "Pacific/Fiji"
        }, {"id": "Pacific/Funafuti", "timezone": "Pacific/Funafuti"}, {
            "id": "Pacific/Galapagos",
            "timezone": "Pacific/Galapagos"
        }, {"id": "Pacific/Gambier", "timezone": "Pacific/Gambier"}, {
            "id": "Pacific/Guadalcanal",
            "timezone": "Pacific/Guadalcanal"
        }, {"id": "Pacific/Guam", "timezone": "Pacific/Guam"}, {
            "id": "Pacific/Honolulu",
            "timezone": "Pacific/Honolulu"
        }, {"id": "Pacific/Kiritimati", "timezone": "Pacific/Kiritimati"}, {
            "id": "Pacific/Kosrae",
            "timezone": "Pacific/Kosrae"
        }, {"id": "Pacific/Kwajalein", "timezone": "Pacific/Kwajalein"}, {
            "id": "Pacific/Majuro",
            "timezone": "Pacific/Majuro"
        }, {"id": "Pacific/Marquesas", "timezone": "Pacific/Marquesas"}, {
            "id": "Pacific/Midway",
            "timezone": "Pacific/Midway"
        }, {"id": "Pacific/Nauru", "timezone": "Pacific/Nauru"}, {
            "id": "Pacific/Niue",
            "timezone": "Pacific/Niue"
        }, {"id": "Pacific/Norfolk", "timezone": "Pacific/Norfolk"}, {
            "id": "Pacific/Noumea",
            "timezone": "Pacific/Noumea"
        }, {"id": "Pacific/Pago_Pago", "timezone": "Pacific/Pago_Pago"}, {
            "id": "Pacific/Palau",
            "timezone": "Pacific/Palau"
        }, {"id": "Pacific/Pitcairn", "timezone": "Pacific/Pitcairn"}, {
            "id": "Pacific/Pohnpei",
            "timezone": "Pacific/Pohnpei"
        }, {"id": "Pacific/Port_Moresby", "timezone": "Pacific/Port_Moresby"}, {
            "id": "Pacific/Rarotonga",
            "timezone": "Pacific/Rarotonga"
        }, {"id": "Pacific/Saipan", "timezone": "Pacific/Saipan"}, {
            "id": "Pacific/Tahiti",
            "timezone": "Pacific/Tahiti"
        }, {"id": "Pacific/Tarawa", "timezone": "Pacific/Tarawa"}, {
            "id": "Pacific/Tongatapu",
            "timezone": "Pacific/Tongatapu"
        }, {"id": "Pacific/Wake", "timezone": "Pacific/Wake"}, {
            "id": "Pacific/Wallis",
            "timezone": "Pacific/Wallis"
        }, {"id": "US/Alaska", "timezone": "US/Alaska"}, {
            "id": "US/Arizona",
            "timezone": "US/Arizona"
        }, {"id": "US/Central", "timezone": "US/Central"}, {
            "id": "US/Eastern",
            "timezone": "US/Eastern"
        }, {"id": "US/Hawaii", "timezone": "US/Hawaii"}, {
            "id": "US/Mountain",
            "timezone": "US/Mountain"
        }, {"id": "US/Pacific", "timezone": "US/Pacific"}, {"id": "UTC", "timezone": "UTC"}
    ]

    static getTimezoneById(id) {
        return this.Timezones.find(t => t.id.toLowerCase() === id.toLowerCase()) || '';
    }

    static getLanguageById(id) {
        return this.Languages.find(l => l.id.toLowerCase() === id.toLowerCase()) || '';
    }

    static getCurrencyById(id) {
        return this.Currencies.find(c => c.id.toLowerCase() === id.toLowerCase()) || '';
    }
}

export default LocaleHelper;