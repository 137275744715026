import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class ContractItems
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/contractItem';

    static createOrUpdate(id, contract){
        const url =`${ContractItems.API_URL}/${id}`
        return axios.post(url, contract).then(response => response.data);
    }

    static list(id){
        const url = `${ContractItems.API_URL}/list${id ? '/'+id : ''}`;
        return axios.get(url).then(response => response.data);
    }
}
