import React from 'react';
import './tests-data-table.scss'
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableHeader, TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from "carbon-components-react";
import {Launch16} from "@carbon/icons-react";

class TestsDataTable extends React.Component {
    static defaultProps = {
        tests: null, test: null,
        testHeaders: [
            {
                key: 'name',
                header: 'Test',
            },
            {
                key: 'type',
                header: 'Type',
            },
            {
                key: 'status',
                header: 'Status'
            },
            {
                key: 'start',
                header: 'Start Date',
            },
            {
                key: 'end',
                header: 'End Date',
            }
        ]
    };

    constructor(props) {
        super(props);
        const {tests} = this.props;
        const {test} = this.props;
        this.state = {
            errors: [],
            test: test,
            tests: tests,
            editing_project: false,
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tests !== this.props.tests || prevProps.test !== this.props.test) {
            this.setState({
                tests: this.props.tests,
                test: this.props.test
            })
        }
    }

    // selectTest(id) {
    //     this.props.handler(null, id)
    // }

    render() {

        const {testHeaders} = this.props;
        const tests = this.state.tests ?
            this.state.tests
                .map(([k, v]) => ({
                        id: k, name: v.name, type: v.type ? v.type.name : '', status: v.status_lbl,
                        project: v.project, start: v.start_date, end: v.end_date,
                    })
                ) : [];

        return (
            <>
            {this.state.loading ? <DataTableSkeleton columnCount={testHeaders.length + 3}/> :
                <DataTable rows={tests} headers={testHeaders} isSortable size="sm">
                    {({
                          rows, headers, getHeaderProps, getTableProps, getRowProps,
                          onInputChange, getToolbarProps, getBatchActionProps
                      }) => (
                        <TableContainer stickyHeader={true}>
                            <TableToolbar {...getToolbarProps()}>
                                <TableToolbarContent>
                                    <TableToolbarSearch
                                        persistent={true} placeholder="Start typing to filter..."
                                        tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                        onChange={onInputChange}
                                    />
                                </TableToolbarContent>
                            </TableToolbar>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader
                                                {...getHeaderProps({header})}
                                                className={'xfh-' + header.key}
                                            >
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                        <TableHeader/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow {...getRowProps({row})}>
                                                {row.cells.map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        className={'xfc-' + cell.info.header}
                                                    >{cell.value}</TableCell>
                                                ))}
                                                <TableCell className="xf-act-wrap">
                                                    <div className="xf-actions">
                                                        <Button className="xff-act" tooltipPosition="left"
                                                                hasIconOnly renderIcon={Launch16}
                                                                size="sm" kind="ghost"
                                                                iconDescription="Open Test"
                                                                onClick={() => {
                                                                    //TODO: open tests
                                                                }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>}
            </>
        )
    }
}

export default TestsDataTable;