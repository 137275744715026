import React from 'react';
import DocHelper from "../Document";


// Create a Context
export class DocumentContext {
    // We need to track the document pages, sections, paragraphs and headings etc. without causing loads of re-renders
    constructor(ppi = 100, page = 0, sid = 0, pid = 0, filterProps = true, flow = [], sectMap = {}, rowMap = {}) {
        this.ppi = ppi;
        this.page = page;
        this.sid = sid;
        this.pid = pid;
        this.filterProps = filterProps;
        this.flow = new Set(flow);
        this.sections = sectMap;
        this.paragraphs = rowMap;
        this.margin = DocHelper.A4MM_M;
        this.width = DocHelper.A4MM_W;
        this.height = DocHelper.A4MM_H;
    }
    registerSection(uuid) {
        if (uuid in this.sections) return this.sections[uuid];
        return this.sections[uuid] = ++this.sid;
    }
    registerParagraph(uuid) {
        if (uuid in this.paragraphs) return this.paragraphs[uuid];
        return this.paragraphs[uuid] = ++this.pid;
    }
}

export function newDocContext(ctx = null) {
    return {
        ctx: ctx ? ctx : new DocumentContext()
    };
}

const DocContext = React.createContext(newDocContext());
// It returns an object with 2 values:
// { Provider, Consumer }

export default DocContext;