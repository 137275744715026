import { ILNode, TableRowNode } from "../../Nodes";
import {PROP_TYPES} from "../../index";


export default class TableNode extends ILNode {
    static defaultChild = TableRowNode;

    addRow = (...cells) => {
        let columns = this?.first?.childCount;
        if (!columns) {
            if (!cells.length) return; // nothing to add
            if (typeof cells[0] === "number") {
                columns = cells[0];
                cells = [];
            } else columns = cells.length;
        }
        return this.appendChildren({
            type: PROP_TYPES.TABLE_ROW, columns: Array.from({length: columns}, (_, i) => (
                {cell: [i < cells.length ? cells[i] : {type: PROP_TYPES.PARAGRAPH}]}
            ))
        });
    }

    delRow = (idx, cleanTree = true) => {
        const row = this.getChild(idx);
        if (!row) return;

        row.remove(cleanTree);

        if (!this.childCount) {
            const parent = this.parent;
            this.remove(cleanTree, !this.required);
            parent.rerender(() => this.restoreSelection());
        }
        else this.rerender(() => this.restoreSelection());
    }

    delColumn = (idx, cleanTree = true) => {
        let changed = false;
        for (const child of this.children) {
            const column = child.getChild(idx);
            if (column) {
                column.remove(cleanTree);
                changed = true;
            }
        }
        if (changed) {
            if (!this.childCount) {
                const parent = this.parent;
                this.remove(cleanTree, !this.required);
                parent.rerender(() => this.restoreSelection());
            }
            else this.rerender(() => this.restoreSelection());
        }
    }
}