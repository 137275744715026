import React from 'react';
import './doc-nav.scss';
import TreeView, {TreeNode} from "carbon-components-react/lib/components/TreeView";


class DocNav extends React.Component {
    state = {tree: []};
    mounted = false;

    componentDidMount = () => this.mounted = true;
    componentWillUnmount = () => this.mounted = false;

    update = tree => this.setState({tree: tree});

    // const treeNode = {
    //     id: 'acc-' + heading.pid,
    //     value: val,
    //     label: <span>{val}</span>,
    //     renderIcon: null,
    //     onSelect: () => document.getElementById(heading.pid).scrollIntoView(
    //         {behavior: "smooth", block: "start", inline: "center"}
    //     ),
    // };

    renderTree = (nodes, withIcons = false, expanded = undefined) => nodes && nodes.map && nodes.map(
        ({ children, renderIcon = null, isExpanded = false, ...nodeProps }) => (
            <TreeNode
                key={nodeProps.id}
                renderIcon={withIcons ? renderIcon : null}
                isExpanded={expanded ?? !!isExpanded}
                {...nodeProps}>
                {this.renderTree(children, withIcons, expanded)}
            </TreeNode>
        )
    );

    render = () => <div className="xf-nav">
        {this.state?.tree?.length && <TreeView label="Navigation">{this.renderTree(this.state.tree)}</TreeView>}
    </div>;
}

export default DocNav;