import STYLES from "../../backend/DocStyles";
import RiskHelper from "../Risk";


class DocHelper {
    // some constants for the super-confusing measurement variants, there's a few of them... sigh
    static EMU_MM = 36000;  // n * EMU_MM = mm -> emus
    static EMU_CM = 360000;  // n * EMU_CM = cm -> emus
    static EMU_IN = 914400;  // n * EMU_CM = in -> emus
    static A4MM_W = 210;  // A4 page width (21cm)
    static A4MM_H = 297;  // A4 page height (29.7cm)
    static A4MM_M = 12.7;  // default "word" narrow margins (1.27cm)
    static A4MM_S = 12.5;  // default "word" header / footer (1.25cm)
    static MS_DPI = 72;  // default DPI used for word documents

    // Twentieths of a point (dxa)
    // The main unit in OOXML is a twentieth of a point. This is used for specifying page dimensions, margins, tabs, etc.

    // Half-points
    // Half-points are used to specify font sizes. A font-size of 12pt equals 24 half points

    // Fiftieths of a percent (pct)
    // <!-- table width in 50th of a percent -->
    //       <w:tblW w:w="2500" w:type="pct"/>

    // This prints a table that takes 50% of the available width.
    // If you want to specify the with in twentieth of points instead, you have to use specify w:type=”dxa”.

    // EMUs (English Metric Unit) - Used to avoid floating point arithmetic
    // EMUs are used for coordinates in vector-based drawings and embedded pictures.
    // The EMU is a virtual unit to bridge both centimeters and inches.
    // One inch equates to 914400 EMUs and a centimeter is 360000.

    static A4PW_E = DocHelper.EMU_CM * DocHelper.A4MM_W / 10;
    static A4PH_E = DocHelper.EMU_CM * DocHelper.A4MM_H / 10;
    static A4PW_T = DocHelper.mmToDxa(DocHelper.A4MM_W);
    static A4PH_T = DocHelper.mmToDxa(DocHelper.A4MM_H);
    static A4NM_T = DocHelper.mmToDxa(DocHelper.A4MM_M);

    static TAB_SIZE = 720;
    static FONT_SIZE = 11;
    static IBM_PLEX_SN = 'IBM Plex Sans';
    static IBM_PLEX_SL = 'IBM Plex Sans Light';
    
    static DI_PRE = 'data:image/png;base64,';
    
    static ANCHOR = {
        None: 0,
        TopLeft: 1,
        TopRight: 2,
        BottomLeft: 3,
        BottomRight: 4
    };
    static ANCHOR_SET = new Set(Object.values(DocHelper.ANCHOR));
    static ANCHOR_ITEMS = [
        {id: DocHelper.ANCHOR.None, label: 'None'},
        {id: DocHelper.ANCHOR.TopLeft, label: 'Top Left'},
        {id: DocHelper.ANCHOR.TopRight, label: 'Top Right'},
        {id: DocHelper.ANCHOR.BottomLeft, label: 'Bottom Left'},
        {id: DocHelper.ANCHOR.BottomRight, label: 'Bottom Right'},
    ];

    static WRAP = {
        NONE: 0,
        SQUARE: 1,
        TIGHT: 2,
        TOP_AND_BOTTOM: 3
    };
    static WRAP_SET = new Set(Object.values(DocHelper.WRAP));
    static WRAP_ITEMS = [
        {id: DocHelper.WRAP.NONE, label: 'No Wrap'},
        {id: DocHelper.WRAP.SQUARE, label: 'Square'},
        {id: DocHelper.WRAP.TIGHT, label: 'Tight'},
        {id: DocHelper.WRAP.TOP_AND_BOTTOM, label: 'Top and Bottom'},
    ];

    static ALIGN = {
        START: "start",
        END: "end",
        CENTER: "center",
        BOTH: "both",
        JUSTIFIED: "both",
        DISTRIBUTE: "distribute",
        LEFT: "left",
        RIGHT: "right"
    };
    static ALIGN_SET = new Set(Object.values(DocHelper.ALIGN));
    static ALIGN_CLASSES = {
        [DocHelper.ALIGN.START]: "tal",
        [DocHelper.ALIGN.END]: "tar",
        [DocHelper.ALIGN.CENTER]: "tac",
        [DocHelper.ALIGN.BOTH]: "taj",
        [DocHelper.ALIGN.JUSTIFIED]: "taj",
        [DocHelper.ALIGN.DISTRIBUTE]: "taj",
        [DocHelper.ALIGN.LEFT]: "tal",
        [DocHelper.ALIGN.RIGHT]: "tar"
    };

    static STYLE = {
        NORMAL: "Normal",
        NO_SPACE: "NoSpacing",
        FINDING: "FindingTitle",
        FOOTER: "MainFooter",
        CAPTION: "Caption-Template",
        COPYRIGHT: "CopyrightFont",
        CODE: "Codeblock",
        LIST: "ParagraphBullets",
        TEMPLATE: "TemplateDirections",
        RISKS: "RiskTable",
        TABLE: "MainTable",
        TABLE_TITLE: "TableTitle",
        TABLE_TEXT: "TableText",
        H1: "Heading1",
        H2: "Heading2",
        H3: "Heading3",
        H4: "Heading4",
        H5: "Heading5",
        H6: "Heading6",
        CRITICAL: "CriticalRisk",
        HIGH: "HighRisk",
        MEDIUM: "MediumRisk",
        LOW: "LowRisk",
        INFO: "InformationalFinding",
    };
    static STYLE_SET = new Set(Object.values(DocHelper.STYLE));

    static RISK_STYLE = {
        [RiskHelper.CRITICAL.id]: "CriticalRisk",
        [RiskHelper.HIGH.id]: "HighRisk",
        [RiskHelper.MEDIUM.id]: "MediumRisk",
        [RiskHelper.LOW.id]: "LowRisk",
        [RiskHelper.INFO.id]: "InformationalFinding",
    };

    static STYLE_CLASSES = {
        "Normal": "normal",
        "NoSpacing": "no-space",
        "FindingTitle": "finding",
        "MainFooter": "footer",
        "Caption-Template": "caption",
        "CopyrightFont": "copy",
        "Codeblock": "code",
        "ParagraphBullets": "list",
        "TemplateDirections": "template",
        "RiskTable": "risks",
        "MainTable": "table",
        "TableTitle": "title",
        "TableText": "text",
        "Heading1": "h1",
        "Heading2": "h2",
        "Heading3": "h3",
        "Heading4": "h4",
        "Heading5": "h5",
        "Heading6": "h6",
        "CriticalRisk": "risk-4",
        "HighRisk": "risk-3",
        "MediumRisk": "risk-2",
        "LowRisk": "risk-1",
        "InformationalFinding": "risk-0",
    };

    static STYLE_LABELS = {
        "Normal": "Normal",
        "NoSpacing": "No Spacing",
        "FindingTitle": "Finding Title",
        "MainFooter": "Main Footer",
        "Caption-Template": "Caption",
        "CopyrightFont": "Copyright",
        "Codeblock": "Code Block",
        "ParagraphBullets": "Bulleted List",
        "TemplateDirections": "Template",
        "RiskTable": "Risk Table",
        "MainTable": "Main Table",
        "TableTitle": "Table Title",
        "TableText": "Table Text",
        "Heading1": "Heading 1",
        "Heading2": "Heading 2",
        "Heading3": "Heading 3",
        "Heading4": "Heading 4",
        "Heading5": "Heading 5",
        "Heading6": "Heading 6",
        "CriticalRisk": "Critical Risk",
        "HighRisk": "High Risk",
        "MediumRisk": "Medium Risk",
        "LowRisk": "Low Risk",
        "InformationalFinding": "Informational Finding"
    };

    static STYLE_ITEMS = Object.entries(DocHelper.STYLE_CLASSES).map(
        ([s, c]) => ({id: s, clazz: c, label: DocHelper.STYLE_LABELS[s]})
    );
    static STYLE_MAP = Object.fromEntries(DocHelper.STYLE_ITEMS.map((f, i) => [f.id, i]));

    static getStyleFontSize = style => {
        switch (style) {
            default:
                return DocHelper.FONT_SIZE;
            case DocHelper.STYLE.COPYRIGHT:
            case DocHelper.STYLE.H1:
                return 24;
            case DocHelper.STYLE.H2:
                return 18;
            case DocHelper.STYLE.H3:
            case DocHelper.STYLE.H5:
            case DocHelper.STYLE.H6:
                return 11;
            case DocHelper.STYLE.H4:
                return 12; // no idea why h4 is larger, that's the size in our report theme :shrug:
            case DocHelper.STYLE.TABLE_TITLE:
                return 11;
            case DocHelper.STYLE.TABLE_TEXT:
                return 9;
            case DocHelper.STYLE.CAPTION:
                return 8;
        }
    }

    static getStyleHeadingLevel = style => {
        switch (style) {
            default:
                return 0;
            case DocHelper.STYLE.COPYRIGHT:
            case DocHelper.STYLE.H1:
                return 1;
            case DocHelper.STYLE.H2:
                return 2;
            case DocHelper.STYLE.H3:
                return 3;
            case DocHelper.STYLE.H4:
                return 4;
            case DocHelper.STYLE.H5:
                return 5;
            case DocHelper.STYLE.H6:
                return 6;
        }
    }

    static getStyleHeadingReset = style => {
        switch (style) {
            default:
                return style;
            case DocHelper.STYLE.COPYRIGHT:
            case DocHelper.STYLE.H1:
            case DocHelper.STYLE.H2:
            case DocHelper.STYLE.H3:
            case DocHelper.STYLE.H4:
            case DocHelper.STYLE.H5:
            case DocHelper.STYLE.H6:
                return DocHelper.STYLE.NORMAL;
        }
    }

    static FONT_ITEMS = [
        {id: 1, label: 'IBM Plex Sans', family: '"IBM Plex Sans", "Helvetica Neue", Arial, sans-serif'},
        {id: 2, label: 'IBM Plex Sans Light', family: '"IBM Plex Sans Light", "Helvetica Neue", Arial, sans-serif'},
        {id: 3, label: 'Arial', family: 'Arial, sans-serif'},
        {id: 4, label: 'Brush Script MT', family: '"Brush Script MT", cursive'},
        {id: 5, label: 'Courier New', family: '"Courier New", Courier, monospace'},
        {id: 6, label: 'Garamond', family: 'Garamond, serif'},
        {id: 7, label: 'Georgia', family: 'Georgia, serif'},
        {id: 8, label: 'Helvetica', family: '"Helvetica Neue", Helvetica, sans-serif'},
        {id: 9, label: 'Tahoma', family: 'Tahoma, sans-serif'},
        {id: 10, label: 'Times New Roman', family: '"Times New Roman", Times, serif'},
        {id: 11, label: 'Trebuchet MS', family: '"Trebuchet MS", sans-serif'},
        {id: 12, label: 'Verdana', family: 'Verdana, sans-serif'},
    ];
    static FONTS = DocHelper.FONT_ITEMS.map(f => f.label);
    static FONT_SET = new Set(DocHelper.FONTS);
    static FONT_MAP = Object.fromEntries(DocHelper.FONTS.map((f, i) => [f, i]));

    static FONT_SIZES = [
        {id: 1, value: 8, label: '8 pt'}, {id: 2, value: 9, label: '9 pt'}, {id: 3, value: 10, label: '10 pt'},
        {id: 4, value: 11, label: '11 pt'}, {id: 5, value: 12, label: '12 pt'}, {id: 6, value: 14, label: '14 pt'},
        {id: 7, value: 16, label: '16 pt'}, {id: 8, value: 18, label: '18 pt'}, {id: 9, value: 20, label: '20 pt'},
        {id: 8, value: 22, label: '22 pt'}, {id: 9, value: 24, label: '24 pt'}, {id: 10, value: 26, label: '26 pt'},
    ];
    static FONT_SIZE_MAP = Object.fromEntries(DocHelper.FONT_SIZES.map((f, i) => [f.value, i]));

    static FORMAT_PROPS = {
        fonts: DocHelper.FONT_ITEMS,
        sizes: DocHelper.FONT_SIZES,
        styles: DocHelper.STYLE_ITEMS,
    };

    static FORMAT_DEFAULTS = {
        align: DocHelper.ALIGN.LEFT,
        font: DocHelper.FONTS[0],
        size: DocHelper.FONT_SIZE,
        style: DocHelper.STYLE_ITEMS[0].id,
        color: '#161616',
        highlight: null,
        link: null,
        bold: false,
        italic: false,
        underline: false,
        strikethrough: false,
        subscript: false,
        superscript: false,
        bulleted: false,
        numbered: false,
        listable: false,
        searching: false,
        collapsed: true,
        breakable: true,
    };

    static FORMAT_KEYS = [
        'align', 'font', 'size', 'style', 'color', 'highlight', 'bold', 'italic', 'underline', 'strikethrough',
        'subscript', 'superscript', // 'link', 'label', 'level', 'spacing', 'indent', 'break'
    ];

    static UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.compile();

    static isUUID(uuid) {
        return DocHelper.UUID_REGEX.test(uuid);
    }

    static FINDING_FIELDS = {
        'location': 'Locations',
        'description': 'Description',
        'evidence': 'Evidence',
        'recommendations': 'Recommendations',
        'references': 'References'
    };

    static isStaticFindingField(field) {
        return field in DocHelper.FINDING_FIELDS;
    }
    static getFindingFieldLabel(field, defaultValue = '') {
        return field in DocHelper.FINDING_FIELDS ? DocHelper.FINDING_FIELDS[field] : defaultValue;
    }

    static getFullDocProps(creator, title, subject, description, lastModifiedBy, sections = [], customProperties = {}, styles = {}) {
        const baseProps = DocHelper.getBaseDocProps(creator, title, subject, description, lastModifiedBy, customProperties);
        return {
            ...baseProps, styles: {...baseProps.styles, ...styles}, sections: [...baseProps.sections, ...sections]
        };
    }

    static getBaseDocProps(creator, title, subject, description, lastModifiedBy, customProperties = {}) {
        return {
            creator: creator,
            title: title,
            subject: subject,
            description: description,
            lastModifiedBy: lastModifiedBy,
            customProperties: Object.entries(customProperties).map(([k, v]) => ({name: k, value: v})),
            externalStyles: STYLES,
            styles: {
                default: {
                    strong: {
                        name: "Strong",
                        basedOn: "Normal",
                        run: {
                            bold: true,
                        },
                    },
                },
            },
            sections: []
        };
    }

    static getBaseSpecProps(creator, title, subject, description, lastModifiedBy, customProperties = {}) {
        return {
            creator: creator,
            title: title,
            subject: subject,
            description: description,
            lastModifiedBy: lastModifiedBy,
            customProperties: {...customProperties}
        };
    }

    static getDateYear(date = null) {
        return date.getFullYear();
    }
    static getShortDate(date) {
        return date.toLocaleDateString();
    }
    static getLongDate(date) {
        return new Intl.DateTimeFormat('default', {
            year: 'numeric', month: 'short', day: 'numeric'
        }).format(date);
    }

    // conversion methods
    static mmToPixels(mm, ppi) {
        return (mm * ppi) / 25.4;  // returns pixel count for specified mm based on ppi
    }
    static mmFromPixels(pixels, ppi) {
        return (pixels * 25.4) / ppi;  // returns mm value for specified pixels based on ppi
    }
    static mmToDxa(mm, dpi = DocHelper.MS_DPI) {
        return (mm / 25.4) * dpi * 20;
    }
    static mmFromDxa(dxa, dpi = DocHelper.MS_DPI) {
        return (dxa / 20 / dpi) * 25.4;
    }
    static inToDxa(inch, dpi = DocHelper.MS_DPI) {
        return inch * dpi * 20;
    }
    static inFromDxa(dxa, dpi = DocHelper.MS_DPI) {
        return dxa / 20 / dpi;
    }
    static cmToEmu(cm) {
        return cm * DocHelper.EMU_CM;
    }
    static cmFromEmu(emu) {
        return emu / DocHelper.EMU_CM;
    }
    static mmToEmu(mm) {
        return mm * DocHelper.EMU_MM;
    }
    static mmFromEmu(emu) {
        return emu / DocHelper.EMU_MM;
    }
    static emuToDxa(emu, dpi = DocHelper.MS_DPI) {
        return DocHelper.ptToDxa(DocHelper.emuToPt(emu, dpi));
    }
    static emuFromDxa(dxa, dpi = DocHelper.MS_DPI) {
        return DocHelper.emuFromPt(DocHelper.ptFromDxa(dxa), dpi);
    }
    static emuToPt(emu, dpi = DocHelper.MS_DPI) {
        return DocHelper.inFromEmu(emu) * dpi;
    }
    static emuFromPt(pt, dpi = DocHelper.MS_DPI) {
        return DocHelper.inToEmu(pt / dpi);
    }
    static ptToDxa(pt) {
        return pt * 20;
    }
    static ptFromDxa(dxa) {
        return dxa / 20;
    }
    static inToEmu(inch) {
        return inch * DocHelper.EMU_IN;
    }
    static inFromEmu(emu) {
        return emu / DocHelper.EMU_IN;
    }
    static getPageId(section_idx, page_idx) {
        return `xfe-page-${section_idx}_${page_idx}`;
    }
    static getParagraphId(section_idx, paragraph_idx) {
        return `xfe-para-${section_idx}_${paragraph_idx}`;
    }
    static getRunId(section_idx, paragraph_idx, run_idx) {
        return `xfe-run-${section_idx}_${paragraph_idx}_${run_idx}`;
    }
}

export default DocHelper;