import React from 'react';
import './client-structured-list.scss'
import {
    Button,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow, StructuredListWrapper,
    TextInput
} from "carbon-components-react";
import {Add16, CheckmarkFilled16, CircleDash16, Edit16, FilterEdit16} from "@carbon/icons-react";
import ClientEditTear from "../ClientEditTear";

class ClientStructuredList extends React.Component {
    static defaultProps = {
        clients: null, client: null,
    };

    constructor(props) {
        super(props);
        const { clients } = this.props;
        const { client } = this.props;
        this.state = {
            errors: [],
            client: client,
            clients: clients,
            editing_client: false,
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.clients !== this.props.clients || prevProps.client !== this.props.client){
            this.setState({
                clients: this.props.clients,
                client: this.props.client
            })
        }
    }

    selectClient(id){
        this.props.handler(null, id)
    }

    handleKeyDown(e, accept = () => { }, reject = () => { }) {
        switch (e.key) {
            default:
                return;
            case 'Enter':
                return accept();
            case 'Escape':  // make sure we exit editing only, not the tear sheet
                e.preventDefault();
                e.stopPropagation();
                return reject();
        }
    }

    render() {
        const clients = this.state.clients ?
            Object.entries(this.state.clients).filter(
                ([_, client]) => !client.deleted && (
                    this.state.search_client == null ||
                    client.name.toLowerCase().includes(this.state.search_client.toLowerCase())
                )
            ).sort(
                (a, b) => a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase())
            ) : [];
        return (
            <div className="xfm-clients">
            <StructuredListWrapper className="xfm-clients-slw">
                <StructuredListHead>
                    <StructuredListRow head>
                        {this.state.search_client == null ?
                            <StructuredListCell head>Clients</StructuredListCell> :
                            <StructuredListCell head className="xts-sle">
                                <TextInput
                                    id="search-cl-in" labelText="Enter search term..." hideLabel={true}
                                    placeholder="Type to start filtering..."
                                    value={this.state.search_client} autoFocus={true} className="xfe-edit"
                                    onChange={e => this.setState({ search_client: e.target.value })}
                                    onBlur={e => e.target.value.toString().trim() === '' && this.setState({ search_client: null })}
                                    onKeyDown={e => this.handleKeyDown(e,
                                        () => e.target.value.toString().trim() === '' && this.setState({ search_client: null }),
                                        () => this.setState({ search_client: null })
                                    )}
                                />
                            </StructuredListCell>
                        }
                        <StructuredListCell head>#</StructuredListCell>
                        <StructuredListCell head className="xts-slb">
                            <div className="xts-wrap" onClick={e => e.stopPropagation()}>
                                <Button
                                    tooltipPosition="bottom" tooltipAlignment="end"
                                    hasIconOnly kind="ghost" size="sm" renderIcon={FilterEdit16}
                                    iconDescription="Filter Clients" className="xts-fil-btn"
                                    name="xts-fil-btn"
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.setState({ search_client: '' });
                                    }} tabIndex={-1}
                                />
                            </div>
                        </StructuredListCell>
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    <StructuredListRow
                        key="row-add" className="xfe-add"
                    >
                        <StructuredListCell>
                            <span>Add Client...</span>
                        </StructuredListCell>
                        <StructuredListCell />
                        <StructuredListCell className="xts-slb">
                            <div className="xts-wrap">
                            <Button
                                renderIcon={Add16}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                        editing_client: true,
                                    })}}
                                hasIconOnly
                                kind="ghost"
                                size="sm"
                                iconDescription="Add"
                                tooltipPosition="left"
                            />
                            </div>
                        </StructuredListCell>
                    </StructuredListRow>
                    {clients.map(([id, client]) =>
                        <StructuredListRow
                            key={`row-${id}`} className={this.state.client === id ? 'xfc-sel' : ''}
                            onClick={() => {
                                this.selectClient(id)
                            }}
                            style={{
                                boxShadow: client.colour + ' 20px 0px 10px -15px inset'
                            }}
                        >
                            <StructuredListCell>
                                <div>
                                    {client.name}
                                </div>
                            </StructuredListCell>
                            <StructuredListCell>
                                <div className="xtc-wrap">
                                    {this.state.client === id ? <CheckmarkFilled16 /> : <CircleDash16 />}
                                </div>
                            </StructuredListCell>
                            <StructuredListCell className="xts-slb">
                                <div className="xts-wrap">
                                <Button
                                    renderIcon={Edit16}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            client_to_edit: client,
                                            editing_client: true,
                                        })}}
                                    hasIconOnly
                                    kind="ghost"
                                    size="sm"
                                    iconDescription="Edit"
                                    tooltipPosition="left"
                                />
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>)}
                </StructuredListBody>
            </StructuredListWrapper>
                { this.state.editing_client !== false && <ClientEditTear
                    client={this.state.client_to_edit}
                    onClose={f => {
                        if(f.updated_client) {
                            let client = {};
                            client[f.updated_client.uuid] = f.updated_client;
                            this.props.handler({...this.state.clients, ...client}, f.updated_client.uuid)
                        }
                        this.setState(s => ({
                            editing_client: false,
                            client_to_edit: null
                        }))
                    }}
                />
                }
            </div>
        );
    }
}

export default ClientStructuredList;