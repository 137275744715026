import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    TooltipIcon
} from "carbon-components-react";
import SessionContext from "../../helpers/SessionContext";
import {Copy16, DotMark24, Edit16} from "@carbon/icons-react";
import FindingEditTear from "../FindingEditTear";
import RiskHelper from "../../helpers/Risk";
import Tests from "../../backend/Templates";
import Loading from "../Loading";

import './finding-templates.scss';


class FindingTemplates extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        headers: [
            {
                key: 'name',
                header: 'Finding Title',
            },
            {
                key: 'type',
                header: 'Type',
            },
            {
                key: 'synopsis',
                header: 'Synopsis',
            },
            {
                key: 'solution',
                header: 'Solution',
            },
            {
                key: 'base_risk',
                header: 'Base Risk',
            },
            {
                key: 'contextual_risk',
                header: 'Contextual Risk',
            },
            {
                key: 'enabled',
                header: 'Enabled',
            },
        ]
    };
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            data: {},
            rows: [],
            total: 0,
            loading: true, // skeleton components
            load_modal: false, // modal loading spinner
            editing: null,
        };

        this.mounted = false;
    }
    componentDidMount = () => {
        this.mounted = true;
        Tests.findings().then(d => {
            if (!this.mounted) return;

            if (!d.success) {
                this.setState({rows: [], data: {}, total: 0, loading: false});
                if (d.error) {
                    this.context.addToast('error', 'An Error Occurred', 'Error retrieving template data', d.error);
                }
                return;
            }

            this.setState({...this.parseDataForState(d.findings), loading: false});

        }).catch(this.context.networkFaultHandler);
    }
    componentWillUnmount = () => this.mounted = false;
    parseDataForState = (data) => {
        const rows = Object.values(data).map(v => ({
            id: v.uuid, name: v.name, type: v.type ? v.type.name : '', enabled: v.enabled ? 'Yes' : 'No',
            synopsis: v.synopsis, solution: v.solution,
            base_risk: v.base_risk, contextual_risk: v.contextual_risk
        }));
        return {rows: rows, data: data, total: rows.length};
    }
    render = () => {
        const { headers: propHeaders } = this.props;
        const { match } = this.props;
        const uuid = match && match.params && match.params.uuid ? match.params.uuid : null; // /finding/:uuid
        return (
            <div className="xf-ft-wrap">
                {this.state.load_modal && <Loading hasLogo={true} modal={true} message="Loading..." />}
                <div className="xft-left">
                    {this.state.loading ? <DataTableSkeleton columnCount={propHeaders.length} /> : <div>
                        <DataTable rows={this.state.rows} headers={propHeaders} isSortable size="md">
                            {({
                                  rows, headers, getHeaderProps, getTableProps, getRowProps,
                                  onInputChange, getToolbarProps, getBatchActionProps, getSelectionProps
                              }) => (
                                <TableContainer stickyHeader={true}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableToolbarContent>
                                            <TableToolbarSearch
                                                persistent={true} placeholder="Start typing to filter..."
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        {...getHeaderProps({ header })}
                                                        className={'xfh-' + header.key}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                                <TableHeader/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow {...getRowProps({ row })} key={row.id}>
                                                    {row.cells.map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className={'xfc-' + cell.info.header}
                                                        >{cell.info.header.endsWith('_risk') ? <TooltipIcon
                                                            className={
                                                                RiskHelper.getClass('xf-rsk-dot xfd', cell.value)
                                                            }
                                                            tooltipText={RiskHelper.getLabel(cell.value)}
                                                            renderIcon={DotMark24} align="end" direction="top"
                                                        /> : cell.value}</TableCell>
                                                    ))}
                                                    <TableCell className="xf-act-wrap">
                                                        <div className="xf-actions">
                                                            <Button className="xff-act"
                                                                    tooltipPosition="top" tooltipAlignment="end"
                                                                    hasIconOnly renderIcon={Edit16}
                                                                    size="sm" kind="ghost"
                                                                    iconDescription="Edit Finding"
                                                                    onClick={() =>
                                                                        this.props.history.push('/manage/finding/' + row.id)
                                                                    }
                                                            />
                                                            <Button className="xff-act"
                                                                    tooltipPosition="top" tooltipAlignment="end"
                                                                    hasIconOnly renderIcon={Copy16}
                                                                    size="sm" kind="ghost"
                                                                    iconDescription="New Child Finding"
                                                                    onClick={() =>
                                                                        this.setState({editing: row.id})
                                                                    }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div> }
                </div>
                {this.state.data && uuid && (uuid in this.state.data) && <FindingEditTear
                    finding={this.state.data[uuid]}
                    onClose={f => f ? this.setState(
                        oldState => {
                            const oldUUID = oldState.editing; // split out to prevent statement eval order bug
                            this.parseDataForState({...oldState.data, [oldUUID]: f});
                        },
                        () => this.props.history.push('/manage/findings')
                    ) : this.props.history.push('/manage/findings')}
                />}
            </div>
        );
    }
}

export default withRouter(FindingTemplates);