import React from 'react';
import './toasty.scss';
import {v4 as uuid4} from "uuid";
import {ToastNotification} from "carbon-components-react";


class Toasty extends React.Component {
    state = {toasts: {}};
    addToast = (kind, title, subtitle, caption, timeout=7500, _uuid = null) => {
        // kind: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'
        const uuid = _uuid != null ? _uuid : uuid4();
        const toast = {kind: kind, title: title, subtitle: subtitle, caption: caption, timeout: timeout};
        this.setState(s => {
            let t = {...s.toasts};
            t[uuid] = toast;
            return {toasts: t};
        });
        return uuid;
    }
    addError = (message, caption='Please try again', title='Error') => this.addToast('error', title, message, caption);
    addInfo = (message, caption='Information', title='Information') => this.addToast('info', title, message, caption);
    addWarning = (message, caption='Warning', title='Warning') => this.addToast('warning', title, message, caption);
    addSuccess = (message, caption='Operation Successful', title='Success') => this.addToast('success', title, message, caption);

    render = () => (
        <div className="xf-toaster">
            {Object.values(this.state.toasts).map((t, i) =>
                <ToastNotification key={i} kind={t.kind} iconDescription="Dismiss" subtitle={t.subtitle}
                                   title={t.title} caption={t.caption} timeout={t.timeout}/>
            )}
        </div>
    );
}

export default Toasty;