import React from 'react';
import {Button, OverflowMenu, OverflowMenuItem} from "carbon-components-react";
import {TableSplit16} from "@carbon/icons-react";
import './docbar-table.scss';
import {v4 as uuid4} from "uuid";


// TODO: accessibility on DocBarTable is tiresome, ditch overflow menu and implement this component properly
class DocBarTable extends React.PureComponent {
    static defaultProps = {
        prefix: 'bx', tooltipPosition: 'bottom', tooltipAlignment: 'center', size: 'md',
        rows: 8, cols: 10, onInsert: (rows, columns) => {}
    };
    state = {rows: 0, cols: 0};
    buttonComponent(props, key) {
        const { iconDescription, tooltipAlignment, tooltipPosition, renderIcon, size, onClick, ..._props } = props;
        return <OverflowMenuItem key={key} onClick={onClick} requireTitle={true} title={iconDescription} itemText={
            <Button kind="ghost" tooltipPosition={tooltipPosition} tooltipAlignment={tooltipAlignment} hasIconOnly
                    renderIcon={renderIcon} onClick={onClick} size={size} iconDescription={iconDescription} {..._props}
            />}
        />
    }
    render() {
        const {
            prefix, iconDescription = "", className = "", size, tooltipPosition, tooltipAlignment, rows, cols, onInsert
        } = this.props;

        // using OverflowMenu is more complex as it doesn't use a carbon button component internally so we have no
        // built-in tooltip, this functionality below adds this back in so it looks the same as the other buttons :)
        const btnClasses = [ // '--tooltip--hidden'
            'xft-btn', className, prefix + '--btn', prefix + '--btn--icon-only', prefix + '--btn--ghost',
            prefix + '--tooltip--a11y', prefix + '--tooltip__trigger', prefix + '--tooltip--visible',
            prefix + '--btn--icon-only--' + tooltipPosition, prefix + '--tooltip--align-' + tooltipAlignment,
        ].join(' ');

        // TODO: OverflowMenu in carbon is stupidly limited so we have to use weird hacks to make it do what we want,
        //       this is horrible and needs fixing at some point, its only ok for now as its quicker than rewriting it
        const uuid = uuid4(), targetClass = `omu-${uuid}`, maxWidth = cols * 1.15;
        return (<>
            <OverflowMenu
                renderIcon={() => <>
                    <div className={prefix + "--assistive-text xft-btn-ast"}>{iconDescription}</div>
                    <TableSplit16 className={prefix + '--btn__icon'} shapeRendering="crispEdges" style={{transform: 'scale(1.05) translate(-0.5px)'}}/>
                </>}
                className={btnClasses + ' ' + prefix + '--btn--' + size}
                iconDescription={iconDescription} menuOptionsClass={"xft-omo " + targetClass}
            >
                {Array.from({length: rows}, (_, i) =>
                    Array.from({length: cols}, (_, j) => {
                        const rowNumber = i + 1, columnNumber = j + 1;
                        const title = `${rowNumber} x ${columnNumber} table`;
                        const style = {};
                        return <OverflowMenuItem
                            key={`r${i}-c${j}`} onClick={() => onInsert(rowNumber, columnNumber)} requireTitle={true}
                            title={title} className={btnClasses + ' xft-btn ' + prefix + '--btn--sm'} style={style}
                            itemText={<div
                                className={'xft-btn-inner ' + ((this.state.rows >= rowNumber && this.state.cols >= columnNumber) ? 'selected' : '')}
                                onMouseOver={() => this.setState({rows: rowNumber, cols: columnNumber})}
                            >
                                <div className={prefix + "--assistive-text xft-btn-ast"}>{title}</div>
                            </div>}
                        />;
                        }
                    )
                )}
            </OverflowMenu>
            {/* I really dislike building inline style tags like this, but OverflowMenu doesn't support styles :( */}
            <style>{`.${targetClass} { max-width: ${maxWidth}rem; width: ${maxWidth}rem;`}</style>
        </>);
    }
}

export default DocBarTable;