import React from 'react';
import DocHelper from "../../../helpers/Document";
import DocContext from "../../../helpers/DocContext";
import './list-run.scss';
import {newNodePropProxy} from "../../../helpers/Utility";
import {ILNode} from "../../il/Nodes";


class ListRun extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        node: null, items: [], style: DocHelper.STYLE.LIST, numbered: false, editable: true
    };
    constructor(props) {
        super(props);
        this.proxy = newNodePropProxy(this);  // uses ilNode values if there, otherwise defaults to standard props
        this.baseRef = React.createRef();
    }
    render() {
        const { node, items, style, numbered, editable, ...otherProps } = this.proxy;
        const children = items.map((r, i) => <li key={i} className="xfl-item">
            {r && (r instanceof ILNode) ? r.render() : r}
        </li>);
        const className = "xfp-list s-" + DocHelper.STYLE_CLASSES[style] + (editable ? ' can-edit' : ' readonly');
        return numbered ?
            <ol ref={this.baseRef} id={node.getDOMId()} className={className} {...otherProps}>{children}</ol> :
            <ul ref={this.baseRef} id={node.getDOMId()} className={className} {...otherProps}>{children}</ul>;
    }
}

export default ListRun;