import React from 'react';
import './contract-structured-list.scss'
import {
    Button,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow, StructuredListWrapper,
    TextInput
} from "carbon-components-react";
import {Add16, CheckmarkFilled16, CircleDash16, Edit16, FilterEdit16} from "@carbon/icons-react";
import ContractEditTear from "../ContractEditTear";

class ContractStructuredList extends React.Component {
    static defaultProps = {
        contracts: null, contract: null, client: null,
    };

    constructor(props) {
        super(props);
        const {contracts} = this.props;
        const {contract} = this.props;
        const {client} = this.props;
        this.state = {
            errors: [],
            contract: contract,
            contracts: contracts,
            client: client,
            editing_contract: false,
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.contracts !== this.props.contracts || prevProps.contract !== this.props.contract
        || prevProps.client !== this.props.client){
            this.setState({
                contracts: this.props.contracts,
                contract: this.props.contract,
                client: this.props.client
            })
        }
    }

    selectContract(id){
        this.props.handler(null, id)
    }

    handleKeyDown(e, accept = () => {}, reject = () => {}) {
        switch (e.key) {
            default:
                return;
            case 'Enter':
                return accept();
            case 'Escape':  // make sure we exit editing only, not the tear sheet
                e.preventDefault();
                e.stopPropagation();
                return reject();
        }
    }

    render() {
        const contracts = this.state.contracts ?
            this.state.contracts.filter(
                ([_, contract]) => !contract.deleted && (
                    this.state.search_contract == null ||
                    contract.name.toLowerCase().includes(this.state.search_contract.toLowerCase())
                )
            ).sort(
                (a, b) => a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase())
            ) : [];
        return (
            <div>
                <StructuredListWrapper className="xfm-projects-slw">
                    <StructuredListHead>
                        <StructuredListRow head>
                            {this.state.search_contract == null ?
                                <StructuredListCell head>Contracts</StructuredListCell> :
                                <StructuredListCell head className="xts-sle">
                                    <TextInput
                                        id="search-cl-in" labelText="Enter search term..." hideLabel={true}
                                        placeholder="Type to start filtering..."
                                        value={this.state.search_contract} autoFocus={true} className="xfe-edit"
                                        onChange={e => this.setState({search_contract: e.target.value})}
                                        onBlur={e => e.target.value.toString().trim() === '' && this.setState({search_contract: null})}
                                        onKeyDown={e => this.handleKeyDown(e,
                                            () => e.target.value.toString().trim() === '' && this.setState({search_contract: null}),
                                            () => this.setState({search_contract: null})
                                        )}
                                    />
                                </StructuredListCell>
                            }
                            <StructuredListCell head>#</StructuredListCell>
                            <StructuredListCell head className="xts-slb">
                                <div className="xts-wrap" onClick={e => e.stopPropagation()}>
                                    <Button
                                        tooltipPosition="bottom" tooltipAlignment="end"
                                        hasIconOnly kind="ghost" size="sm" renderIcon={FilterEdit16}
                                        iconDescription="Filter Contracts" className="xts-fil-btn"
                                        name="xts-fil-btn-p"
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.setState({search_contract: ''});
                                        }} tabIndex={-1}
                                    />
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                        <StructuredListRow
                            key="row-add" className='xfe-add'
                        >
                            <StructuredListCell>
                                <span>Add Contract...</span>
                            </StructuredListCell>
                            <StructuredListCell/>
                            <StructuredListCell className="xts-slb">
                                <div className="xts-wrap">
                                <Button
                                    renderIcon={Add16}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            editing_contract: true,
                                        })
                                    }}
                                    hasIconOnly
                                    kind="ghost"
                                    size="sm"
                                    iconDescription="Add"
                                    tooltipPosition="left"
                                />
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                        {contracts.map(([id, contract]) =>
                            <StructuredListRow
                                key={`row-${id}`} className={this.state.contract === id ? 'xfc-sel' : ''}
                                onClick={() => {
                                    this.selectContract(id);
                                }}
                            >
                                <StructuredListCell>
                                    <div>
                                        {contract.name}
                                    </div>
                                </StructuredListCell>
                                <StructuredListCell>
                                    {this.state.contract === id ? <CheckmarkFilled16/> : <CircleDash16/>}
                                </StructuredListCell>
                                <StructuredListCell className="xts-slb">
                                    <div className="xts-wrap">
                                    <Button
                                        renderIcon={Edit16}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                contract_to_edit: contract,
                                                editing_contract: true,
                                            })
                                        }}
                                        hasIconOnly
                                        size="sm"
                                        kind="ghost"
                                        iconDescription="Edit"
                                        tooltipPosition="left"
                                    />
                                    </div>
                                </StructuredListCell>
                            </StructuredListRow>)}
                    </StructuredListBody>
                </StructuredListWrapper>
                {this.state.editing_contract !== false && <ContractEditTear
                    contract={this.state.contract_to_edit}
                    onClose={f => {
                        if (f.updated_contract) {
                            let contract = {};
                            contract[f.updated_contract.uuid] = f.updated_contract;
                            this.props.handler({...this.state.contracts, ...contract}, f.updated_contract.uuid)
                        }
                        this.setState(s => ({
                            editing_contract: false,
                            contract_to_edit: null
                        }))
                    }}
                    client={this.state.client}
                />
                }
            </div>
        );
    }
}

export default ContractStructuredList;