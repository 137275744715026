

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export function drawBox(
    top, left, width, height, color = 'red', style='dashed', border='1px',
    radius=null, timeout= 450, zIndex = '10000', callback = () => true
) {
    // wahh but what about the react state???
    // its an ephemeral box... for debugging... we don't care if it gets lost in the DOM universe... calm down! XD
    const bx = document.createElement('div');
    if (border) bx.style.border = `${border} ${style} ${color}`; // '1px dashed green';
    bx.style.position = 'absolute';
    bx.style.top = top.toFixed(3) + 'px';
    bx.style.left = left.toFixed(3) + 'px';
    bx.style.width = width.toFixed(3) + 'px';
    bx.style.height = height.toFixed(3) + 'px';
    bx.style.zIndex = zIndex;
    if (radius) bx.style.borderRadius = radius;
    document.body.appendChild(bx);
    const t = timeout > 0 ? setTimeout(() => {
        callback(bx) && document.body.removeChild(bx);
    }, timeout) : 0;
    return {box: bx, time: t};
}
