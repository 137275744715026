

class RiskHelper {
    static RISKS = {
        0: 'Informational',  // INFO
        1: 'Low Risk',  // LOW
        2: 'Medium Risk',  // MEDIUM
        3: 'High Risk',  // HIGH
        4: 'Critical Risk',  // CRITICAL
    };

    static HEADINGS = {
        0: 'Informational Findings',
        1: 'Low-Risk Vulnerabilities',
        2: 'Medium-Risk Vulnerabilities',
        3: 'High-Risk Vulnerabilities',
        4: 'Critical-Risk Vulnerabilities',
    };

    static COLOURS = {
        0: '#198038',  // INFO
        1: '#FDD13A',  // LOW
        2: '#FF832B',  // MEDIUM
        3: '#ba1b23',  // HIGH
        4: '#750e13',  // CRITICAL
    };

    static CLASSES = {
        0: 'info',  // INFO
        1: 'low',  // LOW
        2: 'med',  // MEDIUM
        3: 'high',  // HIGH
        4: 'crit',  // CRITICAL
    };

    static INFO =     {id: 0, label: RiskHelper.RISKS[0], head: RiskHelper.HEADINGS[0], colour: RiskHelper.COLOURS[0], clazz: RiskHelper.CLASSES[0]};
    static LOW =      {id: 1, label: RiskHelper.RISKS[1], head: RiskHelper.HEADINGS[1], colour: RiskHelper.COLOURS[1], clazz: RiskHelper.CLASSES[1]};
    static MEDIUM =   {id: 2, label: RiskHelper.RISKS[2], head: RiskHelper.HEADINGS[2], colour: RiskHelper.COLOURS[2], clazz: RiskHelper.CLASSES[2]};
    static HIGH =     {id: 3, label: RiskHelper.RISKS[3], head: RiskHelper.HEADINGS[3], colour: RiskHelper.COLOURS[3], clazz: RiskHelper.CLASSES[3]};
    static CRITICAL = {id: 4, label: RiskHelper.RISKS[4], head: RiskHelper.HEADINGS[4], colour: RiskHelper.COLOURS[4], clazz: RiskHelper.CLASSES[4]};

    // Q: Why is there repeated data elements here? What's going on?
    // A: Carbon can be super picky with initialSelectedItem so we use this static helper
    //    to ensure we always reference the same static object throughout the application.
    static SELECTS = [RiskHelper.INFO, RiskHelper.LOW, RiskHelper.MEDIUM, RiskHelper.HIGH, RiskHelper.CRITICAL];
    static ID_ITEM = {
        0: RiskHelper.INFO, 1: RiskHelper.LOW, 2: RiskHelper.MEDIUM, 3: RiskHelper.HIGH, 4: RiskHelper.CRITICAL
    };

    static getLabel(risk) {
        return risk in RiskHelper.RISKS ? RiskHelper.RISKS[risk] : '';
    }

    static getColour(risk) {
        return risk in RiskHelper.COLOURS ? RiskHelper.COLOURS[risk] : '';
    }

    static getClassSuffix(risk) {
        return risk in RiskHelper.CLASSES ? RiskHelper.CLASSES[risk] : '';
    }

    static getClass(prefix, risk) {
        return prefix + '-' + RiskHelper.getClassSuffix(risk);
    }

    static getStyleClass(risk) {
        return 's-' + RiskHelper.getClassSuffix(risk);
    }
}

export default RiskHelper;
