import React from 'react';
import './project-structured-list.scss'
import {
    Button,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow, StructuredListWrapper,
    TextInput
} from "carbon-components-react";
import {Add16, CheckmarkFilled16, CircleDash16, Edit16, FilterEdit16} from "@carbon/icons-react";
import ProjectEditTear from "../ProjectEditTear";

class ProjectStructuredList extends React.Component {
    static defaultProps = {
        projects: null, project: null, client: null, contracts: null
    };

    constructor(props) {
        super(props);
        const {projects} = this.props;
        const {project} = this.props;
        const {client} = this.props;
        const {contracts} = this.props;
        this.state = {
            errors: [],
            project: project,
            projects: projects,
            client: client,
            contracts: contracts,
            editing_project: false,
            loading: false
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.projects !== this.props.projects || prevProps.project !== this.props.project
        || prevProps.client !== this.props.client || prevProps.contracts !== this.props.contracts){
            this.setState({
                projects: this.props.projects,
                project: this.props.project,
                client: this.props.client,
                contracts: this.props.contracts
            })
        }
    }

    selectProject(id){
        this.props.handler(null, id)
    }

    handleKeyDown(e, accept = () => {}, reject = () => {}) {
        switch (e.key) {
            default:
                return;
            case 'Enter':
                return accept();
            case 'Escape':  // make sure we exit editing only, not the tear sheet
                e.preventDefault();
                e.stopPropagation();
                return reject();
        }
    }

    render() {
        const projects = this.state.projects ?
            this.state.projects.filter(
                ([_, project]) => !project.deleted && (
                    this.state.search_project == null ||
                    project.name.toLowerCase().includes(this.state.search_project.toLowerCase())
                )
            ).sort(
                (a, b) => a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase())
            ) : [];

        return (
            <div>
                <StructuredListWrapper className="xfm-projects-slw">
                    <StructuredListHead>
                        <StructuredListRow head>
                            {this.state.search_project == null ?
                                <StructuredListCell head>Projects</StructuredListCell> :
                                <StructuredListCell head className="xts-sle">
                                    <TextInput
                                        id="search-cl-in" labelText="Enter search term..." hideLabel={true}
                                        placeholder="Type to start filtering..."
                                        value={this.state.search_project} autoFocus={true} className="xfe-edit"
                                        onChange={e => this.setState({ search_project: e.target.value })}
                                        onBlur={e => e.target.value.toString().trim() === '' && this.setState({ search_project: null })}
                                        onKeyDown={e => this.handleKeyDown(e,
                                            () => e.target.value.toString().trim() === '' && this.setState({ search_project: null }),
                                            () => this.setState({ search_project: null })
                                        )}
                                    />
                                </StructuredListCell>
                            }
                            <StructuredListCell head>#</StructuredListCell>
                            <StructuredListCell head className="xts-slb">
                                <div className="xts-wrap" onClick={e => e.stopPropagation()}>
                                    <Button
                                        tooltipPosition="bottom" tooltipAlignment="end"
                                        hasIconOnly kind="ghost" size="sm" renderIcon={FilterEdit16}
                                        iconDescription="Filter Projects" className="xts-fil-btn"
                                        name="xts-fil-btn-p"
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.setState({ search_project: '' });
                                        }} tabIndex={-1}
                                    />
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                        <StructuredListRow
                            key="row-add" className={'xfe-add '}
                        >
                            <StructuredListCell>
                                <span>Add Project...</span>
                            </StructuredListCell>
                            <StructuredListCell />
                            <StructuredListCell className="xts-slb">
                                <div className="xts-wrap">
                                <Button
                                    renderIcon={Add16}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            editing_project: true,
                                        })}}
                                    hasIconOnly
                                    kind="ghost"
                                    size="sm"
                                    iconDescription="Add"
                                    tooltipPosition="left"
                                />
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                        {projects.map(([id, project]) =>
                            <StructuredListRow
                                key={`row-${id}`} className={this.state.project === id ? 'xfc-sel' : ''}
                                onClick={() => {
                                    this.selectProject(id)
                                }}
                            >
                                <StructuredListCell>
                                    <div>
                                        {project.name}
                                    </div>
                                </StructuredListCell>
                                <StructuredListCell>
                                    {this.state.project === id ? <CheckmarkFilled16 /> : <CircleDash16 />}
                                </StructuredListCell>
                                <StructuredListCell className="xts-slb">
                                    <div className="xts-wrap">
                                    <Button
                                        renderIcon={Edit16}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                project_to_edit: project,
                                                editing_project: true,
                                            })}}
                                        hasIconOnly
                                        size="sm"
                                        kind="ghost"
                                        iconDescription="Edit"
                                        tooltipPosition="left"
                                    />
                                    </div>
                                </StructuredListCell>
                            </StructuredListRow>)}
                    </StructuredListBody>
                </StructuredListWrapper>
                { this.state.editing_project !== false && <ProjectEditTear
                    contracts={this.state.contracts.map(contract => contract[1])}
                    project={this.state.project_to_edit}
                    onClose={f => {
                        if(f.updated_project) {
                            let project = {}
                            project[f.updated_project.uuid] = f.updated_project;
                            this.props.handler({...this.state.projects, ...project}, f.updated_project.uuid)
                        }
                        this.setState(s => ({
                            editing_project: false,
                            project_to_edit: null
                        }))
                    }}
                    client={this.state.client}
                />
                }
            </div>
        );
    }
}

export default ProjectStructuredList;