import React from 'react';
import {Button, OverflowMenu, OverflowMenuItem} from "carbon-components-react";
import {Checkmark16, TextHighlight16} from "@carbon/icons-react";
import {contrastCompare} from "../../helpers/Utility";
import './docbar-color.scss';


class DocBarColor extends React.PureComponent {
    static defaultProps = {
        prefix: 'bx', tooltipPosition: 'bottom', tooltipAlignment: 'center', size: 'md', color: '#161616',
        onChange: c => c, highlight: false,
        colors: [
            '#002d9c', '#1192e8', '#198038', '#005d5d',
            '#6929c4', '#9f1853', '#570408', '#da1e28',
            '#ff832b', '#f1c21b', '#000000', '#161616',
            '#393939', '#525252', '#a8a8a8', '#ffffff',
        ]
    };
    buttonComponent(props, key) {
        const { iconDescription, tooltipAlignment, tooltipPosition, renderIcon, size, onClick, ..._props } = props;
        return <OverflowMenuItem key={key} onClick={onClick} requireTitle={true} title={iconDescription} itemText={
            <Button kind="ghost" tooltipPosition={tooltipPosition} tooltipAlignment={tooltipAlignment} hasIconOnly
                    renderIcon={renderIcon} onClick={onClick} size={size} iconDescription={iconDescription} {..._props}
            />}
        />
    }
    render() {
        const {
            prefix = "", iconDescription = "", className = "", size = "md", color: colour = '#161616',
            onChange = c => c, colors = [], highlight = false, tooltipPosition, tooltipAlignment
        } = this.props;
        const color = colour || '#161616'; // make sure we always have a colour, even if null is passed via props

        // using OverflowMenu is more complex as it doesn't use a carbon button component internally so we have no
        // built-in tooltip, this functionality below adds this back in so it looks the same as the other buttons :)
        const btnClasses = [ // '--tooltip--hidden'
            'xfc-btn', className, prefix + '--btn', prefix + '--btn--icon-only', prefix + '--btn--ghost',
            prefix + '--tooltip--a11y', prefix + '--tooltip__trigger', prefix + '--tooltip--visible',
            prefix + '--btn--icon-only--' + tooltipPosition, prefix + '--tooltip--align-' + tooltipAlignment,
        ].join(' ');

        return (
            <OverflowMenu renderIcon={() => <>
                <div className={prefix + "--assistive-text xfc-btn-ast"}>{iconDescription}</div>
                {/* What happened to TextColor16? - The carbon SVG uses a single path for the entire
                  * icon, this means we can't colour the bar at the bottom separately like the document
                  * editor pattern spec tells us to, so we need to use our own modified copy... sigh :(
                  */}
                {highlight ? <TextHighlight16 style={{fill: color}}/> : <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" aria-label="Text Color" aria-hidden="true" width="16" height="16"
                     viewBox="0 0 32 32" role="img" className={prefix + '--btn__icon'}>
                    <path d="M22 21h2L17 4H15L8 21h2l1.61-4h8.74zm-9.57-6l3.44-8.37h.26L19.54 15z"/>
                    <path d="M6 24H26V28H6z" style={{fill: color || 'currentColor'}}/>
                </svg>}
            </>} className={btnClasses + ' ' + prefix + '--btn--' + size} iconDescription={iconDescription} menuOptionsClass="xfc-omo"
            >
                {colors.map((_color, i) => {
                    const clear = !highlight || _color !== '#ffffff';
                    const title = clear ? _color : 'Clear Highlight';
                    return <OverflowMenuItem
                        key={i} onClick={() => onChange(_color)} requireTitle={true}
                        title={title} className={btnClasses + ' xfc-btn ' + prefix + '--btn--sm'} style={{background: _color}}
                        itemText={<div className={'xfc-btn-inner ' + (clear ? '' : 'chl')}>
                            <div className={prefix + "--assistive-text xfc-btn-ast"}>{title}</div>
                            {(_color === (highlight ? colour : color)) && <Checkmark16
                                className={prefix + '--btn__icon xfc-btn-chk'} style={{color: contrastCompare(_color)}}
                            />}
                        </div>}
                    />;
                })}
            </OverflowMenu>
        );
    }
}

export default DocBarColor;