import React from 'react';
import {Content} from "carbon-components-react/es/components/UIShell";
import SessionContext from "../../helpers/SessionContext";
import Loading from "../../components/Loading";
import Document from "../../document/Document";
import Reporting from "../../backend/Reporting";
import './reporting.scss';
import {BaseRowIL, BaseSectIL, FirstRunIL} from "../../document/il";
import {withRouter} from "react-router-dom";



class Editor extends React.Component {
    static contextType = SessionContext;

    state = {errors: [], data: {}, loading: true};
    mounted = false;
    docRef = React.createRef();

    componentDidMount = () => (this.mounted = true) && this.loadData(this.props?.match?.path, this.props?.match?.params?.uuid);
    componentWillUnmount = () => this.mounted = false;

    loadData = (path, uuid) => this.checkPath(path, () => this.loadSection(uuid), () => this.loadReport(uuid));
    loadReport = uuid => Reporting.report(uuid).then(d => this.handleLoad(d, d => d.report)).catch(this.context.networkFaultHandler);
    loadSection = uuid => Reporting.section(uuid).then(d => this.handleLoad(d, d => d.section)).catch(this.context.networkFaultHandler);

    handleLoad = (data, store) => this.mounted && (data.success ?
            this.setState({data: store(data), loading: false}) : this.setState({data: {}, loading: false},
                () => this.context.addToast('error', 'An Error Occurred', 'Error retrieving data', data.error ? data.error : 'Unknown Error'))
    );

    saveSection = (doc, accept = revision => revision, reject = () => {}) => {
        if (!doc?.sections?.length) {
            this.context.addToast('error', 'An Error Occurred', 'Document object empty', 'Unable to save the document');
            reject();
            return false;
        }
        const rows = doc.sections.reduce((rows, sect) => {
            rows.push(...sect.rows);
            return rows;
        }, []);

        if (!rows?.length) return this.context.addToast('error', 'An Error Occurred', 'No content in the document', 'Unable to save the document');

        Reporting.section(this.props?.match?.params?.uuid, {data: {rows: rows}}).then(data => {
            if (data.success) {
                this.context.addToast('success', 'Document Saved', 'Section content successfully saved', 'Good work!');
                return accept(data.revision);
            }
            this.context.addToast('error', 'An Error Occurred', 'Error saving section', data.error ? data.error : 'Unknown Error');
            reject();
        }).catch(e => {
            this.context.networkFaultHandler(e);
            reject();
        });
        return true;
    }

    saveReport = (doc, accept = () => {}, reject = () => {}) => {
        console.log('saving report', doc);
    }

    renderPlaceholder = () => <Content><div className="xfe-placeholder">Nothing to edit, are you in the right place?</div></Content>;
    renderSection = uuid => this.state?.data?.data?.rows ? this.renderEditorRows(this.state.data.data.rows, uuid, this.state.data.revision, this.saveSection) : this.renderPlaceholder();
    renderReport = uuid => this.state?.data?.data?.sections ? this.renderEditor(this.state.data.data.sections, uuid, this.state.data.revision, this.saveReport) : this.renderPlaceholder();

    // There's a bit of disparity here between our source data and render elements - the source data has sections
    // represent the different pieces of a document merged together in a template. Whereas in the DocIL representation
    // sections relate to the actual sections in a document, so the page dimensions, numbering and margin are all set
    // on DocIL sections, but in the backend these options are set at a report level. So they will need duplicating on
    // each sub-section when the report is created otherwise they'll ignore the template and default to A4 portrait.
    renderEditorRows = (rows, uuid, revision, saveCallback = () => {}) => this.renderEditor(
        [BaseSectIL((rows && rows.length) ? rows : [BaseRowIL(FirstRunIL())])], uuid, revision, saveCallback
    );
    renderEditor = (sections, uuid, revision, saveCallback = () => {}) =>
        <div className="xfe-edit-wrap">
            <div className="xfe-doc">
                <Document
                    context={this.context} ref={this.docRef}
                    uuid={uuid} revision={revision} sections={sections}
                    toolbarTooltipPosition="top"
                    backButtonCallback={() => this.props.history.goBack()}
                    saveDocumentCallback={saveCallback}
                    showSpellCheck={true} showZoomSlider={true}
                    showNavigation={true} showBackButton={true}
                    showFullScreen={false}
                />
            </div>
        </div>;

    renderPath = (path, uuid) => uuid ? this.checkPath(
        path, () => this.renderSection(uuid),
        () => this.renderReport(uuid), this.renderPlaceholder
    ) : this.renderPlaceholder();

    checkPath = (path, sectionCallback, reportCallback, defaultCallback = () => {}) => {
        switch (path) {
            default:
                return defaultCallback();
            case '/edit/section/:uuid':
                return sectionCallback();
            case '/edit/report/:uuid':
                return reportCallback();
        }
    }
    render = () => this.state.loading ? <Loading hasLogo={true} modal={true} /> :this.renderPath(this.props?.match?.path, this.props?.match?.params?.uuid);
}

export default withRouter(Editor);