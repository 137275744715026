import { ILNode } from "../Nodes";
import {DebugConsole} from "../../../helpers/Utility";

export default class RunNode extends ILNode {
    flushPendingUpdates = () => this?.parent?.flushPendingUpdates && this.parent.flushPendingUpdates();

    splitRun = (pos = 0, end = 0, callback = () => {}, render = true, newProps = {}, splice = true) =>
        this?.parent?.splitRun ? this.parent.splitRun(this.idx, pos, end, callback, render, newProps, splice) :
            DebugConsole.warn("RunNode.splitRun: Invalid parent node reference", this.parent);

    splitRow = (pos = 0, callback = () => {}, render = true, newProps = {}, splice = true) =>
        this?.parent?.splitRow ? this.parent.splitRow(this.idx, pos, callback, render, newProps, splice) :
            DebugConsole.warn("RunNode.splitRow: Invalid parent node reference", this.parent);
}