import React from 'react';
import './finding-edit-tear.scss';
import SessionContext from "../../helpers/SessionContext";
import {ComposedModal, ModalHeader, ModalBody, Dropdown, FormGroup, Modal, TextArea, TextInput} from "carbon-components-react";
import '@carbon/ibm-cloud-cognitive/scss/components/Tearsheet/_tearsheet.scss';
import RiskHelper from "../../helpers/Risk";
import Document from "../../document/Document";
import DocHelper from "../../helpers/Document";
import {shallowCompare} from "../../helpers/Utility";
import ReactDOM from "react-dom";
import {BaseSectIL} from "../../document/il";


class FindingEditTear extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        finding: null, onClose: null
    };
    constructor(props) {
        super(props);
        this.mounted = false;
        this.docRef = React.createRef();
        this.nameRef = React.createRef();
        this.nameRun = null;
        this.riskRun = null;
        this.riskLbl = null;
        this.state = this.getStateFromProps();
    }
    componentDidMount = () => this.mounted = true;
    componentWillUnmount = () => this.mounted = false;
    onClose = (reset = false) => {
        this?.docRef?.current?.document?.clearHistory && this.docRef.current.document.clearHistory();
        this?.props?.onClose && this.props.onClose(reset ? null : this.state.data);
    }
    getStateFromProps = () => {
        const { finding } = this.props;
        const rows = [{
            runs: [{
                text: finding.name || '',
                postInit: n => this.nameRun = n,
            }],
            style: DocHelper.STYLE.FINDING,
            spacing: {before: 0, after: 0},
            editable: false,
            size: 24,
            level: 3,
        }, {
            runs: [{
                text: 'Risk: '
            }, {
                text: RiskHelper.getLabel(finding.contextual_risk),
                color: RiskHelper.getColour(finding.contextual_risk),
                postInit: n => this.riskLbl = n  // store a reference to the node for later use
            }],
            style: DocHelper.RISK_STYLE[finding.contextual_risk],
            bold: true,
            size: 22,
            editable: false,
            postInit: n => this.riskRun = n,
        }];

        const docIL = {};
        for (const [f, l] of Object.entries(DocHelper.FINDING_FIELDS)) {
            if (f in finding) {
                rows.push({
                    runs: [{text: l}],
                    style: DocHelper.STYLE.H3,
                    level: 4,
                    editable: false
                });
                ((Array.isArray(finding[f].rows) && finding[f].rows.length) ? finding[f].rows : [{}]).forEach(
                    (r, i) => r && (i ? rows.push(r) : rows.push({...r, required: true}))
                ); // make sure we always have at least one row that's editable but not removable
            }
        }
        if (Array.isArray(finding.sections)) {
            for (const s of finding.sections) {
                rows.push({
                    runs: [{text: s.label}],
                    style: DocHelper.STYLE.H3,
                    level: 4,
                    editable: false
                }); // copy the findings, ensuring the first one is always required
                ((Array.isArray(s.data) && s.data.length) ? s.data : [{}]).forEach(
                    (r, i) => r && (i ? rows.push(r) : rows.push({...r, required: true}))
                );
            }
        }

        return {
            errors: [],
            docIL: docIL,
            data: finding,
            rows: rows,
            status: Object.fromEntries(Object.keys(finding ? finding : {}).map(k => [k, true])),
            loading: false,
            closing: false,
        };
    }
    reset = (callback = () => {}) => this.setState(this.getStateFromProps(), callback);
    save() {
        // TODO: save stuff
        alert('Not Implemented');
    }
    stateFilter = k => k !== "data" && k !== "status";
    shouldComponentUpdate = (nextProps, nextState) => shallowCompare(this, nextProps, nextState, this.stateFilter);
    render = () => {
        const open = this.state.data != null;
        const { finding } = this.props;

        return (<>
            {typeof document !== 'undefined' && ReactDOM.createPortal(
                <ComposedModal
                    className="xff-editor-wrap" // the prop names are confusing, this ones actually the container :/
                    containerClassName="xff-editor"
                    open={open} size="lg"
                    aria-label="Finding Template Editor"
                    preventCloseOnClickOutside={true}
                    onClose={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({closing: true});
                        return false;
                    }}
                >
                    <ModalHeader
                        label="Finding Template Editor" className="xfe-head"
                        title={<span ref={this.nameRef}>{open ? this.state.data.name : ''}</span>}
                        iconDescription="Close template editor"
                    />
                    <ModalBody className="xfe-body">
                        {/* TODO: Move this into its own component so we can validate without re-rendering the doc */}
                        <div className="xfe-left">
                            <FormGroup
                                hasMargin
                                invalid={false}
                                legendText="Finding Properties"
                                message={false}
                                messageText=""
                            >
                                <TextInput
                                    id="name" name="name" labelText="Name" defaultValue={this.state.data.name}
                                    onChange={e => {
                                        const val = e.target.value;
                                        this?.nameRun?.update && this.nameRun.update({text: val});
                                        this?.nameRef?.current && (this.nameRef.current.innerText = val);
                                        this.setState(s => {
                                            // QQ: Why are we editing state objects directly, what about immutability?
                                            // QA: We manually control rendering and state here as we don't want to trigger a
                                            //     full re-render of the whole document when updating the finding properties.
                                            //     This way we only ever re-render the bits we change, increasing efficiency.
                                            //     This means we also don't copy objects on every update, extra efficient! :)
                                            s.data.name = val;
                                            s.status.name = val.trim() !== '';
                                            return {data: s.data, status: s.status};
                                        });
                                    }}
                                /><br/>
                                <TextArea
                                    id="synopsis" name="synopsis" labelText="Synopsis" defaultValue={this.state.data.synopsis}
                                    onChange={e => {
                                        const val = e.target.value;
                                        this.setState(s => {
                                            s.data.synopsis = val;
                                            s.status.synopsis = val.trim() !== '';
                                            return {data: s.data, status: s.status};
                                        });
                                    }}
                                /><br/>
                                <TextArea
                                    id="solution" name="solution" labelText="Solution" defaultValue={this.state.data.solution}
                                    onChange={e => {
                                        const val = e.target.value;
                                        this.setState(s => {
                                            s.data.solution = val;
                                            s.status.solution = val.trim() !== '';
                                            return {data: s.data, status: s.status};
                                        });
                                    }}
                                /><br/>
                                <Dropdown
                                    id="base-risk"
                                    titleText="Base Risk"
                                    helperText="Minimal risk for this finding, regardless of context"
                                    label="Please choose a base risk..."
                                    items={RiskHelper.SELECTS}
                                    initialSelectedItem={RiskHelper.ID_ITEM[this.state.data.base_risk]}
                                    onChange={({ selectedItem }) => {
                                        const val = selectedItem.id;
                                        this.setState(s => {
                                            s.data.base_risk = val;
                                            s.status.base_risk = RiskHelper.CRITICAL.id >= val >= RiskHelper.INFO.id;
                                            return {data: s.data, status: s.status};
                                        });
                                    }}
                                /><br/>
                                <Dropdown
                                    id="contextual-risk"
                                    titleText="Contextual Risk"
                                    helperText="Actual risk for this finding, considering context"
                                    label="Please choose a contextual risk..."
                                    items={RiskHelper.SELECTS}
                                    initialSelectedItem={RiskHelper.ID_ITEM[this.state.data.contextual_risk]}
                                    onChange={({ selectedItem }) => {
                                        const val = selectedItem.id;

                                        this.setState(s => {
                                            s.data.contextual_risk = val;
                                            s.status.contextual_risk = RiskHelper.CRITICAL.id >= val >= RiskHelper.INFO.id
                                            return {data: s.data, status: s.status};
                                        }); // remember this won't trigger a render, so we do it below

                                        // update our node refs, no rendering as setState below re-renders everything anyway
                                        this.riskLbl && this.riskLbl.update && this.riskLbl.update({
                                            text: RiskHelper.getLabel(val), color: RiskHelper.getColour(val)
                                        }, false); // we re-render the parent below anyway, so no render here plz

                                        this.riskRun && this.riskRun.update && this.riskRun.update({
                                            style: DocHelper.RISK_STYLE[val]
                                        }, true);  // ok render now we've updated everything
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div className="xfe-main">
                            <div className="xfe-doc">
                                <Document
                                    context={this.context} ref={this.docRef} uuid={finding.uuid}
                                    sections={[BaseSectIL(this.state.rows)]} toolbarTooltipPosition="top"
                                    showFullScreen={true} showSpellCheck={true}
                                    showZoomSlider={true} showNavigation={false}
                                    saveDocumentCallback={() => this.context.addToast('error', 'Not Implemented', 'Yeah this doesn\'t do anything yet', 'Sorry...')}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </ComposedModal>,
                document.body
            )
            }
            {this.state.closing && typeof document !== 'undefined' && ReactDOM.createPortal(
                <Modal
                    danger open size="xs" modalLabel={open ? this.state.data.name : ''}
                    modalHeading="Are you sure?"
                    secondaryButtonText="Go Back"
                    primaryButtonText="Discard Changes"
                    shouldSubmitOnEnter={false}
                    preventCloseOnClickOutside={false}
                    onRequestClose={() => this.setState({closing: false})}
                    onSecondarySubmit={() => this.setState({closing: false})}
                    onRequestSubmit={() => this.onClose(true)}
                >This will discard any changes made to the finding template.</Modal>,
                document.body
            )}
        </>);
    }
}

export default FindingEditTear;