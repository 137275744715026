import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Clients
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/client';

    static createOrUpdate(id, client){
        const url =`${Clients.API_URL}/${id}`
        return axios.post(url, client).then(response => response.data);
    }

    static list(){
        const url = `${Clients.API_URL}/list`;
        return axios.get(url).then(response => response.data);
    }
}
