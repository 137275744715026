import React from 'react';
import DocHelper from "../../../helpers/Document";
import DocContext from "../../../helpers/DocContext";
import './image-run.scss';
import {newNodePropProxy} from "../../../helpers/Utility";
import {Button, Dropdown, TextInput, Toggle} from "carbon-components-react";
import {Edit16, TrashCan16} from "@carbon/icons-react";


class ImageRun extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        node: null, image: '', label: '', size: [], offset: [],
        align: DocHelper.ALIGN.CENTER, anchor: null,
        background: false, editable: true
    };
    static DIRECTION = {
        TL: 0, TM: 1, TR: 2, RM: 3, BR: 4, BM: 5, BL: 6, LM: 7
    };
    constructor(props) {
        super(props);
        this.proxy = newNodePropProxy(this);  // uses ilNode values if there, otherwise defaults to standard props
        this.baseRef = React.createRef();
        this.imgRef = React.createRef();
        this.direction = -1;
        this.width = -1;
        this.height = -1;
        this.max = 0;
        this.x = -1;
        this.y = -1;

        this.state = {open: false};
    }
    handleMouseMove = e => {
        if (!e.buttons) {
            this.direction = -1;
            return;
        }
        const x = e.clientX, y = e.clientY;
        if (!x || !y) return;

        // widthT = heightT * aspectRatio
        // heightT = widthT / aspectRatio
        const D = this.constructor.DIRECTION, aspectLocked = e.shiftKey;
        const xo = x - this.x, yo = y - this.y;
        let width = this.width, height = this.height;

        const aspectRatio = width / height;

        // yay maths!! XD ... joking... everyone hates maths, you know it, I know it, lets just move on shall we :)
        switch (this.direction) {
            default:
                return;

            // corners
            case D.TL:
                if (aspectLocked) {
                    if (xo > yo) {
                        height = height - yo;
                        width = height * aspectRatio;
                    } else {
                        width = width - xo;
                        height = width / aspectRatio;
                    }
                } else {
                    height = height - yo;
                    width = width - xo;
                }
                break;
            case D.TR:
                if (aspectLocked) {
                    if (xo > yo) {
                        height = height - yo;
                        width = height * aspectRatio;
                    } else {
                        width = width + xo;
                        height = width / aspectRatio;
                    }
                } else {
                    height = height - yo;
                    width = width + xo;
                }
                break;
            case D.BR:
                if (aspectLocked) {
                    if (xo > yo) {
                        height = height + yo;
                        width = height * aspectRatio;
                    } else {
                        width = width + xo;
                        height = width / aspectRatio;
                    }
                } else {
                    height = height + yo;
                    width = width + xo;
                }
                break;
            case D.BL:
                if (aspectLocked) {
                    if (xo > yo) {
                        height = height + yo;
                        width = height * aspectRatio;
                    } else {
                        width = width + xo;
                        height = width / aspectRatio;
                    }
                } else {
                    height = height + yo;
                    width = width - xo;
                }
                break;

            // edges
            case D.TM:
                height = height - yo;
                if (aspectLocked) width = height * aspectRatio;
                break;
            case D.RM:
                width = width + xo;
                if (aspectLocked) height = width / aspectRatio;
                break;
            case D.BM:
                height = height + yo;
                if (aspectLocked) width = height * aspectRatio;
                break;
            case D.LM:
                width = width - xo;
                if (aspectLocked) height = width / aspectRatio;
                break;
        }

        if ((this.max > 0) && width > this.max) return; // cancel if the image is too wide :)

        const { node, size } = this.proxy;

        if (!size || (size.length < 2) || (width !== size[0]) || (height !== size[1])) {
            const { ctx } = this.context;
            node.update({size: [
                DocHelper.mmToEmu(DocHelper.mmFromPixels(width, ctx.ppi)),
                DocHelper.mmToEmu(DocHelper.mmFromPixels(height, ctx.ppi))
            ]});
            this.forceUpdate();
        }
    }
    setDirection = (e, direction) => {
        this.cancelEvent(e);

        const { size } = this.proxy;
        const { ctx } = this.context;

        // we store our original aspect ratio in case the user holds shift after starting the resize
        // this way we can resize with a locked aspect ratio using the original image dimensions :)
        if (size && Array.isArray(size) && size.length > 1) {
            this.width = DocHelper.mmToPixels(DocHelper.mmFromEmu(size[0]), ctx.ppi);
            this.height = DocHelper.mmToPixels(DocHelper.mmFromEmu(size[1]), ctx.ppi);
        } else {
            this.width = this.imgRef?.current?.clientWidth;
            this.height = this.imgRef?.current?.clientHeight;
        }

        this.max = this.baseRef?.current?.clientWidth;
        this.direction = direction;
        this.x = e.clientX;
        this.y = e.clientY;

        document.addEventListener('mousemove', this.handleMouseMove, {capture: true});
        document.addEventListener('mouseup', this.unsetDirection, {capture: true});
        return false;
    }
    unsetDirection = e => {
        this.direction = -1;
        e && this.cancelEvent(e);
        document.removeEventListener('mousemove', this.handleMouseMove, {capture: true});
        document.removeEventListener('mouseup', this.unsetDirection, {capture: true});
        return false;
    }
    cancelEvent = e => {
        e.stopPropagation();
        e.preventDefault();
        if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
        else e.stopImmediatePropagation();
    }
    updateNode = props => {
        const { node } = this.proxy;
        node.update(props, true, node.paginate);
    }
    render() {
        // image size dimensions are in EMUs (english metric units, same as word)
        const { node, image, label, editable: _editable, size, offset, background, align, anchor } = this.proxy;
        const { ctx } = this.context;

        const editable = _editable && !node.preview;

        const showHandles = !background;
        let width = 'auto', height = 'auto';

        let styles = {}, _styles = {}, _classes = 'xfp-img', classes = 'xfp-image';
        if (size && Array.isArray(size) && size.length > 0) {
            styles['width'] = DocHelper.mmToPixels(DocHelper.mmFromEmu(size[0]), ctx.ppi) + 'px';
            if (size.length > 1) {
                styles['height'] = DocHelper.mmToPixels(DocHelper.mmFromEmu(size[1]), ctx.ppi) + 'px';
            }
            width = styles['width'];
            height = styles['height'];
        }
        if (background) {
            _classes += ' xbg';
            classes += ' xbg';
            _styles['margin'] = -DocHelper.mmToPixels(ctx.margin, ctx.ppi);
            _styles['width'] = DocHelper.mmToPixels(ctx.width, ctx.ppi);
            _styles['height'] = DocHelper.mmToPixels(ctx.height, ctx.ppi);
            styles['width'] = _styles['width'];
            styles['height'] = _styles['height'];
            width = styles['width'];
            height = styles['height'];
        }
        if (align) _classes += ' ' + DocHelper.ALIGN_CLASSES[align];

        // TODO: Fix image page anchor and offset in document preview / editor,
        //       maybe make offset use mm for user-input and convert internally?
        // eslint-disable-next-line default-case
        switch(anchor) {
            case DocHelper.ANCHOR.BottomLeft:
                classes += ' abl';
                break;
            case DocHelper.ANCHOR.BottomRight:
                classes += ' abr';
                break;
            case DocHelper.ANCHOR.TopRight:
                classes += ' atr';
                break;
            case DocHelper.ANCHOR.TopLeft:
                classes += ' atl';
                break;
        }
        const D = this.constructor.DIRECTION;
        const { open } = this.state;
        const selAnchor = DocHelper.ANCHOR_ITEMS[anchor ? anchor : 0];
        const hasOffset = offset && (offset.length > 1);


        // Yes we bypass the state here, that's for a very good reason, shhh!
        return <div ref={this.baseRef} className={_classes} style={_styles} id={node.type + '-' + node.uuid} key={node.uuid} onMouseUp={() => showHandles && this.unsetDirection()}>
            <div className="xfi-wrap" style={styles}>
                <img ref={this.imgRef} width={width} height={height} className={classes} alt={label} src={image}/>
                {editable && showHandles && <> {/* we go clockwise round for the resize handles */}
                    <span className="xfi-size xfs-tl corner" onMouseDown={e => this.setDirection(e, D.TL)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-tm edge" onMouseDown={e => this.setDirection(e, D.TM)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-tr corner" onMouseDown={e => this.setDirection(e, D.TR)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-rm edge" onMouseDown={e => this.setDirection(e, D.RM)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-br corner" onMouseDown={e => this.setDirection(e, D.BR)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-bm edge" onMouseDown={e => this.setDirection(e, D.BM)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-bl corner" onMouseDown={e => this.setDirection(e, D.BL)} onMouseUp={() => this.unsetDirection()}/>
                    <span className="xfi-size xfs-lm edge" onMouseDown={e => this.setDirection(e, D.LM)} onMouseUp={() => this.unsetDirection()}/>
                </>}
                {editable && <div className="xfi-actions">
                    <div className="xfi-acts">
                        <Button className="xfi-act"
                                tooltipPosition={'right'} tooltipAlignment={'center'}
                                hasIconOnly renderIcon={TrashCan16} disabled={!editable}
                                size={'sm'} kind="danger"
                                iconDescription="Remove Image"
                                onClick={() => {
                                    const parent = node.parent;
                                    node.remove(true);
                                    parent && parent.rerender && parent.rerender();
                                }}
                        />
                        <Button className="xfi-act"
                                tooltipPosition={'right'} tooltipAlignment={'center'}
                                hasIconOnly renderIcon={Edit16} disabled={!editable}
                                size={'sm'} kind="secondary"
                                iconDescription="Edit"
                                onClick={() => this.setState({open: !open})}
                        />
                    </div>
                    {open && <div className="xfi-act-wrap">
                        <Toggle id="bg" size="sm" toggled={background} labelText="Background" labelA="No" labelB="Yes" onToggle={b => this.updateNode({background: b})} />
                        <Dropdown
                            id="anchor" size="sm" titleText="Page Anchor" label="Anchor..." disabled={background}
                            items={DocHelper.ANCHOR_ITEMS} initialSelectedItem={selAnchor} selectedItem={selAnchor}
                            onChange={({selectedItem}) => this.updateNode({anchor: selectedItem.id})}
                        />
                        <TextInput
                            id="offset-x" size="sm" labelText="Offset X" value={hasOffset ? offset[0] : 0} disabled={background}
                            onChange={e => this.updateNode({offset: [parseInt(e.target.value) || 0, hasOffset ? offset[1] : 0]})}
                        />
                        <TextInput
                            id="offset-y" size="sm" labelText="Offset Y" value={hasOffset ? offset[1] : 0} disabled={background}
                            onChange={e => this.updateNode({offset: [hasOffset ? offset[0] : 0, parseInt(e.target.value) || 0]})}
                        />
                    </div>}
                </div>}
            </div>
        </div>;
        // {
        //     image: TYPES.STRING, background: TYPES.BOOL, size: TYPES.LIST,
        //     offset: TYPES.LIST, anchor: TYPES.ANCHOR, wrap: TYPES.WRAP,
        //     behind: TYPES.BOOL, overlap: TYPES.BOOL, lock: TYPES.BOOL,
        //     zIndex: TYPES.NUMBER, inCell: TYPES.BOOL, margins: TYPES.OBJECT
        // }
    }
}

export default ImageRun;