import React from 'react';
import DocHelper from "../../../helpers/Document";
import DocContext from "../../../helpers/DocContext";
import './table-run.scss';
import {newNodePropProxy} from "../../../helpers/Utility";
import {ILNode} from "../../il/Nodes";
import {TrashCan16} from "@carbon/icons-react";
import {Button} from "carbon-components-react";


// TODO: TableRun cannot currently insert new rows underneath as there is no way to select "after" the table like word
class TableRun extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        node: null, editable: true, cells: [], widths: [],
        style: DocHelper.STYLE.TABLE, align: DocHelper.ALIGN.LEFT
    };
    constructor(props) {
        super(props);
        this.proxy = newNodePropProxy(this);  // uses ilNode values if there, otherwise defaults to standard props
        this.baseRef = React.createRef();
        this.heads = [];
        this.moveIdx = -1;
    }
    get editable() {
        const { node, editable } = this.proxy;
        return editable && !node.preview;
    }
    handleMouseMove = e => {
        if (!this.editable || !e.buttons) return this.stopResize();
        if ((this.moveIdx < 0) || (this.heads.length <= this.moveIdx)) return;
        const header = this.heads[this.moveIdx]?.current;
        if (!header) return;
        const rect = header.getBoundingClientRect();
        if (!rect || !rect.x) return;

        const { ctx } = this.context;
        const ppi = ctx.ppi;
        const dif = e.clientX - rect.x;
        const width = DocHelper.mmToDxa(DocHelper.mmFromPixels(dif, ppi));

        if (!width) return;
        const { node } = this.proxy;
        const i = this.moveIdx;

        if (!node.il['widths']) node.il['widths'] = [];
        if (node.il['widths'].length < i) node.il['widths'] = Array.from(
            {length: i + 1},
            (_, i) => (i >= node.il['widths'].length) ? 0 : node.il['widths'][i]
        );
        node.il['widths'][i] = width;
        this.forceUpdate();
    }
    removeWidth = i => {
        if (!this.editable) return;
        const { node } = this.proxy;
        if (node.il['widths']?.length <= i) return;
        node.il['widths'][i] = 0;
        this.forceUpdate();
    }
    // QQ: Why do these listen on the document?
    // QA: For a smooth resizing experience we need to be able to monitor the users mouse position until they
    //     let go of the mouse, which we can't do within this element, so we use document event listeners :)
    stopResize = () => {
        if (!this.editable) return;
        this.moveIdx = -1;
        document.removeEventListener('mousemove', this.handleMouseMove, {capture: true});
        document.removeEventListener('mouseup', this.stopResize, {capture: true});
        return false;
    }
    initResize = i => {
        if (!this.editable) return; // these checks are pretty unnecessary as these should never get called
        this.moveIdx = i;
        document.addEventListener('mousemove', this.handleMouseMove, {capture: true});
        document.addEventListener('mouseup', this.stopResize, {capture: true});
        return false;
    }
    render() {
        const { node, cells, widths, style, editable, align, ...otherProps } = this.proxy;
        const { ctx } = this.context;
        const ppi = ctx.ppi;

        const className = "xfp-table s-" +
            DocHelper.STYLE_CLASSES[style] + (editable ? ' can-edit' : ' readonly') +
            ((align in DocHelper.ALIGN_CLASSES) ? DocHelper.ALIGN_CLASSES[align] : '');

        const headers = cells[0];
        const body = cells.slice(1);

        if (!headers || !body) return null;
        const headChildren = headers.children;
        const hasAutoWidth = (widths.length < headChildren.length) || widths.includes(0);

        return <table
            ref={this.baseRef} key={node.uuid} data-uuid={node.uuid} id={'table-' + node.uuid} className={className}
            onMouseUp={() => this.stopResize()} style={hasAutoWidth ? {width: '100%'} : {}}
            {...otherProps}>
            <thead>
            <tr>
                {headChildren.map((header, i) => {
                    while (i >= this.heads.length) this.heads.push(React.createRef());
                    const ref = this.heads[i];
                    const width = ((i in widths) && (widths[i] > 0)) ? DocHelper.mmToPixels(DocHelper.mmFromDxa(widths[i]), ppi) : 0;
                    return <th key={i} ref={ref}
                        style={width > 0 ? {width: DocHelper.mmToPixels(DocHelper.mmFromDxa(widths[i]), ppi)} : {}}>
                        <div className="xft-col-act">
                            <Button className="xft-col-btn xft-col-del" disabled={!editable || node.required}
                                    tooltipPosition="top" tooltipAlignment="center"
                                    hasIconOnly renderIcon={TrashCan16}
                                    size="sm" kind="danger--ghost"
                                    iconDescription="Delete Column"
                                    onClick={() => node.delColumn(i)}
                            />
                        </div>
                        <span
                            style={{fontSize: DocHelper.mmToPixels(DocHelper.mmFromEmu(DocHelper.emuFromPt(11)), ppi)}}>
                            {header && (header instanceof ILNode) ? header.render() : header}
                        </span>
                        {this.editable && <div className="xft-col-size">
                            <span className="xft-cs-handle" onMouseDown={() => this.initResize(i)}
                                  onMouseUp={() => this.stopResize()} onDoubleClick={() => this.removeWidth(i)}/>
                        </div>}
                    </th>;
                })}
            </tr>
            </thead>
            <tbody>
            {body.map((row, i) => <tr key={i}>
                {row.children.map((cell, j) => {
                    const width = ((j in widths) && (widths[j] > 0)) ? DocHelper.mmToPixels(DocHelper.mmFromDxa(widths[j]), ppi) : 0;
                    return <td key={j} style={width > 0 ? {width: DocHelper.mmToPixels(DocHelper.mmFromDxa(widths[j]), ppi)} : {}}>
                        <span
                            style={{fontSize: DocHelper.mmToPixels(DocHelper.mmFromEmu(DocHelper.emuFromPt(9)), ppi)}}>
                            {cell && (cell instanceof ILNode) ? cell.render() : cell}
                        </span>
                    </td>;
                })}
            </tr>)}
            </tbody>
        </table>;
    }
}

export default TableRun;