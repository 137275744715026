import React from 'react';
import './toc.scss';
import DocHelper from "../../../helpers/Document";
import DocContext from "../../../helpers/DocContext";

// TODO: Implement TableOfContents
class TableOfContents extends React.PureComponent {
    static contextType = DocContext;
    static defaultProps = {
        sid: 0, pid: 0, rid: 0, size: DocHelper.FONT_SIZE,
        color: null, bold: null, italic: null, underline: null, highlight: null, link: null, linkLbl: null
    };
    render() {
        const {
            sid: s_idx, pid: p_idx, rid: r_idx, color, bold, italic, underline, highlight, size, link, linkLbl, children
        } = this.props;
        const styles = {
            fontSize: DocHelper.mmToPixels(DocHelper.mmFromEmu(DocHelper.emuFromPt(
                typeof size == "number" ? (size / 2) : DocHelper.FONT_SIZE
            )), this.context.ctx.ppi)
        };
        if (color)  styles['color'] = color;
        if (bold)   styles['fontWeight'] = '600';
        if (italic) styles['fontStyle'] = 'italic';
        if (underline) styles['textDecoration'] = 'underline';
        if (highlight) styles['background'] = highlight;

        const rid = DocHelper.getRunId(s_idx, p_idx, r_idx || 0);
        const lbl = linkLbl == null ? link : linkLbl;

        return <span className="xfp-run" style={styles} id={rid} key={rid}>
            {link == null ? children : <a
                href={link} rel="noreferrer" target="_blank" title={lbl} aria-label={lbl}
            >{children}</a>}
        </span>;
    }
}

export default TableOfContents;