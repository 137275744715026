import React from 'react';
import './doc-editor-guide.scss';


class DocEditorGuide extends React.Component {
    static defaultProps = {className: ''};
    render() {
        const { className } = this.props;

        return (
            <div className={"xfd-guide " + className}>
                <div className="xfg-left">
                    <div className="xfg-help">
                        <h3>Information</h3>
                        <p>
                            The document editor tries to emulate the word processing experience people are used to,
                            however there are some limitations due to the way browsers operate so some functionality
                            is limited. Basic document elements are supported, tables, images, paragraphs, lists etc.
                            The usual inline-formatting options are also available such as highlighted text, fonts,
                            subscript, superscript, italic, underline, bold, text alignment, indenting, and both
                            inline and block styles. Styles are static and not currently editable.
                        </p>
                        <p>
                            When pasting content from outside sources into the editor any supported features are added
                            to the document after the current cursor position. If content is missing after pasting this
                            is likely due to it not currently being supported (such as shapes/objects).
                        </p>
                        <p>
                            Use the attachment button on the toolbar to add either images or hyperlinks, if there is
                            text selected the button will allow a hyperlink to be added, otherwise it will allow images
                            to be uploaded and added, alternatively images or screenshots can be pasted directly into
                            the editor. Images pasted or uploaded will also have a caption added automatically, images
                            embedded in pasted rich text content will not. Tables can be inserted using the toolbar
                            and are inserted as a new row in the document.
                        </p>
                        <p>
                            Browsers do not currently support (or no longer support) multiple selection ranges. Due
                            to this the selected text may appear unselected when activating the toolbar or moving the
                            caret to another component outside the editor. When this happens the document editor will
                            remember the last cursor position and restore it before applying any modifications.
                        </p>
                        <p>
                            The current document state, undo/redo history, and caret position are saved automatically
                            and restored if the browser window is closed without saving the document or discarding
                            changes. There is a storage size limit on history data so the number of edits retained in the
                            log will vary from device to device and is entirely dependent on the size of each edit log.
                        </p>
                    </div>
                </div>
                <div className="xfg-right">
                    <div className="xfg-hotkeys">
                        <h3>Hotkeys</h3>
                        <div className="xfh-key-wrap">
                            <div className="xfh-keys">
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Enter</kbd></div>
                                    <div className="xfk-action">New paragraph</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Shift</kbd> + <kbd>Enter</kbd></div>
                                    <div className="xfk-action">New line in the same paragraph</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Enter</kbd></div>
                                    <div className="xfk-action">New line on a new page (page break)</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Tab</kbd></div>
                                    <div className="xfk-action">Increase indent / Next cell in table</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Shift</kbd> + <kbd>Tab</kbd></div>
                                    <div className="xfk-action">Decrease indent / Previous cell in table</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Alt</kbd> + <kbd>Backspace</kbd></div>
                                    <div className="xfk-action">Remove the last row in a table (if empty)</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>C</kbd></div>
                                    <div className="xfk-action">Copy as formatted content</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>C</kbd></div>
                                    <div className="xfk-action">Copy as plain text</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>X</kbd></div>
                                    <div className="xfk-action">Cut as formatted content</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>X</kbd></div>
                                    <div className="xfk-action">Cut as plain text</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>V</kbd></div>
                                    <div className="xfk-action">Paste as formatted content</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>V</kbd></div>
                                    <div className="xfk-action">Paste as plain text</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Z</kbd></div>
                                    <div className="xfk-action">Undo changes</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></div>
                                    <div className="xfk-action">Redo changes</div>
                                </div>
                            </div>
                            <div className="xfh-keys basic">
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>A</kbd></div>
                                    <div className="xfk-action">Select all</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>S</kbd></div>
                                    <div className="xfk-action">Save changes</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>B</kbd></div>
                                    <div className="xfk-action">Make selected text bold</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>I</kbd></div>
                                    <div className="xfk-action">Make selected text italic</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>U</kbd></div>
                                    <div className="xfk-action">Make selected text underlined</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>+</kbd></div>
                                    <div className="xfk-action">Make selected text superscript</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>=</kbd></div>
                                    <div className="xfk-action">Make selected text subscript</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>L</kbd></div>
                                    <div className="xfk-action">Left-align selected paragraphs</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>E</kbd></div>
                                    <div className="xfk-action">Center-align selected paragraphs</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>R</kbd></div>
                                    <div className="xfk-action">Right-align selected paragraphs</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>J</kbd></div>
                                    <div className="xfk-action">Justify-align selected paragraphs</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>{'>'}</kbd></div>
                                    <div className="xfk-action">Increase font size</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>{'<'}</kbd></div>
                                    <div className="xfk-action">Decrease font size</div>
                                </div>
                                <div className="xfh-key">
                                    <div className="xfk-hotkey"><kbd>Ctrl</kbd> + <kbd>Y</kbd></div>
                                    <div className="xfk-action">Redo changes (alternate hotkey)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocEditorGuide;