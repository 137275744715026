import React from 'react';
import { withRouter } from 'react-router-dom';
import './contact-types.scss';
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table, TableBatchAction, TableBatchActions,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow, TableSelectAll, TableSelectRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from "carbon-components-react";
import ContactTypeEditModal from "../ContactTypeEditModal";
import Types from '../../backend/Types'
import SessionContext from "../../helpers/SessionContext";
import Loading from "../Loading";
import {Edit16, Delete16} from "@carbon/icons-react";
import {v4 as uuidv4} from "uuid";
import {RemoveModal} from "@carbon/ibm-cloud-cognitive";

class ContactType extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        headers: [
            {
                key: 'name',
                header: 'Contact Type',
            },
        ]
    };
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            data: {},
            rows: [],
            delete_batch: null,
            open_delete: false,
            load_modal: false,
            editing: false,
            loading: false
        };

        this.tableRef = React.createRef();
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        Types.contacts().then(d => {
            if (!this.mounted) return;

            if (!d.success) {
                this.setState({rows: [], data: {}, total: 0, loading: false});
                if (d.error) {
                    this.context.addToast('error', 'An Error Occurred', 'Error retrieving contact data', d.error);
                }
                return;
            }

            this.setState({...this.parseDataForState(d.contact_types), loading: false});

        }).catch(this.context.networkFaultHandler);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    parseDataForState(data) {
        const rows = this.dataToRows(data);
        return {rows: rows, data: data, total: rows.length};
    }

    dataToRows(data) {
        return Object.values(data).map(c => ({
            id: c.uuid, name: c.name,
        }));
    }

    deleteContactTypes(){
        if(this?.state?.delete_batch?.length){
            let uuids = this.state.delete_batch
            let body = {'uuids': uuids}
            Types.deleteContacts(body).then(result => {
                if (result.success) {
                    this.setState({
                        to_delete: null,
                        open_delete: false
                    })
                    //We edit the state of the DataTable due to the bug where the batch actions
                    //toolbar remains active after the selected rows are deleted.
                    this.setState(s =>
                        this.parseDataForState(Object.fromEntries(Object.entries(s.data).filter(([id]) => !uuids.includes(id)))),
                        () => {this.tableRef?.current && this.tableRef.current.setState({shouldShowBatchActions: false})}
                    )
                }
                else this.context.networkFaultHandler(result.success)
            }).catch(this.context.networkFaultHandler);
        }
    }

    removeTempItems(){
        let temp_removed = Object.fromEntries(
            Object.entries(this.state.data).filter(([_, item]) => !item.uuid.startsWith('temp'))
        )
        this.setState(s => this.parseDataForState(temp_removed))
    }

    render() {
        const { headers: propHeaders } = this.props;
        const { match } = this.props;
        const uuid = match && match.params && match.params.uuid ? match.params.uuid : null;
        //TODO: Where to put the below? Add to a helper?
        // Use state variable for tracking a new item, update modal to set a default key, generate uuid in backend
        // if(this.state.data && uuid && !(uuid in this.state.data)) {
        //     this.context.addToast('error', 'An Error Occurred', 'Item does not exist',
        //         'An item with the specified UUID does not exist.')
        // }
        return (
            <div className="xf-ft-wrap">
                {this.state.load_modal && <Loading hasLogo={true} modal={true} message="Loading..." />}
                <div className="xft-left">
                    {this.state.loading ? <DataTableSkeleton columnCount={propHeaders.length} /> : <div>
                        <DataTable rows={this.state.rows} headers={propHeaders} isSortable size="md" ref={this.tableRef}>
                            {({
                                  rows, headers, getHeaderProps, getTableProps, getRowProps,
                                  onInputChange, getToolbarProps, getBatchActionProps, getSelectionProps, selectedRows
                              }) => (
                                <TableContainer stickyHeader={true}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableBatchActions {...getBatchActionProps()}>
                                            <TableBatchAction
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                                                renderIcon={Delete16}
                                                onClick={() => { this.setState({
                                                    delete_batch: selectedRows.map(sr => sr.id),
                                                    open_delete: true
                                                })}}
                                            >Delete</TableBatchAction>
                                        </TableBatchActions>
                                        <TableToolbarContent>
                                            <TableToolbarSearch
                                                persistent={true} placeholder="Start typing to filter..."
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                            <Button
                                                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                onClick={() => {
                                                    let temp_contact = {
                                                    'uuid': 'temp-' + uuidv4(),
                                                    'name': ''
                                                    }
                                                    this.setState(
                                                        s => this.parseDataForState(
                                                            {...s.data, [temp_contact.uuid]: temp_contact}),
                                                        () => this.props.history.push('/manage/types/contact/' + temp_contact.uuid)
                                                    )}}
                                                size='small'
                                                kind='primary'
                                            >New Contact Type</Button>
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                <TableSelectAll {...getSelectionProps()}/>
                                                {headers.map((header) => (
                                                    <TableHeader
                                                        {...getHeaderProps({ header })}
                                                        className={'xfh-' + header.key}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                                <TableHeader/>
                                                <TableHeader/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow {...getRowProps({ row })} key={row.id}>
                                                    <TableSelectRow {...getSelectionProps({row})}/>
                                                    {row.cells.map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className={'xfc-' + cell.info.header}
                                                        >{cell.value}</TableCell>
                                                    ))}
                                                    <TableCell className="xf-act-wrap">
                                                        <div className="xf-actions">
                                                            <Button className="xff-act"
                                                                tooltipPosition="top" tooltipAlignment="end"
                                                                hasIconOnly renderIcon={Edit16}
                                                                size="sm" kind="ghost"
                                                                iconDescription="Edit Contact Type"
                                                                onClick={() =>
                                                                    this.props.history.push('/manage/types/contact/' + row.id)
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="xf-act-wrap">
                                                        <div className="xf-actions">
                                                            <Button className="xff-act"
                                                                tooltipPosition="top" tooltipAlignment="end"
                                                                hasIconOnly renderIcon={Delete16}
                                                                size="sm" kind="ghost"
                                                                iconDescription="Delete Contact Type"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        delete_batch: [row.id],
                                                                        open_delete: true
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div> }
                </div>
                {this.state.data && uuid && (uuid in this.state.data) && <ContactTypeEditModal
                    contactType={this.state.data[uuid]}
                    onClose={f => {
                        this.removeTempItems()
                        if(f) {
                            this.setState(
                                s => this.parseDataForState({...s.data, [f.uuid]: f}),
                                () => this.props.history.push('/manage/types/contacts')
                            )
                        }
                        else this.props.history.push('/manage/types/contacts')
                    }}
                />}
                { this.state.delete_batch && this.state.open_delete && <RemoveModal
                    body={"Removing the selected " + this.state.delete_batch.length +
                    " item(s) will permanently delete them. This action cannot be undone."}
                    className="remove-modal-test"
                    iconDescription="close"
                    open={true}
                    inputInvalidText="A valid value is required"
                    inputLabelText="Type confirm delete to continue"
                    inputPlaceholderText="confirm delete"
                    label="Remove selected items"
                    onRequestSubmit={ () => {
                        this.deleteContactTypes()
                    }}
                    onClose={ () => this.setState( {
                        delete_batch: null,
                        open_delete: false
                    })}
                    preventCloseOnClickOutside
                    primaryButtonText="Remove"
                    resourceName="confirm delete"
                    secondaryButtonText="Close"
                    title="Confirm removal"
                    textConfirmation={true}
                />}
            </div>
        );
    }
}

export default withRouter(ContactType);