import React from 'react';
import './paragraph.scss';
import DocHelper from "../../helpers/Document";
import DocContext from "../../helpers/DocContext";
import ContentEditable from "react-contenteditable";
import {renderToStaticMarkup} from "react-dom/server";
import {newNodePropProxy} from "../../helpers/Utility";


class Paragraph extends React.Component {
    static contextType = DocContext;
    static defaultProps = {
        style: DocHelper.STYLE.NORMAL, align: DocHelper.ALIGN.LEFT, spacing: null, level: 0, indent: 0,
        break: false, runs: [], node: null, editable: true, focusRef: null, ghostRef: null, ceProps: {}
    };
    constructor(props) {
        super(props);

        this.focusRef = this.props.focusRef == null ? React.createRef() : this.props.focusRef;
        this.ghostRef = this.props.ghostRef == null ? React.createRef() : this.props.ghostRef;
        this.state = {editing: false};
        this.proxy = newNodePropProxy(this);  // uses ilNode values if there, otherwise defaults to standard props
        this.baseRef = React.createRef();
        this.callbacks = {
            start: [], stop: [],
        };
    }
    componentWillUnmount() {
        const { node } = this.props;
        if(!node) return;
        if (node.flushPendingUpdates) node.flushPendingUpdates(); // make sure we save any changes before unmounting
    }
    // moves callback execution to the end of the JS event queue (i.e. execute after the UI thread is finished)
    requeue = callback => setTimeout(callback, 0);
    startEdit = (callback = () => {}) => {
        const { node, editable } = this.proxy;
        if (!editable || node.preview) return;
        const func = () => this.state.editing ? callback() : this.setState({editing: true}, () => this.requeue(callback));
        if(!node) return func();
        node.activate(node, func);
    }
    stopEdit = (callback = () => {}) => !this.state.editing ? callback() : this.setState({editing: false}, () => this.requeue(callback));
    isEditing = () => !!this?.state?.editing;
    get editing() {
        return this.isEditing();
    }
    get ref() {
        const { editable } = this.proxy;
        const { editing } = this.state;
        return (editable && editing) ? this.focusRef : this.ghostRef;
    }
    render() {
        const {
            node, focusRef, ghostRef, ceProps, style: _style, spacing, runs, level, indent,
            break: pb, align, color, bold, italic, underline, highlight, editable: _editable, ...otherProps
        } = this.proxy;

        const style = _style in DocHelper.STYLE_CLASSES ? _style : DocHelper.STYLE.NORMAL;
        const editable = _editable && !node.preview;
        const { editing } = this.state;
        const { ctx } = this.context;
        const ppi = ctx.ppi;
        const styles = {};
        const classes = ['xfp', editable ? ' can-edit' : ' readonly', 's-' + DocHelper.STYLE_CLASSES[style]];

        if (pb) classes.push('pb');

        if(spacing) {
            if (spacing.before) {
                styles['marginTop'] = DocHelper.mmToPixels(DocHelper.mmFromDxa(spacing.before), ppi);
            }
            if (spacing.after) {
                styles['marginBottom'] = DocHelper.mmToPixels(DocHelper.mmFromDxa(spacing.after), ppi);
            }
        }
        // TODO: Remove these row-level props if they're never used, maybe? might be nice as a manual override *shrug*
        if (indent) styles['marginLeft'] = DocHelper.mmToPixels(DocHelper.mmFromDxa(indent * DocHelper.TAB_SIZE), ppi);
        if (color)  styles['color'] = color;
        if (bold)   styles['fontWeight'] = '600';
        if (italic) styles['fontStyle'] = 'italic';
        if (underline) styles['textDecoration'] = 'underline';
        if (highlight) styles['background'] = highlight;
        if (align in DocHelper.ALIGN_CLASSES) classes.push(DocHelper.ALIGN_CLASSES[align]);

        const runProps = {};
        switch (style) {
            default:
                break;
            case DocHelper.STYLE.COPYRIGHT:
            case DocHelper.STYLE.H1:
                runProps['size'] = 24;
                break;
            case DocHelper.STYLE.H2:
                runProps['size'] = 18;
                break;
            case DocHelper.STYLE.H3:
            case DocHelper.STYLE.H5:
            case DocHelper.STYLE.H6:
                runProps['size'] = 11;
                break;
            case DocHelper.STYLE.H4:
                runProps['size'] = 12; // no idea why h4 is larger, that's the size in our report theme :shrug:
                break;
        }

        const renderRun = (r, i) =>
            r.render && typeof r.render === "function" ? r.render({}, runProps) : <React.Fragment key={i}>{r}</React.Fragment>;


        // The data-readonly-msg attribute is used by CSS inside the ::before pseudo element to allow us to display
        // custom labels on hover without needing to insert them into the DOM. This leaves the DOM tree as clean as
        // possible so we can more efficiently traverse it when checking for changes in the ContentEditable areas.
        return <div
            ref={this.baseRef} key={node.uuid} {...otherProps} data-readonly-msg="Read Only"
            className={classes.join(' ')}
        >
            {editable && editing ?
            <ContentEditable
                ref={this.focusRef} style={styles} tagName="p" spellCheck={false} id={node.getDOMId()}
                data-uuid={node.uuid} className={'xfp-edit editable editing'} html={renderToStaticMarkup(runs.map(renderRun))}
                {...ceProps}
            />:
            <p
                ref={this.ghostRef} className={"xfp-row " + (editable ? 'can-edit' : 'readonly')}
                style={styles} id={node.getDOMId()} data-uuid={node.uuid}
            >
                {runs.map(renderRun)}
            </p>}
        </div>;
    }
}

export default Paragraph;