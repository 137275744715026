import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default class Types
{
    static API_URL = window.location.protocol + '//' + window.location.hostname + '/api/types';

    static findings(){
        const url = `${Types.API_URL}/findings`;
        return axios.get(url).then(response => response.data);
    }

    static createOrUpdateFinding(id, finding){
        const url = `${Types.API_URL}/finding/${id}`;
        return axios.post(url, finding).then(response => response.data);
    }

    static deleteFindings(uuids){
        const url = `${Types.API_URL}/findings/delete`;
        return axios.post(url, uuids).then(response => response.data);
    }

    static reports(){
        const url = `${Types.API_URL}/reports`;
        return axios.get(url).then(response => response.data);
    }
    static createOrUpdateReport(id, report){
        const url = `${Types.API_URL}/report/${id}`;
        return axios.post(url, report).then(response => response.data);
    }

    static deleteReport(id){
        const url = `${Types.API_URL}/report/delete/${id}`;
        return axios.delete(url).then(response => response.data);
    }

    static deleteReports(uuids){
        const url = `${Types.API_URL}/reports/delete`;
        return axios.post(url, uuids).then(response => response.data);
    }

    static contacts(){
        const url = `${Types.API_URL}/contacts`;
        return axios.get(url).then(response => response.data);
    }

    static createOrUpdateContact(id, contact){
        const url = `${Types.API_URL}/contact/${id}`;
        return axios.post(url, contact).then(response => response.data);
    }

    static deleteContacts(uuids){
        const url = `${Types.API_URL}/contacts/delete`;
        return axios.post(url, uuids).then(response => response.data);
    }

    static tests(){
        const url = `${Types.API_URL}/tests`;
        return axios.get(url).then(response => response.data);
    }

    static createOrUpdateTest(id, target){
        const url = `${Types.API_URL}/test/${id}`;
        return axios.post(url, target).then(response => response.data);
    }

    static deleteTests(uuids){
        const url = `${Types.API_URL}/tests/delete`;
        return axios.post(url, uuids).then(response => response.data);
    }

    static targets(){
        const url = `${Types.API_URL}/targets`;
        return axios.get(url).then(response => response.data);
    }

    static createOrUpdateTarget(id, target){
        const url = `${Types.API_URL}/target/${id}`;
        return axios.post(url, target).then(response => response.data);
    }

    static deleteTargets(uuids){
        const url = `${Types.API_URL}/targets/delete`;
        return axios.post(url, uuids).then(response => response.data);
    }
}
