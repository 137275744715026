import React from 'react';
import RiskHelper from "../../helpers/Risk";
import './risk-stat-bars.scss';
import {TooltipIcon} from "carbon-components-react";


class RiskStatBars extends React.Component {
    static baseRisks = Object.fromEntries(Object.keys(RiskHelper.RISKS).map(r => [r, 0]));
    static defaultProps = {risks: {...RiskStatBars.baseRisks}, limit: 7};
    render() {
        const { risks: propRisks, limit } = this.props;
        const bars = [], risks = Object.entries({...RiskStatBars.baseRisks, ...propRisks});
        let max = limit;

        for (const [, count] of risks) if (count > max) max = count;

        risks.map(
            ([risk, count], idx) =>
                bars.push(
                    <div className="xfr-bar-wrap" key={idx}>
                        <TooltipIcon
                            align="end" direction="top" className="xfb-tool"
                            tooltipText={`${RiskHelper.getLabel(risk)}: ${count}`}
                            renderIcon={() => // yeah, I know... works a treat though ;)
                                <div className={RiskHelper.getClass('xfr-bar xfb', risk)} style={{
                                    height: `${(Math.min(count, max) * (100 / max)).toFixed(5)}%`
                                }}/>
                            }
                        />
                    </div>
                )
        );

        return <div className="xf-risk-bars">{bars}</div>;
    }
}

export default RiskStatBars;