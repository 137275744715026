import React from 'react';
import {
    Content,
    SideNav,
    SideNavItems,
    SideNavMenuItem,
    SideNavLink, SideNavMenu
} from "carbon-components-react";
import SessionContext from "../../helpers/SessionContext";
import {Report16, TypePattern16, Template16, WarningOther16, Catalog16} from "@carbon/icons-react";
import {Link} from "react-router-dom";
import FindingTemplates from "../../components/FindingTemplates";
import './management.scss';
import ContactTypes from "../../components/ContactTypes";
import ReportTypes from "../../components/ReportTypes";
import TargetTypes from "../../components/TargetTypes"
import FindingTypes from "../../components/FindingTypes";
import TestTypes from "../../components/TestTypes";
import ReportSections from "../../components/ReportSections/ReportSections";

class Management extends React.Component {
    static contextType = SessionContext;

    // Most of these should use sub-components other than simple ones such as the placeholder
    renderPlaceholder = () => <div className="xfd-placeholder">Please choose an option from the menu on the left.</div>;
    renderFindingTemplates = () => <FindingTemplates/>;
    renderFindingTypes = () => <FindingTypes/>;
    renderContactTypes = () => <ContactTypes/>;
    renderReportTypes = () => <ReportTypes/>;
    renderTargetTypes = () => <TargetTypes/>;
    renderTestTypes = () => <TestTypes/>;
    renderReportSections = () => <ReportSections/>;

    // moved these around to tidy up the file a bit, much neater dontchya think ;)
    state = {
        links: [ // Got a blank screen? Don't forget to add your route to the Navigation helper under /manage :)
            {icon: Catalog16, label: 'Contract Catalog', link: '/manage/catalog', render: this.renderPlaceholder}, // TODO: implement /manage/catalog
            {icon: Report16, label: 'Report Templates', link: '/manage/reports', render: this.renderPlaceholder}, // TODO: implement /manage/reports
            {icon: Template16, label: 'Report Sections', link: '/manage/sections', render: this.renderReportSections},
            {icon: WarningOther16, label: 'Finding Templates', link: '/manage/findings', sub: '/manage/finding/', render: this.renderFindingTemplates},
            {icon: TypePattern16, label: 'Data Types', link: [
                {label: 'Contact Types', link: '/manage/types/contacts', sub: '/manage/types/contact/', render: this.renderContactTypes},
                {label: 'Finding Types', link: '/manage/types/findings', sub: '/manage/types/finding/', render: this.renderFindingTypes},
                {label: 'Report Types', link: '/manage/types/reports', sub: '/manage/types/report/', render: this.renderReportTypes},
                {label: 'Target Types', link: '/manage/types/targets', sub: '/manage/types/target/', render: this.renderTargetTypes},
                {label: 'Test Types', link: '/manage/types/tests', sub: '/manage/types/test/', render: this.renderTestTypes}
            ]}
        ]
    };

    render() {
        const { match } = this.props;
        const path = match && match.path ? match.path : '';
        let renderFunc = this.renderPlaceholder, isPlaceholder = true;

        const sideNavItems = [];

        for (const link of this.state.links) {
            if (Array.isArray(link.link)) {
                const subs = [];
                for (const sub of link.link) {
                    subs.push(<SideNavMenuItem element={Link} to={sub.link} key={sub.label}>{sub.label}</SideNavMenuItem>);
                    if (path === sub.link || (sub.sub && path.startsWith(sub.sub))) {
                        renderFunc = sub.render;
                        isPlaceholder = false;
                    }
                }
                sideNavItems.push(<SideNavMenu renderIcon={link.icon} title={link.label} key={link.label}>{subs}</SideNavMenu>);
                continue;
            }
            if (path === link.link || (link.sub && path.startsWith(link.sub))) {
                renderFunc = link.render;
                isPlaceholder = false;
            }
            sideNavItems.push(
                <SideNavLink renderIcon={link.icon} element={Link} to={link.link} isActive={path === link.link} key={link.link}>
                    {link.label}
                </SideNavLink>
            );
        }

        return (
            <Content className="xf-man">
                <div className="xfm-wrap">
                    <div className="xfm-head">
                        <h1>Data Management</h1>
                    </div>
                    <div className="xfm-main">
                        <div className="xfm-left">
                            <SideNav aria-label="Side navigation" isRail defaultExpanded={isPlaceholder} className="xfm-rail">
                                <SideNavItems>{sideNavItems}</SideNavItems>
                            </SideNav>
                        </div>
                        <div className="xfm-right">{renderFunc()}</div>
                    </div>
                </div>
            </Content>
        );
    }
}

export default Management;