import React from 'react';
import './contract-edit-tear.scss';
import SessionContext from "../../helpers/SessionContext";
import { FormGroup, Modal, TextInput, DatePicker, DatePickerInput} from "carbon-components-react";
import '@carbon/ibm-cloud-cognitive/scss/components/Tearsheet/_tearsheet.scss';
import Contract from '../../backend/Contracts'

class ContractEditTear extends React.Component {
    static contextType = SessionContext;
    static defaultProps = {
        contract: null, onClose: null, client: null
    };
    constructor(props) {
        super(props);
        const { contract } = this.props;
        const { client } = this.props;
        this.state = {
            contract: contract,
            name: '',
            budget: '',
            client: client,
            start_date: '',
            end_date: '',
            loading: false,
            closing: false
        };

        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
        this.onClose = this.onClose.bind(this);
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;
        if(this.state.contract){
            this.setState({
                name: this.state.contract.name || null,
                budget: this.state.contract.budget || null,
                start_date: this.state.contract.start_date || null,
                end_date: this.state.contract.end_date || null
            })
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    onClose() {
        const { onClose: callback } = this.props;
        if (callback) callback(this.state);
    }
    reset(callback = () => {}) {
        this.setState({
            name: '',
            budget: null,
            client: null,
            start_date: null,
            end_date: null,
            loading: false,
            closing: false,
        }, callback);
    }
    save() {
        let contract = {
            name: this.state.name,
            budget: this.state.budget,
            client: this.state.client.id,
            start_date: this.state.start_date,
            end_date: this.state.end_date
        }
        if(this.state.contract) {
            Contract.createOrUpdate(this.state.contract.uuid, contract)
                .then(result => {
                    this.setState({
                        updated_contract: result
                    })
                    this.onClose()
                })
                .catch(this.context.networkFaultHandler)
        }
        else {
            Contract.createOrUpdate('create', contract)
                .then(result => {
                    this.setState({
                        updated_contract: result
                    })
                    this.onClose()
                })
                .catch(this.context.networkFaultHandler)
        }
    }
    render() {
        const open = true;
        return (<>
            <Modal
                open={open}
                modalHeading={this.state.name}
                modalLabel="Contract Management"
                primaryButtonText={this.state.contract ? 'Save' : 'Create'}
                secondaryButtonText="Cancel"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    this.setState({closing: true})}
                }
                onRequestSubmit={(e) => this.save(e)}
                onSecondarySubmit={() => this.setState({closing: true})}
            >
                <div className='xts-tear'>
                    <FormGroup
                        hasMargin
                        invalid={false}
                        legendText="Contract Properties"
                        message={false}
                        messageText="hi"
                    >
                        <TextInput
                            id="name" name="name" labelText="Name" value={this.state.name}
                            placeholder="Please enter a contract name..."
                            invalid={!this.state.name.length}
                            invalidText="Contract name cannot be blank."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    name: val
                                }));
                            }}
                        /><br/>
                        <TextInput
                            id="budget"
                            name="budget"
                            labelText="Budget"
                            invalid={!this.state.budget.length}
                            invalidText="Budget cannot be blank."
                            value={this.state.budget}
                            placeholder="Please enter a budget..."
                            onChange={e => {
                                const val = e.target.value;
                                this.setState(s => ({
                                    budget: val
                                }));
                            }}
                        /><br/>
                        <DatePicker datePickerType="single" dateFormat="Y-m-d"
                                    onClose={(date, dateString) => {
                                        this.setState({
                                            start_date: dateString
                                        })
                                    }}>
                            <DatePickerInput
                                id="start-date"
                                placeholder="mm/dd/yyyy"
                                labelText="Start date"
                                invalid={!this.state.start_date.length}
                                invalidText="Start date cannot be blank."
                                value={this.state.start_date}
                                autoComplete="off"
                            />
                        </DatePicker>
                        <br/>
                        <DatePicker datePickerType="single" dateFormat="Y-m-d"
                                    onClose={(date, dateString) => {
                                        this.setState({
                                            end_date: dateString
                                        })
                                    }}>
                            <DatePickerInput
                                id="end-date"
                                placeholder="mm/dd/yyyy"
                                autoComplete="off"
                                labelText="End date"
                                invalid={!this.state.end_date.length}
                                invalidText="End date cannot be blank."
                                value={this.state.end_date}
                            />
                        </DatePicker>
                    </FormGroup>
                </div>
            </Modal>
            {this.state.closing && <Modal
                danger open size="xs" modalLabel={open ? this.state.name : ''}
                modalHeading="Are you sure?"
                secondaryButtonText="Go Back"
                primaryButtonText="Discard Changes"
                shouldSubmitOnEnter={false}
                onRequestClose={() => this.setState({closing: false})}
                onSecondarySubmit={() => this.setState({closing: false})}
                onRequestSubmit={this.onClose}
            >This will discard any changes.</Modal>}
        </>);
    }
}

export default ContractEditTear;