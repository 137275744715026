import React from 'react';
import {Button, FileUploaderDropContainer, FileUploaderItem, TextInput} from "carbon-components-react";
import {Attachment16, Link16} from "@carbon/icons-react";
import './docbar-attach.scss';
import SessionContext from "../../helpers/SessionContext";
import ImageHelper from "../../helpers/Image";


class DocBarAttach extends React.PureComponent {
    static contextType = SessionContext;
    static defaultProps = {
        link: '', className: '', size: 'md', collapsed: true,
        tooltipPosition: 'bottom', tooltipAlignment: 'center',
        onInsert: link => {},  onRemove: link => {},  onAttach: init => {}
    };
    state = {link: '', open: false};
    constructor(props) {
        super(props);
        const { link } = this.props;
        this.state = {
            link: link ? link : '', open: false, files: {}
        };
    }
    handleAddFiles = files => {
        // TODO: Pass image files to service worker for resizing

        const { onAttach } = this.props;

        ImageHelper.processImageFiles(
            _files => {
                onAttach && onAttach(true);
                this.setState(s => ({files: {...s.files, ..._files}}));
            },
            (uuid, updates) =>
                this.setState(s => ({files: {...s.files, [uuid]: {...s.files[uuid], ...updates}}})),
            files => {
                onAttach(false, ...files);
                this.setState({open: false, files: {}});
            },
            this.context.addToast, ...files
        );

        // const _files = {}, compress = [];
        // for (const file of files) {
        //     const invalid = !file || !file?.type?.match || !file.type.match(/image.*/);
        //
        //     const uuid = uuid4();
        //     _files[uuid] = {
        //         uuid: uuid, file: file, name: file.name, type: file.type, valid: !invalid,
        //         error: invalid ? 'Invalid attachment' : null, message: invalid ? 'Unsupported type' : null,
        //         status: invalid ? 'edit' : 'uploading',  // 'uploading' 'edit' 'complete'
        //     }
        //
        //     if (invalid) {
        //         this.context.addToast('error', 'Invalid attachment', 'Only image file types are currently supported as attachments', 'Please try again');
        //         continue;
        //     }
        //
        //     compress.push(_files[uuid]);
        // }
        //
        // const setState = s => this.setState(s);
        // const count = {i: 0}, length = compress.length;
        // const fin = () => {
        //     onAttach(false, ...Object.values(this.state.files));
        //     this.setState({open: false, files: {}});
        // };
        //
        // for (const file of compress) {
        //     const size = {width: 0, height: 0};
        //     new Compressor(file.file, {
        //         ...COMPRESSOR_OPTIONS,
        //
        //         // The compression process is asynchronous,
        //         // which means you have to access the `result` in the `success` hook function.
        //         drew(context, canvas) {
        //             size.width = canvas.width;
        //             size.height = canvas.height;
        //         },
        //         success(result) {
        //             setState(s => ({files: {...s.files, [file.uuid]: {
        //                 ...s.files[file.uuid], status: 'complete', file: result,
        //                 valid: true, width: size.width, height: size.height
        //             }}}));
        //             count.i++;
        //             if (count.i >= length) fin();
        //         },
        //         error(err) {
        //             setState(s => ({files: {...s.files, [file.uuid]: {
        //                 ...files[file.uuid], status: 'edit', invalid: true, error: err.name, message: err.message
        //             }}}));
        //             console.log(err.message);
        //             count.i++;
        //             if (count.i >= length) fin();
        //         },
        //     });
        // }
    }
    render() {
        const { link: _link, className, tooltipAlignment, tooltipPosition, size, collapsed, onInsert, onRemove } = this.props;
        const { link, open, files } = this.state;

        const invalid = (link === _link) || !link || !link.length || (typeof link !== "string") || !(link.startsWith('https://') || link.startsWith('http://'));
        const attach = (collapsed && !_link);

        return <div className="xfb-attach">
            <Button className={'xfb-atc-btn ' + className} tooltipPosition={tooltipPosition} tooltipAlignment={tooltipAlignment}
                    hasIconOnly renderIcon={attach ? Attachment16 : Link16}
                    size={size} kind="ghost" isSelected={open}
                    iconDescription={attach ? "Insert Attachment" : "Insert Hyperlink"}
                    onClick={() => this.setState({open: !open})}
            />
            {open && <div className="xfb-atc-drop">
                {attach ? <>
                    <div className="xfb-adi-file">
                        <div className="bx--file__container">
                            <strong className="bx--file--label">Attach File</strong>
                            <p className="bx--label-description">Only image files are currently supported</p>
                            <FileUploaderDropContainer
                                accept={['.png', '.jpeg', '.jpg', '.gif', '.bmp', '.tiff', '.ico', '.svg', '.webp']}
                                labelText="Drag and drop files here or click to upload"
                                size="small" onAddFiles={(_,{addedFiles}) => this.handleAddFiles(addedFiles)}
                            />
                            {Object.entries(files).map(([uuid, file]) => <div className="xfb-adi-wait" key={uuid}>
                                <FileUploaderItem
                                    size="sm" uuid={uuid} iconDescription="Processing"
                                    invalid={!file.valid} name={file.name}
                                    errorSubject={file.error} errorBody={file.message} status={file.status}
                                    onDelete={() => this.setState(s => {
                                        const _files = {...s.files};
                                        delete _files[uuid];
                                        return {files: {..._files}};
                                    })}
                                />
                            </div>)}
                        </div>
                    </div>
                </> : <>
                    <div className="xfb-adi-link">
                        <TextInput
                            className="xfb-adi-link-input" id="xfb-adi-link" value={link ? link : ''} hideLabel
                            labelText="Link URL Address" placeholder="Please enter a URL to link to..."
                            onChange={c => this.setState({link: c.target.value})}
                        />
                    </div>
                    <div className="xfb-adi-act">
                        <Button className="xfb-adi-btn" kind="ghost" size="sm" disabled={!_link} onClick={() => _link && onRemove(_link)}>Unlink</Button>
                        <Button className="xfb-adi-btn" kind="ghost" size="sm" disabled={invalid} onClick={() => !invalid && onInsert(link)}>Link</Button>
                    </div>
                </>}
            </div>}
        </div>;
    }
}

export default DocBarAttach;